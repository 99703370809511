import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { addNotification, clearAllPerformanceNotifications } from "../../../api-utilities/notification-tools";
import { isNullOrEmpty } from "../../../utilities/helpers";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  targetVertex: string;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  dataContainer: any;
  quantityInHeight: number;
  quantityInWidth: number;
}
class Fan extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    dataContainer: null,
    quantityInHeight: 0,
    quantityInWidth: 0
  };

  async componentDidMount() {
    await this.getFanData(this.props.targetVertex);
  }

  //targetVertex will be passed in.
  //If user switches between different fans we need to fetch the new data
  async componentDidUpdate(prevProps: IProps) {
    if (prevProps.targetVertex != this.props.targetVertex) {
      await this.getFanData(this.props.targetVertex);
    }
  }

  private async getFanData(targetVertex: string) {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await callGetEndpoint(`readapi/GetFanInputValues/${targetVertex}`, projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError) {
      const notification = {
        id: "fanInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetFanInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    //NOTE: quantityInHeight and quantityInWidth are saved in state here and remain unchanged so that the user can see what the initial
    //      quantities were even when they change them.
    if (result.uiDataContainer && result.uiDataContainer.fanInputs) {
      this.setState({
        userInputs: result.uiDataContainer.fanInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        dataContainer: result.uiDataContainer, //contains all the extra data fields
        quantityInHeight: result.uiDataContainer.defaultFan.quantityInHeight,
        quantityInWidth: result.uiDataContainer.defaultFan.quantityInWidth
      });
    }
  }
  private async RunPerformance(targetVertex: string) {
    const { projectId, unitId } = this.props.match.params;

    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for fan and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);

    const result = await callGetEndpoint(`readapi/GetFanInputValues/${targetVertex}`, projectId, unitId);
    if (result.hasError) {
      const notification = {
        id: "fanInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetFanInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    //NOTE: quantityInHeight and quantityInWidth are saved in state here and remain unchanged so that the user can see what the initial
    //      quantities were even when they change them.
    if (result.uiDataContainer && result.uiDataContainer.fanInputs) {
      this.setState({
        userInputs: result.uiDataContainer.fanInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        dataContainer: result.uiDataContainer, //contains all the extra data fields
        quantityInHeight: result.uiDataContainer.defaultFan.quantityInHeight,
        quantityInWidth: result.uiDataContainer.defaultFan.quantityInWidth
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
    if (result.uiDataContainer.fanOptions.length === 0){
      const notification = {
        id: "fanInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">{"No fan options exist for the given inputs"}</div>
      };
      addNotification(notification);
    }
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;

    this.removeValidationError(fieldName);

    let result = await updateInputField("updateFan", projectId, unitId, fieldName, newValue, this.props.targetVertex);

    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fanInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  public async saveFanSelection(newValue: string) {
    let fieldName = "UserSelectedWheelSize";
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    clearAllPerformanceNotifications();
    let updateResult = await updateInputField("updateFan", projectId, unitId, fieldName, newValue, this.props.targetVertex);
    let perResponse = await updateUnitDesign(projectId, unitId);
    const result = await callGetEndpoint(`readapi/GetFanInputValues/${this.props.targetVertex}`, projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError) {
      const notification = {
        id: "fanInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetFanInputValues {result.data.errorMessage}</div>
      };
      addNotification(notification);
    }
    if (result.uiDataContainer && result.uiDataContainer.fanInputs) {
      //If there are any errors in the response, add them to state
      if (result.validationErrors != null) {
        this.addValidationErrors(result.validationErrors);
      }
      if (result.uiDataContainer && result.uiDataContainer.fanInputs) {
        this.setState({
          userInputs: result.uiDataContainer.fanInputs,
          displayFields: result.uiDataContainer.displayFields,
          dropdownOptions: result.dropdownOptions,
          validationErrors: result.validationErrors,
          dataContainer: result.uiDataContainer //contains all the extra data fields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error1");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------
  private async openFanCurveModal() {
    const routeParams = this.props.match.params;
    const projectId = routeParams.projectId;
    const unitId = routeParams.unitId;

    let selectedFan = "";
    if (this.props.targetVertex === "SupplyFanInputs") {
      selectedFan = "supply";
    }
    if (this.props.targetVertex === "ExhaustFanInputs") {
      selectedFan = "exhaust";
    }
    if (this.props.targetVertex === "ReturnFanInputs") {
      selectedFan = "return";
    }
    const modalData = { projectId: projectId, unitId: unitId, selectedFan: selectedFan };
    this.props.updateRedux("UPDATE_MODALS", {
      showModal: true,
      modalType: "FanCurveModal",
      modalData: modalData,
      hideCancelBtn: false
    });
  }

  private async openSoundDataModal() {
    const routeParams = this.props.match.params;
    const projectId = routeParams.projectId;
    const unitId = routeParams.unitId;

    let selectedFan = "";
    if (this.props.targetVertex === "SupplyFanInputs") {
      selectedFan = "supply";
    }
    if (this.props.targetVertex === "ExhaustFanInputs") {
      selectedFan = "exhaust";
    }
    if (this.props.targetVertex === "ReturnFanInputs") {
      selectedFan = "return";
    }
    const modalData = { projectId: projectId, unitId: unitId, selectedFan: selectedFan };
    this.props.updateRedux("UPDATE_MODALS", {
      showModal: true,
      modalType: "FanSoundDataModal",
      modalData: modalData,
      hideCancelBtn: false
    });
  }

  getCSSClass(modelNumber: string, selectedModelNumber: string) {
    return modelNumber == selectedModelNumber ? "acc-td-optional acc-td-selected" : "acc-td-optional";
  }
  getFanChoices(dataContainer: any) {
    const context = this;
    const options = dataContainer.fanOptions;
    const selectedFan = this.getSelectedFan(dataContainer);

    const selectedModelNumber = selectedFan ? selectedFan.modelNumber : 0;

    const arr = options.map(function (item: any, index: number) {
      return (
        <React.Fragment key={index}>
          <div className="acc-td2"></div>
          <div className={context.getCSSClass(item.modelNumber, selectedModelNumber)} onClick={() => context.saveFanSelection(item.fanDiameter)}>
            <div className="acc-data1">{item.modelNumber}</div>
            <div className="acc-data2">{formatNumber(item.fanRPM, 0)}</div>
            <div className="acc-data2">{formatNumber(item.motorRPM, 0)}</div>
            <div className="acc-data2">{item.hpPerFanLabel}</div>
            <div className="acc-data2">{formatNumber(item.staticEfficiencyPercent, 1)}%</div>
            <div className="acc-data2">{formatNumber(item.fei, 2)}</div>
            <div className="acc-data2">{formatNumber(item.arrayLwAOutputDB, 1)}</div>
          </div>
        </React.Fragment>
      );
    });
    return arr;
  }

  getSelectedFan(dataContainer: any) {
    const userInputs = this.state.userInputs;
    const options = dataContainer.fanOptions;
    //If the user has selected a fan and it matches one of the available options, return it
    if (!isNullOrEmpty(userInputs.userSelectedWheelSize)) {
      let selectedFan = options.find((x: any) => x.fanDiameter == userInputs.userSelectedWheelSize);
      if (selectedFan) {
        return selectedFan;
      }
    }

    //otherwise return the default one
    return options.find((x: any) => x.isDefaultOption);
  }

  public render() {
    let title = "";
    let dropDownOptionsNameWidth = "";
    let dropDownOptionsNameHeight = "";
    let dataContainer = this.state.dataContainer;

    if (!dataContainer) {
      return null;
    }

    let hasVav = this.state.displayFields.isVariableAirVolume;

    if (this.props.targetVertex == "SupplyFanInputs") {
      title = "Supply Fan";
      dropDownOptionsNameWidth = "supplyFanQuantityOptionsWidth";
      dropDownOptionsNameHeight = "supplyFanQuantityOptionsHeight";
    } else if (this.props.targetVertex === "ExhaustFanInputs") {
      title = "Exhaust Fan";
      dropDownOptionsNameWidth = "exhaustFanQuantityOptionsWidth";
      dropDownOptionsNameHeight = "exhaustFanQuantityOptionsHeight";
    } else if (this.props.targetVertex == "ReturnFanInputs") {
      title = "Return Fan";
      dropDownOptionsNameWidth = "returnFanQuantityOptionsWidth";
      dropDownOptionsNameHeight = "returnFanQuantityOptionsHeight";
    }

    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }
    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">{title}</legend>

        <div className="accordion-content">
          <div className="acc-fan-spacer"></div>
          <div className="acc-fan-spacer"></div>
          <div className="acc-fan-spacer"></div>

          <div className="accordion-details-btn accordion-btn2" onClick={() => this.openFanCurveModal()}>
            Fan Curves
          </div>
          <div className="accordion-details-btn" onClick={() => this.openSoundDataModal()}>
            Sound Data
          </div>

          {/* {getErrorNotifications(fan!.errorMessages)} */}

          <div className="accordion-row">
            <div className="accordion-label">Total Airflow</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(dataContainer.totalAirflow, 0)}</div>
            </div>
            {!hasVav ? (
              <div className="accordion-label">
                <u>Overrides</u>
              </div>
            ) : null}
            <div className="accordion-label-numeric"></div>
          </div>

          {hasVav ? (
            <div className="accordion-row">
              <div className="accordion-label">Minimum Airflow Turndown</div>
              <div className="accordion-input">
                <div className="accordion-label-numeric2">{dataContainer.minimumAirflowTurndown}</div>
              </div>
              <div className="accordion-label">
                <u>Overrides</u>
              </div>
              <div className="accordion-label-numeric"></div>
            </div>
          ) : null}

          <div className="accordion-row">
            <div className="accordion-label">Total Static Pressure</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(dataContainer.totalStaticPressure, 2)}</div>
            </div>
            <div className="accordion-input">
              <input
                type="text"
                className={this.getCSS("totalStaticPressure", "accordiontextbox")}
                value={formatNumber(savedValues.totalStaticPressure, 2)}
                onChange={(event) => this.updateTextboxValue("totalStaticPressure", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("totalStaticPressure", event.currentTarget.value)}
              />
              {this.getValidationError("totalStaticPressure")}
            </div>
            <div className="accordion-label-numeric"></div>
          </div>
            <div>
              <div className="accordion-row">
                <div className="accordion-label">Fan Quantity in Height</div>
                <div className="accordion-input">
                  <div className="accordion-label-numeric2">{this.state.quantityInHeight}</div>
                </div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("fanQuantityInHeight", "accordionselect")}
                    value={savedValues.fanQuantityInHeight || "Automatic"}
                    onChange={(event) => this.saveInputValue("fanQuantityInHeight", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, dropDownOptionsNameHeight)}
                  </select>
                  {this.getValidationError("fanQuantityInHeight")}
                </div>
                <div className="accordion-label-numeric"></div>
              </div>

              <div className="accordion-row">
                <div className="accordion-label">Fan Quantity in Width</div>
                <div className="accordion-input">
                  <div className="accordion-label-numeric2">{this.state.quantityInWidth}</div>
                </div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("fanQuantityInWidth", "accordionselect")}
                    value={savedValues.fanQuantityInWidth || "Automatic"}
                    onChange={(event) => this.saveInputValue("fanQuantityInWidth", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, dropDownOptionsNameWidth)}
                  </select>
                  {this.getValidationError("fanQuantityInWidth")}
                </div>
                <div className="accordion-label-numeric"></div>
              </div>
            </div>
          {/* )} */}
          <div className="accordion-row">
            <div className="accordion-label">Fan Model</div>
            <div className="accordion-input">
              <select
                className={this.getCSS("fanModelLine", "accordionselect")}
                value={savedValues.fanModelLine || ""}
                onChange={(event) => this.saveInputValue("fanModelLine", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "fanModelLineOptions")}
              </select>
            </div>
          </div>
          <div className="acc-table">
            <div className="acc-td1">
              <div className="acc-data1">
                <b>Model</b>
              </div>
              <div className="acc-data2">Fan RPM</div>
              <div className="acc-data2">Motor RPM</div>
              <div className="acc-data2">MHP Per Fan</div>
              <div className="acc-data2">Static Eff.</div>
              <div className="acc-data2">FEI</div>
              <div className="acc-data2">Array LwA Outlet dB</div>
            </div>
            <div className="acc-td2"></div>

            {this.getFanChoices(dataContainer)}
          </div>
          <div
            className={
              this.props.reduxMenus.optimizedDesignInputChanged
                ? "perf-reset-btn-small drawing-update-design-margin"
                : "perf-reset-btn-small-disabled drawing-update-design-margin"
            }
          >
            <div onClick={() => this.RunPerformance(this.props.targetVertex)}>Update Design</div>
          </div>
          <div className="accordion-row"></div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxValidation: state.reduxValidation,
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Fan);
