import { IAction, SortBy, ProjectStatusFilterBy } from '../../interfaces/interfaces';
//Default values 
let projectManagement:any = {
    projectList: [],
    projectId: '',
    selectedProject: null,
    unitList: [],
    newUnitTag: '',
    statusTypes: [],
    projectName: '',
    jobStatus:ProjectStatusFilterBy.Design,
    projectStatusFilterBy: ProjectStatusFilterBy.All,
    projectNameSortBy: SortBy.None,
    projectLastModifiedSortBy: SortBy.None,
    currentProjectList:[],
    totalProjectRecords: 0,
    projectRecordsPerPage: 10,
    currentPage: 1
}

export const reduxProjectManagement = (state =  projectManagement, action: IAction) => {
  if (action.type === 'UPDATE_PROJECT_MANAGEMENT') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};