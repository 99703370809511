import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, match } from 'react-router-dom'; //needed for 'location' object and 'match.params'
import { mapDispatchToProps } from '../../redux/actions';
import { Link } from 'react-router-dom';
import '../../css/DesignSubMenu.scss';

// expected props
interface IProps {
  location: any;
  match: match<any>;
}
// local state
interface IState {}

class LayoutSubMenu extends React.Component<IProps, IState> {
  private createMenuLink(urlFragment: string, label: string) {
    //Make this the active(underlined) link if it matches the current route
    const isActive = this.isCurrentRoute(urlFragment);
    const baseUrl = this.getBaseUrl();
    return (
      <Link to={baseUrl + urlFragment}>
        <div className={`design-menu-link ${isActive ? ' item-selected' : ''}`}>{label}</div>
      </Link>
    );
  }

  private isCurrentRoute(urlFragment: string) {
    return this.props.location.pathname.includes(urlFragment);
  }
  private getBaseUrl() {
    const routeParams = this.props.match.params;
    return '/pid/' + routeParams.projectId + '/uid/' + routeParams.unitId;
  }
  private getNextButton() {
    let destination = '';
    if (this.isCurrentRoute('/layout/doors')) {
      destination = '/layout/coil';
    }
    if (this.isCurrentRoute('/layout/coil')) {
      destination = '/layout/panels';
    }
    if (this.isCurrentRoute('/layout/panels')) {
      destination = '/layout/length';
    }
    if (this.isCurrentRoute('/layout/length')) {
      destination = '/layout/sizing';
    }
    if (this.isCurrentRoute('/layout/sizing')) {
      destination = '/spec/controls';
    }
    const baseUrl = this.getBaseUrl();

    return (
      <Link to={baseUrl + destination}>
        <div className="design-next-btn">Next &gt;</div>
      </Link>
    );
  }

  public render() {
    return (
      <div className="design-submenu">
        <div className="design-spacer"></div>
        {this.createMenuLink('/layout/doors', 'Access Doors')}
        {this.createMenuLink('/layout/coil', 'Coil Connections')}
        {this.createMenuLink('/layout/panels', 'Layout Adjustments')}
        {this.createMenuLink('/layout/length', 'Length Inputs')}
        {this.createMenuLink('/layout/sizing', 'Inlet/Outlet Sizing')}
        {this.getNextButton()}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutSubMenu));
