import * as React from "react";
import { store } from "../../redux/store";
import { SortBy, ProjectStatusFilterBy } from '../../interfaces/interfaces';
import { Link } from "react-router-dom";
import {
  getUnits,
  addNewProject,
  getProjectsByUser,
  deleteProjectAndUnits,
  updateProject,
  copyUnit,
  copyProject,
  addUnit,
  getProject,
  deleteUnit
} from "../../api-calls/projectDataService";
import { displayError } from "../../api-utilities/api-calls";
import { isEmptyArray, sqlToJsDate } from "../../utilities/helpers";
import { updateReduxAction } from "../../redux/actions";
import { getNumericId } from "../../api-utilities/formatting";
import { cloneDeep } from "lodash";
//This file contains methods that pertain to the ProjectManagement component.

//Calls Project service when user hits "Add New" project button.
export async function AddNewProject() {
  const reduxProjectManagement = store.getState().reduxProjectManagement;
  const projectName = reduxProjectManagement.projectName;

  if (projectName !== "") {
    store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { projectName: ""}));

    //This adds a project to the database and returns a ProjectDTO object with the new id.
    const response = await addNewProject(reduxProjectManagement);
    console.log("-------Response from addNewProject --->");
    console.log(response);
    if (response.success) {
      const projectList = response.data.projectList;

      //refresh the project list
      await getProjectList();

      console.log("------- getProjectList --->");
      console.log(projectList);

      //Select the newly created project row & show the units.
      if (!isEmptyArray(projectList)) {
        selectProject(projectList[0]); //should only be 1 item in the list
      }
    } else {
      const error = { status: 500, message: "Add Project failed. Please try again." };
      displayError(error, "addNewProject");
    }
  }
}
export async function updateEditedProject() {
  //Will update project data as edited by the user.
  const reduxProjectManagement = store.getState().reduxProjectManagement;
  const updatedProjectName = reduxProjectManagement.projectName;
  const selectedProject = reduxProjectManagement.selectedProject;
  if (updatedProjectName !== "") {
    //Re-set project name in redux for the next edit or add new project.
    store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { projectName: "" }));

    //Call the project data service method to update project data.
    const response = await updateProject(selectedProject, reduxProjectManagement);
    
    if (response.success) {
      //refresh the project list
      await getProjectList();
      
      //Select the updated project so the project bar gets updated if the name was edited.
      selectProject(selectedProject);

      //Go back to the page that the edit was on.  
      NewPageSelected(reduxProjectManagement.currentPage);

      
    } else {
      const error = { status: 500, message: "Edit Project failed. Please try again." };
      displayError(error, "updateEditedProject");
    }
  }
}
//Get list of projects filtered by the search text.
// export async function searchProjects(statusTypeId: number, searchText: string) {
//   const response = await getProjectsBySearchText(statusTypeId, searchText);
//   const list = response.projectList;
//   store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { projectList: list }));
// }

// export async function searchProjectsById(projectId: string) {
//   const response = await getProjectsById(projectId);
//   const list = response.projectList;
//   store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { projectList: list }));
// }
//Get List of Projects from server and store in state
export async function getProjectList() {
  const response = await getProjectsByUser();
  if (response) {
    store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { unitList: [], selectedProject: null, projectStatusFilterBy: ProjectStatusFilterBy.All}));
    SetupPaginationWithNewProjectList(response.projectList, SortBy.None, SortBy.None);
  }
}

//Get list of Units when user clicks a project.
export async function selectProject(project: any) {
  //let projectId = getNumericId(project.id);

  const response = await getUnits(project.id);
  const unitList = response.unitList != null ? response.unitList : [];

  store.dispatch(
    updateReduxAction("UPDATE_PROJECT_MANAGEMENT", {
      selectedProject: project,
      unitList: unitList,
      newUnitTag: ""
    })
  );
  return;
}

export async function addNewUnit() {
  //New units created here have no tag yet.
  const reduxProjectManagement = store.getState().reduxProjectManagement;
  const projectIdString = reduxProjectManagement.selectedProject.id;

  //Get the number part of the string ("proj-342") because we return that for routing in the URL
  let projectId = getNumericId(projectIdString);

  //Response object to return from this method.
  let response = { success: false, projectId: projectId, newUnitId: 0 };

  //GraphAPI endpoints use the full string version of the Id (ex: "proj-342", "unit-351")
  const addUnitResult = await addUnit(projectIdString);

  //If addUnit was successful, add new UnitId to the response
  if (addUnitResult.success && !addUnitResult.data.hasError) {
    const unit = addUnitResult.data.unit;

    if (unit.id !== null) {
      let unitId = getNumericId(unit.id);

      response.success = true;
      response.newUnitId = unitId;
      response.projectId = projectId;
      return response;
    }
  }

  const error = { status: 500, message: "Add Unit failed. Please try again." };
  displayError(error, "addNewProject");
  return response;
}

//Get options for the Project Status dropdown
export function getStatusOptions() {
  const reduxProjectManagement = store.getState().reduxProjectManagement;
  const statusOptions = reduxProjectManagement.statusTypes;
  if (!isEmptyArray(statusOptions)) {
    return statusOptions.map((item: any, index: number) => (
      <option key={index} value={item.numericValue}>
        {item.displayValue}
      </option>
    ));
  }
  return null;
}

//Delete project and its associated units
export async function deleteProject(projectId: string) {
  const reduxProjectManagement = store.getState().reduxProjectManagement;

  //Call server to delete this project and its units
  if (projectId) {
    store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: true }));
    const response = await deleteProjectAndUnits(projectId);
    if (response.success) {
      //refresh the project list
      await getProjectList();

      //Clear the selected unit list
      store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { selectedProject: null }));
    }
    store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: false }));
  }
}

export async function deleteUnitById(unitId: string) {
  const reduxProjectManagement = store.getState().reduxProjectManagement;
  const projectId = reduxProjectManagement.selectedProject.id;
  //Call server to delete this unit.
  if (unitId) {
    store.dispatch(updateReduxAction("UPDATE_DISPLAY", { unitToDelete: unitId }));
    const response = await deleteUnit(projectId, unitId);
    if (response.success) {
      //refresh the unit list so the deleted unit is removed.
      const getUnitsResponse = await getUnits(projectId);
      const unitList = getUnitsResponse.unitList;
      store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT", { unitList: unitList, newUnitTag: "" }));
    }
    store.dispatch(updateReduxAction("UPDATE_DISPLAY", { unitToDelete: "" }));
  }
}

//Build table rows from the project list in state
export function getProjectRows() {
  //NOTE: don't move reduxProjectManagement into state.  Several components use it.
  const reduxProjectManagement = store.getState().reduxProjectManagement;
  const selectedProjectId = reduxProjectManagement.selectedProject ? reduxProjectManagement.selectedProject.id : "";
//proj-tr-active
  if (!isEmptyArray(reduxProjectManagement.currentProjectList)) {
    return reduxProjectManagement.currentProjectList.map((item: any, index: number) => (
     selectedProjectId === item.id ? (
        <div className="proj-group proj-top proj-tr-active" key={index}>
        <div className="proj-grid-row1 proj-group-top">
            <div className="">{item.projectName}</div>
            <div className="">{item.jobStatus}</div>
            <div className="">{sqlToJsDate(item.lastModifiedDate)}</div>
         </div>   
          <div className="proj-grid-row2 proj-group-bottom">
            <div></div>
            <div className="proj-link" onClick={(event) => editProject(event, item)}>
              edit
            </div>
            <div className="proj-link" onClick={(event) => openProjectCopyModal(event, item)}>
              copy
            </div>
            <div className="proj-link" onClick={(event) => deleteProjectPrompt(event, item)}>
              delete
            </div>
            {showOutputLink() === true ? (
              <Link to={getOutputDocsUrl()}>
                <div className="proj-link">
                  outputs
                </div>
              </Link>
              )
            :
              (
                <div className="outputs-link-disabled">
                  outputs
                </div>
              )
            }
            <div></div>
          </div>
          </div>
      ) : (
          <div key={index} className="proj-single proj-top">
            <div className="proj-grid-row1 proj-row " onClick={() => selectProject(item)}>
              <div className="">{item.projectName}</div>
              <div className="">{item.jobStatus}</div>
              <div className="">{sqlToJsDate(item.lastModifiedDate)}</div>
          </div>
         </div>   
      
      )
    ));
  } else {
    return <div className="proj-no-results">No Results Found</div>;
  }
}
function showOutputLink(){
  const units = store.getState().reduxProjectManagement.unitList;
  if (units && units.length > 0){
    return true;
  }
  return false;
}
function getOutputDocsUrl() {
  const pm = store.getState().reduxProjectManagement;

  //Don't really need the unit number.  Pass only the unit number.  Comes in as example unit-3715.
  const unitId = pm.unitList[0].id.split('-')[1];
  //pass only the project number.  Comes in as example proj-415
  const projectId = pm.selectedProject.id.split('-')[1];

  return "/pid/" + projectId + "/uid/" + unitId + "/outputs/pdfoutputs";
}
//Open modal window to edit Project details
export async function editProject(event: React.SyntheticEvent<HTMLDivElement>, project: any) {
  //User clicked edit link on project bar.  Don't fire the click event attached to that bar.
  event.stopPropagation();
  await selectProject(project);
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: true,
      modalType: "ProjectModal",
      modalData: "",
      modalId: "edit-project"
    })
  );
}
//Launch modal to prompt user about deleting a project
export async function deleteProjectPrompt(event: React.SyntheticEvent<HTMLDivElement>, project: any) {
  //User clicked Delete link on project bar.  Don't fire the click event attached to that bar.
  event.stopPropagation();
  const modalData = {
    title: "Warning",
    content: <div>{"Delete project " + project.projectName + " and all its units?"}</div>,
    projectIdToDelete: project.id
  };
  //componentDidUpdate method on ProjectManagment.tsx responds to this event
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: true,
      modalType: "ModalPopup",
      modalId: "delete-project",
      modalData: modalData,
      hideCancelBtn: false,
      userResponse: ""
    })
  );
}
export async function deleteUnitPrompt(event: React.SyntheticEvent<HTMLDivElement>, unit: any) {
  //User clicked Delete link on unit band.  Don't fire the click event attached to that bar.
  event.stopPropagation();

  const modalData = {
    title: "Warning",
    content: <div>{"Delete unit " + unit.unitTag + "?"}</div>,
    unitToDelete: unit.id
  };
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: true,
      modalType: "ModalPopup",
      modalId: "delete-unit",
      modalData: modalData,
      hideCancelBtn: false,
      userResponse: ""
    })
  );
}
export function openUnitCopyModal(event: React.SyntheticEvent<HTMLDivElement>, unit: any) {
  //User clicked Copy link on unit band.  Don't fire the click event attached to that bar.
  event.stopPropagation();
  //This triggers ModalContainer.tsx to open the modal
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: true,
      modalType: "UnitCopyModal",
      modalData: unit,
      modalId: "copy-unit"
    })
  );
}
export function openProjectCopyModal(event: React.SyntheticEvent<HTMLDivElement>, project: any) {
  //User clicked Copy link on unit bar.  Don't fire the click event attached to that bar.
  event.stopPropagation();
  //This triggers ModalContainer.tsx to open the modal
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: true,
      modalType: "ProjectCopyModal",
      modalData: project,
      modalId: "copy-project"
    })
  );
}
export function openProjectModal() {
  //This triggers ModalContainer.tsx to open the modal
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: true,
      modalType: "ProjectModal",
      modalData: "",
      modalId: "add-new-project"
    })
  );
}
export async function copyUnitToProject(modalData: any) {
  store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: true }));
  let response = await copyUnit(modalData.sourceProjectId, modalData.targetProjectId, modalData.unitTagName, modalData.unitId);
  store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: false }));

  //handle failed network or api call
  if (response.success === false) {
    const error = {
      status: 500,
      message: response.data
    };
    displayError(error, "Copy Unit");
  }
  //handle api logic error
  if (response.data.hasError) {
    const error = {
      status: 500,
      message: response.data.errorMessage
    };
    displayError(error, "Copy Unit");
  }
  //select the project that we just added a unit to (so its unit list shows up with the new item)
  const projectList = store.getState().reduxProjectManagement.projectList;
  let targetProject = projectList.find((x: any) => x.id === modalData.targetProjectId);
  await selectProject(targetProject);
}

export async function copyProjectWithUnits(userResponse: any) {
  store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: true }));

  let response = await copyProject(userResponse.projectId, userResponse.projectName);
  store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: false }));

  //handle failed network or api call
  if (response.success === false) {
    const error = {
      status: 500,
      message: response.data
    };
    displayError(error, "Copy Project");
  }
  //handle api logic error
  if (response.data.hasError) {
    const error = {
      status: 500,
      message: response.data.errorMessage
    };
    displayError(error, "Copy Project");
  }
}
export function sortByJobName(sortby: SortBy){
  const projectList = store.getState().reduxProjectManagement.projectList;
  let clonedList = cloneDeep(projectList);

  if (sortby === SortBy.ASC){
    clonedList.sort(compareASC);
  }
  else if (sortby === SortBy.DESC){
    clonedList.sort(compareDESC);  
  }
  SetupPaginationWithNewProjectList(clonedList, sortby, SortBy.None);
  clonedList = null;
}
export function sortByLastModified(sortby: SortBy){
  const projectList = store.getState().reduxProjectManagement.projectList;
  let clonedList = cloneDeep(projectList);

  if (sortby === SortBy.ASC){
    clonedList.sort(compareDateASC);
  }
  else if (sortby === SortBy.DESC){
    clonedList.sort(compareDateDESC);  
  }
  SetupPaginationWithNewProjectList(clonedList, SortBy.None, sortby);
  clonedList = null;
}
function compareASC(a:any, b:any){
  let aName, bName;

  if (a.hasOwnProperty('projectName')){
    aName = a.projectName.toUpperCase();
    bName = b.projectName.toUpperCase();
  }
  if (aName < bName){
    return -1;
  }
  if (aName > bName){
    return 1;
  }
  return 0;
}
function compareDESC(a:any, b:any){
  let aName, bName;

  if (a.hasOwnProperty('projectName')){
    aName = a.projectName.toUpperCase();
    bName = b.projectName.toUpperCase();
  }
  if (aName < bName){
    return 1;
  }
  if (aName > bName){
    return -1;
  }
  return 0;
}
function compareDateASC(a:any, b:any){
  let aName, bName;
  if (a.hasOwnProperty('lastModifiedDate')){
    aName = a.lastModifiedDate;
    bName = b.lastModifiedDate;
  }
  if (aName < bName){
    return -1;
  }
  if (aName > bName){
    return 1;
  }
  return 0;
}
function compareDateDESC(a:any, b:any){
  let aName, bName;
  if (a.hasOwnProperty('lastModifiedDate')){
    aName = a.lastModifiedDate;
    bName = b.lastModifiedDate;
  }
  if (aName < bName){
    return 1;
  }
  if (aName > bName){
    return -1;
  }
  return 0;
}
export function SetupPaginationWithNewProjectList(projectList:any, projectNameSort:any, projectLastModifiedSort:any){
  const totalRecords = projectList && projectList.length > 0 ? projectList.length : 0;
  // Records to be displayed on the current page
  const currentRecords = projectList.slice(0, 10);
  store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT",  
    { projectList: projectList, projectNameSortBy: projectNameSort, projectLastModifiedSortBy: projectLastModifiedSort, totalProjectRecords: totalRecords,
      currentProjectList: currentRecords, currentPage: 1}));
  
  //If no projects are returned, then clear the unit list and set selectedProject to null.
  if (projectList && projectList.length === 0){
    store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT",  
    { unitList: [], selectedProject:null}));
  }
}
//Called from the paging links at the bottom of the paging control.
export function NewPageSelected(page:string){
  const pm = store.getState().reduxProjectManagement;
  let currentRecords:any[] = []
  let newCurrentPage = 0;
  let beginIndex = 0;
  let endIndex = 0;

  if (page === "prev"){//0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22
    newCurrentPage = pm.currentPage - 1;
  }
  else if (page === "next"){
    newCurrentPage = pm.currentPage + 1;
  }
  else {
    newCurrentPage = Number(page);
  }

  beginIndex = newCurrentPage * pm.projectRecordsPerPage - 10;
  endIndex = beginIndex + 10;
  currentRecords = pm.projectList.slice(beginIndex, endIndex);

  store.dispatch(updateReduxAction("UPDATE_PROJECT_MANAGEMENT",  { currentProjectList: currentRecords, currentPage: newCurrentPage}));
}