import * as React from 'react';

//=========== React "Context" ==============
//https://reactjs.org/docs/context.html

//This gives us a way to create a "singleton" instance of the IdentityService class and
//use it in any of our components.  We don't want to create a new instance of the IdentityService 
//class in our components because it creates event listeners.  We don't want multiple copies of those
//event listeners running. 

//So we create one instance of the IdentityService class in App.tsx and add it to this global "context"
//provided by React.  This prevents us from having to pass it down manually at every level through props.

//We can't use redux for this either because you can't store an instance of a class in redux.  We'll store
//the logged-in userId in redux, but thats about it.  Anytime we want to use any of the methods in the
//IdentityService class, we just use this singleton.

const IdentityContext = React.createContext({});
export default IdentityContext;
