import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { getInletOutletValues, updateInputField, callPostEndpoint } from "../../api-calls/readApiService";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getValidationError, getInputCSS, getNewDropdownList, getNewValidationErrorsList } from "../../utilities/pageUtils";
import "../../css/ConnectionsOptions.scss";

// expected props
interface IProps {
  match: match<any>;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  connectionSvg: string;
}

class InletOutlet extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    connectionSvg: ""
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await getInletOutletValues(projectId, unitId);

    if (result.hasError){
      const notification = {
        id: "inletOutletInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">getInletOutletValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    this.setState({
      userInputs: result.uiDataContainer.primaryInputs,
      displayFields: result.uiDataContainer.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors
    });
    this.getConnectionSchematic(result.uiDataContainer.primaryInputs);
  }

  private async getConnectionSchematic(primaryInputs: any) {
    if (primaryInputs != null) {
      let result = await callPostEndpoint("readapi/GetConnectionSchematicSvg", primaryInputs);
      if (result.success) {
        this.setState({ connectionSvg: result.data.schematicSvg });
      }
    }
    // if (projectId != null && unitId != null) {
    //   let svgData = await getConnectionSchematicSvg(projectId, unitId);
    //   if (!svgData.hasError) {
    //     this.setState({ connectionSvg: svgData.schematicSvg });
    //   }
    // }
  }

  public getSelectBoxOptions(boxName: any) {
    const options = (this.state.dropdownOptions as any)[boxName];
    return options.map((item: any, index: number) => (
      <option key={index} value={item.textValue}>
        {item.displayValue}
      </option>
    ));
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateInletOutlet", projectId, unitId, fieldName, newValue, "PrimaryInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.primaryInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
        this.getConnectionSchematic(result.data.uiDataContainer.primaryInputs);
      }
    }
  }

  //Whenever we update a field, we'll remove any previous error, and reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let errorsArray = [...this.state.validationErrors];

    //Make a new array that only includes items not matching the given fieldName
    let errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== fieldName.toLowerCase());

    //Exhaust and Outside air inlets/outlets cannot share the same location if both types are hoods or louvers.
    //This error can be triggered by 4 different inputs:outsideairinletlocation, exhaustairoutletlocation, outsideairinlet, exhaustairoutlet
    //If we clear the error for 1 of those fields, we need to clear it for the other 3.
    //----- Special case for outsideairinletlocation, exhaustairoutletlocation -----
    if (fieldName.toLowerCase() === "outsideairinletlocation" || fieldName.toLowerCase() === "exhaustairoutletlocation") {
      errors = errors.filter(
        (item: any) => item.fieldName.toLowerCase() !== "exhaustairoutletlocation" && item.fieldName.toLowerCase() !== "outsideairinletlocation"
      );
    }
    //Return and Outside air inlets/outlets cannot share the same location for ARU units that have mixing box as the airHandlerType.
    if (fieldName.toLowerCase() === "outsideairinletlocation" || fieldName.toLowerCase() === "returnairinletlocation") {
      errors = errors.filter(
        (item: any) => item.fieldName.toLowerCase() !== "returnairinletlocation" && item.fieldName.toLowerCase() !== "outsideairinletlocation"
      );
    }

    //----- Special case for outsideairinlet, exhaustairoutlet -----
    if (fieldName.toLowerCase() === "outsideairinlet" || fieldName.toLowerCase() === "exhaustairoutlet") {
      errors = errors.filter(
        (item: any) => item.fieldName.toLowerCase() !== "outsideairinletlocation" && item.fieldName.toLowerCase() !== "exhaustairoutletlocation"
      );
    }
    this.setState({ validationErrors: errors, lastUpdatedTextbox: "" });
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    let cssClass = "validation-err-right";
    if (fieldName === "outsideAirInletLocation" || fieldName === "supplyAirOutletLocation" || fieldName === "returnAirInletLocation" || fieldName === "exhaustAirOutletLocation"){
      cssClass = "validation-err-right-inlet-outlet";
    }
    return getValidationError(this.state.validationErrors, fieldName, cssClass);
  }

  public render() {
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;
    if (!savedValues) {
      return null;
    }

    return (
      <div className="connect-inputs-main">
        <div className="connect-input-container">
          <div className="connect-fields-left">
            <div className="input-heading">Air Inlet and Outlet</div>

            <div className="input-fields">
              <div className="connect-input-label"></div>
              <div className="connect-input-label2">Type</div>
              <div className="connect-input-label2">Location</div>
            </div>

            <div className="input-fields">
              <div className="inletOutlet-row-display">
                <div className="connect-input-label">Outside Air Inlet</div>
                <div className="connect-input">
                  <select
                    className={this.getCSS("outsideAirInlet", "selectbox")}
                    value={savedValues.outsideAirInlet}
                    onChange={(event) => this.saveInputValue("outsideAirInlet", event.currentTarget.value)}
                    disabled={displayFields.outsideAndExhaustOpeningDisabled}
                  >
                    {this.getSelectBoxOptions("outsideAirInletOptions")}
                  </select>
                  {this.getValidationError("outsideAirInlet")}
                </div>
                <div className="connect-input2">
                  <select
                    className={this.getCSS("outsideAirInletLocation", "selectbox")}
                    value={savedValues.outsideAirInletLocation}
                    onChange={(event) => this.saveInputValue("outsideAirInletLocation", event.currentTarget.value)}
                  >
                    {this.getSelectBoxOptions("outsideAirInletLocationOptions")}
                  </select>
                  <div className="inletOutlet-row-error">{this.getValidationError("outsideAirInletLocation")}</div>
                </div>
              </div>
              <div className="inletOutlet-row-display">
                <div className="connect-input-label">Supply Air Outlet</div>
                <div className="connect-input-label3">Duct</div>
                <div className="connect-input2">
                  <select
                    className={this.getCSS("supplyAirOutletLocation", "selectbox")}
                    value={savedValues.supplyAirOutletLocation}
                    onChange={(event) => this.saveInputValue("supplyAirOutletLocation", event.currentTarget.value)}
                  >
                    {this.getSelectBoxOptions("supplyAirOutletLocationOptions")}
                  </select>
                  <div className="inletOutlet-row-error">{this.getValidationError("supplyAirOutletLocation")}</div>
                </div>
              </div>
              {displayFields.showReturnAirInlet ? (
                <div className="inletOutlet-row-display">
                  <div className="connect-input-label">Return Air Inlet</div>
                  <div className="connect-input-label3">Duct</div>
                  <div className="connect-input2">
                    <select
                      className={this.getCSS("returnAirInletLocation", "selectbox")}
                      value={savedValues.returnAirInletLocation}
                      onChange={(event) => this.saveInputValue("returnAirInletLocation", event.currentTarget.value)}
                    >
                      {this.getSelectBoxOptions("returnAirInletLocationOptions")}
                    </select>
                    <div className="inletOutlet-row-error">{this.getValidationError("returnAirInletLocation")}</div>
                  </div>
                </div>
              ) : null}
              {displayFields.showExhaustAirOutlet ? (
                <div className="inletOutlet-row-display">
                  <div className="connect-input-label">Exhaust Air Outlet</div>
                  <div className="connect-input">
                    <select
                      className={this.getCSS("exhaustAirOutlet", "selectbox")}
                      value={savedValues.exhaustAirOutlet}
                      onChange={(event) => this.saveInputValue("exhaustAirOutlet", event.currentTarget.value)}
                      disabled={displayFields.outsideAndExhaustOpeningDisabled}
                    >
                      {this.getSelectBoxOptions("exhaustAirOutletOptions")}
                    </select>
                    {this.getValidationError("exhaustAirOutlet")}
                  </div>
                  <div className="connect-input2">
                    <select
                      className={this.getCSS("exhaustAirOutletLocation", "selectbox")}
                      value={savedValues.exhaustAirOutletLocation}
                      onChange={(event) => this.saveInputValue("exhaustAirOutletLocation", event.currentTarget.value)}
                    >
                      {this.getSelectBoxOptions("exhaustAirOutletLocationOptions")}
                    </select>
                    <div className="inletOutlet-row-error">{this.getValidationError("exhaustAirOutletLocation")}</div>
                  </div>
                </div>
              ) : null}
            </div>

            {displayFields.showVestibule ? (
              <>
                <div className="input-heading">Vestibule</div>
                <div className="input-fields">
                  <div>
                    <div className="connect-input-label">Unit Position</div>
                    <div className="connect-input">
                      <select
                        className={this.getCSS("outsideAirInlet", "selectbox")}
                        value={savedValues.outsideAirInlet}
                        onChange={(event) => this.saveInputValue("outsideAirInlet", event.currentTarget.value)}
                      >
                        {this.getSelectBoxOptions("outsideAirInlet")}
                      </select>
                      {this.getValidationError("outsideAirInlet")}
                    </div>
                  </div>
                  <div>
                    <div className="connect-input-label">Depth</div>
                    <div className="input-textbox">
                      <input
                        type="text"
                        className={this.getCSS("outsideAirInlet", "textbox")}
                        value={savedValues.outsideAirInlet}
                        onChange={(event) => this.updateTextboxValue("outsideAirInlet", event.currentTarget.value)}
                        onBlur={(event) => this.saveTextboxValue("outsideAirInlet", event.currentTarget.value)}
                      />
                      {this.getValidationError("outsideAirInlet")}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="connect-input-container">
          <div className="connect-drawing-spacer"></div>

          <div className="connect-drawing-grid">
            {this.state.connectionSvg ? <div dangerouslySetInnerHTML={{ __html: this.state.connectionSvg }} /> : null}

            <div className="connect-drawing-label">Elevation View</div>
          </div>
        </div>

        {/* <div className="connect-divider"></div> */}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(InletOutlet);
