import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import EnthalpyWheel from "./Construction/Accordions/EnthalpyWheel";
import Coils from "./Construction/Accordions/Coils";
import IDF from "./Construction/Accordions/IDF";
import Refrigeration from "./Construction/Accordions/Refrigeration";
import Filters from "./Construction/Accordions/FiltersNotUsed";
import FilterSpares from "./Construction/Accordions/FilterSpares";
import Fans from "./Construction/Accordions/Fans";
import Dampers from "./Construction/Accordions/Dampers";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getComponentList } from "../../api-calls/readApiService";
import "../../css/SpecItems.scss";
// expected props
interface IProps {
  updateRedux: any;
  reduxMenus: any;
  match: match<any>;
}
// local state
interface IState {
  bodyContentClass: string;
  componentList: any;
}

class Construction extends React.Component<IProps, IState> {
  public state: IState = {
    bodyContentClass: "spec-body-content",
    componentList: null
  };
  async componentDidMount() {
    //await getComponentConstruction(this.props.match.params);
    //need to wait for new data to determine accordian width.
    //this.adjustAccordianWidth();
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await getComponentList(projectId, unitId);
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });

    if (result.hasError){
      const notification = {
        id: "componentsListError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetComponentsList {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result) {
      this.setState({ componentList: result });
    }
  }
  //Check all the coil objects on the Component Construction screen
  anyCoilsExist(componentList: any) {
    if (
      componentList.hasHotWaterCoil ||
      componentList.hasPreheatHotWaterCoil ||
      componentList.hasChilledWaterCoil ||
      componentList.hasSteamCoil ||
      componentList.hasPackagedRefrigeration || //this contains DX and HGRH coil inputs
      componentList.hasDxSplit
    ) {
      return true;
    }
    return false;
  }

  //Check all the filter objects on the Component Construction screen
  anyFiltersExist(componentList: any) {
    if (
      componentList.hasOutsideAirPrefilter ||
      componentList.hasReturnAirFilter ||
      componentList.hasSupplyAirFinalfilter ||
      componentList.hasOutsideAirHighEfficiencyFilter
    ) {
      return true;
    }
    return false;
  }

  //Check all the fan objects on the Component Construction screen
  anyFansExist(componentList: any) {
    if (componentList.hasSupplyFan || componentList.hasReturnFan || componentList.hasExhaustFan) {
      return true;
    }
    return false;
  }
  public render() {
    const componentList = this.state.componentList;
    if (!componentList) {
      return null;
    }

    return (
      <div className={this.state.bodyContentClass}>
        <div className="spec-field-spacer2"></div>
        {componentList.hasHeatWheel ? <EnthalpyWheel match={this.props.match} /> : null}
        {this.anyCoilsExist(componentList) ? <Coils title="Coils" match={this.props.match} /> : null}
        {componentList.hasIdf ? <IDF match={this.props.match} /> : null}
        {componentList.hasPackagedRefrigeration ? <Refrigeration match={this.props.match} /> : null}
        {this.anyFiltersExist(componentList) ? <FilterSpares match={this.props.match} /> : null}
        {this.anyFansExist(componentList) ? <Fans match={this.props.match} /> : null}
        {componentList.hasDampers ? <Dampers match={this.props.match} /> : null}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Construction);
