import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter, matchPath } from "react-router-dom"; //withRouter needed for location & history properties (see readMe)
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { runFullUnitStructureRebuild, setReCalcLevel } from "../../api-calls/readApiService";
import IdentityContext from "../../security/IdentityContext";
import "../../css/Menus.scss";

// expected props
interface IProps {
  updateRedux: any;
  reduxMenus: any;
  reduxAuth: any;
  history: any;
  location: any;
  reduxOutputDocumentsInfo: any;
}
// local state
interface IState {
  iconDesc: string;
  saveInProgress: boolean;
  hasRepRole: boolean;
}

class TopRightMenu extends React.Component<IProps, IState> {
  //Connect to global IdentityContext (https://reactjs.org/docs/context.html)
  static contextType = IdentityContext;
  private readonly identityService = this.context;

  public state: IState = { iconDesc: "", saveInProgress: false, hasRepRole: false };

  async componentDidMount() {
    this.checkForRepRole();
  }

  async componentDidUpdate(prevProps: any) {
    if (prevProps.reduxAuth.userId != this.props.reduxAuth.userId) {
      //####### User has changed #########
      this.checkForRepRole();
    }
  }

  async checkForRepRole() {
    //Get roles from the user object, and check for the Rep role
    const user = await this.identityService.getUser();
    const rolesArray = await this.identityService.getUserRoles(user);
    let hasRepRole = rolesArray.includes("rep");
    this.setState({ hasRepRole: hasRepRole });
  }

  private openModal(modalTitle: string) {
    const modalData = { title: modalTitle };
    this.props.updateRedux("UPDATE_MODALS", {
      showModal: true,
      modalType: "ModalPopup",
      modalData: modalData,
      hideCancelBtn: false
    });
  }
  //Save in case we bring back dropdown menu
  // private showDropMenu(menu: number, state: boolean) {
  //   if (menu === 1) {
  //     this.props.updateRedux('UPDATE_MENUS', { showTopRightDropdown1: state });
  //   } else {
  //     this.props.updateRedux('UPDATE_MENUS', { showTopRightDropdown2: state });
  //   }
  // }
  private async saveUnit() {
    const match = matchPath(this.props.history.location.pathname, {
      path: "/pid/:projectId/uid/:unitId",
      exact: false,
      strict: false
    });
    if (match) {
      const { projectId, unitId } = match.params as any;
      if (this.props.reduxMenus.enableSave) {
        this.setState({ saveInProgress: true });
        this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
        await runFullUnitStructureRebuild("proj-" + projectId, "unit-" + unitId);
        this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
        this.setState({ saveInProgress: false, iconDesc: "" });
      }
    }
  }
  // private pricingOutput() {
  //   const match = matchPath(this.props.history.location.pathname, {
  //     path: '/pid/:projectId/uid/:unitId',
  //     exact: false,
  //     strict: false
  //   });
  //   if (match) {
  //     const { projectId, unitId } = match.params as any;
  //     this.props.history.push('/pid/' + projectId + '/uid/' + unitId + '/pricing');
  //   }
  // }
  // private printOutput() {
  //   const match = matchPath(this.props.history.location.pathname, {
  //     path: "/pid/:projectId/uid/:unitId",
  //     exact: false,
  //     strict: false
  //   });
  //   if (match) {
  //     const { projectId, unitId } = match.params as any;
  //     this.props.history.push("/pid/" + projectId + "/uid/" + unitId + "/outputs/pdfoutputs");
  //   }
  // }
  showIconDesc(text: string) {
    this.setState({ iconDesc: text });
  }
  showSaveIconDesc(text: string) {
    if (this.props.reduxMenus.enableSave) {
      this.setState({ iconDesc: text });
    }
  }
  private openContactUsModal() {
    this.props.updateRedux("UPDATE_MODALS", {
      showModal: true,
      modalType: "ContactUsModal",
      modalData: null,
      hideCancelBtn: false
    });
  }

  public render() {
    //const reduxMenus = this.props.reduxMenus;
    const route = this.props.location.pathname;
    const displayMenu = route.includes("/auth/login") || route.includes("/loginstart") ? false : true;
    //let pricingClass = this.props.reduxMenus.enableUnitIcons ? 'menu-dd5' : 'menu-dd5-disabled';
    let saveClass = this.props.reduxMenus.enableUnitIcons ? "menu-dd2" : "menu-dd2-disabled";
    let printClass = this.props.reduxMenus.enableUnitIcons
      ? this.props.reduxOutputDocumentsInfo.showDocsReadyNotificationIcon
        ? "menu-dd4-checkmark"
        : "menu-dd4"
      : "menu-dd4-disabled";
    return (
      <div className="app-topright-menus">
        {displayMenu ? (
          <>
            {/*NOTE:SAVE IN CASE WE GO BACK TO SMALL LOADER ON TOP OF ICON {this.state.saveInProgress ? (
              <div className="menu-icon save-icon-loading"></div>
            ) : ( */}
            <div
              className={this.props.reduxMenus.enableSave ? "menu-icon " + saveClass : "menu-icon  menu-dd-disabled" + saveClass}
              onMouseEnter={() => this.showSaveIconDesc("Save unit design")}
              onMouseLeave={() => this.showIconDesc("")}
              onClick={() => this.saveUnit()}
            ></div>
            {/* )} */}

            {/* <div
              className={"menu-icon " + printClass}
              onMouseEnter={() => this.showIconDesc("Outputs")}
              onMouseLeave={() => this.showIconDesc("")}
              onClick={() => this.printOutput()}
            ></div> */}

            {/* <div
              className={'menu-icon ' + pricingClass}
              onMouseEnter={() => this.showIconDesc('Pricing')}
              onMouseLeave={() => this.showIconDesc('')}
              onClick={() => this.pricingOutput()}
            ></div> */}

            <div
              className="menu-icon menu-dd7"
              onMouseEnter={() => this.showIconDesc("Logout")}
              onMouseLeave={() => this.showIconDesc("")}
              onClick={() => this.identityService.logout()}
            ></div>

            <div
              className="menu-icon menu-dd8"
              onMouseEnter={() => this.showIconDesc("Help")}
              onMouseLeave={() => this.showIconDesc("")}
              onClick={() => this.openContactUsModal()}
            ></div>
            <div className="app-clear"></div>
            <div className="menu-icon-desc">{this.state.iconDesc}</div>

            {/* 
            ====================================================================================================
            NOTE: leaving this here in case the business decides to bring back the dropdown menus for any reason
            ====================================================================================================
            <div
              className="top-right-menu menu-item1"
              onMouseEnter={() => this.showDropMenu(1, true)}
              onMouseLeave={() => this.showDropMenu(1, false)}
            >
              <div className="top-menu-text">
                Job Mgmt <i className="menu-arrow-down"></i>
              </div>
              <div
                className={reduxMenus.showTopRightDropdown1 ? 'app-options-menu1' : 'app-display-none'}
                onMouseEnter={() => this.showDropMenu(1, true)}
                onMouseLeave={() => this.showDropMenu(1, false)}
              >
                {displayHomeSave ? (
                  <>
                    <div className="menu-dropdown menu-dd1">
                      <Link to="/project">
                        <div className="menu-label">Project Management</div>
                      </Link>
                    </div>

                    <div className="menu-dropdown menu-dd2" onClick={() => this.openModal('Save Project')}>
                      <div className="menu-label">Save Project</div>
                    </div>
                  </>
                ) : null}
                <div className="menu-dropdown menu-dd3" onClick={() => this.openModal('Copy Unit')}>
                  <div className="menu-label">Copy Unit</div>
                </div>

                <div className="menu-dropdown menu-dd4" onClick={() => this.openModal('Document Generation')}>
                  <div className="menu-label">Document Generation</div>
                </div>

                <div className="menu-dropdown menu-dd5" onClick={() => this.openModal('Edit Properties')}>
                  <div className="menu-label">Edit Properties</div>
                </div>

                {loggingRoutePath ? (
                  <Link to={loggingRoutePath} target="_blank">
                    <div className="menu-dropdown menu-dd6">
                      <div className="menu-label">Logging</div>
                    </div>
                  </Link>
                ) : (
                  <div className="menu-dropdown menu-dd6" onClick={() => alert('Must open a unit first')}>
                    <div className="menu-label">Logging</div>
                  </div>
                )}

                <div className="menu-dropdown menu-dd7" onClick={() => this.identityService.login()}>
                  <div className="menu-label">Login</div>
                </div>
                <div className="menu-dropdown menu-dd7" onClick={() => this.identityService.logout()}>
                  <div className="menu-label">LogOut</div>
                </div>
              </div>
            </div> */}

            {/* <div className="app-box-divider"></div>
            <div
              className="top-right-menu top-right-menu2 menu-item2">
              <div className="top-menu-text">
                <div className="save-btn" onClick={() => this.saveUnit()}>Save</div>
              </div>
            </div> */}
          </>
        ) : null}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus,
    reduxAuth: state.reduxAuth,
    reduxOutputDocumentsInfo: state.reduxOutputDocumentsInfo
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopRightMenu));
