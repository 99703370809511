import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../../redux/actions";
import { updateInputField } from "../../../../api-calls/readApiService";
import { getSelectBoxOptions, getInputCSS, convertTubeWallThickness, convertFinThickness } from "../../../../utilities/pageUtils";
import { isEmptyObject } from "../../../../utilities/helpers";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  title: string;
  componentData: any;
  userInputs: any;
  targetVertex: string;
  match: match<any>;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
}

class CoilDisplayGroupHGRH extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: []
  };
  componentDidMount() {
    //this.checkVFDSharing();
    const componentData = this.props.componentData;

    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: this.props.userInputs,
        displayFields: componentData.displayFields,
        dropdownOptions: componentData.dropdownOptions
      });
    }
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    let result = await updateInputField("updateRefrigeration", projectId, unitId, fieldName, newValue, "RefrigerationInputs");

    if (result.success) {
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.refrigerationInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS([], fieldName, type);
  }

  //------------------------------------------------------------------------------------------
  public render() {
    //const coilPanelItem = this.props.coilPanelItem;
    const savedValues = this.state.userInputs;
    if (isEmptyObject(savedValues)) {
      return null;
    }
    return (
      <div className="spec-coils-container">
        <div className="spec-heading">{this.props.title}</div>
        {/* Casing Construction */}
        <div className="spec-input">
          <select
            className="spec-selectbox"
            value={savedValues.hgrhCasingConstruction || ""}
            onChange={(event) => this.saveInputValue("hgrhCasingConstruction", event.currentTarget.value)}
          >
            {getSelectBoxOptions(this.state.dropdownOptions, "casingMaterialOptions")}
          </select>
        </div>
        {/* Coating */}
        <div className="spec-input">
          <select
            className="spec-selectbox"
            value={savedValues.hgrhCoilCoating || ""}
            onChange={(event) => this.saveInputValue("hgrhCoilCoating", event.currentTarget.value)}
          >
            {getSelectBoxOptions(this.state.dropdownOptions, "hgrCoilCoatingOptions")}
          </select>
        </div>
        {/* Tube Thickness */}
        <div className="spec-input">{convertTubeWallThickness(savedValues.hgrhTubeWallThickness)}</div>
        {/* Fin Material */}
        <div className="spec-input">{savedValues.hgrhFinMaterial}</div>
        {/* Fin Thickness */}
        <div className="spec-input">{convertFinThickness(savedValues.hgrhFinThickness)}</div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(CoilDisplayGroupHGRH);
