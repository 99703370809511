import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../../redux/actions";
import { updateInputField } from "../../../../api-calls/readApiService";
import {getValidationError, getSelectBoxOptionsSingle, getInputCSS, getFilteredValidationErrors, getNewValidationErrorsList} from "../../../../utilities/pageUtils";
import { isEmptyObject } from "../../../../utilities/helpers";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  title: string;
  motorEnclosureOptions: any[];
  shaftGroundingOptions: any[];
  componentData: any;
  userInputs: any;
  targetVertex: string;
  match: match<any>;
  showSafetyCage: boolean;
  refreshAllFanData: (allFansUIResponse: any) => void;
}

// local state
interface IState {
  userInputs: any;
  displayFields: any;
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class FanDisplayGroup extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    validationErrors: [],
    lastUpdatedTextbox: ""
  };
  componentDidMount() {
    const componentData = this.props.componentData;

    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: this.props.userInputs,
        displayFields: componentData.displayFields,
        validationErrors: componentData.validationErrors
      });
    }
  }

  //When calling saveInputValueAllFans, it returns an AllFansUIResponse containing updated data for all fans.
  //We send that response back to the parent Fans.tsx component, so it can refresh all the FanDisplayGroups.
  //That will cause this method to fire, so we can load the new values in state.  But we need to check which
  //values have changed because this method can get fired a lot, and can cause an infinite loop.
  async componentDidUpdate(prevProps: IProps) {
    let prevInputs = prevProps.userInputs;
    let newInputs = this.props.userInputs;
    let update = false;
    //Check all the properties that update ALL FANS. If any of them have changed, reload state from props.
    if (this.valueHasChanged(prevInputs.motorEnclosureType, newInputs.motorEnclosureType)) {
      update = true;
    }
    if (this.valueHasChanged(prevInputs.hasInletGuard, newInputs.hasInletGuard)) {
      update = true;
    }
    if (this.valueHasChanged(prevInputs.includeDoorSafetyKillSwitch, newInputs.includeDoorSafetyKillSwitch)) {
      update = true;
    }
    if (this.valueHasChanged(prevInputs.hasOutletSafetyCage, newInputs.hasOutletSafetyCage)) {
      update = true;
    }
    if (update) {
      this.setState({
        userInputs: this.props.userInputs
      });
    }
  }
  valueHasChanged(prevValue: string, newValue: string) {
    return prevValue != newValue;
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateFan", projectId, unitId, fieldName, newValue, this.props.targetVertex);

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fanInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //========= SPECIAL SAVE METHOD for properties requiring all fans to be updated at once ==============
  public async saveInputValueAllFans(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    
    let result = await updateInputField("updateAllFans", projectId, unitId, fieldName, newValue, "");

    if (result.success) {
      //This method returns an AllFansUIResponse containing updated data for all fans except for dropdown options.
      //So we need to pass that back to the parent Fans.tsx component, so it can refresh all the FanDisplayGroups.
      this.props.refreshAllFanData(result);
    }
  }
  //=====================================================================================================

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------
  public render() {
    const savedValues = this.state.userInputs;
    if (isEmptyObject(savedValues)) {
      return null;
    }
    return (
      <div className="spec-coils-container">
        <div className="spec-heading">{this.props.title}</div>

        {/* Motor Enclosure */}
        <div className="spec-input">
          <select
            className="spec-selectbox"
            value={savedValues.motorEnclosureType || false}
            onChange={(event) => this.saveInputValueAllFans("motorEnclosureType", event.currentTarget.value)}
          >
            {getSelectBoxOptionsSingle(this.props.motorEnclosureOptions)}
          </select>
          {this.getValidationError("motorEnclosureType")}
        </div>

        {/* Shaft Grounding */}
        <div className="spec-input">
          <select
            className="spec-selectbox"
            value={savedValues.shaftGroundingType || false}
            onChange={(event) => this.saveInputValue("shaftGroundingType", event.currentTarget.value)}
          >
            {getSelectBoxOptionsSingle(this.props.shaftGroundingOptions)}
          </select>
          {this.getValidationError("shaftGroundingType")}
        </div>

        {/* Inlet Guard */}
        <div className="spec-input">
          <input
            className=""
            type="checkbox"
            checked={savedValues.hasInletGuard || false}
            onChange={(event) => this.saveInputValueAllFans("hasInletGuard", event.currentTarget.checked.toString())}
          />
        </div>

        {/* Door Safety Kill Switch */}
        <div className="spec-input">
          <input
            className=""
            type="checkbox"
            checked={savedValues.includeDoorSafetyKillSwitch}
            onChange={(event) => this.saveInputValueAllFans("includeDoorSafetyKillSwitch", event.currentTarget.checked.toString())}
          />
        </div>

        {/* Outlet Safety Cage (for APH fans)*/}
        {this.props.showSafetyCage ? (
          <div className="spec-input">
            <input
              className=""
              type="checkbox"
              checked={savedValues.hasOutletSafetyCage}
              onChange={(event) => this.saveInputValueAllFans("hasOutletSafetyCage", event.currentTarget.checked.toString())}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(FanDisplayGroup);
