import * as React from "react";
import { store } from "../../redux/store";
import { connect } from "react-redux";
import { sqlToJsDate } from "../../utilities/helpers";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification } from "../../api-utilities/notification-tools";
import {
  AddNewProject,
  getProjectList,
  getStatusOptions,
  addNewUnit,
  deleteProject,
  deleteUnitById,
  getProjectRows,
  openProjectModal,
  selectProject,
  updateEditedProject,
  copyUnitToProject,
  copyProjectWithUnits,
  sortByJobName,
  sortByLastModified,
  SetupPaginationWithNewProjectList
} from "./ProjectUtilities";
import Unit from "./Unit";
import PagingControl from "./PagingControl";
import { resetReduxModals } from "../../utilities/helpers";
import { jobSearch, jobSearchByStatusAndName } from "../../api-calls/readApiService";
import { getProject } from "../../api-calls/projectDataService";
import "../../css/Project.scss";
import { ProjectStatusFilterBy, SortBy } from "../../interfaces/interfaces";

// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
  reduxProjectManagement: any;
  history: any;
}
// local state
interface IState {
  jobNameSearchText: string;
  jobIdSearchText: string;
  hasEmployeeRole: boolean;
  copyProjectId: string;
  copyProjectHasError: boolean;
  showStatusDropdown: boolean;
}

class ProjectManagement extends React.Component<IProps, IState> {
  //jobSearchText means a search was done and the results were filtered on this text.
  public state: IState = { jobNameSearchText: "", jobIdSearchText: "", hasEmployeeRole: false, copyProjectId: "", copyProjectHasError: false, showStatusDropdown: false };

  async componentDidMount() {
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 1 } });
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });

    await getProjectList();
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { enableUnitIcons: false });
    //Need to refresh the unit list in order to show the Unit tag name that is added on the configuration screen.
    //THe unit tag name is shown on the unit bars.
    if (this.props.reduxProjectManagement.selectedProject) {
      selectProject(this.props.reduxProjectManagement.selectedProject);
    }

    const auth = store.getState().reduxAuth;
    if (auth.userRoles?.length > 0) {
      auth.userRoles.forEach((role: any) => {
        if (role === "employee") {
          this.setState({ hasEmployeeRole: true });
        }
      });
    }
  }

  private handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      this.runSearchByJobName();
    }
  }
  private async getProjectListByStatusAndName(projectStatus: string){
    let response = await jobSearchByStatusAndName("dataapi/searchJobsByStatusAndName", projectStatus, this.state.jobNameSearchText);
  
    if (response.hasError) {
      const notification = {
        id: "searchJobsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">searchJobsByStatusAndName {response.errorMessage}</div>
      };
      addNotification(notification);
    }
    SetupPaginationWithNewProjectList(response.projectList, SortBy.None, SortBy.None);
  }
  private async runSearchByJobName() {
    //Search jobs by name if search text was provided
    if (this.state.jobNameSearchText !== "") {
      let response = await jobSearch("dataapi/searchJobsByName", this.state.jobNameSearchText);

      if (response.hasError) {
        const notification = {
          id: "searchJobsError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">searchJobsByName {response.errorMessage}</div>
        };
        addNotification(notification);
      }
      this.props.updateRedux("UPDATE_PROJECT_MANAGEMENT", { projectStatusFilterBy: ProjectStatusFilterBy.All});

      SetupPaginationWithNewProjectList(response.projectList, SortBy.None, SortBy.None);
            

    } else {
      //If search text is empty, just get all jobs
      await getProjectList();
    }
  }

  private handleKeyDownCopyProjectById(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      this.CopyProjectById();
    }
  }
  private async CopyProjectById() {
    this.setState({ copyProjectHasError: false });

    //strip all non-numeric characters from the user-entered projectId
    let projectIdNumeric = this.state.copyProjectId.replace(/\D/g, "");

    //GraphDB projectId format
    let projectId = `proj-${projectIdNumeric}`;

    //Check if project exists
    const response = await getProject(projectId);

    if (response.hasError || response.projectList == null) {
      this.setState({ copyProjectHasError: true });
      return;
    }

    if (response.hasError == false && response.projectList.length > 0) {
      let project = response.projectList.find((x: any) => x.id == projectId);

      let copyRequest = { projectId: projectId, projectName: `Copy of ${project.projectName}(${projectIdNumeric})` };
      await copyProjectWithUnits(copyRequest);

      //wait for the copy method to finish, then update the project list
      getProjectList();
    } else {
      this.setState({ copyProjectHasError: true });
    }
  }

  private async clearFilter() {
    this.setState({ jobNameSearchText: "", jobIdSearchText: "" });
    //Filter has been cleared, so get all the projects.
    await getProjectList();
  }
  //Add new unit and go to config input screen.
  private async addNewUnitToJob() {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    let response = await addNewUnit();
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (response.success === true) {
      //redirect to unit config input screen.
      this.props.history.push("/pid/" + response.projectId + "/uid/" + response.newUnitId + "/design/unittype");
    }
  }
  //Display list of Units from state.
  private getUnitRows() {
    const selectedProject = this.props.reduxProjectManagement.selectedProject;

    const units = this.props.reduxProjectManagement.unitList;
    if (units) {
      return units.map((item: any, index: number) => <Unit history={this.props.history} key={index} unit={item} projectId={selectedProject.id} />);
    }
    return null;
  }
  //Check for user response to the delete-project modal or the addNewProject modal.
  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    const modal = this.props.reduxModals;

    //If modal was open but is now closed, check what user's response was.
    if (prevProps.reduxModals.showModal !== modal.showModal && modal.showModal === false) {
      if (modal.modalId === "delete-project") {
        if (modal.userResponse === "ok") {
          deleteProject(modal.modalData.projectIdToDelete);
        }
      } else if (modal.modalId === "delete-unit") {
        if (modal.userResponse === "ok") {
          deleteUnitById(modal.modalData.unitToDelete);
        }
      } else if (modal.modalId === "add-new-project") {
        if (this.props.reduxProjectManagement.projectName !== "" && modal.userResponse === "ok") {
          AddNewProject();
        }
      } else if (modal.modalId === "edit-project") {
        if (this.props.reduxProjectManagement.projectName !== "" && modal.userResponse === "ok") {
          updateEditedProject();
        }
      } else if (modal.modalId === "copy-unit") {
        if (modal.userResponse.response === "ok") {
          copyUnitToProject(modal.userResponse);
        }
      } else if (modal.modalId === "copy-project") {
        if (modal.userResponse.response === "ok") {
          await copyProjectWithUnits(modal.userResponse);

          //wait for the copy method to finish, then update the project list
          getProjectList();
        }
      }
      resetReduxModals();
    }
  }
  saveStatusType(projectStatus: any){
    //Set status selection, remove sorting by name and last modified date because we're getting a whole new list of projects.
    this.props.updateRedux("UPDATE_PROJECT_MANAGEMENT", { projectStatusFilterBy: ProjectStatusFilterBy[projectStatus], projectNameSortBy: SortBy.None, projectLastModifiedSortBy: SortBy.None });
    this.setState({showStatusDropdown: false});
    this.getProjectListByStatusAndName(projectStatus);
  }
  handleProjectStatusClick(){
    if (this.state.showStatusDropdown === false){
      this.setState({showStatusDropdown:true})
    }
    else {
      this.setState({showStatusDropdown:false})
    }
  }
  public render() {
    //const active = true;
    let displayCopyJobById = this.state.hasEmployeeRole ? "" : "app-display-none";
    const pj = this.props.reduxProjectManagement;
    const searchCount = pj.projectList?.length;
    const filteredStatusHide = this.state.jobNameSearchText === "" ? "app-visibility-hidden" : "";
    const projectNameUpArrowColor = pj.projectNameSortBy == SortBy.ASC ? "yellow" : "white";
    const projectNameDownArrowColor = pj.projectNameSortBy == SortBy.DESC ? "yellow" : "white";
    const projectLastUpdateUpArrowColor = pj.projectLastModifiedSortBy == SortBy.ASC ? "yellow" : "white";
    const projectLastUpdateDownArrowColor = pj.projectLastModifiedSortBy == SortBy.DESC ? "yellow" : "white";
    const statusDropdown = this.state.showStatusDropdown === false ? "project-hide" : "";
    return (
      <div className="project-mgmt-main">
        <div className="project-list">
          {/* ---------- Project status search, dropdown and New Project input ----------- */}
          <div className="project-search-title">
            Job Search:
            <input
              className="proj-search-text"
              type="text"
              placeholder="Search by job name..."
              value={this.state.jobNameSearchText}
              onChange={(event) => this.setState({ jobNameSearchText: event.currentTarget.value })}
              onKeyDown={(event) => this.handleKeyDown(event)}
            />
            <div className="proj-search-btn" onClick={() => this.runSearchByJobName()}>
              <div className="proj-search-symbol">&#9906;</div>
            </div>
            {/* ---------- Copy Job by ID----------- Only shows for employee role ------ */}
            <div className={"proj-search-id " + displayCopyJobById}>
              <span className="proj-search-id-text">Copy Job By ID:</span>

              <input
                className="proj-search-text"
                type="text"
                placeholder="Enter job id..."
                value={this.state.copyProjectId}
                onChange={(event) => this.setState({ copyProjectId: event.currentTarget.value })}
                onKeyDown={(event) => this.handleKeyDownCopyProjectById(event)}
              />
              <div className="proj-copyId-btn" onClick={() => this.CopyProjectById()}>
                <div>Copy</div>
              </div>
              {this.state.copyProjectHasError ? <div className="proj-copy-error">Job not found</div> : null}
            </div>
          </div>

          {/* ==================== Projects List ============================= */}
          <div className="proj-space"></div>
          <div className={"proj-filtered-by " + filteredStatusHide}>
            <span>{"Search Results (" + searchCount + ")"}</span>
          </div>
          <div className={"proj-clear-search-btn " + filteredStatusHide} onClick={() => this.clearFilter()}>
            Clear Search{" "}
          </div>

          <div className="project-list-add">
            <div className="proj-btn proj-btn-add" onClick={() => openProjectModal()}>
              + Add New Job
            </div>
          </div>
          <div className="app-clear"></div>
          <div className="proj-table1">
            <div className="proj-tr">
              <div className="proj-th-jobname">Job Name 
                <i className={projectNameUpArrowColor + " fa fa-lg fa-solid fa-angle-up project-jobname-arrow-up"} onClick={()=> sortByJobName(SortBy.ASC)}></i>
                <i className={projectNameDownArrowColor + " fa fa-lg fa-solid fa-angle-down project-jobname-arrow-down"} onClick={()=> sortByJobName(SortBy.DESC)}></i>
              </div>
              {/* Hidden Status Dropdown */}
                <select
                  className={statusDropdown + " project-status-select-control"}
                  value={ProjectStatusFilterBy[pj.projectStatusFilterBy]}
                  onChange={(event) => this.saveStatusType(event.currentTarget.value)}
                >
                  <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.All]}</option>
                  <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.Design]}</option>
                  <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.Booking]}</option>
                  <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.Release]}</option>
                </select>
              {/* End Hidden Status Dropdown */}
              <div className="proj-th proj-129">Job Status
                <i className="fa fa-lg fa-solid fa-caret-down project-status-dropdown" onClick={()=> this.handleProjectStatusClick()}></i>
              </div>
              <div className="proj-th proj-107">Last Modified
                <i className={projectLastUpdateUpArrowColor + " fa fa-lg fa-solid fa-angle-up project-lastmodified-arrow-up"} onClick={()=> sortByLastModified(SortBy.ASC)}></i>
                <i className={projectLastUpdateDownArrowColor + " fa fa-lg fa-solid fa-angle-down project-lastmodified-arrow-down"} onClick={()=> sortByLastModified(SortBy.DESC)}></i>
              </div>
            </div>

            {getProjectRows()}
          </div>
          <PagingControl />
          <br />
          <br />
        </div>

        {/* ===================== Units List ===================================== */}
        {this.props.reduxProjectManagement.selectedProject ? (
          <div className="project-details">
            <div className="proj-detail-section">

              <div className="proj-selected-heading-grid">
                <div className="center-align-grid-cell long-text">{pj.selectedProject ? "Job Name: " +pj.selectedProject.projectName : ""}</div>
                <div className="center-align-grid-cell">{pj.selectedProject?.jobStatus ? "Job Status: " + pj.selectedProject.jobStatus : ""}</div>
                <div className="center-align-grid-cell">{pj.selectedProject ? "Created " + sqlToJsDate(pj.selectedProject.createDate) : ""}</div>
                <div className="proj-btn proj-btn-add" onClick={() => this.addNewUnitToJob()}>
                  + Add New Unit
                </div>
              </div>
              <div className="app-clear"></div>

              <div className="proj-unit-sub-heading-grid">
                <div>Unit Tag</div>
                <div>Unit Model#</div>
                <div>List Price</div>
                <div>Unit Qty</div>
                <div></div>
              </div>

              <div className="proj-unit-table">{this.getUnitRows()}</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals,
    reduxProjectManagement: state.reduxProjectManagement
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);
