import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import IdentityContext from '../../security/IdentityContext';
import '../../css/Login.scss';

// expected props
interface IProps {
  history: any;
}
// local state
interface IState {
  username: string;
  password: string;
  error: string;
}

class LoginStart extends React.Component<IProps, IState> {
  public state: IState = {
    username: '',
    password: '',
    error: ''
  };

  //Connect to global IdentityContext (https://reactjs.org/docs/context.html)
  static contextType = IdentityContext;
  private readonly identityService = this.context;

  async componentDidMount() {
    const loggedIn = await this.identityService.isLoggedIn();
    console.log(loggedIn);

    if (loggedIn) {
      // this.props.history.push('/project');
    }
  }

  public render() {
    return (
      <div className="login-inputs">
        <h2>Homepage</h2>
        <br />
        <br />
        <div>Please Log In</div>
        <br />

        <div className="login-btn app-box-shadow" onClick={() => this.identityService.login()}>
          Login
        </div>
        <br />
        <br />
        <br />
        {/* <div>{process.env.REACT_APP_MSG}</div>
        <div>{process.env.REACT_APP_IDENTITY_AUTHORITY}</div>
        <div>{process.env.REACT_APP_IDENTITY_CLIENTID}</div>
        <div>{process.env.REACT_APP_IDENTITY_LOGINREDIRECT}</div>
        <div>{process.env.REACT_APP_IDENTITY_LOGOUTREDIRECT}</div>
        <div>{process.env.REACT_APP_IDENTITY_SILENT}</div> */}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginStart);
