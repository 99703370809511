import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../../redux/actions";
import FanVFDDisplayGroup from "./FanVFDDisplayGroup";
import FanVFDInstall from "./FanVFDInstall";
import { isEmptyObject } from "../../../utilities/helpers";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  componentData: any;
}
// local state
interface IState {
  componentData: any;
  displayFields: any;
}

class FanVFD extends React.Component<IProps, IState> {
  componentDidMount() {
    const componentData = this.props.componentData;
    if (!isEmptyObject(componentData)) {
      this.setState({
        componentData: componentData,
        displayFields: componentData.fanUIDataContainer.displayFields,
      });
    }
  }
  private getFanDisplayGroups() {

    let panelArr: any = [];

    if (this.state.displayFields?.showSupplyFan) {
      panelArr.push(<FanVFDDisplayGroup title="Supply" fanData={this.state.componentData} targetVertex={"SupplyFanInputs"} key="supply" match={this.props.match} />);
    }
    if (this.state.displayFields?.showExhaustFan) {
      panelArr.push(<FanVFDDisplayGroup title="Exhaust" fanData={this.state.componentData} targetVertex={"ExhaustFanInputs"} key="exhaust" match={this.props.match} />);
    }
    if (this.state.displayFields?.showReturnFan) {
      panelArr.push(<FanVFDDisplayGroup title="Return" fanData={this.state.componentData} targetVertex={"ReturnFanInputs"} key="return" match={this.props.match} />);
    }
    return panelArr;
  }

  public render() {
   
    if (isEmptyObject(this.state)) {
      return null;
    }

    return (
      <div className="spec-fields">
        <div className="spec-field-spacer1"></div>

        <div className="spec-heading-2">Fan VFDs</div>
        <FanVFDInstall match={this.props.match} componentData={this.state.componentData} />
        <div className="spec-elec-fields">
          <div className="spec-coils-container spec-margin-top-20">
            <div className="spec-input-label-2">Drive Type</div>
            <div className="spec-input-label">Allow Microdrives</div>
            <div className="spec-input-label">Manual Bypass</div>
          </div>

          {this.getFanDisplayGroups()}
        </div>

        {/* <div className="spec-field-spacer1"></div> */}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FanVFD);
