import { store } from '../redux/store';
import { displayError, displayErrorString} from '../api-utilities/api-calls';
import { IdentityService } from '../security/IdentityService';
import { IUserInfo } from '../interfaces/interfaces';
import { getStringId } from '../api-utilities/formatting';
//This file contains calls to the Lowry backend, which gets results from the PricingDataAPI

const apibase = process.env.REACT_APP_CLIENT_API;

export async function getAuthToken() {
  const auth = store.getState().reduxAuth;
  let token = '';
  //Check to see if reduxAuth contains the access_token. 
  //Using access_token from Redux prevents a bunch of calls to identityserver,
  //but on page reload, sometimes redux doesn't get populated fast enough, and the page tries
  //to call the server with no token, and fails.  So in that case we should get it from IdentityServer.
  if(auth.access_token !== ''){
    token = auth.access_token;
  }
  else{
    const identityService = new IdentityService();
    const user = await identityService.getUser();
    if(user != null){
      token = user.access_token;
    }
  }
  return token;
}

export async function getPriceAndRecalcLevel(projectId: any, unitId: any){
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

  const result = await fetch(apibase + `/dataapi/getPriceAndRecalcLevel/${projIdStr}/${unitIdStr}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}
export async function updateUnitPrice(projectId: any, unitId: any) {
  const auth = store.getState().reduxAuth;
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

  const graphUnitPricingRequest = {
    projectId: projIdStr,
    unitId: unitIdStr,
    state: auth.state,
    postalCode: auth.postalCode,
    saveToDatabase: true
    };

  try {
    const result = await fetch(apibase + '/dataapi/updateGraphUnitPrice', {
      method: 'post',
      body: JSON.stringify(graphUnitPricingRequest),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    });
    if (result.status === 200) {
      return await result.json();
    }
    else{
        let error = {
            hasError: true, errorMessage: "Request failed"
        }
      return error;
    }
  } catch (error) {
    let err = {
        hasError: true, errorMessage: error
    }
  return err;
  }
}


export async function generateUnitPriceSummary(projectId:any, unitId:any, ){
  const auth = store.getState().reduxAuth;
  const apibase = process.env.REACT_APP_CLIENT_API;
  const token = await getAuthToken();
  let response;

  const unitPriceRequest = {
      projectId: projectId,
      unitId: unitId,
      state: auth.state,
      postalCode: auth.postalCode,
      saveToDatabase: true
      };
  
      const result = await fetch(apibase + '/dataapi/generateUnitPriceSummary', {
          method: 'post',
          body: JSON.stringify(unitPriceRequest),
          headers: { 'Content-Type': 'application/json',
              Authorization: `Bearer ${token}` }
      });
      response = {
        //Handles the call from pdf Service
        success: result.status === 200 ? true:false,
        data: result.status === 200 ? await result.json() : await result.text()
      };
      //If not success then display error.
      if(!response.success){
        let errorObj = {
            status: result.status,
            message: response.data.errorMessage
        }
        displayError(errorObj, "GenerateUnitPriceSummary");
        return;
    }
    return response;
}