import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { match } from "react-router";
import { callGetEndpoint, updateInputField } from "../../api-calls/readApiService";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import "../../css/UnitSelection.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
}
// local state
interface IState {
  userInputs: any;
}

class UnitSelection extends React.Component<IProps, IState> {
  public state: IState = { userInputs: null };
  //This is a specific page for selecting the unit type.
  //It contains the 6 Unit Type images the user can click on to select UnitType.

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetUnitSelectionAllValues", projectId, unitId);

    if (result.hasError) {
      const notification = {
        id: "unitSelectionError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetUnitSelectionAllValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer) {
      this.setState({
        userInputs: result.uiDataContainer.primaryInputs
      });
    }
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;

    //Don't call the backend if the unitType has not changed.
    if (this.state.userInputs.unitType === newValue) {
      return;
    }
    let result = await updateInputField("UpdateUnitSelectionInputs", projectId, unitId, fieldName, newValue, "PrimaryInputs");

    if (result.success) {
      if (newValue === "Pool") {
        this.props.updateRedux("UPDATE_MENUS", { showPoolInputs: true });
      } else {
        this.props.updateRedux("UPDATE_MENUS", { showPoolInputs: false });
      }
      //If unitBasicDetails was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({ userInputs: result.data.uiDataContainer.primaryInputs });
      }
    }
  }

  //return the "selected" classname for the currently selected option
  private checkIfSelected(type: string) {
    if (this.state.userInputs && this.state.userInputs.unitType == type) {
      return "unit-img-selected";
    } else return "";
  }

  public render() {
    const savedValues = this.state.userInputs;
    if (!savedValues) {
      return null;
    }

    return (
      <div className="unit-page">
        <div className="unit-select-options">
          {/* NOTE: this is hardcoded to 2, because that's the index for UnitDefinition.UnitTypes.EnergyRecovery ENUM in Lowry.
            TODO: We should refactor this to fetch these ID's from the database table Lowry.UnitType. */}
          <div
            className={"unit-img-eru unit-img-available " + this.checkIfSelected("EnergyRecovery")}
            onClick={() => this.saveInputValue("unitType", "EnergyRecovery")}
          ></div>

          {/* NOTE: this is hardcoded to 1, because that's the index for UnitDefinition.UnitTypes.SingleTunnel ENUM in Lowry.
            TODO: We should refactor this to fetch these ID's from the database table Lowry.UnitType. */}
          <div
            className={"unit-img-ahu unit-img-available " + this.checkIfSelected("SingleTunnel")}
            onClick={() => this.saveInputValue("unitType", "SingleTunnel")}
          ></div>

          <div></div>
          {/* to disable pools, add class pointer-events-none */}
          <div className={"unit-img-pool unit-img-available " + this.checkIfSelected("Pool")} onClick={() => this.saveInputValue("unitType", "Pool")}>
            {" "}
          </div>

          {/* <div className="unit-img-dehum unit-img-unavailable"></div> */}

          <div></div>

          {/* <div className="unit-img-laser1 unit-img-unavailable"></div> */}

          {/* <div className="unit-img-laser2 unit-img-unavailable"></div> */}
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    //reduxConfigInputs: state.reduxConfigInputs
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitSelection);
