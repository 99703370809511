import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../../../redux/actions";
import AccordionHeader from "../../../Shared/AccordionHeader";
import { FilterPanelItem } from "../../../../TypescriptClient";
import { getSpareFilterQuantities } from "../../../../utilities/spareFilterUtil";
import { isEmptyObject } from "../../../../utilities/helpers";
import { callGetEndpoint, updateInputField } from "../../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../../utilities/pageUtils";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  //title: string;
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  openAccordion: boolean;
  componentData: any;
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}
class FilterSpares extends React.Component<IProps, IState> {
  public state: IState = {
    openAccordion: false,
    componentData: null,
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  //==================================================================================================================
  //NOTE: It would be difficult to manage spare filter quantities on each filter vertex individually, and Dave just wants
  //to group them as Pleated and Cartridge spare filters. So we just have 2 spare filter fields in primaryInputs instead.
  //==================================================================================================================

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint(`readapi/GetSpareFilterInputValues`, projectId, unitId);
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    if (!isEmptyObject(result)) {
      this.setState({
        userInputs: result.uiDataContainer.primaryInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
  }

  private togglePanel() {
    this.setState({ openAccordion: !this.state.openAccordion }); //toggle to opposite of current state
  }

  //TODO: Get rid of all these comments. All this logic is handled in the ClientAPI now (FilterReadService, FilterWriteService)
  //async componentDidMount() {
  //GetAllFilters
  //Determine if any are pleated:  showPleatedDropdown
  //Determine if any are cartridge: showCartridgeDropdown
  //When saved, distribute the pleated setting to all filter vertexes that have a "pleated" type
  //When saved, distribute the cartridge setting to all filter vertexes that have a "cartridge" type

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateSpareFilter", projectId, unitId, fieldName, newValue, "PrimaryInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.primaryInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  public render() {
    const active = this.state.openAccordion;
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (isEmptyObject(savedValues)) {
      return null;
    }

    return (
      <div className="">
        <AccordionHeader
          title="Filters"
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.togglePanel()}
        />

        <div className={`acc-panel-1000 ${active === true ? "acc-panel-active" : "acc-panel-inactive"}`}>
          <div className="accordion-content app-box-shadow">
            <div className="spec-fields">
              <div className="spec-field-spacer1"></div>

              <div className="spec-coils-container">
                <div className="spec-input-label">Spare Filter Sets</div>
              </div>
              {/* -------------- Pleated Filters Dropdown ---------------------------- */}
              {displayFields.showPleatedFilterDropdown ? (
                <div className="spec-coils-container">
                  <div className="spec-heading">Pleated Filter Qty</div>
                  <div className="spec-input">
                    <select
                      className="spare-filter-select"
                      value={savedValues.pleatedSpareFilterSetsQuantity || ""}
                      onChange={(event) => this.saveInputValue("pleatedSpareFilterSetsQuantity", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "spareFilterSetsQuantityOptions")}
                    </select>
                  </div>
                </div>
              ) : null}
              {/* --------------- Cartridge Filters Dropdown -------------------------*/}
              {displayFields.showCartridgeFilterDropdown ? (
                <div className="spec-coils-container">
                  <div className="spec-heading">Cartridge Filter Qty</div>
                  <div className="spec-input">
                    <select
                      className="spare-filter-select"
                      value={savedValues.cartridgeSpareFilterSetsQuantity || ""}
                      onChange={(event) => this.saveInputValue("cartridgeSpareFilterSetsQuantity", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "spareFilterSetsQuantityOptions")}
                    </select>
                  </div>
                </div>
              ) : null}
              {/* --------------- Aluminum Filters Dropdown -------------------------*/}
              {displayFields.showAluminumFilterDropdown ? (
                <div className="spec-coils-container">
                  <div className="spec-heading">Aluminum Filter Qty</div>
                  <div className="spec-input">
                    <select
                      className="spare-filter-select"
                      value={savedValues.aluminumSpareFilterSetsQuantity || ""}
                      onChange={(event) => this.saveInputValue("aluminumSpareFilterSetsQuantity", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "spareFilterSetsQuantityOptions")}
                    </select>
                  </div>
                </div>
              ) : null}

              <div className="spec-field-spacer2"></div>
              <div className="spec-note">NOTE: Spare filter quantities reset to zero if any filter types are changed.</div>
              <div className="spec-field-spacer1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    // reduxOAFilterPanel: state.reduxOAFilterPanel,
    // reduxHEFilterPanel: state.reduxHEFilterPanel,
    // reduxSAFilterPanel: state.reduxSAFilterPanel,
    // reduxRAFilterPanel: state.reduxRAFilterPanel
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterSpares);
