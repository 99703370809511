import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import '../../css/Modals.scss';

// expected props
interface IProps {
  reduxModals: any;
  updateRedux: any;
}
// local state
interface IState {}

//This component is a modal with OK and Cancel buttons.
//Mostly used by the "Full Details" buttons and elsewhere on the performance screen & accordions.
class ModalPopup extends React.Component<IProps, IState> {
  //Save the user's response and close the modal
  private userResponse(response: string) {
    this.props.updateRedux('UPDATE_MODALS', { showModal: false, userResponse: response, hideCancelBtn: false });
  }

  public render() {
    const data = this.props.reduxModals.modalData;

    return (
      <div className="modal-popup">
        <div className="modal-title">{this.props.reduxModals.modalData.title}</div>

        {data.content ? <div className="modal-data-content">{data.content}</div> : <div className="modal-spacer"></div>}

        <div className="modal-buttons">
          <div className="modal-btn modal-ok-btn" onClick={() => this.userResponse('ok')}>
            OK
          </div>
          {
            //Hide the cancel button for prerequisite error modal
            this.props.reduxModals.hideCancelBtn ? null : (
              <div className="modal-btn modal-cancel-btn" onClick={() => this.userResponse('cancel')}>
                Cancel
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
