import * as React from 'react';
import { connect } from 'react-redux';
import { match, matchPath } from 'react-router-dom';
import { mapDispatchToProps } from '../../redux/actions';
import { DrawingScreen, DrawingViewType} from '../../api-utilities/enums';
import { getUnitDrawing, callGetEndpoint } from "../../api-calls/readApiService";
import { addNotification, removeNotification } from '../../api-utilities/notification-tools';
import { DrawingViewButtons } from './DrawingViewButtons';
import { formatNumber, RoundToNearest, RoundUpToNearest } from '../../api-utilities/formatting';
import { isEmptyArray } from '../../utilities/helpers';
import '../../css/UnitDrawing.scss';

// expected props
interface IProps {
  updateRedux: any;
  reduxNotifications: any;
  reduxMenus: any;
  match: match<any>;
  history: any;
}
// local state
interface IState {
  drawingState: IDrawingState;
  showUnitWidthTooltip: boolean;
  showUnitHeightTooltip: boolean;
  renderDefaultDrawing:boolean;
  LengthLimitMessageShown:boolean;
  commonData: string;
  defaultViewType: DrawingViewType;
  availableViewTypes: DrawingViewType[];
  hoodAndLouverExtensions: any;
  unit: any;
  showUnitLengthWarning: boolean;
  cabinetAndBaseHeight: any;
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
}

interface IDrawingState {
  svg: string;
  drawingView?: DrawingViewType;
}

class UnitDrawing extends React.Component<IProps, IState> {
  public state: IState = {
    drawingState: { svg: '', drawingView: undefined },
    showUnitWidthTooltip: false,
    showUnitHeightTooltip: false,
    LengthLimitMessageShown:false,
    renderDefaultDrawing:false,
    commonData:"",
    defaultViewType: DrawingViewType.Top,
    availableViewTypes: [],
    hoodAndLouverExtensions: [],
    unit: null,
    showUnitLengthWarning: false,
    cabinetAndBaseHeight: null,
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: []
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint("readapi/GetDrawingValues", projectId, unitId);
    this.setState({
      unit: result.unit,
      commonData: result.commonDataViewModel,
      defaultViewType: result.defaultViewType,
      availableViewTypes: result.availableViewTypes,
      renderDefaultDrawing:true,
      showUnitLengthWarning: result.showUnitLengthWarning,
      cabinetAndBaseHeight: result.cabinetAndBaseHeight,
      hoodAndLouverExtensions: result.hoodLouverExtensionInfo
    });
    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.prerequisiteErrors });
    }
    else {
      //Get default drawing.
      await this.getDefaultDrawing();
      this.showLengthLimitMessage();
    }
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
  }
  componentWillUnmount() {
    removeNotification('unitLengthLimitWarning');
    removeNotification('drawingDataErrors');
  }
  private async getDefaultDrawing(){
    //Get defualt drawing view.
    const drawingView =
    this.state.drawingState.drawingView === undefined
      ? this.state.defaultViewType!
      : this.state.drawingState.drawingView;
      //Show the default drawing.
      await this.selectDrawingView(drawingView);
  }
  //Get the updated SVG
  private async selectDrawingView(newValue: number) {
      let commonData = this.state.commonData;
      this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
      const response = await getUnitDrawing(this.props.match.params, newValue, DrawingScreen.Drawing, commonData);

      if (response?.data?.dataPropertyErrors?.length > 0){
        const notification = {
          id: "drawingDataErrors",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">Drawing data errors {response.data.dataPropertyErrors}</div>
        };
        addNotification(notification);
      }
      let svg = '';
      if (response?.data.drawings?.length){
        svg = response?.data.drawings[0].unitDrawingSvg;
      }
      this.setState({ drawingState: { svg: svg ?? '', drawingView: newValue } });
      this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
  }

  private getViewButtonOptions() {
    return (
      <>
      <DrawingViewButtons
        updateDrawingView={this.selectDrawingView.bind(this)}
        drawingView={this.state.drawingState.drawingView ?? DrawingViewType.FrontSide}
        availableViewTypes={this.state.availableViewTypes ?? []}
      ></DrawingViewButtons>
      </>
    );
  }
  //Show/Hide tooltips on mouseover
  private showTooltip(tooltip: 'width' | 'length', state: boolean) {
    if (tooltip === 'width') {
      this.setState({ showUnitWidthTooltip: state });
    } else {
      this.setState({ showUnitHeightTooltip: state });
    }
  }
  //Get display content for each tooltip
  private getTooltipContent(tooltip: string) {
    const tooltipData = this.state.hoodAndLouverExtensions;
    if (tooltipData) {
      const tooltipItems = this.getTooltipListItems(tooltipData);
      const showTooltip = tooltip === 'width' ? this.state.showUnitWidthTooltip : this.state.showUnitHeightTooltip;

      return (
        <div className={showTooltip ? 'dimensions-tooltip1' : 'app-display-none'}>
          <div className="dimensions-tooltip-content">
            The Overall Width and Overall Length values account for unit features such as roof overhang, electrical
            panels, and lifting lugs but do not include hoods and louvers{isEmptyArray(tooltipItems) ? '.' : ':'}
            <br />
            <ul> {tooltipItems}</ul>
          </div>
        </div>
      );
    }
  }
  //hoodAndLouverExtensions comes from the server as a list of strings.
  private getTooltipListItems(tooltipData: any) {
    return tooltipData.map((item: any, index: number) => (
       <li key={index} className="dimensions-li">
         {item}
       </li>
      ));
  }
  //------------------------------------------------------------------------------------------
  private showLengthLimitMessage(){
    let showLengthWarning = this.state.showUnitLengthWarning;
    
    //Pop up the error message at bottom of screen, if it hasn't already been shown.
    if (showLengthWarning && !this.state.LengthLimitMessageShown) {
      let message =
        'Unit dimensions exceed what is shippable without unit splits based on the unit’s length given its height. Output documents are not available for generation. If it is not possible to change inputs to allow the unit to be shippable, contact your Innovent sales person and they will evaluate if unit splits can be included.';
      const notification = {
        id: 'unitLengthLimitWarning',
        notificationType: 'warning',
        title: 'Warning',
        content: <div className="nf-div">{message}</div>
      };
      addNotification(notification);
      this.setState({ LengthLimitMessageShown: true });
    }
    //Remove notification if there is no height or width error and we have previously shown the notification.
    else if (!showLengthWarning && this.state.LengthLimitMessageShown) {
      this.setState({ LengthLimitMessageShown: false });
      removeNotification('unitLengthLimitWarning');
    }
    return showLengthWarning;
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    return (
      <div className="drawing-inputs-main">
        <div className="drawing-container">
          <div className="drawing-left">
            <div className="drawing-fields-left">
              <div className="drawing-clearances">
                <div className="input-heading">Recommended Clearances</div>

                <div className="drawing-tr">
                  <div className="drawing-li">
                    <li />
                  </div>
                  <div className="drawing-text">Minimum 40" in front of access doors.</div>
                </div>

                <div className="drawing-tr">
                  <div className="drawing-li">
                    <li />
                  </div>
                  <div className="drawing-text">
                    Minimum 36" in front of electrical panels. Electrical panel depth can vary from 8" to 16".
                  </div>
                </div>

                <div className="drawing-tr">
                  <div className="drawing-li">
                    <li />
                  </div>
                  <div className="drawing-text">
                    Component removal may require more space than listed recommended clearances.
                  </div>
                </div>

                <div className="input-fields"></div>
              </div>

              <div className="drawing-clearances">
                <div className="input-heading">Curb Height Reminder</div>

                <div className="drawing-tr">
                  <div className="drawing-li">
                    <li />
                  </div>
                  <div className="drawing-text">
                    All unit height values shown here are NOT inclusive of the unit curb if one is included.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="drawing-heading">Unit Drawing Preview</div>

            <div className="drawing-drawing-grid">
              <div>
                <div className="drawing-btn-label">View:</div>
                {this.getViewButtonOptions()}
              </div>
              <div className="drawing-svg">
                {this.state.drawingState.svg !== '' ? (
                  <div dangerouslySetInnerHTML={{ __html: this.state.drawingState.svg }} />
                ) : null}
              </div>
            </div>

            <div className="drawing-dimensions">
              <div className="drawing-stats">
                <label className="dimensions-label">
                  Cabinet & Base Height: {RoundUpToNearest(this.state.cabinetAndBaseHeight, 1)}"
                </label>
                <label className="dimensions-label">Cabinet Width: {this.state.unit?.casingWidth}"</label>
                <label className="dimensions-label">Cabinet Length: {this.state.unit?.casingLength}"</label>
                <label className="dimensions-label">
                  Shipping Weight: {RoundToNearest(this.state.unit?.shippingWeight! || 0, 100)} lbs.
                </label>
              </div>
              <div className="drawing-stats">
                <label className="dimensions-label">Overall Height: {formatNumber(this.state.unit?.overallHeight, 0)}"</label>
                <label className="dimensions-label">
                  Overall Width: {formatNumber(this.state.unit?.overallWidth, 0)}"
                  <span
                    className="dimensions-info"
                    onMouseEnter={() => this.showTooltip('width', true)}
                    onMouseLeave={() => this.showTooltip('width', false)}
                  ></span>
                  {this.getTooltipContent('width')}
                </label>
                <label className="dimensions-label">
                  Overall Length: {this.state.unit?.overallLength}"
                  <span
                    className="dimensions-info"
                    onMouseEnter={() => this.showTooltip('length', true)}
                    onMouseLeave={() => this.showTooltip('length', false)}
                  ></span>
                  {this.getTooltipContent('length')}
                </label>
                <label className="dimensions-label">
                  {/* Operating Weight: {formatNumber(this.state.unit?.operatingWeight!, 0)} lbs. */}
                  Operating Weight: {RoundToNearest(this.state.unit?.operatingWeight! || 0, 100)} lbs.
                </label>
              </div>
            </div>
          </div>

          <div className="drawing-td3">{/* Take up remaining horizontal space in browser */}</div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus,
    reduxNotifications: state.reduxNotifications
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitDrawing);
