import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import '../../css/App.scss';

// expected props
interface IProps {
  reduxDisplay: any;
}
// local state
interface IState {}

class Loading extends React.Component<IProps, IState> {
  public render() {
    const loadingCSS = this.props.reduxDisplay.showLoader ? 'app-loading' : 'app-display-none';
    const mainCSS = this.props.reduxDisplay.showLoader ? 'app-loading-blocker' : 'app-display-none';
    return  <div className={mainCSS}>
              <div className={loadingCSS}></div>
            </div>;
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxDisplay: state.reduxDisplay
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Loading);
