import * as React from "react";
import { connect } from "react-redux";
import { withRouter, match } from "react-router-dom"; //needed for 'location' object and 'match.params'
import { mapDispatchToProps } from "../../redux/actions";
import { Link } from "react-router-dom";
import "../../css/DesignSubMenu.scss";

// expected props
interface IProps {
  location: any;
  match: match<any>;
  reduxMenus: any;
}
// local state
interface IState {}

class OutputsSubMenu extends React.Component<IProps, IState> {
  private createMenuLink(urlFragment: string, label: string) {
    //Make this the active(underlined) link if it matches the current route
    const isActive = this.isCurrentRoute(urlFragment);
    const baseUrl = this.getBaseUrl();
    return (
      <Link to={baseUrl + urlFragment}>
        <div className={`design-menu-link ${isActive ? " item-selected" : ""}`}>{label}</div>
      </Link>
    );
  }
  private isCurrentRoute(urlFragment: string) {
    return this.props.location.pathname.includes(urlFragment);
  }

  private getBaseUrl() {
    const routeParams = this.props.match.params;
    return "/pid/" + routeParams.projectId + "/uid/" + routeParams.unitId;
  }

  public render() {
    return (
      <div className="design-submenu">
        <div className="design-spacer"></div>
        {this.createMenuLink("/outputs/pdfoutputs", "Output Documents")}
        {this.createMenuLink("/outputs/cadoutputs", "CAD Files")}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OutputsSubMenu));
