import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import {sendSupportEmail} from '../../api-calls/emailDataService';
import '../../css/ContactUs.scss';

// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
}
// local state
interface IState {emailAddress:string, name:string, phone:string, message:string, emailValidationMessage:string,
            phoneValidationMessage:string, messageValidation:string}

class ContactUsModal extends React.Component<IProps, IState> {
  public state: IState = {emailAddress:"", name:"", phone:"", message:"", emailValidationMessage:"", phoneValidationMessage:"", messageValidation:""};
    
  public render() {
    
    return (
      <div className="contactUsModal">
        <div className="title-bar">
            <div className="contact-us-label">CONTACT US</div>
            <div className="close-x" onClick={() => this.userResponse('cancel')}>X</div>
        </div>
        <div className="row">
            <div className="left-column">
                <div className="form-label"><span className="required">*</span>Email Address</div>
                <input
                    className="text-input" 
                    type="text" 
                    placeholder="Enter email address"
                    value={this.state.emailAddress}
                    onChange={(event) => this.setState({'emailAddress': event.currentTarget.value})}
                ></input>
                <div className="validation-problem">{this.state.emailValidationMessage}</div>
                <div className="form-label">Name</div>
                <input 
                    className="text-input" 
                    type="text" 
                    placeholder="Enter name"
                    value={this.state.name}
                    onChange={(event) => this.setState({'name': event.currentTarget.value})}
                ></input>

                <div className="form-label">Phone Number</div>
                <input 
                    className="text-input" 
                    type="text" 
                    placeholder="Enter phone number"
                    value={this.state.phone}
                    onChange={(event) => this.setState({'phone': event.currentTarget.value})}
                ></input><div className="validation-problem">{this.state.phoneValidationMessage}</div>
            </div>
            {/* <div className="right-column"> Keep in case we add content to the right side.
                <div className="form-label"></div>
            </div> */}
        </div>
        <div>
            <div className="form-label"><span className="required">*</span>Message</div>
            <div className="validation-problem">{this.state.messageValidation}</div>
            <textarea 
                className="text-input-message" 
                placeholder="Enter message"
                value={this.state.message}
                onChange={(event) => this.setState({'message': event.currentTarget.value})}>
            </textarea>
        </div>
        <div className="send-button-container">
            <div className="submit-btn" onClick={() => this.sendResponse()}>
                SEND
            </div>
        </div>
      </div>
    );
  }
  private sendResponse(){
  //Validate inputs and send email if valid, otherwise set validation error messages.
    let valid = true;
    if (this.state.emailAddress === ""){
        valid = false;
        this.setState({emailValidationMessage:"Email is required"});
    }
    else {
        this.setState({emailValidationMessage:""});
    }
    if (this.state.message === "") {
        valid = false;
        this.setState({messageValidation:"Message is required"})
    }
    else {
        this.setState({messageValidation:""});
    }
    let validEmail = this.validateEmail(this.state.emailAddress);
    let validPhone = this.validatePhone(this.state.phone);
    if (this.state.emailAddress !== "" && !validEmail){
        valid = false;
        this.setState({"emailValidationMessage": "Email format incorrect"});
    }
    else if (this.state.emailAddress !== "" && validEmail) {
        this.setState({"emailValidationMessage": ""});
    }
    if (this.state.phone !== "" && !validPhone){
        valid = false;
        this.setState({"phoneValidationMessage": "Phone number format incorrect"});
    }
    else if (this.state.phone !== "" && validPhone) {
        this.setState({"phoneValidationMessage": ""});
    }
    if (valid){
        let response = sendSupportEmail(this.state.emailAddress, this.state.message, this.state.name, this.state.phone);
        this.clearFields();
        this.userResponse("cancel");
    }
  }

  private userResponse(response: string) {
    let modalResponse = {
        response:response
    }
    this.clearFields();
    //This will trigger ModalContainer.tsx to hide all modals
    this.props.updateRedux('UPDATE_MODALS', { showModal: false, userResponse: modalResponse});
  }

  private validateEmail(email:string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private validatePhone(phone:string) {
    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;;
    return re.test(phone);
  }
  private clearFields(){
    this.setState({emailAddress:"", name:"", phone:"", message:"", emailValidationMessage:"", phoneValidationMessage:"", messageValidation:""});
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsModal);

