import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, match } from 'react-router-dom'; //needed for 'location' object and 'match.params'
import { mapDispatchToProps } from '../../redux/actions';
import { Link } from 'react-router-dom';
import '../../css/DesignSubMenu.scss';

// expected props
interface IProps {
  location: any;
  match: match<any>;
  reduxMenus: any;
}
// local state
interface IState {}

class DesignSubMenu extends React.Component<IProps, IState> {
  //TODO: animate underlining

  private createMenuLink(urlFragment: string, label: string) {
    //Make this the active(underlined) link if it matches the current route
    const isActive = this.isCurrentRoute(urlFragment);
    const baseUrl = this.getBaseUrl();
    return (
      <Link to={baseUrl + urlFragment}>
        <div className={`design-menu-link ${isActive ? ' item-selected' : ''}`}>{label}</div>
      </Link>
    );
  }
  private isCurrentRoute(urlFragment: string) {
    return this.props.location.pathname.includes(urlFragment);
  }

  private getBaseUrl() {
    const routeParams = this.props.match.params;
    return '/pid/' + routeParams.projectId + '/uid/' + routeParams.unitId;
  }
  private getNextButton() {
    let showPools = this.props.reduxMenus.showPoolInputs;
    let destination = '';

    if (this.isCurrentRoute('/design/unittype') && !showPools ) {
      destination = '/design/config';
    }
    if (this.isCurrentRoute('/design/unittype') && showPools ) {
      destination = '/design/pools';
    }
    if (this.isCurrentRoute('/design/pools') && showPools) {
      destination = '/design/config';
    }
    if (this.isCurrentRoute('/design/config')) {
      destination = '/design/options';
    }
    if (this.isCurrentRoute('/design/options')) {
      destination = '/design/system';
    }
    if (this.isCurrentRoute('/design/system')) {
      destination = '/design/performance';
    }
    if (this.isCurrentRoute('/design/performance')) {
      destination = '/drawing/drawing';
    }

    const baseUrl = this.getBaseUrl();

    return (
      <Link to={baseUrl + destination}>
        <div className="design-next-btn">Next &gt;</div>
      </Link>
    );
  }

  public render() {
    return (
      <div className="design-submenu">
        <div className="design-spacer"></div>
        {this.createMenuLink('/design/unittype', 'Unit Type')}
        {this.props.reduxMenus.showPoolInputs ? this.createMenuLink('/design/pools', 'Pool Space'):null}
        {this.createMenuLink('/design/config', 'Configuration Inputs')}
        {this.createMenuLink('/design/options', 'Inlet & Outlet')}
        {this.createMenuLink('/design/system', 'Temps & System')}
        {this.createMenuLink('/design/performance', 'Optimize Design')}
        {this.getNextButton()}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignSubMenu));
