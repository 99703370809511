import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class UnitSizingLimits extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetUnitSizingLimitsValues", projectId, unitId);
    if (result.uiDataContainer && result.uiDataContainer.fineTuneInputs) {
      this.setState({
        userInputs: result.uiDataContainer.fineTuneInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
  }
  componentWillUnmount(){
    const { projectId, unitId } = this.props.match.params;
    //Make sure to set back casingDimensionLimitType to "None" if no inches are input.
    if (this.state.userInputs.casingDimensionLimitType !== "None" && (this.state.userInputs.casingHeightOrWidthLimit === null ||
        this.state.userInputs.casingHeightOrWidthLimit === "")){
          let result = updateInputField("UpdateUnitSizingLimits", projectId, unitId, "casingDimensionLimitType", "None", "FineTuneInputs");
        }
  }
  private async RunPerformance(){
    const { projectId, unitId } = this.props.match.params;
   
    if (this.state.userInputs.casingDimensionLimitType !== "None" && (this.state.userInputs.casingHeightOrWidthLimit === null ||
      this.state.userInputs.casingHeightOrWidthLimit === "")){
        //Don't run performance.  Must have a value for limit (inches) if the limitType is Height or Width.
        return;
     }
     else {
      this.removeValidationError("casingHeightOrWidthLimit");
     }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for unit sizing and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);

    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
  }
  getCasingHeightWidthDisabled() {
    const basicDetails = this.state.userInputs;
    if (basicDetails && basicDetails.casingDimensionLimitType) {
      if (basicDetails.casingDimensionLimitType !== null) {
        return false;
      }
    }
    return true;
  }

  public getSelectBoxOptions(boxName: any) {
    return [<option value="0">None</option>, <option value="1">Height</option>, <option value="2">Width</option>];
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);

    //Remove validation for casingHeightOrWidthLimit if limit type is set to "None".
    if (fieldName === "casingDimensionLimitType" && newValue === "None"){
      this.removeValidationError("casingHeightOrWidthLimit");
    }
    let result = await updateInputField("UpdateUnitSizingLimits", projectId, unitId, fieldName, newValue, "FineTuneInputs");

    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fineTuneInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //------------------------------------------------------------------------------------------
  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error1");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  public render() {
    const savedValues = this.state.userInputs;

    if (!savedValues) {
      return null;
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">Unit Sizing Limits</legend>

        <div className="">
          <div className="accordion-content">
            <div className="acc-coil-spacer"></div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Cabinet Limit Type</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("casingDimensionLimitType", "accordionselect")}
                    value={savedValues.casingDimensionLimitType || ""}
                    onChange={(event) => this.saveInputValue("casingDimensionLimitType", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "casingDimensionLimitOptions")}
                  </select>
                </div>
                {this.getValidationError("casingDimensionLimitType")}
              </div>
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Cabinet Limit</div>
                <div className="accordion-input">
                  <input
                    type="text"
                    className={this.getCSS("casingHeightOrWidthLimit", "accordiontextbox")}
                    value={savedValues.casingHeightOrWidthLimit != null ? savedValues.casingHeightOrWidthLimit : ""}
                    onChange={(event) => this.updateTextboxValue("casingHeightOrWidthLimit", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("casingHeightOrWidthLimit", event.currentTarget.value)}
                    placeholder="inches"
                  />
                </div>
                {this.getValidationError("casingHeightOrWidthLimit")}
              </div>
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Coil FPM Limit</div>
                <div className="accordion-input">
                  <input
                    type="text"
                    className={this.getCSS("coilAndFilterFpmLimit", "accordiontextbox")}
                    value={savedValues.coilAndFilterFpmLimit || ""}
                    onChange={(event) => this.updateTextboxValue("coilAndFilterFpmLimit", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("coilAndFilterFpmLimit", event.currentTarget.value)}
                  />
                </div>
                {this.getValidationError("coilAndFilterFpmLimit")}
              </div>
            </div>
            <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin" : "perf-reset-btn-small-disabled drawing-update-design-margin"}>
                <div onClick={() => this.RunPerformance()}>Update Design</div>
              </div>
            <div className="acc-spacer"></div>
          </div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitSizingLimits);
