import { store } from "../redux/store";
import { updateReduxAction } from "../redux/actions";
import { AirInletOrOutletType } from "../TypescriptClient";

export function isEmptyObject(obj: any) {
  if (typeof obj === "undefined" || obj === null) {
    return true; //object does not exist
  }
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true; //object exists but is empty
  }
  return false;
}

export function isEmptyArray(arr: any) {
  if (typeof arr === "undefined" || arr === null) {
    return true;
  }
  if (arr.length === null || !(arr.length > 0)) {
    return true;
  }
  return false;
}

export function isNullOrEmpty(item: any) {
  if (typeof item === "undefined" || item === null || item === "") {
    return true;
  }
  return false;
}

//Format with commas and specified number of decimal places
export function formatNumber(value: number, decimalPlaces: number) {
  if (value) {
    const formatted = value.toLocaleString("en-US", {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    });
    return formatted.toString();
  }
  return value;
}

export function isOddNumber(num: number) {
  return num % 2 === 1;
}

//TODO: take a closer look at the generated typescript objects.
//Why do most of the generated classes include " | undefined"?
//That kind of defeats the purpose of strong-types, makes it difficult to access properties
//and requires this function just to get a boolean value.
export function getBooleanValue(property: any) {
  if (property.value && property.value === true) {
    return true;
  }
  return false;
}

export function resetReduxModals() {
  store.dispatch(
    updateReduxAction("UPDATE_MODALS", {
      showModal: false,
      modalType: "",
      modalId: "",
      modalData: null,
      userResponse: null,
      hideCancelBtn: false
    })
  );
}

export function getConnectionTypeName(connectionType?: AirInletOrOutletType) {
  switch (connectionType) {
    case AirInletOrOutletType.Duct:
      return "Duct";
    case AirInletOrOutletType.Hood:
      return "Hood";
    case AirInletOrOutletType.Louver:
      return "Louver";
    default:
      return "";
  }
}

export function sqlToJsDate(sqlDate: any) {
  if (sqlDate) {
    //convert to javascript date
    let dt = new Date(sqlDate);

    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;
  } else {
    return "";
  }
}

export function sqlToJsDateTime(sqlDate: any) {
  if (sqlDate) {
    //convert to javascript date and time
    let dt = new Date(sqlDate);

    let hours = dt.getHours();
    let minutes = dt.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let minutesStr = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutesStr + " " + ampm;

    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()} \xa0\xa0 ${strTime}`; //NOTE: \xa0 is a 'space'
  } else {
    return "";
  }
}
