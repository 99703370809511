import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../../redux/actions";
import { updateInputField } from "../../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptionsSingle,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../../utilities/pageUtils";
import { isEmptyObject } from "../../../../utilities/helpers";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  title: string;
  constructionOptions: any;
  componentData: any;
  userInputs: any;
  targetVertex: string;
  match: match<any>;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class DamperDisplayGroup extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };
  componentDidMount() {
    const componentData = this.props.componentData;

    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: this.props.userInputs,
        displayFields: componentData.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    
    let result = await updateInputField("updateDamper", projectId, unitId, fieldName, newValue, this.props.targetVertex);

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.damperInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------

  public render() {
    //const damperPanelItem = this.props.damperPanelItem;
    const savedValues = this.state.userInputs;
    if (isEmptyObject(savedValues)) {
      return null;
    }
    return (
      <div className="spec-dampers-container">
        <div className="spec-heading">{this.props.title}</div>

        {this.props.targetVertex === "ExhaustAirDamperInputs" ? (
          <div className="spec-input">
            <select
              className="spec-selectbox"
              value={savedValues.motorizedOrGravity}
              onChange={(event) => this.saveInputValue("motorizedOrGravity", event.currentTarget.value)}
            >
              {getSelectBoxOptions(this.state.dropdownOptions, "motorizedOrGravityOptions")}
            </select>
            {this.getValidationError("motorizedOrGravity")}
          </div>
        ) : (
          <div className="spec-input">Motorized</div>
        )}

        {savedValues.motorizedOrGravity === "Gravity" ? (
          <div className="spec-input">{savedValues.damperConstruction}</div>
        ) : (
          <div className="spec-input">
            <select
              className="spec-selectbox"
              value={savedValues.damperConstruction}
              onChange={(event) => this.saveInputValue("damperConstruction", event.currentTarget.value)}
            >
              {getSelectBoxOptionsSingle(this.props.constructionOptions)}
            </select>
            {this.getValidationError("damperConstruction")}
          </div>
        )}

        {/* Blade */}
        <div className="spec-input">{savedValues.bladeType}</div>

        {/* Actuator */}
        <div className="spec-input">{savedValues.actuatorType === "TwoPosition" ? "Two-Position" : savedValues.actuatorType}</div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(DamperDisplayGroup);
