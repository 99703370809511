import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getTempsAndSystemValues, updateInputField } from "../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import "../../css/TempsSystem.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  disableInputs: boolean;
}

class TempsSystem extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    disableInputs: false
  };

  async componentDidMount() {
    // await getTempsAndSystemInputs(true, this.props.match.params);
    // this.checkForWinterDryBulbError();
    const { projectId, unitId } = this.props.match.params;
    const result = await getTempsAndSystemValues(projectId, unitId);

    if (result.hasError) {
      const notification = {
        id: "tempsAndSystemInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">getTempsAndSystemValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    this.setState({
      userInputs: result.uiDataContainer.primaryInputs,
      displayFields: result.uiDataContainer.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors
    });
    this.checkForWinterDryBulbError(result.uiDataContainer.displayFields, result.uiDataContainer.primaryInputs.energyRecoveryDeviceType);
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    if (this.state.disableInputs) {
      return;
    }
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    this.setState({ disableInputs: true });
    let result = await updateInputField("updateTempsAndSystem", projectId, unitId, fieldName, newValue, "PrimaryInputs");
    this.setState({ disableInputs: false });
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.primaryInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }

      //Special case if we updated outsideWinterDryBulb. Check if we need to display the notification box.
      if (result.data.uiDataContainer != null && fieldName === "outsideWinterDryBulb") {
        this.checkForWinterDryBulbError(result.data.uiDataContainer.displayFields, result.data.uiDataContainer.primaryInputs.energyRecoveryDeviceType);
      }
    }
  }

  componentWillUnmount() {
    //Remove warning when navigating to another page.
    removeNotification("winterdrybulb");
  }

  //------------------------------------------------------------------------------------------
  // private async updateWinterDryBulb(newValue: string) {
  //   if (this.state.lastUpdatedTextbox !== 'outsideWinterDryBulb') {
  //     return;
  //   }
  //   await this.saveInputValue('outsideWinterDryBulb', newValue);
  //   this.checkForWinterDryBulbError();
  // }

  private checkForWinterDryBulbError(displayFields: any, energyRecoveryDeviceType: any) {
    //const displayFields = this.state.displayFields;
    //console.log(energyRecoveryDeviceType);
    if (displayFields.showWinterDryBulbWarning) {
      let message = "";
      if (energyRecoveryDeviceType === "SensiblePlate") {
        message = "Preheat recommended to avoid plate frosting.";
      } else if (energyRecoveryDeviceType === "EnthalpyWheel") {
        message = "Preheat recommended to avoid wheel frosting.";
      } else {
        message = "Please select an energy recovery device.";
      }

      const notification = {
        id: "winterdrybulb",
        notificationType: "warning",
        title: "Warning",
        content: <div className="nf-div">{message}</div>
      };
      addNotification(notification);
    } else {
      removeNotification("winterdrybulb");
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  public render() {
    const savedValues = this.state.userInputs;

    const displayFields = this.state.displayFields;
    let oaConditionsClass = "";
    if (displayFields?.summerDryBulbFieldName?.length > 27){
      oaConditionsClass = "oaConditionsLabel";
    }
    if (!savedValues) {
      return null;
    }

    return (
      <div className="system-inputs-main">
        <div className="system-input-container">
          <div className="system-addl-components">
            <div className="system-heading">Location</div>
            <div className="input-fields">
              <div>
                <div className="system-input-label">Elevation</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    disabled={displayFields.elevationReadOnly}
                    className={this.getCSS("elevation", "textbox")}
                    value={savedValues.elevation}
                    onChange={(event) => this.updateTextboxValue("elevation", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("elevation", event.currentTarget.value)}
                  />
                </div>
                {this.getValidationError("elevation")}
              </div>

              <div className="system-label-sm">(For elevations below 2000 ft, airflows shall be treated as standard air)</div>
            </div>

            <div className="input-heading">Outside Air Conditions</div>
            <div className="input-fields">
              <div className={oaConditionsClass}>
                <div className="system-input-label">{displayFields.summerDryBulbFieldName}</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    disabled={displayFields.summerDryBulbReadOnly}
                    className={this.getCSS("outsideSummerDryBulb", "textbox")}
                    value={savedValues.outsideSummerDryBulb || ""}
                    onChange={(event) => this.updateTextboxValue("outsideSummerDryBulb", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("outsideSummerDryBulb", event.currentTarget.value)}
                  />
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("outsideSummerDryBulb")}
              </div>

              <div className={oaConditionsClass}>
                <div className="system-input-label">{displayFields.summerWetBulbFieldName}</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    disabled={displayFields.summerWetBulbReadOnly}
                    className={this.getCSS("outsideSummerWetBulb", "textbox")}
                    value={savedValues.outsideSummerWetBulb || ""}
                    onChange={(event) => this.updateTextboxValue("outsideSummerWetBulb", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("outsideSummerWetBulb", event.currentTarget.value)}
                  />
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("outsideSummerWetBulb")}
              </div>

              <div>
                <div className="system-input-label">Winter Dry Bulb</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    className={this.getCSS("outsideWinterDryBulb", "textbox")}
                    value={savedValues.outsideWinterDryBulb || savedValues.outsideWinterDryBulb === 0 ? savedValues.outsideWinterDryBulb : ""}
                    onChange={(event) => this.updateTextboxValue("outsideWinterDryBulb", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("outsideWinterDryBulb", event.currentTarget.value)}
                  />
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("outsideWinterDryBulb")}
              </div>
            </div>
          </div>

          <div className="system-fields-left">
            <div className="input-heading">External SP and Power Supply</div>

            <div className="input-fields">
              <div>
                <div className="system-input-label">{displayFields.renameSupplyESPToTotalESP ? "Total ESP" : "Supply ESP"}</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    className={this.getCSS("supplyEsp", "textbox")}
                    value={savedValues.supplyEsp}
                    onChange={(event) => this.updateTextboxValue("supplyEsp", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("supplyEsp", event.currentTarget.value)}
                  />
                </div>
                {this.getValidationError("supplyEsp")}
              </div>

              {displayFields.showReturnESP ? (
                <div>
                  <div className="system-input-label">Return ESP</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      className={this.getCSS("returnEsp", "textbox")}
                      value={savedValues.returnEsp}
                      onChange={(event) => this.updateTextboxValue("returnEsp", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("returnEsp", event.currentTarget.value)}
                    />
                  </div>
                  {this.getValidationError("returnEsp")}
                </div>
              ) : null}

              <div>
                <div className="system-input-label">Unit Electrical - Voltage</div>
                <div className="input-textbox">
                  <select
                    className={this.getCSS("unitElectricalVoltage", "selectbox")}
                    value={savedValues.unitElectricalVoltage}
                    onChange={(event) => this.saveInputValue("unitElectricalVoltage", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "unitVoltageOptions")}
                  </select>
                </div>
                {this.getValidationError("unitElectricalVoltage")}
              </div>
            </div>
          </div>
        </div>

        <div className="system-input-container2">
          {displayFields.showReturnAirConditions ? (
            <div>
              <div className="system-heading">Return Air Conditions</div>
              <div className="system-next-btn"></div>
              <div className="input-fields">
                <div>
                  <div className="system-input-label">Summer Dry Bulb</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.returnAirConditionsReadOnly}
                      className={this.getCSS("returnSummerDryBulb", "textbox")}
                      value={savedValues.returnSummerDryBulb}
                      onChange={(event) => this.updateTextboxValue("returnSummerDryBulb", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("returnSummerDryBulb", event.currentTarget.value)}
                    />
                  </div>
                  {this.getValidationError("returnSummerDryBulb")}
                </div>

                <div>
                  <div className="system-input-label">Summer Relative Humidity %</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.returnAirConditionsReadOnly}
                      className={this.getCSS("returnSummerRelativeHumidity", "textbox")}
                      value={savedValues.returnSummerRelativeHumidity}
                      onChange={(event) => this.updateTextboxValue("returnSummerRelativeHumidity", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("returnSummerRelativeHumidity", event.currentTarget.value)}
                    />
                  </div>
                  {this.getValidationError("returnSummerRelativeHumidity")}
                </div>

                <div>
                  <div className="system-input-label">Winter Dry Bulb</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.returnAirConditionsReadOnly}
                      className={this.getCSS("returnWinterDryBulb", "textbox")}
                      value={savedValues.returnWinterDryBulb}
                      onChange={(event) => this.updateTextboxValue("returnWinterDryBulb", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("returnWinterDryBulb", event.currentTarget.value)}
                    />
                  </div>
                  {this.getValidationError("returnWinterDryBulb")}
                </div>

                <div>
                  <div className="system-input-label">Winter Relative Humidity %</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.returnAirConditionsReadOnly}
                      className={this.getCSS("returnWinterRelativeHumidity", "textbox")}
                      value={savedValues.returnWinterRelativeHumidity}
                      onChange={(event) => this.updateTextboxValue("returnWinterRelativeHumidity", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("returnWinterRelativeHumidity", event.currentTarget.value)}
                    />
                  </div>
                  {this.getValidationError("returnWinterRelativeHumidity")}
                </div>
              </div>{" "}
            </div>
          ) : null}

          {displayFields.showLeavingAirConditions ? <div className="input-heading">Leaving Air Conditions</div> : null}
          <div className="input-fields">
            {displayFields.showCoolingDryBulbTemp ? (
              <div>
                <div className="system-input-label">Cooling Off-Coil Dry Bulb</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    disabled={displayFields.leavingAirConditionsReadOnly}
                    className={this.getCSS("coolingOffCoilDryBulb", "textbox")}
                    value={savedValues.coolingOffCoilDryBulb}
                    onChange={(event) => this.updateTextboxValue("coolingOffCoilDryBulb", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("coolingOffCoilDryBulb", event.currentTarget.value)}
                  />
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("coolingOffCoilDryBulb")}
              </div>
            ) : null}

            {displayFields.showReheatTemp ? (
              <>
                <div>
                  <div className="system-input-label">Reheat Dry Bulb</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.leavingAirConditionsReadOnly}
                      className={this.getCSS("reheatTemp", "textbox")}
                      value={savedValues.reheatTemp}
                      onChange={(event) => this.updateTextboxValue("reheatTemp", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("reheatTemp", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                  </div>
                  {this.getValidationError("reheatTemp")}
                </div>
              </>
            ) : null}
            {displayFields.showPreheatDryBulbTemp ? (
              <>
                <div>
                  <div className="system-input-label">Preheat Dry Bulb</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      className={this.getCSS("preheatDryBulb", "textbox")}
                      value={savedValues.preheatDryBulb}
                      onChange={(event) => this.updateTextboxValue("preheatDryBulb", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("preheatDryBulb", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                  </div>
                  {this.getValidationError("preheatDryBulb")}
                </div>
              </>
            ) : null}

            {displayFields.showHeatingDryBulbTemp ? (
              <div>
                <div className="system-input-label">Heating Dry Bulb</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    disabled={displayFields.leavingAirConditionsReadOnly}
                    className={this.getCSS("heatingTemp", "textbox")}
                    value={savedValues.heatingTemp}
                    onChange={(event) => this.updateTextboxValue("heatingTemp", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("heatingTemp", event.currentTarget.value)}
                  />
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("heatingTemp")}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(TempsSystem);
