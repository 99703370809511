import React from "react";
import IdentityContext from "../security/IdentityContext";
import { Redirect, Route } from "react-router-dom";

// expected props
interface IProps {
  component: any;
  path: any;
}
// local state
interface IState {
  isLoggedIn: boolean | null;
}

class PrivateRoute extends React.Component<IProps, IState> {
  public state: IState = { isLoggedIn: null };

  //Connect to global IdentityContext (https://reactjs.org/docs/context.html)
  static contextType = IdentityContext;
  private readonly identityService = this.context;

  //This is just a private variable we use to hold the promise object for the call to identityService.isLoggedIn
  _asyncRequest: any;

  async componentDidMount() {
    //This is an odd solution for an odd use case. The render method needs to know if the user is logged in, but that
    //depends on an Async call to identityService.isLoggedIn(), and a render method in React can't be async.  So we have
    //to use this.state.

    //But since this is a routing component, it can get loaded/unloaded multiple times and the combination of
    //async promise objects, state, and routing/redirects here can cause an infinite loop.  So we have to use the
    //variable "_asyncRequest" to hold the promise, and have the ability to cancel it in the componentWillUnmount method.
    //The example comes from here:
    //https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html

    this._asyncRequest = this.identityService.isLoggedIn();
    const loggedIn = await this._asyncRequest;
    this._asyncRequest = null;
    this.setState({ isLoggedIn: loggedIn });
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  public render() {
    const isLoggedIn = this.state.isLoggedIn;

    //If the async call to identityService.isLoggedIn() hasn't finished yet, don't try to load a route.  Just return a div.
    if (isLoggedIn == null) {
      return <div>loading.........</div>;
    }

    //This copies all the properties from "this.props" but it renames "this.props.component" to "Component" so we can refer
    //to it that way below. The "...rest" means just copy the rest of the properties as-is.
    const { component: Component, ...rest } = this.props;

    //This is hard to read, but what its doing is:
    //1. Rendering a <Route /> and passing all props through to it.
    //2. If user is logged in, it returns whatever "Component" was passed in.
    //3. If not, it redirects to login page.
    return (
      //TODONEW: Put this back once identityservice is working
      <Route
        {...rest}
        render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: "/loginstart", state: { from: props.location } }} />)}
      />

      //<Route {...rest} render={(props) => <Component {...props} />} />
    );
  }
}

export default PrivateRoute;
