import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import {getFanCurveChart} from '../../api-calls/chartDataService';
import '../../css/Modals.scss';

// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
}
// local state
interface IState {chartData:any; chartError:boolean, loading:boolean}

class FanCurveModal extends React.Component<IProps, IState> {
    public state: IState = {chartData:null, chartError:false, loading:false};

  //This is a specific modal for displaying a fan curve chart
  async componentDidMount() {
    let data = this.props.reduxModals.modalData;
    let projectId = data.projectId;
    let unitId = data.unitId;
    let selectedFan = data.selectedFan;
    this.setState({loading:true});
    let chart = await getFanCurveChart(projectId, unitId, selectedFan);
    if(chart != null){
        let url = URL.createObjectURL(chart);
        this.setState({chartData:url, loading:false});
    }
    else{
        this.setState({chartError: true, loading:false});
    }
  }
  public render() {
    
    return (
      <div className="fanCurveModal">

        {this.state.loading ? (<div className="fancurve-content fancurve-loading"></div>) : null}

        {this.state.chartData != null ? <img src={this.state.chartData} alt="" /> : null}

        {this.state.chartError ? <div className="fancurve-content">
            <div className="fancurve-error">
                Unable to create fan curve chart based on the available data.
                <br/><br/> Please update unit design and try again.</div>
            </div> : null}
                

        <div className="button-container">
            <div className="proj-create-btn" onClick={() => this.userResponse('cancel')}>
                Close
            </div>
        </div>
      </div>
    );
  }


  private userResponse(response: string) {
    let modalResponse = {
        response:response,
        unitId:this.props.reduxModals.modalData.id
    }
    //This will trigger ModalContainer.tsx to hide all modals
    this.props.updateRedux('UPDATE_MODALS', { showModal: false, userResponse: modalResponse});
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxProjectManagement: state.reduxProjectManagement,
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FanCurveModal);

