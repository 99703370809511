import { store } from '../redux/store';
import { updateReduxAction } from '../redux/actions';
import {
  ProjectClient,
  TypedRefAtom,
  TypedRefPathDto,
  DrawingViewType,
  PropertyModification,
  UIProperty,
  DrawingScreen
} from '../TypescriptClient';
import { clearAllModifyPropertyErrors, updateEditedFields } from './validations';
import {
  distributeComponentsUI,
  distributeInletOutlets,
  distributeComponentConstructionPanels,
  distributeElectrical
} from './accordion-data';
import { formatData, ConfigInputFormats, TempsSystemFormats, formatPerformanceData } from './formatting';
import { IdentityService } from '../security/IdentityService';
const baseUrl = '';

export async function getUnitSelection(routeParams: any) {
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;
  try {
    const unitDefinition = await projClient.getUnitSelectionUI(projectId, unitId);

    store.dispatch(updateReduxAction('UPDATE_UNIT_DEFINITION', unitDefinition));
  } catch (error) {
    displayError(error, 'getUnitSelectionUI');
  }
}

//Purpose: Get ConfigurationInputs object from the API and put into reduxConfigInputs
export async function getConfigInputs(initialLoad: boolean, routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const configInputs = await projClient.getConfigurationInputsUI(projectId, unitId);

    //If this is the first call (at page load) we need to clear out all the validation messages
    //that come back from the server.  (Don't want a bunch of red errors appearing right away)
    if (initialLoad) {
      store.dispatch(updateReduxAction('UPDATE_VALIDATION', { userEditedFields: [] }));
    }

    const newState = formatData(configInputs, ConfigInputFormats);

    //1. Update redux with the configInputsUI values that come from the Lowry API endpoint.
    store.dispatch(updateReduxAction('UPDATE_CONFIG_INPUTS', newState));
  } 
  catch (error) {
    displayError(error, 'getConfigurationInputsUI');
  }
}

//Purpose: Get ConnectionsOptions object from the API and put into reduxConnections
export async function getConnectionsOptions(initialLoad: boolean, routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const connectInputs = await projClient.getConnectionsAndOptionsUI(projectId, unitId);

    //If this is the first call (at page load) we need to clear out all the validation messages
    //that come back from the server.  (Don't want a bunch of red errors appearing right away)
    if (initialLoad) {
      store.dispatch(updateReduxAction('UPDATE_VALIDATION', { userEditedFields: [] }));
    }

    store.dispatch(updateReduxAction('UPDATE_CONNECTIONS', connectInputs));
  } catch (error) {
    displayError(error, 'getConnectionsAndOptionsUI');
  }
}

//Purpose: Get TempsAndSystem object from the API and put into reduxTempsAndSystem
export async function getTempsAndSystemInputs(initialLoad: boolean, routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const tempAndSystemInputs = await projClient.getTempsAndSystemUI(projectId, unitId);

    //If this is the first call (at page load) we need to clear out all the validation messages
    //that come back from the server.  (Don't want a bunch of red errors appearing right away)
    if (initialLoad) {
      store.dispatch(updateReduxAction('UPDATE_VALIDATION', { userEditedFields: [] }));
    }

    const newState = formatData(tempAndSystemInputs, TempsSystemFormats);

    store.dispatch(updateReduxAction('UPDATE_TEMPS_SYSTEM', newState));
  } catch (error) {
    displayError(error, 'getTempsAndSystemUI');
  }
}

//Purpose: Get Performance object from the API and put into reduxPerformance
export async function getPerformance(initialLoad: boolean, routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));
    const performanceUIpromise = projClient.getPerformanceUI(projectId, unitId);
    const optimizedDesignPerformancePromise = projClient.getOptimizedDesignPerformance(projectId, unitId);
    const responses = await Promise.all([performanceUIpromise, optimizedDesignPerformancePromise]);
    const performanceScreenResult = responses[0];
    const optimizedDesignPerformance = responses[1];

    //If inputs are missing, don't show design performance errors.
    if (optimizedDesignPerformance.hasError === true && !performanceScreenResult.propertyErrorsByScreenName){
      displayErrorString(optimizedDesignPerformance.errorMessage ?? "Unknown Error Occured", "getOptimizedDesignPerformance");
    }
    //If this is the first call (at page load) we need to clear out all the validation messages
    //that come back from the server.  (Don't want a bunch of red errors appearing right away)
    if (initialLoad) {
      store.dispatch(updateReduxAction('UPDATE_VALIDATION', { userEditedFields: [] }));
    }

    if (performanceScreenResult.propertyErrorsByScreenName) {
      store.dispatch(
        updateReduxAction('UPDATE_PREREQUISITE_ERRORS', {
          errorList: performanceScreenResult.propertyErrorsByScreenName
        })
      );
    } else {
      const newState = formatPerformanceData(performanceScreenResult.uiScreen, optimizedDesignPerformance.designPerformanceResult!);
      store.dispatch(updateReduxAction('UPDATE_PREREQUISITE_ERRORS', { errorList: null }));
      store.dispatch(updateReduxAction('UPDATE_PERFORMANCE', newState));
    }
  } catch (error) {
    displayError(error, 'getPerformanceUI');
  } finally {
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
  }
}

//Purpose: Get UnitDefinition object from the API (it contains all the data objects for accordions)
export async function getAccordions(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const componentsUI = await projClient.getComponentsUI(projectId, unitId);
    //ComponentsUI will be null if required inputs are not valid.  This call is for the
    //optimize design screen and if ComponentsUI is null, the missing field modal will show
    // and the screen will be blank.
    if (componentsUI !== null){
      distributeComponentsUI(componentsUI);
    }
    
  } catch (error) {
    displayError(error, 'getComponentsUI');
  }
}

//Purpose: Get Performance object from the API and put into reduxPerformance
export async function getAccessEditingAndDrawingUI(initialLoad: boolean, routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const drawingInputs = await projClient.getAccessEditingAndDrawingUI(projectId, unitId);

    //If this is the first call (at page load) we need to clear out all the validation messages
    //that come back from the server.  (Don't want a bunch of red errors appearing right away)
    if (initialLoad) {
      store.dispatch(updateReduxAction('UPDATE_VALIDATION', { userEditedFields: [] }));
    }

    store.dispatch(updateReduxAction('UPDATE_EDIT_DRAWING', drawingInputs));
  } catch (error) {
    displayError(error, 'getAccessEditingAndDrawingUI');
  }
}

export async function getAccessDoorsUI(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const accessDoors = await projClient.getAccessDoorsUI(projectId, unitId);
    store.dispatch(updateReduxAction('UPDATE_ACCESS_DOORS', accessDoors));
  } catch (error) {
    displayError(error, 'getAccessEditingAndDrawingUI');
  }
}
export async function getCoilConnectionsUI(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const rslt = await projClient.getCoilConnectionsUI(projectId, unitId);
    store.dispatch(updateReduxAction('UPDATE_COIL_CONNECTIONS', rslt));
  } catch (error) {
    displayError(error, 'getCoilConnectionsUI');
  }
}
export async function getElectFurnacePanelsUI(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const rslt = await projClient.getElectFurnacePanelsUI(projectId, unitId);
    store.dispatch(updateReduxAction('UPDATE_ELECTRICAL_FURNACE_PANELS', rslt));
  } catch (error) {
    displayError(error, 'getElectFurnacePanelsUI');
  }
}
export async function getLengthInputsUI(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const rslt = await projClient.getLengthInputsUI(projectId, unitId);
    store.dispatch(updateReduxAction('UPDATE_LENGTH_INPUTS', rslt));
  } catch (error) {
    displayError(error, 'getLengthInputsUI');
  }
}

//Purpose: Get UnitDrawing from the API
export async function getUnitDrawing(routeParams: any, viewType: DrawingViewType, uiScreen: DrawingScreen) {
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const apiResponse = await projClient.getUnitDrawingSvg(projectId, unitId, viewType, uiScreen);
    return apiResponse;
  } catch (error) {
    displayError(error, 'getUnitDrawingSvg');
  }
  return null;
}

export async function getInletOutletDrawing(routeParams: any, selectedConnection: string) {
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const apiResponse = await projClient.getInletOutletDrawingSvg(projectId, unitId, selectedConnection);
    return apiResponse;
  } catch (error) {
    displayError(error, 'getInletOutletDrawingSvg');
  }
  return null;
}

//Purpose: Get all 4 Inlet/Outlet objects from the API
export async function getInletsOutlets(initialLoad: boolean, routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const inletsOutlets = await projClient.getInletOutletSizingUI(projectId, unitId);
    distributeInletOutlets(inletsOutlets);
  } catch (error) {
    displayError(error, 'getInletOutletSizingUI');
  }
}

//Purpose: Get data for the component construction screens
export async function getComponentConstruction(routeParams: any) {
  // clearAllModifyPropertyErrors();
  // const projClient = await getClientWithAuth();
  // const { projectId, unitId } = routeParams;

  // try {
  //   const data = await projClient.getComponentConstructionUI(projectId, unitId);
  //   distributeComponentConstructionPanels(data);
  // } catch (error) {
  //   displayError(error, 'getComponentConstructionUI');
  // }
}

//Purpose: Get data for the spec/electrical screens
export async function getElectrical(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const data = await projClient.getElectricalUI(projectId, unitId);
    distributeElectrical(data);
  } catch (error) {
    displayError(error, 'getElectrical');
  }
}

//Purpose: Get data for the spec/controls screen
export async function getControls(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const data = await projClient.getControlsUI(projectId, unitId);
    store.dispatch(updateReduxAction('UPDATE_CONTROLS', data));
  } catch (error) {
    displayError(error, 'getControls');
  }
}

//Purpose: Get data for the spec/unit cabinet screen
export async function getUnitCabinet(routeParams: any) {
  clearAllModifyPropertyErrors();
  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const data = await projClient.getUnitCabinetUI(projectId, unitId);
    store.dispatch(updateReduxAction('UPDATE_UNITCABINET', data));
  } catch (error) {
    displayError(error, 'getUnitCabinet');
  }
}
//Purpose: Delete a project by Id
export async function deleteProject(projectId: any) {
  const projClient = await getClientWithAuth();

  try {
    const apiResponse = await projClient.deleteProject(projectId);
    store.dispatch(updateReduxAction('UPDATE_ENTITY_TYPES', {}));
    return apiResponse.status;
  } catch (error) {
    displayError(error, 'deleteProject');
  }
  return 0;
}

//Purpose: Get ConfigurationInputs object from the API and put into reduxConfigInputs
export async function runAdjustedPerformance(routeParams: any) {
//Update redux to invalidate the unit price
store.dispatch(updateReduxAction('UPDATE_PRICING', { priceNeedsUpdate: true }));

  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));
    const apiResponse = await projClient.runPerformanceCalculations(projectId, unitId);
    if (apiResponse.status === 200) {
      return 'success';
    } else {
      displayErrorString("Request Failed", "RunPerformanceCalculations");
      return 'failed';
    }
  } catch (error) {
    displayError(error, 'runPerformanceCalculations');
    return 'failed';
  } finally {
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
  }
}

/**
 * Makes a resetToOptimized API call which clears almost all the user's performance screen changes
 */
export async function resetToOptimized(routeParams: any) {
//Update redux to invalidate the unit price
store.dispatch(updateReduxAction('UPDATE_PRICING', { priceNeedsUpdate: true }));

  const projClient = await getClientWithAuth();
  const { projectId, unitId } = routeParams;

  try {
    const apiResponse = await projClient.resetToOptimized(projectId, unitId);
    if (apiResponse.status === 200) {
      return 'success';
    } else {
      displayErrorString("Request Failed", "resetToOptimized");
      return 'failed';
    }
  } catch (error) {
    displayError(error, 'resetToOptimized');
    return 'failed';
  }
}

//Saves newValue for a property to "save file". 
//Returns configInputsUI
export async function modifyConfigInputsProperty(
  reduxObject: any,
  propertyName: string,
  newValue: any,
  projectId: string,
  unitId: number
) {
  // const projClient = await getClientWithAuth();
  // const propertyMod = createPropertyModification(unitId, newValue, propertyName, reduxObject);

  // if (propertyMod) {
  //   updateEditedFields(propertyName.toString()); //let redux know the user has edited this field
  //   try {
  //     return await projClient.modifyConfigInputs(projectId, propertyMod);
  //   } 
  //   catch (error) {
  //     displayError(error, 'modifyConfigInputsProperty');
  //     return {hasError:true, errorMessage: "server 500 error"};
  //   }
  //   // catch (error) {
  //   //   if (error.status === 401) {
  //   //     displayError(error, 'modifyConfigInputsProperty');
  //   //     return "failed";
  //   //   } else {
  //   //     return "failed";
  //   //   }
  //   // }
  // }
}
export async function UpdateMultipleUnits(projectId:any, unitIds:Array<number>){
  const projClient = await getClientWithAuth();
  const reduxMenus = store.getState().reduxMenus;

   //Check if another save is already in progress
  if(reduxMenus.enableSave == false){
    return;
  }

  try {      
    
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));
    
    //Disable saving while a save is in progress (prevents multiple save calls at the same time)
    store.dispatch(updateReduxAction('UPDATE_MENUS', { enableSave: false }));

    let response = await projClient.updateMultipleUnits(projectId, unitIds);

    store.dispatch(updateReduxAction('UPDATE_MENUS', { enableSave: true }));
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));

    let hasError = response.hasError ? "failed": "success";
    if (hasError === "failed"){
      let ErrorObj = {
        status: 500,
        message: "UpdateMultipleUnits failed " + response.errorMessage
      }
      store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
      displayError(ErrorObj, "UpdateMultipleUnits");
    }
    return hasError;
  } catch (error) {
    store.dispatch(updateReduxAction('UPDATE_MENUS', { enableSave: true }));
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
    let ErrorObj = {
      status: 500,
      message: "UpdateMultipleUnits failed" 
    }
    displayError(ErrorObj, "UpdateMultipleUnits");
    return "failed";
  }  
}

//Update full unit details in the database
//NOTE: this call doesn't return anything.
export async function saveUnitDesign(projectId:any, unitId:any, showLoader:boolean = false) {
  const projClient = await getClientWithAuth();
  const reduxMenus = store.getState().reduxMenus;

   //Check if another save is already in progress
  if(reduxMenus.enableSave == false){
    return;
  }

  try {      
    if (showLoader){
      store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));
    }
    //Disable saving while a save is in progress (prevents multiple save calls at the same time)
    store.dispatch(updateReduxAction('UPDATE_MENUS', { enableSave: false }));

    let response = await projClient.updateUnitDesign(projectId, unitId);

    store.dispatch(updateReduxAction('UPDATE_MENUS', { enableSave: true }));
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));

    let hasError = response.hasError ? "failed": "success";
    if (hasError === "failed"){
      let ErrorObj = {
        status: 500,
        message: "updateUnitDesign failed " + response.errorMessage
      }
      displayError(ErrorObj, "UpdateUnitDesign");
    }
    return hasError;
  } catch (error) {
    store.dispatch(updateReduxAction('UPDATE_MENUS', { enableSave: true }));
    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
    let ErrorObj = {
      status: 500,
      message: "updateUnitDesign failed" 
    }
    displayError(ErrorObj, "UpdateUnitDesign");
    return "failed";
  }
}

//Saves newValue for a property to "save file". Does not return any response.
export async function modifyServerProperty(
  reduxObject: any,
  propertyName: string,
  newValue: any,
  projectId: string,
  unitId: number
) {
  //Update redux to invalidate the unit price
  store.dispatch(updateReduxAction('UPDATE_PRICING', { priceNeedsUpdate: true }));

  const projClient = await getClientWithAuth();
  const propertyMod = createPropertyModification(unitId, newValue, propertyName, reduxObject);

  if (propertyMod) {
    updateEditedFields(propertyName.toString()); //let redux know the user has edited this field

    try {
      console.log("ModifyProperty, UnitId:"+unitId);
      await projClient.modifyProperty(projectId, propertyMod);
      return "success";
    } catch (error) {
      // if (error.status === 401) {
      //   displayError(error, 'modifyProperty');
      //   return {apiErrorCode:401, errorMessage: "unauthorized"};
      // } else {
        //displayError(error, 'modifyServerProperty ' + propertyMod.propertyName);
        return error;
     // }
    }
  }
}

export async function modifySelectedModelProperty<T, K extends keyof T>(
  reduxObject: T,
  propertyName: K,
  newValue: any,
  projectId: string,
  unitId:number
) {
    //Update redux to invalidate the unit price
    store.dispatch(updateReduxAction('UPDATE_PRICING', { priceNeedsUpdate: true }));

  const projClient = await getClientWithAuth();
  const propertyMod = createPropertyModification(unitId, newValue, propertyName, reduxObject);
  if (propertyMod) {
    try {
      return await projClient.modifySelectedModelProperty(projectId, propertyMod);
    } catch (error) {
      // if (error.status === 401) {
      //   displayError(error, 'modifySelectedModelProperty');
      // } else {
      //   throw error;
      // }
      return error;
    }
  }
}

export async function modifyCasingSizeLimitProperty<T, K extends keyof T>(
  reduxObject: T,
  propertyName: K,
  newValue: any,
  projectId: string,
  unitId:number
) {
    //Update redux to invalidate the unit price
    store.dispatch(updateReduxAction('UPDATE_PRICING', { priceNeedsUpdate: true }));
    
  const projClient = await getClientWithAuth();
  const propertyMod = createPropertyModification(unitId, newValue, propertyName, reduxObject);
  if (propertyMod) {
    updateEditedFields(propertyName.toString()); //let redux know the user has edited this field
    try {
      return await projClient.modifyCasingSizeLimitProperty(projectId, propertyMod);
    } catch (error) {
      // if (error.status === 401) {
      //   displayError(error, 'modifyCasingSizeLimitProperty');
      // } else {
      //   throw error;
      // }
      return error;
    }
  }
}

function createPropertyModification<T, K extends keyof T>(unitId: number, newValue: any, propertyName: K, reduxObject: T) {
  const reduxProperty = reduxObject[propertyName];
  const id = (reduxProperty as any)['id'] as string;
  const typedRefPath = createTypedRefPath(id, true, reduxProperty as any); //pointer to back-end class
  if (reduxProperty) {
    return {
      unitId: unitId,
      propertyName: id.split('.').slice(-1)[0], // Property name is the last item in the refPath
      entityTypedRefPath: typedRefPath,
      newValue: newValue
    } as PropertyModification;
  }
  return null;
}

//Delete a property from the save file
//This not only removes the user-entered value, but also deletes the property from the save file altogether.
export async function deleteServerProperty<T, K extends keyof T>(
  reduxObject: T,
  propertyName: K,
  projectId: string,
  unitId: number
) {
  const projClient = await getClientWithAuth();
  const reduxProperty = reduxObject[propertyName];

  if (reduxProperty) {
    const id = (reduxProperty as any)['id'] as string;
    const typedRefPath = createTypedRefPath(id, true, reduxProperty as any); //pointer to back-end class

    try {
      await projClient.deleteProperty(projectId, {
        propertyName: id.split('.').slice(-1)[0], // Property name is the last item in the refPath
        entityTypedRefPath: typedRefPath
      });
    } catch (error) {
      // if (error.status === 401) {
      //   displayError(error, 'deleteProperty');
      // } else {
      //   throw error;
      // }
      return error;
    }
  }
}
export async function getProjectJsonFile(routeParams: any) {
  const projClient = await getClientWithAuth();
  const { projectId } = routeParams;

  try {
    return await projClient.getProjectJson(projectId);
  } catch (error) {
    displayError(error, 'getProjectJson');
    return null;
  }
}

//Purpose: Get an Auth token to use when making API calls
export async function getClientWithAuth() {
  const projClient = new ProjectClient(baseUrl);
  const auth = store.getState().reduxAuth;
  let token = '';
  //Check to see if reduxAuth contains the access_token. 
  //Using access_token from Redux prevents a bunch of calls to identityserver,
  //but on page reload, sometimes redux doesn't get populated fast enough, and the page tries
  //to call the server with no token, and fails.  So in that case we should get it from IdentityServer.
  if(auth.access_token !== ''){
    token = auth.access_token;
  }
  else{
    const identityService = new IdentityService();
    const user = await identityService.getUser();
    token = user.access_token;
  }
  

  projClient.transformOptions = (opts) => {
    const headers = (opts as RequestInit).headers;
    (opts as RequestInit).headers = { ...headers, Authorization: `Bearer ${token}` };
    return Promise.resolve(opts);
  };

  return projClient;
}

//Purpose: something to do with pointers to API objects
//TODO: Nate fill in detail here
//Parameter2: id: "Root.UnitCollection.Units[0].UnitTag"
//Parameter3: true
export function createTypedRefPath(refPath: string, stripPropertyFromEnd: boolean, uiProperty: UIProperty) {
  const refPathAtoms = refPath.split('.');
  if (stripPropertyFromEnd) {
    refPathAtoms.pop(); // Remove the property name atom
  }
  const typedAtoms = refPathAtoms.map((refAtom) => ({ value: refAtom } as TypedRefAtom));
  return { atoms: typedAtoms, entityType: uiProperty.entityType } as TypedRefPathDto;
}

//Looks like this can take in a standard http error object or a custom error object.
export function displayError(error: any, source: string) {
  const reduxStore = store.getState();

  //Only display this error if there's not already an error showing
  if (reduxStore.reduxServerErrors.showError !== true) {
    const errorObj: any = { showError: true, errorSource: source };
    if (error.status === 401) {
      errorObj.errorType = 401;
    } else {
      errorObj.errorType = 500;
      errorObj.errorMessage = `${source} ${error.status} Error:  ${(error.message?.substring(0,650) || '')}`;
    }

    store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
    store.dispatch(updateReduxAction('UPDATE_SERVER_ERROR', errorObj));
  }
}
export function displayErrorString(errorMessage: string, source: string) {
  const reduxStore = store.getState();
  const errorObj: any = { showError: true, errorSource: source };
  //Only display this error if there's not already an error showing
  
  
  if (reduxStore.reduxServerErrors.showError !== true) {
      errorObj.errorType = 500;
      errorObj.errorMessage = source + ' Error: ' + (errorMessage?.substring(0,650) || '');
  }
  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
  store.dispatch(updateReduxAction('UPDATE_SERVER_ERROR', errorObj));
}