import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { Link } from "react-router-dom";
import "../../css/TopMenu.scss";

// expected props
interface IProps {}
// local state
interface IState {}

//This is a specific header for the project management page, that doesn't have all the chevron options.
class ProjectManagementHeader extends React.Component<IProps, IState> {
  public render() {
    return (
      <div className="topmenu">
        <div className="topmenu-link topmenu-selected">Job Files</div>
        <div className="topmenu-arrow topmenu-arrow-selected"></div>
      </div>

      // <div className="app-project-design">
      //   <div className="breadcrumbcontainer">
      //     <div className="breadcrumb">
      //       <Link to={'/project'} className=" active">
      //         <span className="breadcrumb__inner">
      //           <span>Job Files</span>
      //         </span>
      //       </Link>
      //       <div className="proj-mgmt-header-spacer"></div>

      //       <a className="breadcrumb-last">
      //         <span className="breadcrumb__inner">
      //           <span></span>
      //         </span>
      //       </a>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    // reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementHeader);
