import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import {
  addNotification,
  clearAllPerformanceNotifications,
  getErrorNotifications,
  accordionHasError,
  removeNotification
} from "../../../api-utilities/notification-tools";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  componentData: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}
class PrimaryPower extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const componentData = this.props.componentData;
    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: componentData.uiDataContainer.controlsInputs,
        displayFields: componentData.uiDataContainer.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }
  //   async componentDidUpdate(prevProps: IProps) {
  //     console.log(prevProps.heaterType + ":" + this.props.heaterType);
  //     if (prevProps.heaterType != this.props.heaterType) {
  //       await this.getFilterData(this.props.heaterType);
  //     }
  //   }

  //   private async getFilterData(heaterType: string) {
  //     const { projectId, unitId } = this.props.match.params;
  //     const result = await getFilterInputValues(projectId, unitId, heaterType);

  //     if (result.uiDataContainer.filterInputs) {
  //       this.setState({
  //         userInputs: result.uiDataContainer.filterInputs,
  //         displayFields: result.uiDataContainer.displayFields,
  //         dropdownOptions: result.dropdownOptions,
  //         validationErrors: result.validationErrors
  //       });
  //     }
  //   }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateElectrical", projectId, unitId, fieldName, newValue, "ControlsInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.controlsInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------
  public render() {

    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }

    return (
      <div className="spec-fields">
        <div className="spec-field-spacer1"></div>

        <div className="spec-heading-2">Primary Power Circuit - Disconnect</div>

        <div className="spec-elec-fields">
          <div className="spec-input-label-2">Voltage</div>
          <div className="spec-input-2">{displayFields.voltageLabel}</div>
          <br />

          <div className="spec-input-label-2">SCCR</div>
          <div className="spec-input-2">
            <select
              className="spec-selectbox"
              value={savedValues.shortCircuitCurrentRating || ""}
              onChange={(event) => this.saveInputValue("shortCircuitCurrentRating", event.currentTarget.value)}
            >
              {getSelectBoxOptions(this.state.dropdownOptions, "shortCircuitCurrentRatingOptions")}
            </select>
            {this.getValidationError("shortCircuitCurrentRating")}
          </div>

          <div>
            <div className="spec-input-label-2">Disconnect Fusing</div>
            <div className="spec-input-3-Left">
              <input
                className=""
                type="checkbox"
                checked={savedValues.hasDisconnectFusing || false}
                disabled={displayFields.disconnectFusingDisabled ? true : false}
                onChange={(event) => this.saveInputValue("hasDisconnectFusing", event.currentTarget.checked.toString())}
              />
            </div>
          </div>
          {/* <div>{this.props.reduxElectrical.disconnectFusingDisabled}</div>  */}
          <div>
            <div className="spec-input-label-2">Phase/Voltage Protection</div>
            <div className="spec-input-3-Left">
              <input
                className=""
                type="checkbox"
                disabled={displayFields.phaseVoltageProtectionDisabled ? true : false}
                checked={savedValues.hasPhaseVoltageProtection || false}
                onChange={(event) => this.saveInputValue("hasPhaseVoltageProtection", event.currentTarget.checked.toString())}
              />
            </div>
          </div>
        </div>

        {/* <div className="spec-field-spacer1"></div> */}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PrimaryPower);
