import { IAction } from '../../interfaces/interfaces';

//Default values 
let defaultValues:any = {
    showResetToOptimized: false,
    showRecalcButton: false,
    showUserModifiedValues: false,
    selectedComponent:null,
    selectedComponentName: ""
}

export const reduxOptimizeDesign = (state = defaultValues, action: IAction) => {
  if (action.type === 'UPDATE_OPTIMIZE_DESIGN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};