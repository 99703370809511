import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { callGetEndpoint } from "../../../api-calls/readApiService";
import { isEmptyObject } from "../../../utilities/helpers";
import { addNotification } from "../../../api-utilities/notification-tools";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
}
// local state
interface IState {
  userInputs: any;
  operatingMode: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  idfComponent: any;
}
class IndirectFiredFurnace extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    operatingMode: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    idfComponent: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetIndirectFiredFurnaceInputValues", projectId, unitId);

    if (result.hasError){
      const notification = {
        id: "idfInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetIndirectFiredFurnaceInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
  }

    if (result.uiDataContainer) {
      this.setState({
        idfComponent: result.uiDataContainer.idf,
        operatingMode: result.uiDataContainer.idfOpMode
        //displayFields: result.uiDataContainer.displayFields,
        //dropdownOptions: result.dropdownOptions,
        //validationErrors: result.validationErrors
      });
    }
  }

  //------------------------------------------------------------------------------------------

  public render() {
    let idfComponent = this.state.idfComponent;
    let operatingMode = this.state.operatingMode;

    if (!idfComponent) {
      return null;
    }

    if (isEmptyObject(operatingMode)) {
      const notification = {
        id: "coilOperatingModeError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">Indirect fired furnace operating mode not found.</div>
      };
      addNotification(notification);
      operatingMode = {};
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">Indirect Fired Furnace</legend>

        <div className="accordion-content">
          <div className="acc-fan-spacer"></div>

          {/* {getErrorNotifications(this.props.reduxIDF.errorMessages)} */}

          <div className="accordion-row">
            <div className="accordion-label">Entering Air Temp</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(operatingMode.enteringDryBulb, 1) || ""}</div>
            </div>
            <div className="accordion-label">Leaving Air Temp</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.leavingDryBulb, 1) || ""}</div>
          </div>

          <div className="accordion-row">
            <div className="accordion-label-50">Model</div>
            <div className="accordion-input-230">
              <div className="acc-label-ellip acc-label-170">{idfComponent.arrayDisplayString}</div>
            </div>
            <div className="accordion-label">Output MBH</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.leavingMBHOut,1)}</div>
          </div>

          <div className="accordion-row"></div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(IndirectFiredFurnace);
