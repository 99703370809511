import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../../../redux/actions";
import AccordionHeader from "../../../Shared/AccordionHeader";
import CoilDisplayGroup from "./CoilDisplayGroup";
import CoilDisplayGroupDX from "./CoilDisplayGroupDX";
import CoilDisplayGroupHGRH from "./CoilDisplayGroupHGRH";
import { isEmptyObject } from "../../../../utilities/helpers";
import { callGetEndpoint } from "../../../../api-calls/readApiService";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  title: string;
  updateRedux: any;
  // reduxHotWaterCoilPanel: CoilPanelItem;
  // reduxHotWaterPreheatPanel: CoilPanelItem;
  // reduxChilledWaterCoilPanel: CoilPanelItem;
  // reduxSteamCoilPanel: CoilPanelItem;
  // reduxDXCoilPanel: CoilPanelItem;
  // reduxHGRHCoilPanel: CoilPanelItem;
  match: match<any>;
}
// local state
interface IState {
  openAccordion: boolean;
  componentData: any;
}

class Coils extends React.Component<IProps, IState> {
  public state: IState = {
    openAccordion: false,
    componentData: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint("readapi/GetAllCoilInputValues", projectId, unitId);
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    if (!isEmptyObject(result)) {
      this.setState({
        componentData: result
      });
    }
  }

  private togglePanel() {
    this.setState({ openAccordion: !this.state.openAccordion }); //toggle to opposite of current state
  }

  public render() {
    const active = this.state.openAccordion;
    const componentData = this.state.componentData;
    if (isEmptyObject(componentData)) {
      return null;
    }

    const displayFields = componentData.uiDataContainer.displayFields;
    
    return (
      <div className="">
        <AccordionHeader
          title={this.props.title}
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.togglePanel()}
        />

        <div className={`acc-panel-1000 ${active === true ? "acc-panel-active" : "acc-panel-inactive"}`}>
          <div className="accordion-content app-box-shadow">
            <div className="spec-fields">
              <div className="spec-field-spacer1"></div>
              <div className="spec-coils-container">
                <div className="spec-input-spacer" />
                <div className="spec-input-label">Casing Construction</div>
                <div className="spec-input-label">Coating</div>
                <div className="spec-input-label">Tube Thickness</div>
                <div className="spec-input-label">Fin Material</div>
                <div className="spec-input-label">Fin Thickness</div>
                {/* <div className="spec-input-label">UV Lights</div> */}
              </div>
              {displayFields.showPreHeatHotWaterCoil ? (
                <CoilDisplayGroup
                  title="Hot Water Preheat"
                  targetVertex="PreHeatHotWaterCoilInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.preHeatHotWaterCoilInputs}
                  match={this.props.match}
                />
              ) : null}
              {displayFields.showHotWaterCoil ? (
                <CoilDisplayGroup
                  title="Hot Water"
                  targetVertex="HotWaterCoilInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.hotWaterCoilInputs}
                  match={this.props.match}
                />
              ) : null}
              {displayFields.showChilledWaterCoil ? (
                <CoilDisplayGroup
                  title="Chilled Water"
                  targetVertex="ChilledWaterCoilInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.chilledWaterCoilInputs}
                  match={this.props.match}
                />
              ) : null}
              {displayFields.showSteamCoil ? (
                <CoilDisplayGroup
                  title="Steam"
                  targetVertex="SteamCoilInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.steamCoilInputs}
                  match={this.props.match}
                />
              ) : null}
              {displayFields.showDxCoil ? (
                <CoilDisplayGroupDX
                  title="Direct Expansion"
                  targetVertex="RefrigerationInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.refrigerationInputs}
                  match={this.props.match}
                />
              ) : null}
              {displayFields.showHotGasReheatCoil ? (
                <CoilDisplayGroupHGRH
                  title="Hot Gas Reheat"
                  targetVertex="RefrigerationInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.refrigerationInputs}
                  match={this.props.match}
                />
              ) : null}
              <div className="spec-field-spacer2"></div>
              <div className="spec-field-spacer1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Coils);
