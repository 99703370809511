import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import "../../css/UnitCopyModal.scss";
// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
  reduxProjectManagement: any;
}
// local state
interface IState {
  projectName: string;
  selectedProjectId: string;
}

class ProjectCopyModal extends React.Component<IProps, IState> {
  public state: IState = { projectName: "", selectedProjectId: "" };

  //This is a specific modal for copying a unit to the project selected by the user.
  componentDidMount() {
    //Default Unit tag name...User may edit.
    let projectName = "Copy of " + this.props.reduxModals.modalData.projectName;
    this.setState({ projectName: projectName, selectedProjectId: this.props.reduxModals.modalData.id });
  }
  public render() {
    let disabled = this.state.projectName === "" || this.state.selectedProjectId === "" ? "disabledDiv" : "";

    return (
      <div className="copyUnit">
        <div className="header">Copy Job</div>
        <div className="unitName">
          <label className="unitModalLabel">Job Name</label>
          <input
            className="unitTagInput"
            type="text"
            placeholder="Job Name"
            value={this.state.projectName}
            onChange={(event) => this.setState({ projectName: event.currentTarget.value })}
          />
        </div>

        <div className="button-container">
          <div className={"proj-create-btn " + disabled} onClick={() => this.userResponse("ok")}>
            Copy
          </div>
          <div className="proj-cancel-btn" onClick={() => this.userResponse("cancel")}>
            Cancel
          </div>
        </div>
      </div>
    );
  }

  private userResponse(response: string) {
    let copyProjectResponse = {
      response: response,
      projectId: this.state.selectedProjectId,
      projectName: this.state.projectName
    };
    //This will trigger the componentDidUpdate method on ProjectManagement.tsx
    this.props.updateRedux("UPDATE_MODALS", { showModal: false, userResponse: copyProjectResponse });
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxProjectManagement: state.reduxProjectManagement,
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCopyModal);
