import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import { addNotification} from "../../../api-utilities/notification-tools";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  reduxComponentPerformance:any;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  operatingMode: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}
class SteamCoil extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    operatingMode: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await callGetEndpoint("readapi/GetCoilInputValues/SteamCoilInputs", projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError) {
      const notification = {
        id: "steamCoilInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetCoilInputValues/SteamCoilInputs {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.coilInputs) {
      this.setState({
        userInputs: result.uiDataContainer.coilInputs,
        operatingMode: result.uiDataContainer.steamCoilOpMode,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
  }
  private async RunPerformance(){
    const { projectId, unitId } = this.props.match.params;
   
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for steam coil and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);
    const result = await callGetEndpoint("readapi/GetCoilInputValues/SteamCoilInputs", projectId, unitId);
    if (result.hasError) {
      const notification = {
        id: "steamCoilInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetCoilInputValues/SteamCoilInputs {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.coilInputs) {
      this.setState({
        userInputs: result.uiDataContainer.coilInputs,
        operatingMode: result.uiDataContainer.steamCoilOpMode,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateCoil", projectId, unitId, fieldName, newValue, "SteamCoilInputs");

    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.coilInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error0");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------

  public render() {
    const steamCoil = this.props.reduxComponentPerformance.steamCoil;
    if (isEmptyObject(steamCoil)) {
      return null;
    }
    
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;
    let operatingMode = this.state.operatingMode;

    if (!savedValues) {
      return null;
    }

    if (isEmptyObject(operatingMode)) {
      const notification = {
        id: "coilOperatingModeError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">Steam coil operating mode not found.</div>
      };
      addNotification(notification);
      operatingMode = {};
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">Steam Coil</legend>

        <div className="accordion-content">
          <div className="accordion-row">
            <div className="accordion-label">Entering Air Temp</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(operatingMode.enteringDryBulb, 1) || ""}</div>
            </div>
            <div className="accordion-label">Rows</div>
            <div className="accordion-label-numeric">{steamCoil.rows}</div>
          </div>
          <div className="accordion-row">
            <div className="accordion-label">Leaving Air Temp</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(operatingMode.leavingDryBulb, 1) || ""}</div>
            </div>
            <div className="accordion-label">Fins Per Inch</div>
            <div className="accordion-label-numeric">{steamCoil.finsPerInch}</div>
          </div>
          <div className="accordion-row">
            <div className="acc-fieldset">
              <div className="accordion-label">Steam Pressure</div>
              <div className="accordion-input">
                <input
                  type="text"
                  className={this.getCSS("steamPressure", "accordiontextbox")}
                  value={formatNumber(savedValues.steamPressure, 1)}
                  onChange={(event) => this.updateTextboxValue("steamPressure", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("steamPressure", event.currentTarget.value)}
                />
              </div>
              {this.getValidationError("steamPressure")}
            </div>
            <div className="accordion-label">Air Pressure Drop</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.airPressureDrop, 2)}</div>
          </div>
          <div className="accordion-row">
            <div className="accordion-label"></div>
            <div className="accordion-input"></div>
            <div className="accordion-label">Condensate (lb/hr)</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.condensate, 1)}</div>
          </div>
          <div className="accordion-row"></div>
          <div className="acc-spacer"></div>
          <div>
            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Rows Override</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("rows", "accordionselect")}
                    value={savedValues.rows || ""}
                    onChange={(event) => this.saveInputValue("rows", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "rowsOptions")}
                  </select>
                </div>
                {this.getValidationError("rows")}
              </div>

              <div className="acc-fieldset">
                <div className="accordion-label">Tube Thickness</div>
                <div className="accordion-input-100">
                  <select
                    className={this.getCSS("tubeWallThickness", "accordionselect")}
                    value={savedValues.tubeWallThickness}
                    onChange={(event) => this.saveInputValue("tubeWallThickness", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "tubeWallThicknessOptions")}
                  </select>
                </div>
                {this.getValidationError("tubeWallThickness")}
              </div>
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Fins per Inch Override</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("finsPerInch", "accordionselect")}
                    value={savedValues.finsPerInch || ""}
                    onChange={(event) => this.saveInputValue("finsPerInch", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "finsPerInchOptions")}
                  </select>
                </div>
                {this.getValidationError("finsPerInch")}
              </div>

              <div className="acc-fieldset">
                <div className="accordion-label">Fin Material</div>
                <div className="accordion-input-100">
                  <select
                    className={this.getCSS("finMaterial", "accordionselect")}
                    value={savedValues.finMaterial}
                    onChange={(event) => this.saveInputValue("finMaterial", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "finMaterialOptions")}
                  </select>
                </div>
                {this.getValidationError("finMaterial")}
              </div>
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Tube Diameter</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("tubeDiameter", "accordionselect")}
                    value={savedValues.tubeDiameter}
                    onChange={(event) => this.saveInputValue("tubeDiameter", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "tubeDiameterOptions")}
                  </select>
                </div>
                {this.getValidationError("tubeDiameter")}
              </div>

              <div className="acc-fieldset">
                <div className="accordion-label">Fin Thickness</div>
                <div className="accordion-input-100">
                  <select
                    className={this.getCSS("finThickness", "accordionselect")}
                    value={savedValues.finThickness}
                    onChange={(event) => this.saveInputValue("finThickness", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "finThicknessOptions")}
                  </select>
                </div>
                {this.getValidationError("finThickness")}
              </div>
            </div>
            <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin" : "perf-reset-btn-small-disabled drawing-update-design-margin"}>
                <div onClick={() => this.RunPerformance()}>Update Design</div>
              </div>
            <div className="acc-spacer"></div>
          </div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxComponentPerformance: state.reduxComponentPerformance,
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SteamCoil);
