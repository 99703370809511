import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { isEmptyObject } from "../../../utilities/helpers";
import { updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptionsSingle,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  title: string;
  fanData: any;
  targetVertex:string;
  match: match<any>;
}
// local state
interface IState {
  fanInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  targetVertex: string;
  disableManualBypass:boolean;
}

class FanVFDDisplayGroup extends React.Component<IProps, IState> {
  async componentDidMount() {
    const fanData = this.props.fanData;
    if (!isEmptyObject(fanData)) {
      if (this.props.title === "Supply"){
        this.setState({
          dropdownOptions: fanData.fanDropDownOptions.supplyFanDriveTypeOptions,
          displayFields: fanData.fanUIDataContainer.displayFields,
          disableManualBypass: fanData.fanUIDataContainer.displayFields.disableSupplyManualBypass,
          fanInputs: fanData.fanUIDataContainer.supplyFanInputs,
          validationErrors: fanData.validationErrors,
          targetVertex: this.props.targetVertex
        });
      }
      if (this.props.title === "Exhaust"){
        this.setState({
          dropdownOptions: fanData.fanDropDownOptions.exhaustFanDriveTypeOptions,
          displayFields: fanData.fanUIDataContainer.displayFields,
          disableManualBypass: fanData.fanUIDataContainer.displayFields.disableExhaustManualBypass,
          fanInputs: fanData.fanUIDataContainer.exhaustFanInputs,
          validationErrors: fanData.validationErrors,
          targetVertex: this.props.targetVertex
        });
      }
      if (this.props.title === "Return"){
        this.setState({
          dropdownOptions: fanData.fanDropDownOptions.returnFanDriveTypeOptions,
          displayFields: fanData.fanUIDataContainer.displayFields,
          disableManualBypass: fanData.fanUIDataContainer.displayFields.disableReturnManualBypass,
          fanInputs: fanData.fanUIDataContainer.returnFanInputs,
          validationErrors: fanData.validationErrors,
          targetVertex: this.props.targetVertex
        });
      }
    }
  }
                   
  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    //this.removeValidationError(fieldName);

    let result = await updateInputField("updateFan", projectId, unitId, fieldName, newValue, this.state.targetVertex);
    if (result.success) {
      //If there are any errors in the response, add them to state
      // if (result.data.validationErrors != null) {
      //   this.addValidationErrors(result.data.validationErrors);
      // }

      //If there are any dropdownLists present in the response, then update those in state
      // if (result.data.dropdownOptions != null) {
      //   this.updateDropdowns(result.data.dropdownOptions);
      // }
      
      //if uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        var disableManualBypass;
        if (this.state.targetVertex === "SupplyFanInputs"){
          disableManualBypass = result.data.uiDataContainer.displayFields.disableSupplyManualBypass;
        }
        if (this.state.targetVertex === "ExhaustFanInputs"){
          disableManualBypass = result.data.uiDataContainer.displayFields.disableExhaustManualBypass;
        }
        if (this.state.targetVertex === "ReturnFanInputs"){
          disableManualBypass = result.data.uiDataContainer.displayFields.disableReturnManualBypass;
        }
        this.setState({
          fanInputs: result.data.uiDataContainer.fanInputs,
          displayFields: result.data.uiDataContainer.displayFields,
          disableManualBypass: disableManualBypass
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------

  public render() {
    if (isEmptyObject(this.state)) {
      return null;
    }
    const savedValues = this.state.fanInputs;
    const displayFields = this.state.displayFields;
    return (
      <div className="spec-coils-container">
        <div className="spec-heading-fan">{this.props.title}</div>
        {/* FanArrayVfdSharing */}
        <div className="spec-input">
          <select className="spec-selectbox" value={savedValues.fanArrayVfdSharing} onChange={(event) => this.saveInputValue("fanArrayVfdSharing", event.currentTarget.value)}>
            {getSelectBoxOptionsSingle(this.state.dropdownOptions)}
          </select>
        </div>

        {/* Allow Microdrives */}
        <div>
          <div className="spec-input-3-Left">
            <input
              disabled={displayFields.disableMicroDrivesCheckbox}
              className=""
              type="checkbox"
              checked={getChecked(savedValues.allowMicroDrives)}
              onChange={(event) => this.saveInputValue("allowMicrodrives", event.currentTarget.checked.toString())}
            />
          </div>
        </div>

        {/* Manual Bypass*/}
        <div>
          <div className="spec-input-3-Left">
            <input
              disabled={this.state.disableManualBypass}
              className=""
              type="checkbox"
              checked={getChecked(savedValues.hasManualBypass)}
              onChange={(event) => this.saveInputValue("hasManualBypass", event.currentTarget.checked.toString())}
            />
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(FanVFDDisplayGroup);
