import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../../redux/actions";
import { updateInputField } from "../../../../api-calls/readApiService";
import {
  getValidationError,
  convertTubeWallThickness,
  getSelectBoxOptions,
  getInputCSS,
  convertFinThickness,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../../utilities/pageUtils";
import { isEmptyObject } from "../../../../utilities/helpers";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  title: string;
  //component: string;
  //actionType: string;
  //fanVfdItem: FanVfdItem;
  componentData: any;
  userInputs: any;
  targetVertex: string;
  match: match<any>;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class CoilDisplayGroup extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };
  componentDidMount() {
    //this.checkVFDSharing();
    const componentData = this.props.componentData;

    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: this.props.userInputs,
        displayFields: componentData.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }
  componentWillUnmount() {
    //Remove warning when navigating to another page.
    //removeNotification("fanArrayvfdsharing");
  }
  componentDidUpdate(prevProps: any) {
    //showVFDSharingErrorMessage changed, see whether or not to show the notification.
    // if (prevProps.fanVfdItem.showVfdSharingErrorMessage !== this.props.fanVfdItem.showVfdSharingErrorMessage) {
    //   this.checkVFDSharing();
    // }
  }
  private checkVFDSharing() {
    // if (this.props.fanVfdItem.showVfdSharingErrorMessage) {
    //   const notification = {
    //     id: "fanArrayvfdsharing",
    //     notificationType: "warning",
    //     title: "Warning",
    //     content: <div className="nf-div">{"No VFD large enough for full array."}</div>
    //   };
    //   addNotification(notification);
    // } else {
    //   removeNotification("fanArrayvfdsharing");
    // }
  }
  //   async componentDidMount() {
  //     await this.getFilterData(this.props.heaterType);
  //   }

  //   async componentDidUpdate(prevProps: IProps) {
  //     console.log(prevProps.heaterType + ":" + this.props.heaterType);
  //     if (prevProps.heaterType != this.props.heaterType) {
  //       await this.getFilterData(this.props.heaterType);
  //     }
  //   }

  //   private async getFilterData(heaterType: string) {
  //     const { projectId, unitId } = this.props.match.params;
  //     const result = await getFilterInputValues(projectId, unitId, heaterType);

  //     if (result.uiDataContainer.filterInputs) {
  //       this.setState({
  //         userInputs: result.uiDataContainer.filterInputs,
  //         displayFields: result.uiDataContainer.displayFields,
  //         dropdownOptions: result.dropdownOptions,
  //         validationErrors: result.validationErrors
  //       });
  //     }
  //   }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);

    let result = await updateInputField("updateCoil", projectId, unitId, fieldName, newValue, this.props.targetVertex);

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.coilInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  private getCoilCoatingOptionsName(title:string){
    //Steam, Chilled Water, Hot Water, Hot Water Preheat
    if (title === "Steam" || title === "Hot Water"){
      return "heatingCoilCoatingOptions";
    }
    if (title === "Chilled Water"){
      return "chilledWaterCoilCoatingOptions";
    }
    if (title === "Hot Water Preheat"){
      return "preheatCoilCoatingOptions";
    }
    if (title === "Direct Expansion"){
      return "dxCoilCoatingOptions";
    }
    if (title === "Hot Gas Reheat"){
      return "hgrCoilCoatingOptions";
    }
    return "";
  }
  //------------------------------------------------------------------------------------------
  public render() {
    const savedValues = this.state.userInputs;
    const coilCoatingOptionsName = this.getCoilCoatingOptionsName(this.props.title);
    if (isEmptyObject(savedValues)) {
      return null;
    }
    return (
      <div className="spec-coils-container">
        <div className="spec-heading">{this.props.title}</div>
        {/* Casing Construction */}
        <div className="spec-input">
          <select
            className="spec-selectbox"
            value={savedValues.casingConstruction || ""}
            onChange={(event) => this.saveInputValue("casingConstruction", event.currentTarget.value)}
          >
            {getSelectBoxOptions(this.state.dropdownOptions, "casingMaterialOptions")}
          </select>
          {this.getValidationError("casingConstruction")}
        </div>
        {/* Coating */}
        <div className="spec-input">
          <select
            className="spec-selectbox"
            value={savedValues.coilCoating || ""}
            onChange={(event) => this.saveInputValue("coilCoating", event.currentTarget.value)}
          >
            {getSelectBoxOptions(this.state.dropdownOptions, coilCoatingOptionsName)}
          </select>
          {this.getValidationError("coating")}
        </div>
        {/* Tube Thickness */}
        <div className="spec-input">{convertTubeWallThickness(savedValues.tubeWallThickness)}</div>
        {/* Fin Material */}
        <div className="spec-input">{savedValues.finMaterial}</div>
        {/* Fin Thickness */}
        <div className="spec-input">{convertFinThickness(savedValues.finThickness)}</div>
        {/* Include UV Lights */}
        {/* {coilPanelItem.componentName === "Chilled Water Coil" || coilPanelItem.componentName === "DX Coil" ? (
          <div className="spec-input">
            <input
              className=""
              type="checkbox"
              checked={this.getChecked(this.props.coilPanelItem.includeUVLights)}
              onChange={(event) => this.inputBuilder.updateCheckbox('includeUVLights', event)}
            />
          </div>
        ) : (
          <div className="spec-input-spacer" />
        )} */}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  //ownProps:  Allows us to use this.props.component to decide which redux object to subscribe to
  // const coilPanelItem = (() => {
  //   switch (ownProps.component) {
  //     case 'reduxHotWaterCoilPanel':
  //       return state.reduxHotWaterCoilPanel;
  //     case 'reduxHotWaterPreheatPanel':
  //       return state.reduxHotWaterPreheatPanel;
  //     case 'reduxChilledWaterCoilPanel':
  //       return state.reduxChilledWaterCoilPanel;
  //     case 'reduxSteamCoilPanel':
  //       return state.reduxSteamCoilPanel;
  //     case 'reduxDXCoilPanel':
  //       return state.reduxDXCoilPanel;
  //     case 'reduxHGRHCoilPanel':
  //       return state.reduxHGRHCoilPanel;
  //     default:
  //       return null;
  //   }
  // })();
  // return {
  //   coilPanelItem
  // };
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(CoilDisplayGroup);
