import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import { addNotification } from "../../../api-utilities/notification-tools";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  targetVertex: string;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  operatingMode: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  humidifierManifoldComponent: any;
  enteringRHPercent: any;
}
class HumidifierManifold extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    operatingMode: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    humidifierManifoldComponent: null,
    enteringRHPercent: null
  };

  async componentDidMount() {
    await this.getHumidifierManifoldData(this.props.targetVertex);
  }

  async componentDidUpdate(prevProps: IProps) {
    if (prevProps.targetVertex != this.props.targetVertex) {
      await this.getHumidifierManifoldData(this.props.targetVertex);
    }
  }

  private async getHumidifierManifoldData(targetVertex: string) {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint(`readapi/GetHumidifierManifoldInputValues/${targetVertex}`, projectId, unitId);

    if (result.uiDataContainer && result.uiDataContainer.humidifierManifoldInputs) {
      this.setState({
        userInputs: result.uiDataContainer.humidifierManifoldInputs,
        operatingMode: result.uiDataContainer.humidifierManifoldOpMode,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        humidifierManifoldComponent: result.uiDataContainer.humidifierManifold,
        enteringRHPercent: result.uiDataContainer.enteringRHPercent
      });
    }
  }
  private async RunPerformance(targetVertex: string) {
    const { projectId, unitId } = this.props.match.params;
   
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for humidifier manifold and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);
    const result = await callGetEndpoint(`readapi/GetHumidifierManifoldInputValues/${targetVertex}`, projectId, unitId);

    if (result.uiDataContainer && result.uiDataContainer.humidifierManifoldInputs) {
      this.setState({
        userInputs: result.uiDataContainer.humidifierManifoldInputs,
        operatingMode: result.uiDataContainer.humidifierManifoldOpMode,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        humidifierManifoldComponent: result.uiDataContainer.humidifierManifold,
        enteringRHPercent: result.uiDataContainer.enteringRHPercent
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;

    this.removeValidationError(fieldName);
    let result = await updateInputField("updateHumidifierManifold", projectId, unitId, fieldName, newValue, this.props.targetVertex);
    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }
      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.humidifierManifoldInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------

  public render() {
    let savedValues = this.state.userInputs;
    let humidifierManifoldComponent = this.state.humidifierManifoldComponent;
    let displayFields = this.state.displayFields;
    let operatingMode = this.state.operatingMode;

    if (!savedValues) {
      return null;
    }

    if (isEmptyObject(operatingMode)) {
      const notification = {
        id: "coilOperatingModeError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">Humidifier manifold operating mode not found.</div>
      };
      addNotification(notification);
      operatingMode = {};
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">Humidifier Manifold</legend>

        <div className="accordion-content">
          <div className="acc-fan-spacer"></div>
          <div className="accordion-row">
            <div className="acc-fieldset">
              <div className="accordion-label">Air Temp</div>
              <div className="accordion-input">
                <div className="accordion-label-numeric2">{formatNumber(operatingMode.enteringDryBulb, 1)}</div>
              </div>
            </div>
            <div className="accordion-label">Absorption Distance</div>
            <div className="accordion-label-numeric">{humidifierManifoldComponent.absorptionDistance}</div>
          </div>

          <div className="accordion-row">
            <div className="acc-fieldset">
              <div className="accordion-label">Entering RH%</div>
              <div className="accordion-input">
                <div className="accordion-label-numeric2">{formatNumber(this.state.enteringRHPercent, 1)}</div>
              </div>
            </div>
            <div className="acc-fieldset">
              <div className="accordion-label">Steam Pressure(PSIG)</div>
              <div className="accordion-input-100">
                <input
                  type="text"
                  disabled={!displayFields.steamPressureEnabled}
                  className={this.getCSS("steamPressure", "accordiontextbox-50")}
                  value={formatNumber(savedValues.steamPressure,0)}
                  onChange={(event) => this.updateTextboxValue("steamPressure", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("steamPressure", event.currentTarget.value)}
                />
              </div>
              {this.getValidationError("steamPressure")}
            </div>
          </div>

          <div className="accordion-row">
            <div className="acc-fieldset">
              <div className="accordion-label">Desired Leaving RH%</div>
              <div className="accordion-input">
                <input
                  type="text"
                  className={this.getCSS("desiredLeavingRH", "accordiontextbox")}
                  value={formatNumber(savedValues.desiredLeavingRH, 0)}
                  onChange={(event) => this.updateTextboxValue("desiredLeavingRH", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("desiredLeavingRH", event.currentTarget.value)}
                />
              </div>
              {this.getValidationError("desiredLeavingRH")}
            </div>
            <div className="accordion-label">Steam Capacity(lb/hr)</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.steamCapacity, 1) || ""}</div>
          </div>

          <div className="accordion-row">
            <div className="acc-fieldset">
              <div className="accordion-label">Steam Source</div>
              <div className="accordion-input">
                <select
                  className="spec-selectbox-3"
                  value={savedValues.steamSource}
                  onChange={(event) => this.saveInputValue("steamSource", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "steamSourceOptions")}
                </select>
              </div>
            </div>
          </div>
          <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin" : "perf-reset-btn-small-disabled drawing-update-design-margin"}>
            <div onClick={() => this.RunPerformance(this.props.targetVertex)}>Update Design</div>
          </div>
          <div className="accordion-row"></div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HumidifierManifold);
