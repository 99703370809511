import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { store } from "../../redux/store";
import { updateReduxAction } from "../../redux/actions";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getConfigInputsAllValues, updateInputField, callPostEndpoint } from "../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import "../../css/ConfigurationInputs.scss";
import { isEmptyArray } from "../../utilities/helpers";
import { getStringId } from "../../api-utilities/formatting";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  layoutSvg: string;
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
}

class ConfigurationInputs extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    layoutSvg: "",
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: []
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await getConfigInputsAllValues(projectId, unitId);
    
    if (result.hasError){
      const notification = {
        id: "configInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">getConfigInputsAllValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    this.setState({
      userInputs: result.uiDataContainer?.primaryInputs,
      displayFields: result.uiDataContainer?.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors
    });
    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.poolPrerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.poolPrerequisiteErrors });
    }
    else {
      this.getLayoutSchematic(result.uiDataContainer.primaryInputs);
    }
  }

  private async getLayoutSchematic(primaryInputs: any) {
    const { projectId, unitId } = this.props.match.params;
    let projIdStr = getStringId('project', projectId);
    let unitIdStr = getStringId('unit', unitId);
    let request = {
      PrimaryInputs: primaryInputs,
      ProjectId: projIdStr,
      UnitId: unitIdStr
    }
    if (primaryInputs != null) {
      let result = await callPostEndpoint("readapi/GetLayoutSchematicSvg", request);
      if (result.success) {
        this.setState({ layoutSvg: result.data.schematicSvg });
      }
    }
  }

  // private async getLayoutSchematic(projectId: any, unitId: any) {
  //   if (projectId != null && unitId != null) {
  //     let svgData = await getLayoutSchematicSVG(projectId, unitId);
  //     if (!svgData.hasError) {
  //       this.setState({ layoutSvg: svgData.schematicSvg });
  //     }
  //   }
  // }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;

    this.removeValidationError(fieldName);
    //Special case: If airHandlerType is changed, need to remove validations for Return Air
    //because diffrent validations apply depending on airHandlerType selected.  Mixing box validations
    //are diffrent from economizer validations so when switching between the two, we should remove
    //the validation messages that were there before the change.
    if (fieldName === "airflowArrangement") {
      this.removeValidationError("ReturnAirAltitudeOnlyAcfm");
    }
    let result = await updateInputField("updateConfigInputs", projectId, unitId, fieldName, newValue, "PrimaryInputs");

    if (result.success) {
      if (fieldName === "unitType"){
        if (newValue === "Pool"){
          this.props.updateRedux('UPDATE_MENUS', { showPoolInputs: true});
        }
        else {
          this.props.updateRedux('UPDATE_MENUS', { showPoolInputs: false});
        }
      }
      if (result.data.uiDataContainer != null) {
        //Remove prior validation messages for return, supply, outside, and exhaust air.
        //New validation messages will be populated from the validations passed in.
        this.checkReturnSupplyOutsideExhaustValidations(result.data.uiDataContainer);
      }

      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }
      if (fieldName === "unitTag"){
        //Notify projectHeader that the title has changed.
        store.dispatch(updateReduxAction("UPDATE_DISPLAY", { headerTitleChanged: true }));
      }
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.primaryInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
        //update the schematic drawing
        this.getLayoutSchematic(result.data.uiDataContainer.primaryInputs);
       
        //check for coolingType warnings
        this.checkCoolingSelection(result.data.uiDataContainer);
        //check for CFM limit warning
        this.checkCfmLimitWarning(result.data.uiDataContainer);
      }
    }
  }
  //Return, Supply, Outside, and Exhaust air have validations run on them when:
  //1. Their value is changed.
  //2. After any of these fields get auto-calculated.
  //3. When you first enter the page.
  //Note: Most validations set their value to null and do not get removed here because of the null check.
  //If the values are not null, remove the previous errors.
  public checkReturnSupplyOutsideExhaustValidations(uiDataContainer: any) {
    if (
      uiDataContainer.primaryInputs.returnAirAltitudeOnlyAcfm !== null &&
      this.state.validationErrors.find((x: any) => x.fieldName === "ReturnAirAltitudeOnlyAcfm")
    ) {
      this.removeValidationError("ReturnAirAltitudeOnlyAcfm");
    }
    if (
      uiDataContainer.primaryInputs.supplyAirAltitudeOnlyAcfmUserValue !== null &&
      this.state.validationErrors.find((x: any) => x.fieldName === "SupplyAirAltitudeOnlyAcfmUserValue")
    ) {
      this.removeValidationError("SupplyAirAltitudeOnlyAcfmUserValue");
    }
    if (
      uiDataContainer.primaryInputs.outsideAirAltitudeOnlyAcfmUserValue !== null &&
      this.state.validationErrors.find((x: any) => x.fieldName === "OutsideAirAltitudeOnlyAcfmUserValue")
    ) {
      this.removeValidationError("OutsideAirAltitudeOnlyAcfmUserValue");
    }
    if (uiDataContainer.primaryInputs.exhaustAirAltitudeOnlyAcfmUserValue !== null) {
      this.removeValidationError("ExhaustAirAltitudeOnlyAcfmUserValue");
    }
  }

  private checkCfmLimitWarning(uiDataContainer: any) {
    removeNotification("cfmlimit"); //clear previous notifications

    if (uiDataContainer && uiDataContainer.displayFields) {
      const fields = uiDataContainer.displayFields;
      if (fields.showCfmLimitWarning) {
        this.showCfmLimitInfoBox();
      }
    }
  }
  private showCfmLimitInfoBox() {
    const notification = {
      id: "cfmlimit",
      notificationType: "warning",
      title: "Warning",
      content: (
        <div className="nf-div">
          {"The entered CFMs may exceed the limits of what is possible or shippable. This would be seen on the Optimize Design screen."}
        </div>
      )
    };
    addNotification(notification);
  }

  //Check if we should show the coolingType warning box
  private checkCoolingSelection(uiDataContainer: any) {
    removeNotification("coolingInfoBox"); //clear previous notifications

    if (uiDataContainer && uiDataContainer.displayFields) {
      const fields = uiDataContainer.displayFields;
      if (fields.showDxSplitWarning) {
        this.showCoolingInfoBox(
          "Unit does not include compressors or a condensing section and shall require a separate remote air cooled condensing unit. Contact Innovent sales for a quote on ACCU unit or provide by others."
        );
      }
      if (fields.showDxSplitHGRWarning) {
        this.showCoolingInfoBox(
          "Unit includes compressors but not a condensing section and shall require a separate remote air cooled condenser. Contact Innovent sales for a quote on air cooled condenser or provide by others."
        );
      }
    }
  }
  private showCoolingInfoBox(displayMessage: string) {
    const notification = {
      id: "coolingInfoBox",
      notificationType: "info",
      title: "Info",
      content: <div className="nf-div">{displayMessage}</div>
    };
    addNotification(notification);
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Certain configuration fields cannot be calculated due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }

    return (
      <div className="config-inputs-main">
        <div className="config-input-container">
          <div className="input-heading">Unit Basics</div>
          <div className="input-fields">
            <div>
              <div className="config-input-label">Unit Type</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("unitType", "selectbox")}
                  value={savedValues.unitType}
                  onChange={(event) => this.saveInputValue("unitType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "unitTypeOptions")}
                </select>
                <span className="app-required"> *</span>
              </div>
              {this.getValidationError("unitType")}
            </div>

            <div>
              <div className="config-input-label">Unit Tag</div>
              <div className="input-textbox">
                <input
                  type="text"
                  className={this.getCSS("unitTag", "textbox")}
                  value={savedValues.unitTag || ""}
                  onChange={(event) => this.updateTextboxValue("unitTag", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("unitTag", event.currentTarget.value)}
                />
                <span className="app-required"> *</span>
              </div>
              {this.getValidationError("unitTag")}
            </div>

            <div>
              <div className="config-input-label">Unit Quantity</div>
              <div className="input-textbox">
                <input
                  type="text"
                  className={this.getCSS("unitQuantity", "textbox")}
                  value={savedValues.unitQuantity}
                  onChange={(event) => this.updateTextboxValue("unitQuantity", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("unitQuantity", event.currentTarget.value)}
                />
                <span className="app-required"> *</span>
              </div>
              {this.getValidationError("unitQuantity")}
            </div>

            {displayFields.showEnergyRecoveryDevice ? (
              <div>
                <div className="config-input-label">Energy Recovery Device</div>
                <div className="input-textbox">
                  <select
                    className={this.getCSS("energyRecoveryDeviceType", "selectbox")}
                    value={savedValues.energyRecoveryDeviceType || 0}
                    onChange={(event) => this.saveInputValue("energyRecoveryDeviceType", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "energyRecoveryDeviceOptions")}
                  </select>
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("energyRecoveryDeviceType")}
              </div>
            ) : null}

            <div>
              <div className="config-input-label">Unit Location</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("unitLocationType", "selectbox")}
                  value={savedValues.unitLocationType}
                  onChange={(event) => this.saveInputValue("unitLocationType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "unitLocationOptions")}
                </select>
              </div>
              {this.getValidationError("unitLocationType")}
            </div>
          </div>
          <div className="input-heading">Application</div>
          <div className="input-fields">
            {displayFields.showAirflowArrangement ? (
              <div>
                <div className="config-input-label">Airflow Arrangement</div>
                <div className="input-textbox">
                  <select
                    className={this.getCSS("airflowArrangementType", "selectbox")}
                    value={savedValues.airflowArrangementType}
                    onChange={(event) => this.saveInputValue("airflowArrangementType", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "airflowArrangementOptions")}
                  </select>
                </div>
                {this.getValidationError("airflowArrangementType")}
              </div>
            ) : null}
            <div>
              <div className="config-input-label">Airflow Control</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("airflowSystem", "selectbox")}
                  value={savedValues.airflowSystem}
                  onChange={(event) => this.saveInputValue("airflowSystem", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "airVolumeControlOptions")}
                </select>
              </div>
              {this.getValidationError("airflowSystem")}
            </div>

            <div>
              <div className="config-input-label">Economizer</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("economizer", "selectbox")}
                  value={savedValues.economizer || 0}
                  onChange={(event) => this.saveInputValue("economizer", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "economizerOptions")}
                </select>
              </div>
              {this.getValidationError("economizer")}
            </div>

            <div>
              <div className="config-input-label">Night Setback</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("nightSetback", "selectbox")}
                  value={savedValues.nightSetback || 0}
                  onChange={(event) => this.saveInputValue("nightSetback", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "nightSetbackOptions")}
                </select>
              </div>
              {this.getValidationError("nightSetback")}
            </div>
          </div>

          <div className="input-heading">Heat/Cool Airflow Rate</div>
          <div className="input-fields">

            {displayFields.isPoolUnit ? (
              <>
              <div>
                <div className="config-input-label">Min Outside Air</div>
                <div className="input-textbox">
                  <input
                    disabled={displayFields.isPoolUnit}
                    type="text"
                    className={this.getCSS("minPoolOutsideAirCfm", "textbox")}
                    value={savedValues.minPoolOutsideAirCfm ?? ""}
                  />
                </div>
              </div>
              <div>
                <div className="config-input-label">Max Outside Air</div>
                <div className="input-textbox">
                  <input
                    disabled={displayFields.isPoolUnit}
                    type="text"
                    className={this.getCSS("outsideAirAltitudeOnlyAcfmUserValue", "textbox")}
                    value={savedValues.outsideAirAltitudeOnlyAcfmUserValue ?? ""}
                  />
                </div>
              </div>
              <div>
                <div className="config-input-label">Supply Air</div>
                <div className="input-textbox">
                  <input
                    disabled={displayFields.isPoolUnit}
                    type="text"
                    className={this.getCSS("supplyAirAltitudeOnlyAcfmUserValue" , "textbox")}
                    value={savedValues.supplyAirAltitudeOnlyAcfmUserValue ?? ""}
                  />
                </div>
              </div>
              <div>
                <div className="config-input-label">Return Air</div>
                <div className="input-textbox">
                  <input
                    disabled={displayFields.isPoolUnit}
                    type="text"
                    className={this.getCSS("returnAirAltitudeOnlyAcfm", "textbox")}
                    value={savedValues.returnAirAltitudeOnlyAcfm ?? ""}
                  />
                </div>
              </div>
              <div>
                <div className="config-input-label">Exhaust Air</div>
                <div className="input-textbox">
                  <input
                    disabled={displayFields.isPoolUnit}
                    type="text"
                    className={this.getCSS("exhaustAirAltitudeOnlyAcfmUserValue", "textbox")}
                    value={savedValues.exhaustAirAltitudeOnlyAcfmUserValue ?? ""}
                  />
                </div>
              </div>
              </>
            ): null}
            {/* Not Pool Units */}
            {displayFields.showOutsideAir && !displayFields.isPoolUnit ? (
            <div>
              <div className="config-input-label">Outside Air</div>
              <div className="input-textbox">
                <input
                  type="text"
                  className={this.getCSS("outsideAirAltitudeOnlyAcfmUserValue", "textbox")}
                  value={savedValues.outsideAirAltitudeOnlyAcfmUserValue == null ? "" : savedValues.outsideAirAltitudeOnlyAcfmUserValue}
                  onChange={(event) => this.updateTextboxValue("outsideAirAltitudeOnlyAcfmUserValue", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("outsideAirAltitudeOnlyAcfmUserValue", event.currentTarget.value)}
                />
                <span className="app-required"> *</span>
              </div>
              {this.getValidationError("outsideAirAltitudeOnlyAcfmUserValue")}
            </div>
            ) : null}
            {displayFields.showSupplyAndReturnAir && !displayFields.isPoolUnit ? (
              <>
                <div>
                  <div className="config-input-label">Supply Air</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.disableCFMs}
                      className={this.getCSS("supplyAirAltitudeOnlyAcfmUserValue", "textbox")}
                      value={savedValues.supplyAirAltitudeOnlyAcfmUserValue == null ? "" : savedValues.supplyAirAltitudeOnlyAcfmUserValue}
                      onChange={(event) => this.updateTextboxValue("supplyAirAltitudeOnlyAcfmUserValue", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("supplyAirAltitudeOnlyAcfmUserValue", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                  </div>
                  {this.getValidationError("supplyAirAltitudeOnlyAcfmUserValue")}
                </div>

                <div>
                  <div className="config-input-label">Return Air</div>
                  <div className="input-textbox">
                    <input
                      type="text"
                      disabled={displayFields.disableCFMs}
                      className={this.getCSS("returnAirAltitudeOnlyAcfm", "textbox")}
                      value={savedValues.returnAirAltitudeOnlyAcfm == null ? "" : savedValues.returnAirAltitudeOnlyAcfm}
                      onChange={(event) => this.updateTextboxValue("returnAirAltitudeOnlyAcfm", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("returnAirAltitudeOnlyAcfm", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                  </div>
                  {this.getValidationError("returnAirAltitudeOnlyAcfm")}
                </div>
              </>
            ) : null}
            {displayFields.showExhaustAir  && !displayFields.isPoolUnit? (
              <div>
                <div className="config-input-label">Exhaust Air</div>
                <div className="input-textbox">
                  <input
                    type="text"
                    className={this.getCSS("exhaustAirAltitudeOnlyAcfmUserValue", "textbox")}
                    value={savedValues.exhaustAirAltitudeOnlyAcfmUserValue == null ? "" : savedValues.exhaustAirAltitudeOnlyAcfmUserValue}
                    onChange={(event) => this.updateTextboxValue("exhaustAirAltitudeOnlyAcfmUserValue", event.currentTarget.value)}
                    onBlur={(event) => this.saveTextboxValue("exhaustAirAltitudeOnlyAcfmUserValue", event.currentTarget.value)}
                  />
                  <span className="app-required"> *</span>
                </div>
                {this.getValidationError("exhaustAirAltitudeOnlyAcfmUserValue")}
              </div>
            ) : null}
          </div>

          {displayFields.showUnitLayoutInputs ? (
            <>
              <div className="input-heading">Unit Layout</div>
              <div className="input-fields">
                <div>
                  <div className="config-input-label">Air Tunnel Orientation</div>
                  <div className="input-textbox">
                    <select
                      className={this.getCSS("airTunnelOrientationType", "selectbox")}
                      value={savedValues.airTunnelOrientationType}
                      onChange={(event) => this.saveInputValue("airTunnelOrientationType", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "airTunnelOrientationOptions")}
                    </select>
                  </div>
                  {this.getValidationError("airTunnelOrientationType")}
                </div>

                <div>
                  <div className="config-input-label">Return Air Direction</div>
                  <div className="input-textbox">
                    <select
                      className={this.getCSS("returnAirDirectionType", "selectbox")}
                      value={savedValues.returnAirDirectionType}
                      onChange={(event) => this.saveInputValue("returnAirDirectionType", event.currentTarget.value)}
                      disabled={displayFields.returnAirDirectionDisabled}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "returnAirDirectionOptions")}
                    </select>
                  </div>
                  {this.getValidationError("returnAirDirectionType")}
                </div>

                <div>
                  <div className="config-input-label">Supply Tunnel Location</div>
                  <div className="input-textbox">
                    <select
                      className={this.getCSS("supplyTunnelLocationType", "selectbox")}
                      value={savedValues.supplyTunnelLocationType}
                      onChange={(event) => this.saveInputValue("supplyTunnelLocationType", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "supplyTunnelLocationOptions")}
                    </select>
                  </div>
                  {this.getValidationError("supplyTunnelLocationType")}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="config-input-container2">
          <div className="config-drawing-spacer"></div>
          <div className="config-drawing-grid">{this.state.layoutSvg ? <div dangerouslySetInnerHTML={{ __html: this.state.layoutSvg }} /> : null}</div>

          <div className="config-input-fields-HeatingCooling">
          <div className="input-heading-no-margin">Heating &amp; Cooling</div>
          <div className="input-fields">
            <div>
              <div className="config-input-label">Cooling (incl.HG Reheat)</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("coolingType", "selectbox")}
                  value={savedValues.coolingType || 0}
                  onChange={(event) => this.saveInputValue("coolingType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "coolingOptions")}
                </select>
              </div>
              {this.getValidationError("coolingType")}
            </div>

            <div>
              <div className="config-input-label">Heating</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("heatingType", "selectbox")}
                  value={savedValues.heatingType || 0}
                  onChange={(event) => this.saveInputValue("heatingType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "heatingOptions")}
                </select>
              </div>
              {this.getValidationError("heatingType")}
            </div>

            {displayFields.showCoolHeatSequence ? (
              <>
                <div>
                  <div className="config-input-label">Cool/Heat Order</div>
                  <div className="input-textbox">
                    <select
                      className={this.getCSS("coolHeatSequenceType", "selectbox")}
                      value={savedValues.coolHeatSequenceType}
                      onChange={(event) => this.saveInputValue("coolHeatSequenceType", event.currentTarget.value)}
                      disabled={displayFields.coolHeatSequenceDisabled}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "coolHeatSequenceOptions")}
                    </select>
                  </div>
                  {this.getValidationError("coolHeatSequenceType")}
                </div>
              </>
            ) : null}

            <div>
              <div className="config-input-label">Pre-Heat</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("preHeatType", "selectbox")}
                  value={savedValues.preHeatType || 0}
                  onChange={(event) => this.saveInputValue("preHeatType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "preHeatOptions")}
                </select>
              </div>
              {this.getValidationError("preHeatType")}
            </div>
          </div>
          {!displayFields.isPoolUnit ? (
          <>
          <div className="input-heading">Other Components</div>
            <div className="input-fields">
              <div>
                <div className="config-input-label">Sound Attenuator</div>
                <div className="connect-input">
                  <select
                    className={this.getCSS("soundAttenuatorType", "selectbox")}
                    value={savedValues.soundAttenuatorType || 0}
                    onChange={(event) => this.saveInputValue("soundAttenuatorType", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "soundAttenuatorOptions")}
                  </select>
                  {this.getValidationError("soundAttenuatorType")}
                </div>
              </div>

              <div>
                <div className="config-input-label">Humidifier Manifold</div>
                <div className="connect-input">
                  <select
                    className={this.getCSS("humidifierManifoldType", "selectbox")}
                    value={savedValues.humidifierManifoldType || 0}
                    onChange={(event) => this.saveInputValue("humidifierManifoldType", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "humidifierManifoldOptions")}
                  </select>
                  {this.getValidationError("humidifierManifoldType")}
                </div>
              </div>
            </div>
          </>
          ):null}
          </div>

         <div className="config-input-fields">
            <div className="input-heading">Filters</div>
            <div>
              <div className="config-long-label">{displayFields.airPrefilterLabel}</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("outsideAirPrefilterType", "selectbox")}
                  value={savedValues.outsideAirPrefilterType}
                  onChange={(event) => this.saveInputValue("outsideAirPrefilterType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "outsideAirPrefilterOptions")}
                </select>
              </div>
              {this.getValidationError("outsideAirPrefilterType")}
            </div>

            <div>
              <div className="config-long-label">{displayFields.highEfficiencyFilterLabel}</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("outsideAirHighEfficiencyFilterType", "selectbox")}
                  value={savedValues.outsideAirHighEfficiencyFilterType || 0}
                  onChange={(event) => this.saveInputValue("outsideAirHighEfficiencyFilterType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "outsideAirHighEfficiencyFilterOptions")}
                </select>
              </div>
              {this.getValidationError("outsideAirHighEfficiencyFilterType")}
            </div>

            <div>
              <div className="config-long-label">Supply Air Final Filter</div>
              <div className="input-textbox">
                <select
                  className={this.getCSS("supplyAirFinalFilterType", "selectbox")}
                  value={savedValues.supplyAirFinalFilterType || 0}
                  onChange={(event) => this.saveInputValue("supplyAirFinalFilterType", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "supplyAirFinalFilterOptions")}
                </select>
              </div>
              {this.getValidationError("supplyAirFinalFilterType")}
            </div>

            {displayFields.showReturnAirFilter ? (
              <div>
                <div className="config-long-label">Return Air Filter</div>
                <div className="input-textbox">
                  <select
                    className={this.getCSS("returnAirFilterType", "selectbox")}
                    value={savedValues.returnAirFilterType}
                    onChange={(event) => this.saveInputValue("returnAirFilterType", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "returnAirFilterOptions")}
                  </select>
                </div>
                {this.getValidationError("returnAirFilterType")}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    //reduxConfigInputs: state.reduxConfigInputs as ConfigurationInputsUI,
    //reduxValidation: state.reduxValidation
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationInputs);
