import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import Notification from './Notification';
import { INotification } from '../../interfaces/interfaces';
import '../../css/Notifications.scss';

// expected props
interface IProps {
  reduxNotifications: any;
  updateRedux: any;
}
// local state
interface IState {}

//This component appears at the bottom of the screen and shows warnings, errors, etc.
class ModalNotifications extends React.Component<IProps, IState> {
  private getNotifications() {
    if (this.props.reduxNotifications.notifications.length > 0) {
      return this.props.reduxNotifications.notifications.map((item: INotification, index: number) => (
        <Notification notificationData={item} key={index}></Notification>
      ));
    }
    return null;
  }

  public render() {
    return <div className="nf-container">{this.getNotifications()}</div>;
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxNotifications: state.reduxNotifications
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalNotifications);
