import Oidc from 'oidc-client';
import { store } from '../redux/store';
import { updateReduxAction } from '../redux/actions';
import { addNotification } from '../api-utilities/notification-tools';
import {IUserInfo} from '../interfaces/interfaces';

export class IdentityService {
  private userMgr: any;
  // public user: any;

  public constructor() {
    const settings = {
      authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
      client_id: process.env.REACT_APP_IDENTITY_CLIENTID,
      redirect_uri: process.env.REACT_APP_IDENTITY_LOGINREDIRECT,
      post_logout_redirect_uri: process.env.REACT_APP_IDENTITY_LOGOUTREDIRECT,
      response_type: 'id_token token',
      scope: `openid profile ${process.env.REACT_APP_IDENTITY_LOWRYAPISCOPE}`,
      revokeAccessTokenOnSignout: true,
      automaticSilentRenew: true,
      silent_redirect_uri: process.env.REACT_APP_IDENTITY_SILENT,
      userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
    };
    this.userMgr = new Oidc.UserManager(settings);
    let context = this;

    this.userMgr.events.addUserLoaded(() => {
      console.log('******** USER LOADED');
    });
    this.userMgr.events.addUserUnloaded(() => {
      console.log('******** USER UNLOADED');
    });
    //If the browser is still open, the access_token will auto-renew indefinitely through the
    //silent-renew process (see silent-refresh.html in the "public" folder)
    //We don't want that.  If there's no user activity, let it expire and log the user out.
    //This event should fire every 4 hours (AccessTokenLifetime setting on Identity Server) just
    //before the access token expires.
    this.userMgr.events.addAccessTokenExpiring(() => {
      console.log('********  ACCESS TOKEN EXPIRING');
      const timeStamp = store.getState().reduxSession.userActivityTimestamp;
      const timeout = Date.now() - context.minutesToMilliseconds(30); //30 minutes ago

      //See if the user is actively using the site.  If so, let the accessToken auto-renew.
      //If not, let it expire and log them out.
      if (timeStamp < timeout) {
        console.log('****** USER INACTIVITY TIMEOUT');
        context.logout();
      }
      console.log('TIME NOW: ' + new Date());
    });
    this.userMgr.events.addAccessTokenExpired(() => {
      console.log('********  ACCESS TOKEN EXPIRED');
      context.logout();
    });
    this.userMgr.events.addSilentRenewError((error: any) => {
      console.log('******** SILENT RENEW ERROR');
      this.handleError(error);
    });
    //This event notifies us that user is signed out on the provider side
    this.userMgr.events.addUserSignedOut(() => {
      console.log('******** USER SIGNED OUT');
      context.logout();
    });
    Oidc.Log.logger = console;
  }
  minutesToMilliseconds(min: number) {
    return min * 60000;
  }

  public async login() {
    this.userMgr.signinRedirect().catch((error: any) => this.handleError(error));
  }
  public async completeLogin() {
    try {
      const user = await this.userMgr.signinRedirectCallback();
      console.log('LOGIN SUCCESSFUL: ' + new Date());
      console.log('Access Token Exp: ' + this.getCurrentTimeFromStamp(user.expires_at).toString());
      this.loadUserInfoToRedux(user);
      return user;
    } catch (err) {
      return null;
    }
  }

  public async loadUserInfoToRedux(user:any){
    //Get user roles
    let rolesArray = await this.getUserRoles(user);
    var obj = JSON.parse(user.profile.profiledata);
    let state = obj.State;
    let postalCode = obj.PostalCode;
    store.dispatch(updateReduxAction('UPDATE_AUTH', {userId:user.profile.sub,
      access_token: user.access_token, userRoles:rolesArray, state: state, postalCode: postalCode }));
  }

  public async getUser() {
    const user = await this.userMgr.getUser();
    if (user) {
      return user;
    } else {
      return null;
    }
  }

  public async getUserRoles(user:any){
    let client_id = process.env.REACT_APP_IDENTITY_CLIENTID;
    let rolesArray = [];

      if(user && user.profile){
        //Get the auto-assigned role from the IdentityService "UserType" variable (employee, rep, customer)
        if(user.profile.UserType){
          rolesArray.push(user.profile.UserType.toLowerCase());
        }

        //Get custom roles from the IdentityService profiledata (any new roles we have manually assigned to people)
        if(user.profile.profiledata){
          let obj = JSON.parse(user.profile.profiledata);
          let userRoles = obj.UserRolesArr;

          //Get the roles matching the client_id for this app.
          //NOTE: there are different client_ids for dev, qa, stg, etc...
          let lowryRoles = userRoles.find((x: any) => x.C === client_id);
          if(lowryRoles){
            for(const role of lowryRoles.R){
              rolesArray.push(role.toLowerCase());
            }
          }
        }
      }
      return rolesArray;  
  }

  public async getUserInfo(user:any){

    let userInfo: IUserInfo = {
    userId : '',
    userType :'',
    firstName : '',
    lastName : '',
    email : '',
    officeId : '',
    officeName : '',
   };
    

      if(user && user.profile){
        userInfo.userId = user.profile.sub;
        userInfo.firstName = user.profile.FirstName;
        userInfo.lastName = user.profile.LastName;
        userInfo.email = user.profile.Email;
        //Get the auto-assigned role from the IdentityService "UserType" variable (employee, rep, customer)
        if(user.profile.UserType){
          userInfo.userType = user.profile.UserType.toLowerCase();
        }
        //Get custom officeMemberShipArr from the IdentityService profiledata 
        if(user.profile.profiledata){
          let obj = JSON.parse(user.profile.profiledata);
          if(obj.OfficeMembershipsArr != null && obj.OfficeMembershipsArr.length > 0){
            userInfo.officeId = obj.OfficeMembershipsArr[0].OfficeId;
            userInfo.officeName = obj.OfficeMembershipsArr[0].OfficeName;
          }
        }
      }
      return userInfo;  
  }

  public async isLoggedIn() {
    const user = await this.userMgr.getUser();
    if (user) {
      return user.access_token && !user.expired;
    }
    return false;
  }

  public async logout() {
    await this.userMgr.signoutRedirect().catch((error: any) => this.handleError(error));
    await this.userMgr.clearStaleState();
    console.log('******** IDENTITY LOGOUT');
    store.dispatch(updateReduxAction('UPDATE_AUTH', {userId:'', access_token: '', userRoles:[], state: '', postalCode: ''}));
  }
  public async completeLogout() {
    await this.userMgr.signoutRedirectCallback().catch((error: any) => this.handleError(error));
    await this.userMgr.removeUser(); //removes user from localStorage
    return null;
  }
  public async silentRedirectCallback() {
    await this.userMgr.signinSilentCallback().catch((error: any) => this.handleError(error));
    return null;
  }
  public async handleError(error: any) {
    console.log('Problem with authentication endpoint: ', error);
    const notification = {
      id: 'authenticationError',
      notificationType: 'error',
      title: 'Error',
      content: error.toString()
    };
    addNotification(notification);
  }
  public async querySessionStatus() {
    return await this.userMgr.querySessionStatus().catch((error: any) => this.handleError(error));
  }
  //unix timestamp conversion
  public getCurrentTimeFromStamp(timestamp: any) {
    let d = new Date(timestamp * 1000);
    return d;
  }
}
