import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  componentData: any;
  //reduxUnitCabinet: UnitCabinetUI;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class Exterior extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const componentData = this.props.componentData;

    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: componentData.uiDataContainer.fineTuneInputs,
        displayFields: componentData.uiDataContainer.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn"t changed, don"t call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateUnitCabinet", projectId, unitId, fieldName, newValue, "FineTuneInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fineTuneInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------
  public render() {
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }

    return (
      <div className="spec-fields">
        <div className="spec-field-spacer1"></div>

        <div className="spec-heading-2">Exterior Cabinet Construction</div>

        <div className="spec-elec-fields">
          <div>
            <div className="spec-input-label-3B">Exterior Material</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.exteriorMaterial}
                onChange={(event) => this.saveInputValue("exteriorMaterial", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "exteriorMaterialOptions")}
              </select>
              {this.getValidationError("exteriorMaterial")}
            </div>
          </div>

          {displayFields.showExteriorMaterialPaintColor ? (
            <div>
              <div className="spec-input-label-3B">Exterior Material Paint Color</div>
              <div className="spec-input-2">
                <input
                  type="text"
                  className={this.getCSS("exteriorMaterialPaintColor", "spectextbox")}
                  value={savedValues.exteriorMaterialPaintColor}
                  onChange={(event) => this.updateTextboxValue("exteriorMaterialPaintColor", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("exteriorMaterialPaintColor", event.currentTarget.value)}
                />

                {this.getValidationError("exteriorMaterialPaintColor")}
              </div>
            </div>
          ) : null}

          <div>
            <div className="spec-input-label-3B">Mounting Type</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.mountingType}
                onChange={(event) => this.saveInputValue("mountingType", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "mountingTypeOptions")}
              </select>
              {this.getValidationError("mountingType")}
            </div>
          </div>

          {displayFields.showCurbProvidecBy ? (
            <div>
              <div className="spec-input-label-3B">Curb Provided By</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.curbProvidedBy}
                  onChange={(event) => this.saveInputValue("curbProvidedBy", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "curbProvidedByOptions")}
                </select>
                {this.getValidationError("curbProvidedBy")}
              </div>
            </div>
          ) : null}

            <div>
              <div className="spec-input-label-3B">Casing Wall Thickness</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.casingWallThickness}
                  onChange={(event) => this.saveInputValue("casingWallThickness", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "casingWallThicknessOptions")}
                </select>
                {this.getValidationError("casingWallThickness")}
              </div>
            </div>

          {displayFields.showCurbHeight ? (
            <div>
              <div className="spec-input-label-3B">Curb Height</div>
              <div className="spec-input-2">
                <input
                  type="text"
                  className={this.getCSS("curbHeight", "spectextbox")}
                  value={savedValues.curbHeight}
                  onChange={(event) => this.updateTextboxValue("curbHeight", event.currentTarget.value)}
                  onBlur={(event) => this.saveTextboxValue("curbHeight", event.currentTarget.value)}
                />
              </div>
              <div className="spec-input-label-2">{this.getValidationError("curbHeight")}</div>
            </div>
          ) : null}

          <div>
            <div className="spec-input-label-3B">Shipping Shrink Wrap</div>
            <div className="spec-input-checkbox">
              <input
                className=""
                disabled={displayFields.shippingShrinkWrapEnabled ? false : true}
                type="checkbox"
                checked={getChecked(savedValues.shippingShrinkWrap)}
                onChange={(event) => this.saveInputValue("shippingShrinkWrap", event.currentTarget.checked.toString())}
              />
            </div>
          </div>
        </div>

        <div className="spec-field-spacer1"></div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    // reduxUnitCabinet: state.reduxUnitCabinet,
    // reduxValidation: state.reduxValidation
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Exterior);
