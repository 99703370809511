import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { getErrorNotifications, accordionHasError } from "../../../api-utilities/notification-tools";
import { addNotification, removeNotification } from "../../../api-utilities/notification-tools";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";
import SpecItems from "../../SpecItems";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  reduxComponentPerformance:any;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  dataContainer: any;
}

class PlateHeatExchanger extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    dataContainer: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await callGetEndpoint("readapi/GetPlateHeatExchangerInputValues", projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError) {
      const notification = {
        id: "plateInputValuesError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetPlateHeatExchangerInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.plateHeatExchangerInputs) {
      this.setState({
        userInputs: result.uiDataContainer.plateHeatExchangerInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
  }
  private async RunPerformance(){
    const { projectId, unitId } = this.props.match.params;
   
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for plate and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);
    const result = await callGetEndpoint("readapi/GetPlateHeatExchangerInputValues", projectId, unitId);
    if (result.hasError) {
      const notification = {
        id: "plateInputValuesError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetPlateHeatExchangerInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.plateHeatExchangerInputs) {
      this.setState({
        userInputs: result.uiDataContainer.plateHeatExchangerInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;

    this.removeValidationError(fieldName);

    let result = await updateInputField("updatePlateHeatExchanger", projectId, unitId, fieldName, newValue, "PlateHeatExchangerInputs");

    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.plateHeatExchangerInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  public showPlateValidations(){
    if (this.props.reduxComponentPerformance.validationMessages){
      var plateMessages = this.props.reduxComponentPerformance.validationMessages.filter((item:any) => item.component === "PlateHx");
      if (plateMessages){
        var messages = plateMessages.map((item: any, index: number) => (
          item.message
        ));
        return getErrorNotifications(messages);
      }
   }
  }
  //------------------------------------------------------------------------------------------

  public render() {
    const savedValues = this.state.userInputs;
    const disablePlateInputs = this.state.displayFields?.plateHeatExhangerReadOnly;
    if (!savedValues) {
      return null;
    }

    // const selectedPlateHxModelNumber = plateHX.selectedPlateHXModel ? plateHX.selectedPlateHXModel.value : '';
    // const isLocked = getBooleanValue(plateHX.isSelectionLocked);
    const isLocked = false;

    let dataContainer = this.state.dataContainer;

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">Plate Heat Exchanger</legend>

        <div className="accordion-content">
          {/* // <div className="">
      //   <AccordionHeader
      //     title={this.props.title}
      //     subtitle={this.props.subtitle}
      //     classname="accordion-btn"
      //     active={this.state.openAccordion}
      //     locked={isLocked}
      //     hasError={accordionHasError(this.props.reduxPlateHeatExchanger.errorMessages)}
      //     togglePanel={() => this.togglePanel()}
      //   />

      //   <div className={`acc-panel-540 ${active === true ? 'acc-panel-active' : 'acc-panel-inactive'}`}>
      //     <div className={`accordion-content app-box-shadow ${isLocked ? 'acc-content-locked' : ''}`}>
      //       <div className="acc-locking">
      //         <input
      //           className="acc-checkbox"
      //           type="checkbox"
      //           //disabled={!selectedPlateHxModelNumber}
      //           checked={isLocked}
      //           onChange={(event) => this.updateLockCheckBox(event.currentTarget.checked, selectedPlateHxModelNumber, event)}
      //         />
      //         <span className="acc-lock-text">Lock Plate HX Selection</span>
      //       </div> */}

          <div className="hw-spacer"></div>
          
          {this.showPlateValidations()}

          <div className="accordion-label3">Outside Airflow</div>
          {formatNumber(dataContainer.outsideAirflow, 0)}
          <div></div>
          <div className="accordion-label3">Exhaust Airflow</div>
          {formatNumber(dataContainer.exhaustAirflow, 0)}
          <div></div>
          <div className="acc-fieldset">
            <div className="accordion-label3">Square Size &amp; Spacing</div>
            <div className="accordion-input">
              <select
                disabled={isLocked || disablePlateInputs}
                className={this.getCSS("squareAndSpacing", "accordionselect")}
                value={savedValues.squareAndSpacing || ""}
                onChange={(event) => this.saveInputValue("squareAndSpacing", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "plateHxSquareAndSpacingOptions")}
              </select>
            </div>
            {this.getValidationError("squareAndSpacing")}
          </div>

          <div className="acc-fieldset">
            <div className="accordion-label3">Width (mm)</div>
            <div className="accordion-input">
              <select
                disabled={isLocked || disablePlateInputs}
                className={this.getCSS("plateHxWidthMm", "accordionselect")}
                value={savedValues.plateHxWidthMm || ""}
                onChange={(event) => this.saveInputValue("plateHxWidthMm", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "plateHxWidthOptions")}
              </select>
            </div>
            {this.getValidationError("plateHxWidthMm")}
          </div>
          {/* Labels for choices */}
          <div className="acc-table">
            <div className="acc-td1">
              <div className="acc-data1">
                <b>Model</b>
              </div>
              <div className="acc-data2">Sensible Effectiveness</div>
              <div className="acc-data2">Summer Design DB LAT</div>
              <div className="acc-data2">Summer Design WB LAT</div>
              <div className="acc-data2">Winter Design LAT</div>
              <div className="acc-data2">Air Pressure Drop</div>
            </div>
            <div className="acc-td2"></div>
            <div className="acc-td-optional acc-td-selected">
              <div className="acc-data1">{dataContainer.model}</div>
              <div className="acc-data2">{formatNumber(dataContainer.sensibleEffectiveness, 0)}%</div>
              <div className="acc-data2">{formatNumber(dataContainer.summerLAT, 1)}F</div>
              <div className="acc-data2">{formatNumber(dataContainer.summerWetBulbLAT, 1)}F</div>
              <div className="acc-data2">{formatNumber(dataContainer.winterLAT, 1)}F</div>
              <div className="acc-data2">{formatNumber(dataContainer.airPressureDrop, 2)}"</div>
            </div>
            {/* {this.getPlateHXChoices(plateHX, selectedPlateHxModelNumber)} */}
          </div>

          <div className="acc-spacer"></div>

          <div>
            <div className="accordion-label2"></div>
            <div className="accordion-label-centered">Sensible</div>
            <div></div>
            <div className="accordion-label2">Summer Temp Eff.</div>
            <div className="accordion-label-numeric1">{formatNumber(dataContainer.summerTempEff, 0)}%</div>
            <div></div>
            <div className="accordion-label2">Winter Temp Eff.</div>
            <div className="accordion-label-numeric1">{formatNumber(dataContainer.winterTempEff, 0)}%</div>
            <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin" : "perf-reset-btn-small-disabled drawing-update-design-margin"}>
                <div onClick={() => this.RunPerformance()}>Update Design</div>
              </div>
            <div className="acc-spacer"></div>
          </div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxComponentPerformance: state.reduxComponentPerformance,
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PlateHeatExchanger);
