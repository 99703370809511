import * as React from 'react';
import {DrawingScreen, DrawingViewType} from '../../api-utilities/enums';
import { DrawingViewButtons } from './DrawingViewButtons';

interface IProps {
  drawingScreen: DrawingScreen;
  availableViewTypes: DrawingViewType[];
  drawingViewInfo: { svg: string; drawingView?: DrawingViewType };
  selectDrawingView: (newValue: DrawingViewType) => Promise<void>;
}
interface IState {}

export class DrawingViewer extends React.Component<IProps, IState> {
  private getViewButtonOptions() {
    return (
      <DrawingViewButtons
        updateDrawingView={this.props.selectDrawingView}
        drawingView={this.props.drawingViewInfo.drawingView}
        availableViewTypes={this.props.availableViewTypes}
      ></DrawingViewButtons>
    );
  }

  public render() {
    return (
      <div className="drawing-drawing-grid">
        <div>
          <div className="drawing-btn-label">View:</div>
          {this.getViewButtonOptions()}
        </div>
        <div className="drawing-svg">
          {this.props.drawingViewInfo.svg !== '' ? (
            <div dangerouslySetInnerHTML={{ __html: this.props.drawingViewInfo.svg }} />
          ) : null}
        </div>
      </div>
    );
  }
}
