import * as React from "react";
import { store } from "../../redux/store";
import { connect } from "react-redux";
import { match } from "react-router-dom"; //needed for 'location' object and 'match.params'
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { submitPdfProcessingRequest, getPdfProcessingRecords } from "../../api-calls/pdfGeneratorService";
import "../../css/Output.scss";
import { getOutputDocumentsData } from "../../api-calls/projectDataService";
import { isEmptyArray, sqlToJsDateTime } from "../../utilities/helpers";
import { Link } from "react-router-dom";

// expected props
interface IProps {
  updateRedux: any;
  location: any;
  match: match<any>;
  reduxMenus: any;
  reduxTempsAndSystem: any;
  history: any;
  reduxOutputDocumentsInfo: any;
  reduxProjectManagement: any;
}
// local state
interface IState {
  unitDrawing: boolean;
  salesGuidSpecification: boolean;
  quote: boolean;
  salesScope: boolean;
  units: any;
  docSelectAll: boolean;
  unitSelectAll: boolean;
  versionTemplate: string;
  submittalCoverSheet: boolean;
  poolDesignSummary: boolean;
  performanceDataSummary: boolean;
  airflowSchematic: boolean;
  sensorSchematic: boolean;
  powerElectricalSchematic: boolean;
  drainTrapDetail: boolean;
  submittalScope: boolean;
  warranty: boolean;
  termsAndConditions: boolean;
  generateDocsClicked: boolean;
  unitSelected: any;
  selectedUnitId: any;
  sequenceGenerator: boolean;
  baseDetail: boolean;
  curbDetail: boolean;
  componentsInstalledByOthers: boolean;
  designRadioButton: boolean;
  quoteRadioButton: boolean;
  hasPool:boolean;
}

class Outputs extends React.Component<IProps, IState> {
  timer: any; //define a timer variable for our SetTimeout

  public state: IState = {
    versionTemplate: "Version-3-Template",
    submittalCoverSheet: false,
    poolDesignSummary: false,
    performanceDataSummary: false,
    unitDrawing: false,
    salesGuidSpecification: false,
    quote: false,
    salesScope: false,
    airflowSchematic: false,
    sensorSchematic: false,
    powerElectricalSchematic: false,
    drainTrapDetail: false,
    submittalScope: false,
    warranty: false,
    termsAndConditions: false,
    units: null,
    docSelectAll: false,
    unitSelectAll: false,
    generateDocsClicked: false,
    unitSelected: null,
    selectedUnitId: null,
    sequenceGenerator: false,
    baseDetail: false,
    curbDetail: false,
    componentsInstalledByOthers: false,
    designRadioButton: false,
    quoteRadioButton: false,
    hasPool: false
  };
  async componentDidMount() {
    // this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 6 } });
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const { projectId, unitId } = this.props.match.params;

    const response = await getOutputDocumentsData(`proj-${projectId}`);
    if (response.hasError) {
      const notification = {
        id: "outputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetOutputDocumentsData {response.errorMessage}</div>
      };
      addNotification(notification);
    } else {
      let unitList = response.outputUnits;
      let hasPool = this.checkForPool(unitList);

      this.setState({ units: unitList, hasPool: hasPool });
      

      unitList = null;
      removeNotification("ataskwascancelled");
      //TODO: somebody put a comment here to indicate why this is needed
      //await getTempsAndSystemInputs(true, this.props.match.params);

      //TODO: somebody put a comment here to indicate why this is needed
      //const result = await getConfigInputsAllValues(projectId, unitId);

      //TODO: somebody put a comment here to indicate why this is needed
      // this.checkForWinterDryBulbError(
      //   result.unitBasicDetails.unitBasicDetailsDisplayFields,
      //   result.unitBasicDetails.unitBasicDetailsDTO.energyRecoveryDeviceType
      // );

      let dtos = response.pdfProcessingRecords;
      let activeRecords = dtos.filter(
        (item: any) => item.processingStatusName.toLowerCase() !== "completed" && item.processingStatusName.toLowerCase() !== "failed"
      );
      this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", {
        pdfRecords: dtos,
        activeProcessingRecordsCount: activeRecords.length,
        pollForUpdates: activeRecords.length > 0 ? true : false
      });

      this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
      this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", { isOutputPage: true, showDocsReadyNotificationIcon: false });
    }
  }

  async componentDidUpdate(prevProps: any) {
    //If the user does a "save" on an unsaved unit when on the outputs screen, update the current unit so it can be selected for output docs.
    //Don't update units if generate docs has been clicked because that will remove the selections for the checkboxes for the units selected.
    if (
      prevProps.reduxMenus.enableSave !== this.props.reduxMenus.enableSave &&
      this.props.reduxMenus.enableSave === true &&
      this.state.generateDocsClicked === false
    ) {
      const { projectId, unitId } = this.props.match.params;
      const response = await getOutputDocumentsData(`proj-${projectId}`);
      if (response.hasError) {
        const notification = {
          id: "outputsError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">GetOutputDocumentsData {response.errorMessage}</div>
        };
        addNotification(notification);
      } else {
        let unitList = response.outputUnits;
        this.setState({ units: unitList });
        unitList = null;
      }
    }
  }
  private checkForPool(unitList:any){
    let pools = unitList.find((item:any)=> item.unitType.toLowerCase() === "pool");
    if (pools){
      return true;
    }
    return false;
  }
  //TODO: somebody put a comment here to indicate why this is needed on this page
  private checkForWinterDryBulbError(displayFields: any, energyRecoveryDeviceType: any) {
    //JIRA-7292
    //If Energy Recovery Device is set to Heat Wheel (input made on Configuration screen) and the entered value for the Winter Dry Bulb field within the Outside Air
    //Conditions section is below -20F, a warning shall be provided that says "Preheat recommended to avoid wheel frosting."
    if (displayFields.showEnergyRecoveryDevice) {
      let winterDryBulb = this.props.reduxTempsAndSystem.outsideWinterDryBulb?.value;
      if (!winterDryBulb && winterDryBulb != 0) {
        return;
      }
      let message = "";
      if (energyRecoveryDeviceType === 1) {
        message = "Preheat recommended to avoid plate frosting.";
      } else if (energyRecoveryDeviceType === 2) {
        message = "Preheat recommended to avoid wheel frosting.";
      } else {
        message = "Please select an energy recovery device.";
      }
      if (this.props.reduxTempsAndSystem.preHeat?.value === 0 && winterDryBulb < -20) {
        const notification = {
          id: "winterdrybulb",
          notificationType: "warning",
          title: "Warning",
          content: <div className="nf-div">{message}</div>
        };
        addNotification(notification);
      } else {
        removeNotification("winterdrybulb");
      }
    }
  }

  public render() {
    //let showTemplateChoice = !this.state.salesGuidSpecification ? 'app-display-none' : '';
    let Disabled = this.DisableGenerateButton() ? "app-outputs-generate-disable" : "";
    let docSelectText = this.state.docSelectAll ? "Clear All" : "Select All";
    let unitSelectText = this.state.unitSelectAll ? "Clear All" : "Select All";
    return (
      <div className="app-outputs">
        {/* <div className="app-outputs-title">Generate Output Documents</div> */}

        <div className="app-output-container">
          {/*================================= Left Column ================================ */}
          <div className="app-outputs-left">
            <div className="app-outputs-heading">Document Package Type</div>
            <div className="app-outputs-spacer2"></div>
            <div className="app-outputs-checkbox app-outputs-docs"> Design</div>
            <input
              type="radio"
              value="design"
              name="design"
              checked={this.state.designRadioButton}
              onChange={() => this.DocumentPackageTypeRadioClick("design")}
              onClick={() => this.DocumentPackageTypeRadioClick("design")}
            />
            <div className="app-outputs-checkbox app-outputs-docs"> Quote</div>
            <input
              type="radio"
              value="quote"
              name="quote"
              checked={this.state.quoteRadioButton}
              onChange={() => this.DocumentPackageTypeRadioClick("quote")}
              onClick={() => this.DocumentPackageTypeRadioClick("quote")}
            />

            <div className="app-outputs-spacer"></div>
            <div className="app-outputs-heading">Available Documents</div>
            <div className="app-clear"></div>
            <div className="app-outputs-subheading">Document Selection</div>
            <a className="linkAsButton" href="#" onClick={() => this.SelectAllDocuments()}>
              {docSelectText}
            </a>

            <div className="app-outputs-checkbox app-outputs-docs"> Submittal Cover Sheet</div>
            <input
              type="checkbox"
              checked={this.state.submittalCoverSheet}
              onChange={(event) => this.setState({ submittalCoverSheet: event.currentTarget.checked })}
            />
            
            {this.state.hasPool === true ? (
              <>
                <div className="app-outputs-checkbox app-outputs-docs"> Pool Design Summary</div>
                <input
                  type="checkbox"
                  checked={this.state.poolDesignSummary}
                  onChange={(event) => this.setState({ poolDesignSummary: event.currentTarget.checked })}
                /> 
              </>
            ) : null }

            <div className="app-outputs-checkbox app-outputs-docs"> Performance Data Summary</div>
            <input
              type="checkbox"
              checked={this.state.performanceDataSummary}
              onChange={(event) => this.setState({ performanceDataSummary: event.currentTarget.checked })}
            />

            <div className="app-outputs-checkbox app-outputs-docs"> Unit Drawing</div>
            <input type="checkbox" checked={this.state.unitDrawing} onChange={(event) => this.setState({ unitDrawing: event.currentTarget.checked })} />
            <div className="app-outputs-checkbox app-outputs-docs"> Sales Guide Specification</div>
            <input
              type="checkbox"
              checked={this.state.salesGuidSpecification}
              onChange={(event) => this.setState({ salesGuidSpecification: event.currentTarget.checked })}
            />

            {/* <div className={'versionTemplate ' + showTemplateChoice}>
              <select
                value={this.state.versionTemplate}
                onChange={(event) => {
                  this.setState({ versionTemplate: event.currentTarget.value });
                }}
              >
                <option value="Version-3-Template">Version-3-Template</option>
                <option value="Version-1-Template">Version-1-Template</option>
              </select>
            </div> */}
            <div className="app-outputs-checkbox app-outputs-docs"> Quote</div>
            <input type="checkbox" checked={this.state.quote} onChange={(event) => this.setState({ quote: event.currentTarget.checked })} />
            <div className="app-outputs-checkbox app-outputs-docs"> Sales Scope</div>
            <input type="checkbox" checked={this.state.salesScope} onChange={(event) => this.setState({ salesScope: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Airflow Schematic</div>
            <input
              type="checkbox"
              checked={this.state.airflowSchematic}
              onChange={(event) => this.setState({ airflowSchematic: event.currentTarget.checked })}
            />

            <div className="app-outputs-checkbox app-outputs-docs"> Drain Connection/Trap Detail</div>
            <input type="checkbox" checked={this.state.drainTrapDetail} onChange={(event) => this.setState({ drainTrapDetail: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Base Detail</div>
            <input type="checkbox" checked={this.state.baseDetail} onChange={(event) => this.setState({ baseDetail: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Curb Detail</div>
            <input type="checkbox" checked={this.state.curbDetail} onChange={(event) => this.setState({ curbDetail: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Sensor Schematic</div>
            <input type="checkbox" checked={this.state.sensorSchematic} onChange={(event) => this.setState({ sensorSchematic: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Sequence of Operation</div>
            <input
              type="checkbox"
              checked={this.state.sequenceGenerator}
              onChange={(event) => this.setState({ sequenceGenerator: event.currentTarget.checked })}
            />

            <div className="app-outputs-checkbox app-outputs-docs"> Power Electrical Schematic</div>
            <input
              type="checkbox"
              checked={this.state.powerElectricalSchematic}
              onChange={(event) => this.setState({ powerElectricalSchematic: event.currentTarget.checked })}
            />

            <div className="app-outputs-checkbox app-outputs-docs"> Submittal Scope</div>
            <input type="checkbox" checked={this.state.submittalScope} onChange={(event) => this.setState({ submittalScope: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Components Shipped Loosely</div>
            <input
              type="checkbox"
              checked={this.state.componentsInstalledByOthers}
              onChange={(event) => this.setState({ componentsInstalledByOthers: event.currentTarget.checked })}
            />

            <div className="app-outputs-checkbox app-outputs-docs"> Innovent Standard Warranty</div>
            <input type="checkbox" checked={this.state.warranty} onChange={(event) => this.setState({ warranty: event.currentTarget.checked })} />

            <div className="app-outputs-checkbox app-outputs-docs"> Terms and Conditions</div>
            <input
              type="checkbox"
              checked={this.state.termsAndConditions}
              onChange={(event) => this.setState({ termsAndConditions: event.currentTarget.checked })}
            />

            <div className="app-outputs-spacer"></div>
            <div className="app-outputs-heading">Unit Selection</div>
            <div className="app-clear"></div>

            <div className="app-outputs-subheading">Generate Docs For</div>
            <a className="linkAsButton" href="#" onClick={() => this.SelectAllUnits()}>
              {unitSelectText}
            </a>
            {this.buildUnitCheckboxes()}

            <div className={"app-outputs-generate-btn " + Disabled}>
              <div onClick={() => this.SubmitProcessingRequest()}>Generate Document Package</div>
            </div>
          </div>

          {/*====================================== Right Column ================================ */}
          <div className="app-outputs-right">
            <div>
              <div className="app-outputs-heading" onClick={() => this.getListOfPdfRecords()}>
                Output Documents
              </div>

              <div className="app-outputs-spacer2"></div>

              <div className="outputs-table1">
                <div className="proj-tr">
                  <div className="proj-th">Status</div>
                  {/* <div className="proj-th outputs-table-column">ProjectId</div> */}
                  <div className="proj-th">UnitIds</div>
                  <div className="proj-th">Unit Tags</div>
                  <div className="proj-th outputs-table-doc-type-column">Doc Type</div>
                  <div className="proj-th">Created</div>
                  <div className="proj-th outputs-table-download-column">Download</div>
                </div>

                {this.getPdfRows()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //========================================= Methods for PDF Documents Table ============================================
  //======================================================================================================================

  //Build table rows from the project list in state
  private getPdfRows() {
    const list = this.props.reduxOutputDocumentsInfo.pdfRecords;

    if (!isEmptyArray(list)) {
      return list.map((item: any, index: number) => (
        <div key={index} className={this.getRowClassName(item.processingStatusName)}>
          <div className="proj-td">{this.getPdfStatus(item.processingStatusName)}</div>
          {/* <div className="proj-td outputs-table-column">{item.projectId}</div> */}
          <div className="proj-td outputs-table-column-overflow">{item.unitIdListString}</div>
          <div className="proj-td outputs-table-column-overflow">{item.unitTagsListString}</div>
          <div className="proj-td">{item.documentType}</div>
          <div className="proj-td">{sqlToJsDateTime(item.createDate)}</div>
          <div className="outputs-download-td">{item.errorMessage == null ? this.getPdfLink(item) : this.getPdfError(item.errorMessage)}</div>
        </div>
      ));
    } else {
      return <div className="proj-no-results">No Results</div>;
    }
  }

  private getRowClassName(statusName: string) {
    if (statusName == "Completed") {
      return "proj-tr";
    }
    if (statusName == "Failed") {
      return "proj-tr";
    }
    return "proj-tr-running";
  }
  private getPdfLink(item: any) {
    if (item.processingStatusName == "Completed") {
      let url = item.pdfUrl;
      return (
        <Link to={{ pathname: url }} target="_blank">
          <div className="proj-pdf-icon-sm"></div>
        </Link>
      );
    }
    return null;
  }
  private getPdfError(message: string) {
    return "error";
  }
  private getPdfStatus(statusName: string) {
    if (statusName == "Completed") {
      return <div>{statusName}</div>;
    }
    if (statusName == "Failed") {
      return <div className="proj-status-failed">{statusName}</div>;
    }
    //For all statuses aside from Completed and Failed, show the progress bar
    return (
      <div>
        <div className="proj-progress-bar"></div>
        <div className="proj-progress">{statusName}</div>
      </div>
    );
  }

  private async getListOfPdfRecords() {
    const routeParams = this.props.match.params;
    const projectId = routeParams.projectId;

    //Check if the stored projectId that we were last polling matches the project we're currently on
    let pollingProjectMatches = this.props.reduxOutputDocumentsInfo.projectId == projectId ? true : false;

    this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", {
      projectId: projectId
    });

    //If we're not currently polling, or not polling for the project we're actually on, then get processing records from DB
    if (this.props.reduxOutputDocumentsInfo.pollForUpdates === false || !pollingProjectMatches) {
      //console.log('GettingData From outputs page projectDataAPI');
      let response: any = await getPdfProcessingRecords(projectId);
      //console.log(response);

      if (!response.data.hasError) {
        //Check if any records are actively running (status not equal to completed or failed)
        let dtos = response.data.pdfProcessingDTOs;
        let activeRecords = dtos.filter(
          (item: any) => item.processingStatusName.toLowerCase() !== "completed" && item.processingStatusName.toLowerCase() !== "failed"
        );
        this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", {
          pdfRecords: dtos,
          activeProcessingRecordsCount: activeRecords.length,
          pollForUpdates: activeRecords.length > 0 ? true : false
        });
      } else {
        const notification = {
          id: "outputsError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">GetPDFProcessingRecords {response.data.errorMessage}</div>
        };
        addNotification(notification);
      }
    }
  }

  private async SubmitProcessingRequest() {
    const { projectId, unitId } = this.props.match.params;
    //await this.checkSave();
    //console.log('Saving completed....process PDF');
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });

    const routeParams = this.props.match.params;
    const auth = store.getState().reduxAuth;

    let unitIds: any = [];
    let unitTags: any = [];
    this.state.units.forEach((unit: any) => {
      if (unit.checked == true) {
        unitIds.push(unit.id);
        unitTags.push(unit.unitTag);
      }
    });

    const pdfProcessingRequest = {
      ProjectId: `proj-${projectId}`,
      UnitIds: unitIds,
      SubmittalCoverSheet: this.state.submittalCoverSheet,
      PoolDesignSummary: this.state.poolDesignSummary,
      PerformanceDataSummary: this.state.performanceDataSummary,
      UnitDrawing: this.state.unitDrawing,
      SalesGuidSpecification: this.state.salesGuidSpecification,
      Quote: this.state.quote,
      SalesScope: this.state.salesScope,
      GuideSpecVersionTemplate: this.state.versionTemplate,
      AirflowSchematic: this.state.airflowSchematic,
      SensorSchematic: this.state.sensorSchematic,
      DrainTrapDetail: this.state.drainTrapDetail,
      PowerElectricalSchematic: this.state.powerElectricalSchematic,
      SubmittalScope: this.state.submittalScope,
      InnoventStandardWarranty: this.state.warranty,
      TermsAndConditions: this.state.termsAndConditions,
      SequenceGenerator: this.state.sequenceGenerator,
      BaseDetail: this.state.baseDetail,
      CurbDetail: this.state.curbDetail,
      ComponentsInstalledByOthers: this.state.componentsInstalledByOthers,
      IsDesignDocumentType: this.state.designRadioButton,
      IsQuoteDocumentType: this.state.quoteRadioButton,
      UnitTags: unitTags,
      State: auth.state,
      PostalCode: auth.postalCode
    };

    //IMPORTANT: Clear out the current selections, and gray out the submit button.
    //This is to prevent the user from doubleClicking or clicking twice, adding multiple records.

    //this.ClearAllDocuments();
    //this.ClearAllUnitCheckboxes();

    //Call PDFToolsAPI to publish a processing request to ServiceBus
    // this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    let response: any = await submitPdfProcessingRequest(pdfProcessingRequest);
    if (response.data.hasError) {
      const notification = {
        id: "outputsError",
        notificationType: "error",
        title: "Error",
        content: (
          <div className="nf-div">
            SubmitPDFProcessingRequest {response.data.errorMessage !== null && response.data.errorMessage !== ""} ? {response.data.errorMessage} :{" "}
            {response.data.individualErrors}
          </div>
        )
      };
      addNotification(notification);
    } else {
      let unitList = response.data.outputUnits;
      this.setState({ units: unitList });
      unitList = null;

      let dtos = response.data.pdfProcessingRecords;
      //start polling for updates
      this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", {
        pdfRecords: dtos,
        projectId: `proj-${projectId}`,
        pollForUpdates: true
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.ClearAllDocuments();
    this.ClearAllUnitCheckboxes();
    //reset the Document Type Radio buttons
    this.setState({
      designRadioButton: false,
      quoteRadioButton: false
    });
  }

  componentWillUnmount() {
    this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", {
      pollForUpdates: true,
      activeProcessingRecordsCount: 0,
      showDocsReadyNotificationIcon: false,
      isOutputPage: false
    });
  }

  // private async checkSave() {
  //   //If a save is not already running then save the unit before generating output docs.
  //   if (this.props.reduxMenus.enableSave) {
  //     //Save unit design each time the drawing button is selected unless a save is already in progress.
  //     //console.log('Saving unit');
  //     await this.SaveUnits();
  //   } else {
  //     //console.log('No need to save this unit');
  //   }
  // }
  //Update needsFullSave property on units that were just saved.
  private updateSavedUnits(newlySavedUnitIds: any) {
    let units = [...this.state.units];
    newlySavedUnitIds.forEach((savedUnitId: number) => {
      let unitToUpdate = units.find((x) => x.id == savedUnitId);
      if (unitToUpdate) {
        unitToUpdate.needsFullSave = false;
      }
    });
    this.setState({ units: units });
  }

  //Save currently selected unit and put currentlySelectedUniId into state.savedUnits
  // private async SaveUnits() {
  //   //Try and get parameters from the url
  //   const match = matchPath(this.props.history.location.pathname, {
  //     path: '/pid/:projectId/uid/:unitId',
  //     exact: false,
  //     strict: false
  //   });
  //   if (match) {
  //     const { projectId } = match.params as any;

  //     let unitIds: any = [];
  //     //Get units to save.
  //     this.state.units.forEach((unit: any) => {
  //       if (unit.checked == true && unit.needsFullSave == true) {
  //         unitIds.push(unit.unitId);
  //       }
  //     });
  //     //unitIds.push(this.state.selectedUnitId);radio button

  //     this.updateSavedUnits(unitIds);

  //     //Save units to database.
  //     await UpdateMultipleUnits(projectId, unitIds);
  //   }
  // }

  private DisableGenerateButton() {
    let docChecked =
      this.state.submittalCoverSheet === true ||
      this.state.poolDesignSummary === true ||
      this.state.performanceDataSummary === true ||
      this.state.unitDrawing === true ||
      this.state.salesGuidSpecification === true ||
      this.state.quote === true ||
      this.state.salesScope === true ||
      this.state.airflowSchematic === true ||
      this.state.sensorSchematic === true ||
      this.state.drainTrapDetail === true ||
      this.state.powerElectricalSchematic === true ||
      this.state.submittalScope === true ||
      this.state.warranty === true ||
      this.state.termsAndConditions === true ||
      this.state.sequenceGenerator === true ||
      this.state.baseDetail === true ||
      this.state.curbDetail === true ||
      this.state.componentsInstalledByOthers === true;

    let unitChecked = false;
    //Use with checkboxes.
    if (this.state.units) {
      this.state.units.forEach((unit: any) => {
        if (unit.checked == true) {
          unitChecked = true;
        }
      });
    }
    //let unitChecked = this.state.selectedUnitId !== null ? true : false;
    return !docChecked || !unitChecked;
  }

  private SelectAllDocuments() {
    if (this.state.docSelectAll) {
      this.ClearAllDocuments();
      return;
    } else {
      this.setState({
        submittalCoverSheet: true,
        poolDesignSummary: true,
        performanceDataSummary: true,
        unitDrawing: true,
        salesGuidSpecification: true,
        quote: true,
        salesScope: true,
        airflowSchematic: true,
        sensorSchematic: true,
        drainTrapDetail: true,
        powerElectricalSchematic: true,
        submittalScope: true,
        docSelectAll: true,
        warranty: true,
        termsAndConditions: true,
        sequenceGenerator: true,
        baseDetail: true,
        curbDetail: true,
        componentsInstalledByOthers: true
      });
    }
  }

  private ClearAllDocuments() {
    this.setState({
      submittalCoverSheet: false,
      poolDesignSummary: false,
      performanceDataSummary: false,
      unitDrawing: false,
      salesGuidSpecification: false,
      quote: false,
      salesScope: false,
      airflowSchematic: false,
      sensorSchematic: false,
      drainTrapDetail: false,
      powerElectricalSchematic: false,
      submittalScope: false,
      docSelectAll: false,
      warranty: false,
      termsAndConditions: false,
      sequenceGenerator: false,
      baseDetail: false,
      curbDetail: false,
      componentsInstalledByOthers: false
    });
  }
  // private ClearUnitSelection() { radio button
  //   this.setState({ selectedUnitId: null });
  // }
  //Use with checkboxes.
  private SelectAllUnits() {
    if (this.state.unitSelectAll) {
      this.ClearAllUnitCheckboxes();
      return;
    } else {
      let copyUnits = [...this.state.units];
      copyUnits.forEach((unit) => {
        unit.checked = true;
      });

      this.setState({ units: copyUnits, unitSelectAll: true });
    }
  }
  //Use with checkboxes.
  private ClearAllUnitCheckboxes() {
    let copyUnits = [...this.state.units];
    copyUnits.forEach((unit) => {
      unit.checked = false;
    });

    this.setState({ units: copyUnits, unitSelectAll: false });
  }
  //This is for use with checkboxes.
  private updateUnitSelected(unitId: any, checked: boolean) {
    let copyUnits = [...this.state.units];
    let unit = copyUnits.find((item: any) => item.id === unitId);
    unit.checked = checked;
    this.setState({ units: copyUnits });

    let checkedUnits = copyUnits.filter((x) => x.checked);
    //If all are checked, show "clear all" link
    if (checkedUnits && checkedUnits.length === copyUnits.length) {
      this.setState({ unitSelectAll: true });
    } else {
      //All are not checked, show "select all" link
      this.setState({ unitSelectAll: false });
    }
  }
  // private updateUnitSelected(id: any, checked: boolean) { radio button
  //   this.setState({ selectedUnitId: id });
  // }
  private buildUnitCheckboxes() {
    if (!this.state.units) {
      return null;
    }
    return this.state.units.map((item: any, index: number) => (
      <div key={index}>
        <div className="app-outputs-checkbox app-outputs-units"> {item.unitTag + (item.isOversized ? " (Oversized)" : "")}</div>
        <input
          disabled={item.isOversized || item.unitTag === null}
          type="checkbox"
          name="unit"
          value={item.unitTag != null ? item.unitTag : ""}
          checked={item.checked ? true : false}
          onChange={(event) => this.updateUnitSelected(item.id, event.currentTarget.checked)}
        />
      </div>
    ));
  }

  private DocumentPackageTypeRadioClick(radioName: any) {
    if (radioName == "design") {
      if (!this.state.designRadioButton) {
        //case : when the user selects the design radio button
        this.setState({
          designRadioButton: true,
          quoteRadioButton: false,
          submittalCoverSheet: true,
          poolDesignSummary: true,
          performanceDataSummary: true,
          unitDrawing: true,
          salesGuidSpecification: true,
          quote: false,
          salesScope: false,
          airflowSchematic: true,
          sensorSchematic: true,
          drainTrapDetail: true,
          powerElectricalSchematic: true,
          submittalScope: true,
          warranty: false,
          termsAndConditions: false,
          sequenceGenerator: true,
          baseDetail: true,
          curbDetail: true,
          componentsInstalledByOthers: true
        });
      } else if (this.state.designRadioButton) {
        //case : when the user de-selects the design radio button
        this.setState({
          designRadioButton: false,
          quoteRadioButton: false,
          submittalCoverSheet: false,
          poolDesignSummary: false,
          performanceDataSummary: false,
          unitDrawing: false,
          salesGuidSpecification: false,
          quote: false,
          salesScope: false,
          airflowSchematic: false,
          sensorSchematic: false,
          drainTrapDetail: false,
          powerElectricalSchematic: false,
          submittalScope: false,
          warranty: false,
          termsAndConditions: false,
          sequenceGenerator: false,
          baseDetail: false,
          curbDetail: false,
          componentsInstalledByOthers: false
        });
      }
    } else {
      if (!this.state.quoteRadioButton) {
        //case : when the user selects the quote radio button
        this.setState({
          quoteRadioButton: true,
          designRadioButton: false,
          submittalCoverSheet: false,
          poolDesignSummary: false,
          performanceDataSummary: false,
          unitDrawing: false,
          salesGuidSpecification: false,
          quote: true,
          salesScope: true,
          airflowSchematic: false,
          sensorSchematic: false,
          drainTrapDetail: false,
          powerElectricalSchematic: false,
          submittalScope: false,
          warranty: true,
          termsAndConditions: true,
          sequenceGenerator: false,
          baseDetail: false,
          curbDetail: false,
          componentsInstalledByOthers: false
        });
      } else if (this.state.quoteRadioButton) {
        //case : when the user de-selects the quote radio button
        this.setState({
          designRadioButton: false,
          quoteRadioButton: false,
          submittalCoverSheet: false,
          poolDesignSummary: false,
          performanceDataSummary: false,
          unitDrawing: false,
          salesGuidSpecification: false,
          quote: false,
          salesScope: false,
          airflowSchematic: false,
          sensorSchematic: false,
          drainTrapDetail: false,
          powerElectricalSchematic: false,
          submittalScope: false,
          warranty: false,
          termsAndConditions: false,
          sequenceGenerator: false,
          baseDetail: false,
          curbDetail: false,
          componentsInstalledByOthers: false
        });
      }
    }
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus,
    reduxTempsAndSystem: state.reduxTempsAndSystem,
    reduxOutputDocumentsInfo: state.reduxOutputDocumentsInfo,
    reduxProjectManagement: state.reduxProjectManagement
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Outputs);
