import * as React from 'react';
import { INotification } from '../../interfaces/interfaces';
import '../../css/Notifications.scss';

// expected props
interface IProps {
  notificationData: INotification;
}
// local state
interface IState {}

class NotificationMessage extends React.Component<IProps, IState> {
  public render() {
    const data = this.props.notificationData;
    const type = this.props.notificationData.notificationType; //success,info,warning,error

    return (
      <div className="nf-body">
        <div className="nf-tr">
          <div className={['nf-left', `nf-${type}-tab`].join(' ')}></div>
          <div className="nf-right">
            <div className={`nf-${type}-content`}>{data.content}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationMessage;
