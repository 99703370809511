import * as React from 'react';
import {DrawingViewType} from '../../api-utilities/enums';
import '../../css/UnitDrawing.scss';

interface IProps {
  drawingView?: DrawingViewType;
  availableViewTypes: DrawingViewType[];
  updateDrawingView: (view: DrawingViewType) => Promise<void>;
}
interface IState {}

export class DrawingViewButtons extends React.Component<IProps, IState> {
  private getButtonCSS(value: number) {
    const currentValue = this.props.drawingView;

    //add class to extend button length if its one of the longer display values
    let cssClass =
      value === DrawingViewType.TopUpperTunnel || value === DrawingViewType.TopBottomTunnel
        ? 'drawing-btn drawing-btn-135'
        : 'drawing-btn';

    if (value === currentValue) {
      cssClass += ' drawing-btn-selected';
    }
    return cssClass;
  }

  public render() {
    const context = this;
    const options = {
      [DrawingViewType.FrontSide]: 'Front Side',
      [DrawingViewType.BackSide]: 'Back Side',
      [DrawingViewType.LeftEnd]: 'Left End',
      [DrawingViewType.RightEnd]: 'Right End',
      [DrawingViewType.Top]: 'Top',
      [DrawingViewType.TopUpperTunnel]: 'Top (Upper Tunnel)',
      [DrawingViewType.TopBottomTunnel]: 'Top (Lower Tunnel)'
    };
    return this.props.availableViewTypes.map((value) => {
      const displayValue = options[value];
      return (
        <div key={value} className={context.getButtonCSS(value)} onClick={() => this.props.updateDrawingView(value)}>
          {displayValue}
        </div>
      );
    });
  }
}
