import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"; //withRouter needed for location & history properties (see readMe)
import { mapDispatchToProps } from "../../redux/actions";
// import { IdentityService } from '../../security/IdentityService';
import IdentityContext from "../../security/IdentityContext";
import "../../css/Login.scss";

// expected props
interface IProps {
  history: any;
}
// local state
interface IState {}

class IdentityLogout extends React.Component<IProps, IState> {
  //Connect to global IdentityContext (https://reactjs.org/docs/context.html)
  static contextType = IdentityContext;
  private readonly identityService = this.context;

  async componentDidMount() {
    //const finish = await this.identityService.silentRedirectCallback();
    const user = await this.identityService.completeLogout();
    console.log(user);
    if (user === null) {
      console.log("user is logged out");
      this.props.history.push("/");
    }
  }

  public render() {
    return (
      <div className="login-inputs">
        <br />
        <br />
        You are now logged out.
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdentityLogout));
