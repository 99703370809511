import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { updateInputField } from "../../api-calls/readApiService";
import {
  getPoolValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredPoolValidationErrors,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import "../../css/PoolInputs.scss";
import { formatNumber } from "../../utilities/helpers";
import { getTooltipContent } from "./PoolUtilities";

// expected props
interface IProps {
  match: match<any>;
  poolData:any;
  isFirstPool:boolean;
  displayFields: any;
  validationErrors:any[];
  dropdownOptions: any[];
  targetVertex:string;
  deletePool: (poolIndex: number) => void;
  //runPoolSpaceValidations: () => void;
}
// local state
interface IState {
  singlePoolInputs: any,
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  ShowPoolDesignTypeToolTip: boolean;
  ShowPoolAreaToolTip: boolean;
  ShowWaterDesignTempToolTip: boolean;
  ShowPoolFeaturesToolTip: boolean;
  ShowActivityFactorToolTip: boolean;
}

class SinglePoolInput extends React.Component<IProps, IState> {
  public state: IState = {
    singlePoolInputs: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    displayFields: null,
    ShowPoolDesignTypeToolTip: false,
    ShowPoolAreaToolTip: false,
    ShowWaterDesignTempToolTip: false,
    ShowPoolFeaturesToolTip: false,
    ShowActivityFactorToolTip: false
  };

  async componentDidMount() {
    this.setState({
        singlePoolInputs: this.props.poolData,
        displayFields: this.props.displayFields,
        dropdownOptions: this.props.dropdownOptions,
        validationErrors: this.props.validationErrors
    });
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.singlePoolInputs, { [fieldName]: newValue });
    this.setState({ singlePoolInputs: newState, lastUpdatedTextbox: fieldName });
  }
  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string, poolIndex: number) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue, poolIndex);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string, poolIndex: number) {
    const { projectId, unitId } = this.props.match.params;

    this.removeValidationError(fieldName, poolIndex);
    let result = await updateInputField("updateSinglePoolInputs", projectId, unitId, fieldName, newValue, this.props.targetVertex);

    if (result.data.hasError){
        const notification = {
          id: "individualPoolInputsError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">updateSinglePoolInputs {result.data.errorMessage}</div>
        };
        addNotification(notification);
      }
    if (result.success) {
      //If pool area was modified, need to run validations on the main page as pool area can affect the validation
      //for the deck area field on poolInputs page.
      // if (fieldName === "poolArea"){
      //   this.props.runPoolSpaceValidations();
      // }
      
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
            singlePoolInputs: result.data.uiDataContainer.poolInputs,
            displayFields: result.data.uiDataContainer.displayFields
        });
        if (result.data.uiDataContainer.dropdownOptions){
          this.setState({dropdownOptions: result.data.uiDataContainer.dropdownOptions});
        }
      }
    }
  }
  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string, poolIndex: number, className:string = "validation-pool") {
    //Call the validation method from pageUtils
    return getPoolValidationError(this.state.validationErrors, fieldName, poolIndex, className);
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string, poolIndex: number) {
    let newArray = getFilteredPoolValidationErrors(this.state.validationErrors, fieldName, poolIndex);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //Show/Hide tooltips on mouseover and mouseleave
  private showTooltip(tooltip: any, state: boolean) {
    if (tooltip === "poolDesignType") {
      this.setState({ ShowPoolDesignTypeToolTip: state });
    }
    if (tooltip === "poolArea") {
      this.setState({ ShowPoolAreaToolTip: state });
    }
    if (tooltip === "waterDesignTemp") {
      this.setState({ ShowWaterDesignTempToolTip: state });
    }
    if (tooltip === "poolFeatures"){
      this.setState({ ShowPoolFeaturesToolTip:state});
    }
    if (tooltip === "activityFactor") {
      this.setState({ ShowActivityFactorToolTip: state });
    }
  }
  public render() {
    const pool = this.state.singlePoolInputs;
    const displayFields = this.state.displayFields;
    if (!displayFields || !pool) {
      return null;
    }

    return (
        <div className="pool-wrapper">
            <div className="pool-bold">
            <input
                type="text"
                className="pool-input-numeric-110"
                placeholder="Pool Description"
                value={pool.poolName || ""}
                onChange={(event) => this.updateTextboxValue("poolName", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("poolName", event.currentTarget.value, pool.poolIndex)}
                />
                {this.getValidationError("poolName", pool.poolIndex)}
            </div>
            <div>
                <select
                    className={this.getCSS("poolDesignType", "poolSelectLarge")}
                    value={pool.poolDesignType || ""}
                    onChange={(event) => this.saveInputValue("poolDesignType", event.currentTarget.value, pool.poolIndex)}
                    >
                    {getSelectBoxOptions(this.state.dropdownOptions, "poolTypeOptions")}
                </select>
                <span className="app-required"> *</span>
                <span
                    className="pool-tooltip-info"
                    onMouseEnter={() => this.showTooltip("poolDesignType", true)}
                    onMouseLeave={() => this.showTooltip("poolDesignType", false)}
                ></span>
                {getTooltipContent("poolDesignType", this.state.ShowPoolDesignTypeToolTip)}
            </div>
            <div>
                <input
                type="text"
                className="pool-input-numeric-50"
                value={pool.poolArea }
                onChange={(event) => this.updateTextboxValue("poolArea", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("poolArea", event.currentTarget.value, pool.poolIndex)}
                />
                <span className="app-required"> *</span>
                <span
                    className="pool-tooltip-info"
                    onMouseEnter={() => this.showTooltip("poolArea", true)}
                    onMouseLeave={() => this.showTooltip("poolArea", false)}
                ></span>
                {getTooltipContent("poolArea", this.state.ShowPoolAreaToolTip)}
                {this.getValidationError("poolArea", pool.poolIndex)}
            </div>
            <div>
                <input
                type="text"
                className="pool-input-numeric-50"
                value={formatNumber(pool.waterDesignTemp, 1) }
                onChange={(event) => this.updateTextboxValue("waterDesignTemp", event.currentTarget.value)}
                onBlur={(event) => this.saveTextboxValue("waterDesignTemp", event.currentTarget.value, pool.poolIndex)}
                />
                <span className="app-required"> *</span>
                <span
                    className="pool-tooltip-info"
                    onMouseEnter={() => this.showTooltip("waterDesignTemp", true)}
                    onMouseLeave={() => this.showTooltip("waterDesignTemp", false)}
                ></span>
                {getTooltipContent("waterDesignTemp", this.state.ShowWaterDesignTempToolTip)}
                {this.getValidationError("waterDesignTemp", pool.poolIndex)}
            </div>
            <div>
                <select
                    className={this.getCSS("poolFeatures", "poolSelectLarge")}
                    value={pool.poolFeatures || ""}
                    onChange={(event) => this.saveInputValue("poolFeatures", event.currentTarget.value, pool.poolIndex)}
                    >
                    {getSelectBoxOptions(this.state.dropdownOptions, "pool" + pool.poolIndex + "FeaturesOptions")}
                </select>
                <span className="app-required"> *</span>
                <span
                    className="pool-tooltip-info"
                    onMouseEnter={() => this.showTooltip("poolFeatures", true)}
                    onMouseLeave={() => this.showTooltip("poolFeatures", false)}
                ></span>
                {getTooltipContent("poolFeatures", this.state.ShowPoolFeaturesToolTip)}
            </div>
            <div className="pool-activity-factor">
                <input
                disabled={true}
                type="text"
                className="pool-input-numeric-50"
                value={pool.activityFactor || ""}
                />
                <span
                    className="pool-tooltip-info pool-info-bubble-adjustment"
                    onMouseEnter={() => this.showTooltip("activityFactor", true)}
                    onMouseLeave={() => this.showTooltip("activityFactor", false)}
                ></span>
                {getTooltipContent("activityFactor", this.state.ShowActivityFactorToolTip)}
            </div>
            <div>
                {this.props.isFirstPool === false ? 
                <span className="proj-link" onClick={() => this.props.deletePool(pool.poolIndex)}>Delete</span> 
                : <span className="pool-first"></span>}
            </div>
        </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SinglePoolInput);
