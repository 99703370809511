import { IAction } from '../../interfaces/interfaces';
import { SoundAttenuatorComponentUI, HumidifierManifoldComponentUI } from '../../TypescriptClient';

export const reduxSupplyAttenuator = (state: SoundAttenuatorComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_SUPPLY_ATTENUATOR') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

export const reduxReturnAttenuator = (state: SoundAttenuatorComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_RETURN_ATTENUATOR') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

export const reduxHumidifierManifold = (state: HumidifierManifoldComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_HUMIDIFIER_MANIFOLD') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
