import * as React from "react";
import { connect } from "react-redux";
import { match, matchPath } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { DrawingScreen, DrawingViewType} from '../../api-utilities/enums';
import "../../css/UnitDrawing.scss";
import "../../css/Performance.scss";
import { DrawingViewer } from "./DrawingViewer";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { callGetEndpoint, updateInputField, getUnitDrawing } from "../../api-calls/readApiService";
import { getValidationError, getInputCSS, getSelectBoxOptions } from "../../utilities/pageUtils";
import { isEmptyArray } from "../../utilities/helpers";

// expected props
interface IProps {
  //reduxLengthInputs: LengthInputsUI;
  updateRedux: any;
  match: match<any>;
  history: any;
}

// local state
interface IState {
  topDrawingState: IDrawingState;
  bottomDrawingState: IDrawingState;
  inputChanged: boolean;
  defaultDrawingRendered: boolean;
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  unit: any;
  commonData: string;
  airTunnelOrientationType:string;
  defaultViewType: DrawingViewType;
  topDrawingAvailableViewTypes: DrawingViewType[];
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
}

interface IDrawingState {
  svg: string;
  drawingView?: DrawingViewType;
}

class LengthInputs extends React.Component<IProps, IState> {
  public state: IState = {
    topDrawingState: { svg: "", drawingView: undefined },
    bottomDrawingState: { svg: "", drawingView: undefined },
    inputChanged: false,
    defaultDrawingRendered: false,
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    unit: null,
    commonData:"",
    airTunnelOrientationType:"",
    defaultViewType: DrawingViewType.Top,
    topDrawingAvailableViewTypes: [],
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: []
  };
  componentWillUnmount() {
    //Remove warning when navigating to another page.
    removeNotification("airflowtransitions");
  }
  async componentDidMount() {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetLengthInputValues", projectId, unitId);
    this.setState({
      userInputs: result.uiDataContainer?.fineTuneInputs,
      displayFields: result.uiDataContainer?.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      unit: result.unit,
      commonData: result.commonDataViewModel,
      airTunnelOrientationType: result.airTunnelOrientationType,
      defaultViewType: result.defaultViewType,
      topDrawingAvailableViewTypes: result.topDrawingAvailableViewTypes
    });
    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.prerequisiteErrors });
    }
    else {
      await this.getDefaultDrawings();
      this.checkForMinTransitionSpaceWarning();
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  private async getDefaultDrawings() {
    //Get defualt drawing view for Length Inputs.
    let topDrawingView =
    this.state.topDrawingState.drawingView === undefined
      ? this.state.defaultViewType
      : this.state.topDrawingState.drawingView;
    if (typeof(topDrawingView) === 'undefined'){
      topDrawingView = this.state.airTunnelOrientationType === "Stacked" ? DrawingViewType.TopUpperTunnel : DrawingViewType.Top;
    }
    await this.selectTopDrawingView(topDrawingView);
    await this.selectBottomDrawingView(this.state.bottomDrawingState.drawingView ?? DrawingViewType.FrontSide);
  }
  private async selectTopDrawingView(newValue: DrawingViewType) {
    this.setState({ topDrawingState: await this.getDrawingState(newValue) });
  }
  private async selectBottomDrawingView(newValue: DrawingViewType) {
    this.setState({ bottomDrawingState: await this.getDrawingState(newValue) });
  }
  //Get the updated SVG
  private async getDrawingState(newValue: number) {
    //New UnitDrawingService endpoint.
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    let commonData = this.state.commonData;
    const response = await getUnitDrawing(this.props.match.params, newValue, DrawingScreen.AccessDoors, commonData);
    let svg = '';
    //let svg = "<svg height=\"300\" viewbox=\"0 0 650 300\" preserveAspectRatio=\"xMidYMid meet\">\r\n  <rect desc=\"unit casing exterior\" x=\"0\" y=\"0\" width=\"640\" height=\"96.75776062334181\" style=\"stroke:black;stroke-width:1;fill:none\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"primary tunnel interior\" x=\"2.1990400141668593\" y=\"49.47840031875433\" width=\"630.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"secondary tunnel interior\" x=\"2.1990400141668593\" y=\"2.1990400141668593\" width=\"6398.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n</svg>";
    if (response?.data.drawings?.length){
      svg = response?.data.drawings[0].unitDrawingSvg;
    }
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    return { svg: svg ?? '', drawingView: newValue } as IDrawingState;
  }

  private getTopDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.LengthInputs}
        availableViewTypes={this.state.topDrawingAvailableViewTypes ?? []}
        drawingViewInfo={this.state.topDrawingState}
        selectDrawingView={this.selectTopDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }
  private getBottomDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.LengthInputs}
        availableViewTypes={[DrawingViewType.FrontSide, DrawingViewType.BackSide]}
        drawingViewInfo={this.state.bottomDrawingState}
        selectDrawingView={this.selectBottomDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }

  //------------------------------------------------------------------------------------------
  private async RunPerformance(){
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetLengthInputValues", projectId, unitId);
    this.setState({
      userInputs: result.uiDataContainer.fineTuneInputs,
      displayFields: result.uiDataContainer.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      unit: result.unit,
      commonData: result.commonDataViewModel,
      inputChanged: false
    });

    await this.getDefaultDrawings();
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
  }
  private async saveAndCheckTransitionAngles(fieldName: string, newValue: string) {
    await this.saveInputValue(fieldName, newValue);
    this.checkForMinTransitionSpaceWarning(newValue);
  }
  private checkForMinTransitionSpaceWarning(value: string = "") {
    //JIRA-7909:A toast warning message should appear anytime the user changes the value of Airflow Transitions to ‘Min Transition Space’.
    //50.0,  "Min Transition Space", 45.0, "Standard Transition Space", 40.0, "Max Transition Space"
    if (value == "Minimum"){
      const notification = {
        id: "airflowtransitions",
        notificationType: 'warning',
        title: 'Warning',
        content: <div className="nf-div">{"Selecting Min Transition Space means airflow transitional angles shall be pushed to the limit of acceptability for each component. Doors may additionally impact overall unit length."}</div>
      };
      addNotification(notification);
    }
    else {
      removeNotification("airflowtransitions");
    }
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateLengthInputs", projectId, unitId, fieldName, newValue, "FineTuneInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If unitBasicsData was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fineTuneInputs,
          displayFields: result.data.uiDataContainer.displayFields,
          inputChanged: true
        });
      }
    }
  }
  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    console.log(this.state.validationErrors);
    let errorsArray = [...this.state.validationErrors];

    //Make a new array that only includes items not matching the given fieldName
    let errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== fieldName.toLowerCase());

    this.setState({ validationErrors: errors, lastUpdatedTextbox: "" });
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    //Make a copy of the list from state.dropdownOptions
    const newState = Object.assign({}, this.state.dropdownOptions, {});

    //Update newState with any new values we got from the server
    Object.entries(newDropdownOptions).forEach(([key, value]) => {
      if (value != null) {
        (newState as any)[key] = value;
      }
    });

    this.setState({ dropdownOptions: newState });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    //make a copy of error messages collection in state
    let errorsArray = [...this.state.validationErrors];

    for (const error of validationErrors) {
      const exists = this.state.validationErrors.find((x: any) => x.fieldName.toLowerCase() === error.fieldName.toLowerCase());
      if (!exists) {
        errorsArray.push(error);
      }
    }
    this.setState({ validationErrors: errorsArray });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error0");
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    const savedValues = this.state.userInputs;
    if (!savedValues) {
      return null;
    }
    const displayFields = this.state.displayFields;

    return (
      <div className="drawing-inputs-main">
        <div className="drawing-container">
          <div className="drawing-left">
            <div className="drawing-fields-left">
              <fieldset className="drawing-fieldset length-fieldset legend-spacer">
                <legend className="drawing-legend length-legend">Component Spacing Inputs</legend>

                <div className="drawing-fields-spacer"></div>

                <div className="input-fields">
                  <div className="accordion-row">
                    <div className="drawing-input-label">Airflow Transitions</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "transitionAngles", "accordion-select")}
                        value={savedValues.transitionAngles}
                        onChange={(event) => this.saveAndCheckTransitionAngles("transitionAngles", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "transitionAnglesOptions")}
                      </select>
                    </div>
                    {this.getValidationError("transitionAngles")}
                  </div>
                  <div className="accordion-row">
                    <div className="drawing-input-label">Heating/Cooling Access</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "heatingCoolingAccess", "accordion-select")}
                        value={savedValues.heatingCoolingAccess}
                        onChange={(event) => this.saveInputValue("heatingCoolingAccess", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "heatingCoolingAccessOptions")}
                      </select>
                    </div>
                    {this.getValidationError("heatingCoolingAccess")}
                  </div>
                  <div className="accordion-row">
                    <div className="drawing-input-label">Allow Fan/Duct Overlap</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "allowFanDuctOverlap", "accordion-select")}
                        value={savedValues.allowFanDuctOverlap}
                        onChange={(event) => this.saveInputValue("allowFanDuctOverlap", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "allowFanDuctOverlapOptions")}
                      </select>
                    </div>
                    {this.getValidationError("allowFanDuctOverlap")}
                  </div>
                </div>

                <div className="drawing-fields-spacer"></div>
                <div className="drawing-fields-spacer"></div>
              </fieldset>

              <fieldset className="drawing-fieldset length-fieldset legend-spacer">
                <legend className="drawing-legend length-legend">Door Length Inputs</legend>

                <div className="drawing-fields-spacer"></div>

                <div className="input-fields">
                  <div className="accordion-row">
                    <div className="drawing-input-label2">Fan Door Length</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "minFanDoorLength", "drawing-select")}
                        value={savedValues.minFanDoorLength}
                        onChange={(event) => this.saveInputValue("minFanDoorLength", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "minFanDoorLengthOptions")}
                      </select>
                    </div>
                    {this.getValidationError("minFanDoorLength")}
                  </div>

                  <div className="accordion-row">
                    <div className="drawing-input-label2">Filter Door Length</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "minFilterDoorLength", "drawing-select")}
                        value={savedValues.minFilterDoorLength}
                        onChange={(event) => this.saveInputValue("minFilterDoorLength", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "minFilterDoorLengthOptions")}
                      </select>
                    </div>
                    {this.getValidationError("minFilterDoorLength")}
                  </div>

                  {displayFields.showEnergyRecoveryDoorLength ? 
                  <div className="accordion-row">
                    <div className="drawing-input-label2">Energy Recovery Door Length</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "minEnergyRecoveryDoorLength", "drawing-select")}
                        value={savedValues.minEnergyRecoveryDoorLength}
                        onChange={(event) => this.saveInputValue("minEnergyRecoveryDoorLength", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "minEnergyRecoveryDoorLengthOptions")}
                      </select>
                    </div>
                    {this.getValidationError("minEnergyRecoveryDoorLength")}
                  </div>
                  : null}
                  <div className="accordion-row">
                    <div className="drawing-input-label2">Heating Coil Door Length</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "minCoilDoorLength", "drawing-select")}
                        value={savedValues.minCoilDoorLength}
                        onChange={(event) => this.saveInputValue("minCoilDoorLength", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "minCoilDoorLengthOptions")}
                      </select>
                    </div>
                    {this.getValidationError("minCoilDoorLength")}
                  </div>

                  <div className="accordion-row">
                    <div className="drawing-input-label2">All Other Door Length</div>
                    <div className="drawing-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "allOtherMinDoorLength", "drawing-select")}
                        value={savedValues.allOtherMinDoorLength}
                        onChange={(event) => this.saveInputValue("allOtherMinDoorLength", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "allOtherMinDoorLengthOptions")}
                      </select>
                    </div>
                    {this.getValidationError("allOtherMinDoorLength")}
                  </div>
                </div>

                <div className="drawing-fields-spacer"></div>
                <div className="drawing-fields-spacer"></div>
              </fieldset>

              <fieldset className="drawing-fieldset length-fieldset legend-spacer">
                <legend className="drawing-legend length-legend">Length Outputs</legend>

                <div className="drawing-fields-spacer"></div>

                <div className="input-fields">
                  <div className="accordion-row">
                    <div className="drawing-input-label2">Cabinet Length</div>
                    <div className="drawing-input-label3">{this.state.unit.casingLength || "n/a"}"</div>
                  </div>

                  <div className="accordion-row">
                    <div className="drawing-input-label2">Overall Length</div>
                    <div className="drawing-input-label3">{this.state.unit.overallLength || "n/a"}"</div>
                  </div>
                </div>

                <div className="drawing-fields-spacer"></div>
                <div className="drawing-fields-spacer"></div>
              </fieldset>

              <div className={this.state.inputChanged === true ? "perf-reset-btn drawing-update-design-margin" : "perf-reset-btn-disabled drawing-update-design-margin"}>
                <div onClick={() => this.RunPerformance()}>Update Design</div>
              </div>
            </div>
          </div>

          <div className="drawing-right">
            <div className="drawing-heading">Unit Drawing Preview</div>

            {this.getTopDrawingViewer()}

            {this.getBottomDrawingViewer()}
          </div>

          <div className="drawing-td3">{/* Take up remaining horizontal space in browser */}</div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(LengthInputs);
