import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../../redux/actions";
import AccordionHeader from "../../../Shared/AccordionHeader";
import FanDisplayGroup from "./FanDisplayGroup";
import { callGetEndpoint } from "../../../../api-calls/readApiService";
import { isEmptyObject } from "../../../../utilities/helpers";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  openAccordion: boolean;
  componentData: any;
  supplyFanMotorEnclosureOptions:any[];
  exhaustFanMotorEnclosureOptions:any[];
  returnFanMotorEnclosureOptions:any[];
  supplyFanShaftGroundingOptions:any[];
  exhaustFanShaftGroundingOptions:any[];
  returnFanShaftGroundingOptions:any[];
}

class Fans extends React.Component<IProps, IState> {
  public state: IState = {
    openAccordion: false,
    componentData: null,
    supplyFanMotorEnclosureOptions: [],
    exhaustFanMotorEnclosureOptions: [],
    returnFanMotorEnclosureOptions: [],
    supplyFanShaftGroundingOptions: [],
    exhaustFanShaftGroundingOptions: [],
    returnFanShaftGroundingOptions: [],
  };

  private togglePanel() {
    this.setState({ openAccordion: !this.state.openAccordion }); //toggle to opposite of current state
  }
  //Note: Dropdown options only need to be put in state at page load because the options will never change based on 
  //inputs made on the fan construction page.
  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint("readapi/GetAllFanInputValues", projectId, unitId);
    if (!isEmptyObject(result)) {
      this.setState({
        componentData: result,
        supplyFanMotorEnclosureOptions: result.dropdownOptions.supplyFanMotorEnclosureOptions,
        exhaustFanMotorEnclosureOptions: result.dropdownOptions.exhaustFanMotorEnclosureOptions,
        returnFanMotorEnclosureOptions: result.dropdownOptions.returnFanMotorEnclosureOptions,
        supplyFanShaftGroundingOptions: result.dropdownOptions.supplyFanShaftGroundingOptions,
        exhaustFanShaftGroundingOptions: result.dropdownOptions.exhaustFanShaftGroundingOptions,
        returnFanShaftGroundingOptions: result.dropdownOptions.returnFanShaftGroundingOptions,
      });
    }
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
  }

  //This method gets passed to the FanDisplayGroups.
  //Whenever calling one of the "updateAllFans" methods, they need to pass all the updated
  //fan info back here so we can refresh all the FanDisplayGroups.
  public refreshAllFanData = (allFansUIResponse: any) => {
    this.setState({
      componentData: allFansUIResponse.data
    });
  };

  public render() {
    const active = this.state.openAccordion;
    const componentData = this.state.componentData;
    if (isEmptyObject(componentData)) {
      return null;
    }
    const displayFields = componentData.uiDataContainer.displayFields;
    //Show or hide the Outlet Safety Cage Title.
    let outletSafetyCageClass = displayFields.showSupplyFanOutletSafetyCage || displayFields.showExhaustFanOutletSafetyCage ||
                                    displayFields.showReturnFanOutletSafetyCage ? "" : "app-display-none";
    return (
      <div className="">
        <AccordionHeader
          title="Fans"
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.togglePanel()}
        />

        <div className={`acc-panel-1000 ${active === true ? "acc-panel-active" : "acc-panel-inactive"}`}>
          <div className="accordion-content app-box-shadow">
            <div className="spec-fields">
              <div className="spec-field-spacer1"></div>

              <div className="spec-coils-container">
                <div className="spec-field-spacer3"></div>
                <div className="spec-input-label">Motor Enclosure</div>
                <div className="spec-input-label">Shaft Grounding</div>
                <div className="spec-input-label">Inlet Guard</div>
                <div className="spec-input-label">Door Safety Kill Switch</div>
                <div className={"spec-input-label " + outletSafetyCageClass}>Outlet Safety Cage</div>
              </div>

              {displayFields.showSupplyFan ? (
                <FanDisplayGroup
                  title="Supply"
                  targetVertex="SupplyFanInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.supplyFanInputs}
                  showSafetyCage={displayFields.showSupplyFanOutletSafetyCage}
                  motorEnclosureOptions={this.state.supplyFanMotorEnclosureOptions}
                  shaftGroundingOptions={this.state.supplyFanShaftGroundingOptions}
                  key="supply"
                  match={this.props.match}
                  refreshAllFanData={this.refreshAllFanData} //pass a function reference to the FanDisplayGroup
                />
              ) : null}

              {displayFields.showExhaustFan ? (
                <FanDisplayGroup
                  title="Exhaust"
                  targetVertex="ExhaustFanInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.exhaustFanInputs}
                  showSafetyCage={displayFields.showExhaustFanOutletSafetyCage}
                  motorEnclosureOptions={this.state.exhaustFanMotorEnclosureOptions}
                  shaftGroundingOptions={this.state.exhaustFanShaftGroundingOptions}
                  key="exhaust"
                  match={this.props.match}
                  refreshAllFanData={this.refreshAllFanData} //pass a function reference to the FanDisplayGroup
                />
              ) : null}

              {displayFields.showReturnFan ? (
                <FanDisplayGroup
                  title="Return"
                  targetVertex="ReturnFanInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.returnFanInputs}
                  showSafetyCage={displayFields.showReturnFanOutletSafetyCage}
                  motorEnclosureOptions={this.state.returnFanMotorEnclosureOptions}
                  shaftGroundingOptions={this.state.returnFanShaftGroundingOptions}
                  key="return"
                  match={this.props.match}
                  refreshAllFanData={this.refreshAllFanData} //pass a function reference to the FanDisplayGroup
                />
              ) : null}

              <div className="spec-field-spacer2"></div>
              <div className="spec-field-spacer1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Fans);
