export enum DrawingScreen {
    Drawing = 0, 
    CoilConnections = 1, 
    ElectricalFurnacePanels = 2, 
    AccessDoors = 3, 
    LengthInputs = 4, 
}
export enum DrawingViewType {
    FrontSide = 0, 
    BackSide = 1, 
    LeftEnd = 2, 
    RightEnd = 3, 
    Top = 4, 
    TopUpperTunnel = 5, 
    TopBottomTunnel = 6, 
}