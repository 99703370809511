import * as React from 'react';
import { connect } from 'react-redux';
import { match, matchPath } from 'react-router-dom'; //needed for 'location' object and 'match.params'
import { mapDispatchToProps } from '../redux/actions';
import { UpdateMultipleUnits } from '../api-utilities/api-calls';
import { generateUnitPriceSummary } from '../api-calls/pricingDataService';
import { getUnitsWithOversizedInfo } from '../api-calls/projectDataService';
import { addNotification, removeNotification } from '../api-utilities/notification-tools';
import { formatNumber,formatProjectInfo, formatUnitListPriceInfo, formatTrucksRequired } from "../api-utilities/formatting";
import '../css/Output.scss';
import "../css/Accordions.scss";

//------- NO LONGER USED IN FIREBIRD.  KEEP AS REFERENCE WHEN MOVING TO ADMIN SITE ------------

// expected props
interface IProps {
  updateRedux: any;
  location: any;
  match: match<any>;
  reduxMenus: any;
  reduxEditDrawing:any;
  history: any;
  reduxProjectManagement: any;
}
// local state
interface IState {
  units:any,
  generatePricingClicked:boolean,
  unitSelected:any,
  unitPriceSummaryInfo:any
}

class Pricing extends React.Component<IProps, IState> {
  public state: IState = {units:null, generatePricingClicked:false, unitSelected:null, unitPriceSummaryInfo: null};

  async componentDidMount() {
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const response = await getUnitsWithOversizedInfo(this.props.reduxProjectManagement.selectedProject.id);
    let unitList = response.unitsWithOversizeInfo;
    this.setState({units: unitList});
    this.props.updateRedux('UPDATE_MENUS', { topMenu: { activeItem: 5 } });
    unitList = null;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
  }
  async componentDidUpdate(prevProps:any){
    //If the user does a "save" on an unsaved unit when on the pricing screen, update the current unit so it can be selected for pricing.
    //Don't update units if generate docs has been clicked because that will remove the selections for the checkboxes for the units selected.
    if (prevProps.reduxMenus.enableSave !== this.props.reduxMenus.enableSave && this.props.reduxMenus.enableSave === true && 
      this.state.generatePricingClicked === false) {
      const response = await getUnitsWithOversizedInfo(this.props.reduxProjectManagement.selectedProject.id);
      if(response.hasError){
        const notification = {
          id: "pricingSummaryError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">GetUnitsWithOversizedInfo {response.errorMessage}</div>
        };
        addNotification(notification);
      }
      else{
        let unitList = response.unitsWithOversizeInfo;
        this.setState({ units: unitList });
        unitList = null;
      }
    }
  }
  
  public render() {
    let Disabled = this.DisableGenerateButton() ? "app-outputs-generate-disable" : "";
    const projectInfo = this.state.unitPriceSummaryInfo === null ? null : formatProjectInfo(this.state.unitPriceSummaryInfo.projectInfo); 
    const unitListPriceInfo = this.state.unitPriceSummaryInfo === null ? null :formatUnitListPriceInfo(this.state.unitPriceSummaryInfo.unitListPriceInfo);
    const multiplier = this.state.unitPriceSummaryInfo === null ? null :this.state.unitPriceSummaryInfo.multiplier;
    const jobListPrice = this.state.unitPriceSummaryInfo === null ? null : "$" +formatNumber(this.state.unitPriceSummaryInfo.jobListPrice, 0).toString();
    const jobNetPrice = this.state.unitPriceSummaryInfo === null ? null : "$" +formatNumber(this.state.unitPriceSummaryInfo.jobNetPrice, 0).toString();

    return (
      <div className="app-outputs">
        <div className="app-outputs-title">Generate Pricing</div>

        <div className="app-output-container">
           {/*================================= Left Column ================================ */}
          <div className="app-outputs-left">
            <div className="app-outputs-heading">Unit Selection</div> 
            <div className="app-clear"></div>
            <div className="app-outputs-subheading">Generate Pricing For</div>
            {this.buildUnitRadioButtons()}
            <div className={'app-outputs-generate-btn ' + Disabled}> 
              <div onClick={() => this.generateUnitPriceSummary()}>Show Pricing Details</div>
            </div>  
          </div>
          {projectInfo !== null ?
          <div className="app-outputs-right">
            <fieldset className="optimize-fieldset">
            <legend className="optimize-legend">Pricing Summary</legend>

            <div className="accordion-content">
              <div className="acc-spacer"></div>
              <div className="accordion-row">
              <div className="accordion-label">Job Name</div>
              <div className="accordion-label">{projectInfo.name}</div>
              </div>
              <div className="accordion-row">
              <div className="accordion-label">Rep Office Name</div>
              <div className="accordion-label">{projectInfo.officeName}</div>
              </div> 
              <div className="accordion-row">  
              <div className="accordion-label">Rep Sales Person</div>
              <div className="accordion-label">{projectInfo.createdByFirstName + " " + projectInfo.createdByLastName}</div>
              </div> 
              <div className="accordion-row">
              <div className="accordion-label">Unit Tag</div>
              <div className="accordion-label">{unitListPriceInfo.unitTag}</div>
              </div>
              <div className="accordion-row">
              <div className="accordion-label">List Price*</div>
              <div className="accordion-label">{unitListPriceInfo.listPrice}</div>
              </div>
             
              <div className="accordion-row">
              <div className="accordion-label">Model</div>
              <div className="accordion-label">{unitListPriceInfo.modelNumber}</div>
              </div>
              <div className="accordion-row">
              <div className="accordion-label">Quantity</div>
              <div className="accordion-label">{unitListPriceInfo.unitQuantity}</div>
              </div>
              <div className="accordion-row">
              <div className="accordion-label">Unit Will Require</div>
              <div className="accordion-label">{formatTrucksRequired(unitListPriceInfo.truckRequired, unitListPriceInfo.unitQuantity)}</div>
              </div>
              <div className="accordion-row">
              <div className="accordion-label">Job List Price</div>
              <div className="accordion-label">{jobListPrice}</div>
              </div>
              <div className="accordion-row">
              <div className="accordion-label">Multiplier</div>
              <div className="accordion-label">{multiplier}</div>
              </div>
              <div className="acc-spacer"></div>
              <div className="accordion-row">
              <div className="accordion-label"><b>Job Net Price</b></div>
              <div className="accordion-label"><b>{jobNetPrice}</b></div>
              <div className="accordion-label outputs-pricing-notes"><b>Price does NOT include freight.</b></div>
              </div>
              <div className="acc-spacer"></div>
              <div className="accordion-row">
              <div className="accordion-label">Total Freight Cost</div>
              <div className="accordion-label">{unitListPriceInfo.totalFreightCost}</div>
              </div>
              <div className="acc-spacer"></div>
              <div className="acc-spacer"></div>
              <div className="accordion-row"> 
              <div>*When unit Quantity is greater than 1 for a given Tag, the List Price shown is per unit.</div>
              </div>
              <div className="acc-spacer"></div>
            </div>
            </fieldset>
          </div>
          : null}
        </div>
      </div>
    );
  }
  private DisableGenerateButton(){
    let unitChecked = this.state.unitSelected !== null ? true : false;
    return !unitChecked;
  }
  
  private updateUnitSelected(id: any, checked: boolean){
    this.setState({unitSelected: id});
  }
  private buildUnitRadioButtons(){
    if (!this.state.units){
      return null;
    }
    return (
      this.state.units.map((item:any, index:number) =>(
        <div key={index}>
        <div className="app-outputs-checkbox app-outputs-units"> {item.unitTag + (item.isOversized ? " (Oversized)" : "")}</div>
        <input
            disabled={item.isOversized}
            type="radio"
            name="unit"
            value={item.unitTag ?? ""}
            checked = {item.id == this.state.unitSelected ? true : false}
            onChange={(event) => this.updateUnitSelected(item.id,event.currentTarget.checked)}
        />
        </div>
      ))
    );
  }
  private async generateUnitPriceSummary(){
    this.setState({unitPriceSummaryInfo: null});
    this.setState({generatePricingClicked: true});
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    
    let response : any = await generateUnitPriceSummary(this.props.reduxProjectManagement.selectedProject.id, this.state.unitSelected);
    if(response.data.hasError){
      const notification = {
        id: "UnitPriceSummaryError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GenerateUnitPriceSummary {response.data.errorMessage !== null && response.data.errorMessage !== ""} ? {response.data.errorMessage} : {response.data.individualErrors}</div>
      };
      addNotification(notification);
    }
    else{
      this.setState({unitPriceSummaryInfo: response.data});
    }
    
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    this.setState({generatePricingClicked: false});
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus,
    reduxProjectManagement: state.reduxProjectManagement,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
