import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { callGetEndpoint } from "../../../api-calls/readApiService";
import { isEmptyObject } from "../../../utilities/helpers";
import { addNotification } from "../../../api-utilities/notification-tools";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  reduxComponentPerformance:any;
  targetVertex: string;
}
// local state
interface IState {
  electricHeateroperatingMode: any;
  preheatElectricHeateroperatingMode: any;
  dataContainer: any;
}
class ElectricHeater extends React.Component<IProps, IState> {
  public state: IState = {
    electricHeateroperatingMode: null,
    preheatElectricHeateroperatingMode: null,
    dataContainer: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;

    const result = await callGetEndpoint(`readapi/GetElectricHeaterInputValues/${this.props.targetVertex}`, projectId, unitId);

    if (result.uiDataContainer) {
      this.setState({
        electricHeateroperatingMode: result.uiDataContainer.electricHeaterOpMode,
        preheatElectricHeateroperatingMode: result.uiDataContainer.preheatElectricHeaterOpMode,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
  }
  //------------------------------------------------------------------------------------------

  public render() {
    let dataContainer = this.state.dataContainer;
    if (isEmptyObject(dataContainer)) {
      return null;
    }

    let targetVertex = this.props.targetVertex;
    let title = targetVertex == "ElectricHeater" ? "Electric Heater" : targetVertex == "PreheatElectricHeater" ? "Electric Preheat" : "";

    let electricHeater = {} as any;
    let operatingMode = {} as any;
    let powerKw = null;
    let amps = null;

    if (this.props.targetVertex == "ElectricHeater") {
      electricHeater = this.props.reduxComponentPerformance.electricHeater;
      operatingMode = this.state.electricHeateroperatingMode;
    }
    if (this.props.targetVertex == "PreheatElectricHeater") {
      electricHeater = this.props.reduxComponentPerformance.preheatElectricHeater;
      operatingMode = this.state.preheatElectricHeateroperatingMode;
    }

    if (isEmptyObject(electricHeater)) {
      return null;
    }
    else{ //We need to show the total PowerKw and Amps
      if(electricHeater.powerKWPerHeater !== null && electricHeater.quantityInHeight !== null && electricHeater.quantityInWidth !== null){
        powerKw = electricHeater.powerKWPerHeater * electricHeater.quantityInHeight * electricHeater.quantityInWidth;
      }
      if(electricHeater.amperagePerHeater !== null && electricHeater.quantityInHeight !== null && electricHeater.quantityInWidth !== null){
        amps = electricHeater.amperagePerHeater * electricHeater.quantityInHeight * electricHeater.quantityInWidth;
      }
    }
    if (!operatingMode) {
      return null;
    }

    if (isEmptyObject(operatingMode)) {
      const notification = {
        id: "elecOperatingModeError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">{targetVertex} operating mode not found.</div>
      };
      addNotification(notification);
      operatingMode = {};
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">{title}</legend>

        <div className="accordion-content">
          <div className="acc-fan-spacer"></div>

          <div className="accordion-row">
            <div className="accordion-label">Entering Air Temp</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{formatNumber(operatingMode.enteringDryBulb, 1) || ""}</div>
            </div>
            <div className="accordion-label">Leaving Air Temp</div>
            <div className="accordion-label-numeric">{formatNumber(operatingMode.leavingDryBulb, 1) || ""}</div>
          </div>

          <div className="accordion-row">
            <div className="accordion-label">Voltage</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{dataContainer.voltage}</div>
            </div>
            <div className="accordion-label">Power (kW)</div>
            <div className="accordion-label-numeric">{formatNumber(powerKw, 1) || ""}</div>
          </div>

          <div className="accordion-row">
            <div className="accordion-label">Control Type</div>
            <div className="accordion-input">
              <div className="accordion-label-numeric2">{dataContainer.controlType}</div>
            </div>
            <div className="accordion-label">Amps</div>
            <div className="accordion-label-numeric">{formatNumber(amps, 1) || ""}</div>
          </div>

          <div className="accordion-row"></div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxComponentPerformance: state.reduxComponentPerformance
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ElectricHeater);
