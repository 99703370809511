import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getElectricalInputValues } from "../../api-calls/readApiService";
import { isEmptyObject } from "../../utilities/helpers";
import PrimaryPower from "./Electrical/PrimaryPower";
import FanVFD from "./Electrical/FanVFD";
import Circuit120V from "./Electrical/Circuit120V";
import Circuit24V from "./Electrical/Circuit24V";
import "../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  componentData: any;
}

class Electrical extends React.Component<IProps, IState> {
  public state: IState = {
    componentData: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await getElectricalInputValues(projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError){
      const notification = {
        id: "electricalInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetElectricalInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (!isEmptyObject(result.uiDataContainer)) {
      this.setState({
        componentData: result
      });
    }
  }
  public render() {
    const componentData = this.state.componentData;
    if (isEmptyObject(componentData)) {
      return null;
    }
    return (
      <div className="spec-body-content">

        <div className="spec-field-spacer2"></div>

        <div className="spec-page-division">
          <PrimaryPower match={this.props.match} componentData={componentData} />
        </div>

        <div className="spec-page-division">
          <FanVFD match={this.props.match} componentData={componentData}/>
        </div>

        <div className="spec-page-division">
          <Circuit120V match={this.props.match} componentData={componentData} />
        </div>

        <div className="spec-page-division">
          <Circuit24V match={this.props.match} componentData={componentData} />
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Electrical);
