import { store } from '../redux/store';
import {getAuthToken} from '../api-calls/projectDataService';
import {displayErrorString} from '../api-utilities/api-calls';
//This file contains calls to the Lowry backend, which gets results from the ChartAPI

const apibase = process.env.REACT_APP_CLIENT_API;

export async function sendSupportEmail(emailAddress: string, message: string, name: string, phone: string){
    const token = await getAuthToken();

    const contactUsEmailRequest = {
        userEmail: emailAddress,
        message: message,
        userName: name,
        userPhone: phone
      };

    const result = await fetch(apibase + '/dataapi/sendSupportEmail', {
      method: 'post',
      body: JSON.stringify(contactUsEmailRequest),
      headers: { 'Content-Type': 'application/json',
      Authorization: `Bearer ${token}` }
    });

    if (result.status === 200) {
        const data = await result.json();
        if (data.hasError){
          displayErrorString(data.errorMessage, "sendSupportEmail");
        }
        return data;
      }
      else{
        displayErrorString("Requst failed", 'sendSupportEmail');
      }
  }
