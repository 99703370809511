import { IAction } from '../../interfaces/interfaces';
import { FanVfdItem } from '../../TypescriptClient';

export const reduxElectricalSupplyFan = (state: FanVfdItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_ELECTRICAL_SUPPLYFAN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxElectricalExhaustFan = (state: FanVfdItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_ELECTRICAL_EXHASTFAN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxElectrical = (state: any = {}, action: IAction) => {
  if (action.type === 'UPDATE_ELECTRICAL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
