import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { match } from "react-router";
import UnitSizingLimits from "./ComponentDisplay/UnitSizingLimits";
import ChilledWaterCoil from "./ComponentDisplay/ChilledWaterCoil";
import HotWaterCoil from "./ComponentDisplay/HotWaterCoil";
import SteamCoil from "./ComponentDisplay/SteamCoil";
import Fan from "./ComponentDisplay/Fan";
import Filter from "./ComponentDisplay/Filter";
import CombinedFilter from "./ComponentDisplay/CombinedFilter";
import HumidifierManifold from "./ComponentDisplay/HumidifierManifold";
import SoundAttenuator from "./ComponentDisplay/SoundAttenuator";
import HeatWheel from "./ComponentDisplay/HeatWheel";
import ElectricHeater from "./ComponentDisplay/ElectricHeater";
import IndirectFiredFurnace from "./ComponentDisplay/IndirectFiredFurnace";
import PlateHeatExchanger from "./ComponentDisplay/PlateHeatExchanger";
import PackagedAc from "./ComponentDisplay/PackagedAc";
import "../../css/Accordions.scss";

// expected props
interface IProps {
  // updateRedux: any
  reduxOptimizeDesign: any;
  match: match<any>;
  componentPerformanceData: any;
  updateRedux: any;
}
// local state
interface IState {}

class OptimizeDesignInputs extends React.Component<IProps, IState> {
  public state: IState = {};

  public getButton(componentName: string, displayName: string) {
    const selectedComponent = this.props.reduxOptimizeDesign.selectedComponent;
    const selectedComponentName = this.props.reduxOptimizeDesign.selectedComponentName;
    const isActive = selectedComponentName == componentName ? true : false;
    //console.log(selectedComponentName + ":" + componentName);
    const locked = false;
    const hasError = false;

    return (
      <div key={componentName} className={`component-btn ${isActive ? " component-btn-selected" : ""}`} onClick={() => this.selectComponent(componentName)}>
        {locked ? <div className="acc-lock-icon"></div> : null}
        {hasError ? <div className="acc-header-error"></div> : null}
        <span>{displayName}</span>
      </div>
    );
  }

  componentWillUnmount() {
    this.selectComponent("none");
  }

  //Create buttons for all the components that got returned by the runPerformanceCalculations api call
  public getComponentButtons() {
    const componentPerformanceData = this.props.componentPerformanceData;
    if (!componentPerformanceData) {
      return null;
    }

    let primaryInputs = componentPerformanceData.primaryInputs;
    if (!primaryInputs) {
      return null;
    }
    const buttonArr = [];

    buttonArr.push(this.getButton("unitSizingLimits", "Unit Sizing Limits"));
    if (componentPerformanceData.heatWheel) {
      buttonArr.push(this.getButton("heatWheel", "Heat Wheel"));
    }
    if (componentPerformanceData.plateHx) {
      buttonArr.push(this.getButton("plateHx", "Plate Heat Exchanger"));
    }
    if (componentPerformanceData.chilledWaterCoil) {
      buttonArr.push(this.getButton("chilledWaterCoil", "Chilled Water Coil"));
    }
    if (primaryInputs.coolingType == "DXSplit") {
      buttonArr.push(this.getButton("dxSplit", "Refrigeration Split System"));
    }
    if (primaryInputs.coolingType == "DXPackaged") {
      buttonArr.push(this.getButton("dxPackaged", "Packaged Refrigeration"));
    }
    if (primaryInputs.coolingType == "DXPackagedWithHotGasReheat") {
      buttonArr.push(this.getButton("dxPackagedHgrh", "Packaged Refrigeration w/HGRH"));
    }
    if (componentPerformanceData.preheatHotWaterCoil) {
      buttonArr.push(this.getButton("preheatHotWaterCoil", "Hot Water Preheat"));
    }
    if (componentPerformanceData.preheatElectricHeater) {
      buttonArr.push(this.getButton("preheatElectricHeater", "Electric Preheat"));
    }
    if (componentPerformanceData.hotWaterCoil) {
      buttonArr.push(this.getButton("hotWaterCoil", "Hot Water Coil"));
    }
    if (componentPerformanceData.steamCoil) {
      buttonArr.push(this.getButton("steamCoil", "Steam Coil"));
    }
    if (componentPerformanceData.idf) {
      buttonArr.push(this.getButton("idf", "Indirect Fired Furnace"));
    }
    if (componentPerformanceData.electricHeater) {
      buttonArr.push(this.getButton("electricHeater", "Electric Heater"));
    }
    if (componentPerformanceData.humidifierManifold) {
      buttonArr.push(this.getButton("humidifierManifold", "Humidifier Manifold"));
    }
    if (componentPerformanceData.supplyFan) {
      buttonArr.push(this.getButton("supplyFan", "Supply Fan"));
    }
    if (componentPerformanceData.exhaustFan) {
      buttonArr.push(this.getButton("exhaustFan", "Exhaust Fan"));
    }
    if (componentPerformanceData.returnFan) {
      buttonArr.push(this.getButton("returnFan", "Return Fan"));
    }
    //outsideAirPreFilter is the object that holds both Outside Air and Supply Air
    //Pre and high efficiency filters.  Determin label to show on button.
    if (componentPerformanceData.outsideAirPreFilter) {
      let buttonLabel = "";
      if (componentPerformanceData.primaryInputs.airTunnelOrientationType !== "NotSet") {
        if (componentPerformanceData.outsideAirPreFilter.stage2Rating) {
          buttonLabel = "Outside Air Filters";
        } else {
          buttonLabel = "Outside Air Prefilter";
        }
      } else {
        if (componentPerformanceData.outsideAirPreFilter.stage2Rating) {
          buttonLabel = "Supply Air Filters";
        } else {
          buttonLabel = "Supply Air Prefilter";
        }
      }
      buttonArr.push(this.getButton("outsideAirPreFilter", buttonLabel));
    }
    if (componentPerformanceData.outsideAirHighEfficiencyFilter) {
      buttonArr.push(this.getButton("outsideAirHighEfficiencyFilter", "Outside Air High Efficiency Filter"));
    }
    if (componentPerformanceData.supplyAirFilter) {
      buttonArr.push(this.getButton("supplyAirFinalFilter", "Supply Air Final Filter"));
    }
    if (componentPerformanceData.returnAirFilter) {
      buttonArr.push(this.getButton("returnAirFilter", "Return Air Filter"));
    }
    if (componentPerformanceData.supplyAirSoundAttenuator) {
      buttonArr.push(this.getButton("supplyAirSoundAttenuator", "Supply Sound Attenuator"));
    }
    if (componentPerformanceData.returnAirSoundAttenuator) {
      buttonArr.push(this.getButton("returnAirSoundAttenuator", "Return Sound Attenuator"));
    }
    return buttonArr;
  }

  public selectComponent(componentName: string) {
    const components = {
      unitSizingLimits: <UnitSizingLimits match={this.props.match} />,
      chilledWaterCoil: <ChilledWaterCoil match={this.props.match} />,
      dxPackaged: <PackagedAc dxType="packaged" match={this.props.match} />,
      dxPackagedHgrh: <PackagedAc dxType="packagedHgrh" match={this.props.match} />,
      dxSplit: <PackagedAc dxType="split" match={this.props.match} />,
      electricHeater: <ElectricHeater targetVertex="ElectricHeater" match={this.props.match} />,
      exhaustFan: <Fan targetVertex="ExhaustFanInputs" match={this.props.match} />,
      heatWheel: <HeatWheel match={this.props.match} />,
      hotWaterCoil: <HotWaterCoil targetVertex="HotWaterCoilInputs" match={this.props.match} />,
      humidifierManifold: <HumidifierManifold targetVertex="HumidifierManifoldInputs" match={this.props.match} />,
      idf: <IndirectFiredFurnace match={this.props.match} />,
      outsideAirPreFilter: <CombinedFilter match={this.props.match} />,
      plateHx: <PlateHeatExchanger match={this.props.match} />,
      preheatElectricHeater: <ElectricHeater targetVertex="PreheatElectricHeater" match={this.props.match} />,
      preheatHotWaterCoil: <HotWaterCoil targetVertex="PreHeatHotWaterCoilInputs" match={this.props.match} />,
      returnAirFilter: <Filter targetVertex="ReturnAirFilterInputs" match={this.props.match} />,
      returnAirSoundAttenuator: <SoundAttenuator targetVertex="ReturnSoundAttenuatorInputs" match={this.props.match} />,
      returnFan: <Fan targetVertex="ReturnFanInputs" match={this.props.match} />,
      steamCoil: <SteamCoil match={this.props.match} />,
      supplyAirFinalFilter: <Filter targetVertex="SupplyAirFinalFilterInputs" match={this.props.match} />,
      supplyAirSoundAttenuator: <SoundAttenuator targetVertex="SupplySoundAttenuatorInputs" match={this.props.match} />,
      supplyFan: <Fan targetVertex="SupplyFanInputs" match={this.props.match} />,
      none: null
    };

    //stupid sytax to appease typescript. This puts the actual TSX component in redux, so it can be displayed below.
    this.props.updateRedux("UPDATE_OPTIMIZE_DESIGN", {
      selectedComponent: components[componentName as keyof typeof components],
      selectedComponentName: componentName
    });
  }

  public render() {
    const selectedComponent = this.props.reduxOptimizeDesign.selectedComponent;
    return (
      <div>
        <div className="optimize-heading2">Review &amp; Modify</div>

        <div className="optimize-components-main">
          <div className="optimize-left">
            <div className="optimize-buttons">{this.getComponentButtons()}</div>
          </div>
          <div className="optimize-right">{selectedComponent}</div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxOptimizeDesign: state.reduxOptimizeDesign
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OptimizeDesignInputs);
