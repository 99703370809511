import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import { deleteUnitPrompt, openUnitCopyModal } from './ProjectUtilities';
import { getNumericId } from '../../api-utilities/formatting';
import '../../css/Project.scss';
import { formatNumber } from '../../utilities/helpers';

// expected props
interface IProps {
  updateRedux: any;
  unit: any;
  projectId: string;
  history: any;
  reduxDisplay: any;
}
// local state
interface IState {}

class Unit extends React.Component<IProps, IState> {
  public state: IState = {};

  public render() {
    let deleteClass = this.props.reduxDisplay.unitToDelete === this.props.unit.id ? "app-display-none" : "";
    let spinnerClass = this.props.reduxDisplay.unitToDelete === this.props.unit.id ? "" : "app-display-none";
    let unitPrice = this.props.unit.unitPrice ? "$" + formatNumber(this.props.unit.unitPrice, 0) : "-";
    return (
      <div className="proj-unit-tr" onClick={() => this.editUnit()}>
        <div title={this.props.unit.unitTag} className="proj-unit-td-name ">
          {this.props.unit.unitTag}
        </div>
        <div title={this.props.unit.modelNumber} className="proj-unit-td-model">
          {this.props.unit.modelNumber}
        </div>
        <div className="proj-unit-td-price">
          {unitPrice}
        </div>
        <div className="proj-unit-td-qty">
          {this.props.unit.unitQuantity}
        </div>
        <div className="proj-unit-td proj-link" onClick={(event) => openUnitCopyModal(event, this.props.unit)}>
          copy
        </div>
        <div className={deleteClass + " proj-unit-td proj-link"} onClick={(event) => deleteUnitPrompt(event, this.props.unit)}>
          delete
        </div>
        <div className={spinnerClass + " proj-unit-td"}>
          <div className="spinner"></div>
        </div>
      </div>
    );
  }
  private editUnit() {
    let projectId = getNumericId(this.props.projectId);
    let unitId = getNumericId(this.props.unit.id);
    this.props.history.push('/pid/' + projectId + '/uid/' + unitId + '/design/unittype');
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {reduxDisplay: state.reduxDisplay};
}
export default connect(mapStateToProps, mapDispatchToProps)(Unit);
