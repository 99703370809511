import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getWarrantyInputValues, updateInputField } from "../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import "../../css/Warranty.scss";

// expected props
interface IProps {
  updateRedux: any;
  location: any;
  match: match<any>;
  history: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class Warranties extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await getWarrantyInputValues(projectId, unitId);

    if (result.hasError){
      const notification = {
        id: "warrantyValuesError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetWarrantyInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer.primaryInputs) {
      this.setState({
        userInputs: result.uiDataContainer.primaryInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors
      });
    }
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 5 } });
  }
  //Text box values for warranties only have one option, so just return the display value for the textbox.
  public getTextBoxValue(boxName: any) {
    const options = (this.state.dropdownOptions as any)[boxName];
    return options[0].displayValue;
  }
  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateWarranties", projectId, unitId, fieldName, newValue, "PrimaryInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.primaryInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }
  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------

  public render() {
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }

    return (
      <div className="spec-body-content">
        <div className="spec-page-division">
          <div className="warranty-fields">
            <div className="warranty-title">Warranties</div>
            <div className="spec-elec-fields">
              <div>
                <div className="warranty-input-label">Unit Parts Warranty</div>
                <div className="warranty-textbox">
                  <select
                    className="warranty-select"
                    value={savedValues.unitPartsWarranty}
                    onChange={(event) => this.saveInputValue("unitPartsWarranty", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "unitPartsWarrantyOptions")}
                  </select>
                </div>
              </div>
              {displayFields.showCompressorWarranty ? (
                <div>
                  <div className="warranty-input-label">Compressor Warranty</div>
                  <div className="warranty-textbox">
                    <select
                      className="warranty-select"
                      value={savedValues.compressorWarranty}
                      onChange={(event) => this.saveInputValue("compressorWarranty", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "compressorWarrantyOptions")}
                    </select>
                  </div>
                </div>
              ) : null}

              {displayFields.showPlateHXWarranty ? (
                <div>
                  <div className="warranty-input-label">Plate Heat Exchanger Warranty</div>
                  <div className="warranty-textbox">
                    <input type="text" disabled={true} className="warranty-input" value={this.getTextBoxValue('plateHXWarrantyOptions')} />
                  </div>
                </div>
              ) : null}

              {displayFields.showIDFWarranty ? (
                <div>
                  <div className="warranty-input-label">Indirect Fired Furnance Warranty</div>
                  <div className="warranty-textbox">
                    <input type="text" disabled={true} className="warranty-input" value={this.getTextBoxValue('idfWarrantyOptions')} />
                  </div>
                </div>
              ) : null}
              <div className="spec-field-spacer1"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Warranties);
