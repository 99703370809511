import { store } from '../redux/store';
import { displayError, displayErrorString} from '../api-utilities/api-calls';
import { IdentityService } from '../security/IdentityService';
import { IUserInfo } from '../interfaces/interfaces';
//This file contains calls to the Lowry backend, which gets results from the ProjectDataAPI

const apibase = process.env.REACT_APP_CLIENT_API;

export async function getAuthToken() {
  const auth = store.getState().reduxAuth;
  let token = '';
  //Check to see if reduxAuth contains the access_token. 
  //Using access_token from Redux prevents a bunch of calls to identityserver,
  //but on page reload, sometimes redux doesn't get populated fast enough, and the page tries
  //to call the server with no token, and fails.  So in that case we should get it from IdentityServer.
  if(auth.access_token !== ''){
    token = auth.access_token;
  }
  else{
    const identityService = new IdentityService();
    const user = await identityService.getUser();
    if(user != null){
      token = user.access_token;
    }
  }
  return token;
}

export async function getProjectsByUser() {
  const token = await getAuthToken();
  try {
    const result = await fetch(apibase + '/dataapi/getProjectsByUser', {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` }
    });
    if (result.status === 200) {
      const data = await result.json();
      if (data.hasError){
        displayErrorString(data.errorMessage, "getAllProjects");
      }
      return data;
    }
    else{
      displayErrorString("Requst failed", 'getAllProjects');
    }
  } catch (error) {
    displayError(error, 'getAllProjects');
  }
}

export async function getProject(projectId:string) {
  const token = await getAuthToken();
  let source = "";
  let url = "";

  url = apibase + '/dataapi/getProject/'+ projectId;
  source = "getProject";

  const result = await fetch(url, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });

  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, source);
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", source);
  }
}

// export async function getProjectsBySearchText(statusTypeId: number, searchText:string) {
//   const auth = store.getState().reduxAuth;
//   const token = await getAuthToken();
//   let source = "";
//   let url = "";
//   if (searchText !== ""){
//     url = apibase + '/dataapi/getProjectsBySearchText/' + statusTypeId + '/' + auth.userId + '/' + searchText;
//     source = "getProjectsBySearchText";
//   }
//   else {
//     url = apibase + '/dataapi/getAllProjects/' + statusTypeId + '/' + auth.userId;
//     source = "getAllProjects";
//   }
//   const result = await fetch(url, {
//     method: 'get',
//     headers: { Authorization: `Bearer ${token}` }
//   });

//   if (result.status === 200) {
//     const data = await result.json();
//     if (data.hasError){
//       displayErrorString(data.errorMessage, source);
//     }
//     return data;
//   }
//   else{
//     displayErrorString("Requst failed", source);
//   }
// }

export async function getStatusTypes() {
  const token = await getAuthToken();
try{
  const result = await fetch(apibase + '/dataapi/getEnumValues/100', {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, "getEnumValues(100)");
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", "getEnumValues(100)");
  }
}
catch{
  console.log("request failed: getEnumValues(100)");
  displayErrorString("Requst failed", "getEnumValues(100)");
  let response:any = {
    enumDtoList: []
  };
  return response;
}
}

//Update project based on user changes in the edit project modal dialog.
export async function updateProject(project:any, reduxProjectManagement:any){
  //const auth = store.getState().reduxAuth;
  const token = await getAuthToken();

  const editedProjectRequest = {
    //createdById: auth.userId,
    projectName: reduxProjectManagement.projectName,
    consultingEngineer: reduxProjectManagement.consultingEngineer,
    contractor: reduxProjectManagement.contractor,
    city: reduxProjectManagement.city,
    state: reduxProjectManagement.state,
    projectId:project.id,
    jobStatus:reduxProjectManagement.jobStatus
  };
 
  const result = await fetch(apibase + '/dataapi/updateProject', {
    method: 'post',
    body: JSON.stringify(editedProjectRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}

export async function addNewProject(reduxProjectManagement: any) {
  const token = await getAuthToken();
  const identityService = new IdentityService();
  const user = await identityService.getUser();
  const userInfo: IUserInfo = await identityService.getUserInfo(user);
  const newProjectRequest = {
    projectName: reduxProjectManagement.projectName,
    consultingEngineer:reduxProjectManagement.consultingEngineer ?? "",
    contractor: reduxProjectManagement.contractor ?? "",
    city: reduxProjectManagement.city ?? "",
    state: reduxProjectManagement.state ?? "",
    userId: userInfo.userId,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    userType: userInfo.userType,
    email: userInfo.email,
    jobStatus:reduxProjectManagement.jobStatus
  };
  const result = await fetch(apibase + '/dataapi/createproject', {
    method: 'post',
    body: JSON.stringify(newProjectRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}

//Add new unit to the database and projectFileString
export async function addUnit(projectId: number) {
  const token = await getAuthToken();
  let userRole = null;
  // const identityService = new IdentityService();
  // const user = await identityService.getUser();
  // const userInfo: IUserInfo = await identityService.getUserInfo(user);
  const auth = store.getState().reduxAuth;
    if (auth.userRoles?.length > 0) {
      auth.userRoles.forEach((role: any) => {
        if (role === "rep") {
          userRole = "rep";
        }
        if (role === "employee"){
          userRole = "employee";
        }
        if (role === "customer"){
          userRole = "customer";
        }
      });
    }
  const newUnitDto = {
    projectId: projectId,
    userType: userRole
  };
  const result = await fetch(apibase + '/dataapi/createUnit', {
    method: 'post',
    body: JSON.stringify(newUnitDto),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}

export async function getUnits(projectId: number) {
  const token = await getAuthToken();
try{
    const result = await fetch(apibase + '/dataapi/getUnits/' + projectId, {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` }
    });
    if (result.status === 200) {
      const data = await result.json();
      if (data.hasError){
        displayErrorString(data.errorMessage, "getUnits");
      }
      return data;
    }
    else{
      displayErrorString("Requst failed", 'getUnits');
    }
  }
  catch{
    displayErrorString("Requst failed", 'getUnits');
  }
}

export async function getOutputDocumentsData(projectId: any) {
  const token = await getAuthToken();

  const result = await fetch(apibase + '/dataapi/getOutputDocumentsData/' + projectId, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, "getOutputDocumentsData");
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", 'getOutputDocumentsData');
  }
}

export async function getUnitsWithOversizedInfo(projectId: any) {
  const token = await getAuthToken();

  const result = await fetch(apibase + '/dataapi/getUnitsWithOversizedInfo/' + projectId, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, "getUnitsWithOversizedInfo");
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", 'getUnitsWithOversizedInfo');
  }
}

export async function getUnitsForOutputDocs(projectId: number) {
  const token = await getAuthToken();

  const result = await fetch(apibase + '/dataapi/getUnitsForOutputDocUI/' + projectId, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, "getUnits");
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", 'getUnits');
  }
}

export async function getUnit(routeParams: any) {
  const { unitId } = routeParams;
  const token = await getAuthToken();

  const result = await fetch(apibase + '/dataapi/getUnit/' + unitId, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, "getUnit");
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", 'getUnit');
  }
}


//Copy a unit - call goes directly to ProjectDataAPI
export async function copyUnit(sourceProjectId:string, targetProjectId:string, unitTagName:string, unitId:number) {
  const token = await getAuthToken();

  const newUnitRequest = {
    sourceProjectId: sourceProjectId,
    targetProjectId: targetProjectId,
    unitId: unitId,
    unitTag: unitTagName
  };
  const result = await fetch(apibase + '/dataapi/copyUnit', {
    method: 'post',
    body: JSON.stringify(newUnitRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}

//Copy a Project - call goes directly to ProjectDataAPI
export async function copyProject(projectId:string, projectName:string) {
   const token = await getAuthToken();

  const copyProjectRequest = {
    projectId: projectId,
    projectName: projectName,
  };
  const result = await fetch(apibase + '/dataapi/copyProject', {
    method: 'post',
    body: JSON.stringify(copyProjectRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}

export async function getProjectAndUnitName(projectId: string, unitId: string, appVersion:string) {
  const token = await getAuthToken();
  let targetEndpoint = "dataapi/getProjectAndUnitName";

  const result = await fetch(`${apibase}/${targetEndpoint}/${projectId}/${unitId}/${appVersion}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });

  if (result.status === 200) {
    const data = await result.json();
    if (data.hasError){
      displayErrorString(data.errorMessage, "getProjectAndUnitName");
    }
    return data;
  }
  else{
    displayErrorString("Requst failed", 'getProjectAndUnitName');
  }
}

export async function deleteProjectAndUnits(projectId:string){
  const token = await getAuthToken();

  const result = await fetch(apibase + `/dataapi/deleteProject/${projectId}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });

  //result.json = successful object
  //result.text = error message
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}

export async function deleteUnit(projectId:string, unitId:string){
  const token = await getAuthToken();

  const result = await fetch(apibase + `/dataapi/deleteUnit/${projectId}/${unitId}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });

  //result.json = successful object
  //result.text = error message
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}
