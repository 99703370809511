import * as React from 'react';
import '../../css/Accordions.scss';

// expected props
interface IProps {
  title: string;
  subtitle: string;
  classname: string;
  active: boolean;
  locked: boolean;
  hasError: boolean;
  togglePanel: () => void;
}
// local state
interface IState {}

/** NOTE: this header is the same on all accordions.
 *  We just pass in the title, subtitle, its active state, and a function to toggle it on/off.
 */
class AccordionHeader extends React.Component<IProps, IState> {
  private getHeaderCSS() {
    let cssString = this.props.classname;
    if (this.props.active) {
      cssString += ' active-accordian';
    }
    //Gold bottom border. Try without for now (per Dave).  May bring it back.
    // if (this.props.locked) {
    //   cssString += ' acc-header-locked';
    // }

    return cssString;
  }

  public render() {
    const title = this.props.title;
    const subtitle = this.props.subtitle;
    const active = this.props.active;
    const locked = this.props.locked;
    const hasError = this.props.hasError;
    return (
      <button className={this.getHeaderCSS()} onClick={() => this.props.togglePanel()}>
        {locked ? <div className="acc-lock-icon"></div> : null}
        {hasError ? <div className="acc-header-error"></div> : null}
        <span>{title}</span>
        {subtitle && active === true ? (
          <span>
            <br />
            {subtitle}
          </span>
        ) : null}

        <div className={`accordion-arrow ${active === true ? 'acc-up-arrow' : 'acc-down-arrow'}`}></div>
      </button>
    );
  }
}

export default AccordionHeader;
