export function setLocalStorageKey(key: string, data: string) {
  localStorage.setItem(key, data);
}
export function clearLocalStorageKey(key: string) {
  localStorage.removeItem(key);
}
//Get any keys we've saved in localStorage and use them to set default state values for
//the matching objects in redux.
export function getInitialState() {
  let reduxInitialState = {};

  //TODO: Later we can expand this if we want to store multiple things in localStorage.
  //Right now, we're only storing the auth token for reduxAuth.

  //TODO: Probably remove this.  IdentityService is now handling auth.
  //We won't be putting tokens in reduxAuth anymore.
  const savedState = localStorage.getItem('reduxAuth');
  if (savedState) {
    reduxInitialState = { reduxAuth: { token: JSON.parse(savedState) } };
  }

  return reduxInitialState;
}
