import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import { Link, withRouter } from 'react-router-dom'; //withRouter needed for location & history properties (see readMe)
import '../../css/ServerError.scss';

// expected props
interface IProps {
  reduxServerErrors: any;
  updateRedux: any;
  history: any;
}
// local state
interface IState {}

class ServerError extends React.Component<IProps, IState> {
  private clearAuthData() {
    this.props.updateRedux('UPDATE_SERVER_ERROR', {
      showError: false,
      errorType: null,
      errorSource: '',
      errorMessage: ''
    });
    //clearLocalStorageKey('reduxAuth');
  }
  private closeError() {
    this.props.updateRedux('UPDATE_SERVER_ERROR', {
      showError: false,
      errorType: null,
      errorSource: '',
      errorMessage: ''
    });
  }

  public render() {
    const error = this.props.reduxServerErrors;
    const modalCSS = error.showError ? 's-error-container' : 'app-display-none';

    return (
      <div className={modalCSS}>
        <div className="s-error-content">
          <div className="s-error-icon">X</div>
          <div className="s-error-content">
            {error.errorType === 401 ? (
              <>
                <div>Error: Authentication Failed. Your session may have timed out.</div>
                <div>
                  <span>You may want to try logging in again. </span>
                  <Link to="/auth/login" onClick={() => this.clearAuthData()}>
                    Go to Login
                  </Link>
                </div>
              </>
            ) : (
              error.errorMessage
            )}
          </div>
          <div className="s-error-close" onClick={() => this.closeError()}>
            X
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxServerErrors: state.reduxServerErrors
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServerError));
