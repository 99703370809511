import * as React from 'react';
import { mapDispatchToProps } from "../../redux/actions";
import { connect } from "react-redux";
import { NewPageSelected } from './ProjectUtilities';

// expected props
interface IProps {
  updateRedux: any;
  reduxProjectManagement: any;
}
// local state
interface IState {}
//NOTE:
//Paging control assumes there will be at most 30 page links.  This allows for 300 projects.
class PagingControl extends React.Component<IProps, IState> {
  public render() {
    const pm = this.props.reduxProjectManagement;
    if (!pm.totalProjectRecords || pm.totalProjectRecords === 0){
      return null;
    }
    
    let pages:any[] = [];
    pages = this.getPageLinksCssClasses();
    return (
      <div className="proj-paging-control">
        <div className={"proj-paging " + pages[0].blueClass + " " + pages[0].eventsClass} onClick={() => NewPageSelected("prev")}>&lt; prev</div>
        {this.getPages(pages)}
        <div className={"proj-paging " + pages[31].blueClass + " " + pages[31].eventsClass} onClick={() => NewPageSelected("next")}>next &gt;</div>
      </div>
    );
  }
  getPages(pages:any){
    //Hard-coding 30 pages at 10 per page = 300 projects.
    const pageLinks = pages.map(function (page: any, index: number) {
      // Index 0 and 31 are the prev / next pages, so don't include them.
      if (index !== 0 && index !== 31){
        return <div key={index} className={"proj-paging " + page.blueClass + " " + page.activeClass + " " + page.eventsClass} onClick={() => NewPageSelected((index).toString())}>{index}</div>
      }
    });
    return pageLinks;
  }
  getPageLinksCssClasses(){
    
    let pages:any[] = [];
    const pm = this.props.reduxProjectManagement;
    
    let totalPages = Math.ceil(pm.totalProjectRecords / pm.projectRecordsPerPage);

    if (!pm.totalProjectRecords || pm.totalProjectRecords === 0){
      return pages;
    }
    const currentPage = pm.currentPage;

    let active = ""; //means the currently selected page.
    let blue = ""; //means a page in which there will be records to show.
    let pointerEvents = ""; // means a page that has click events.

    //Set up the list of objects for pages 1 through 20.
    for(let x = 1; x <= 30; x++){
      //Determin which "pages" should be blue, which is active, and which have click events.
      active = currentPage === x ? "proj-paging-active" : "";
    
      //[1,2,3,4,5,6,7,8,9,10],11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30
      active = currentPage === x ? "proj-paging-active" : "";
      blue = x <= totalPages ? "blue" : "project-hide";
      pointerEvents = active === "" && blue === "blue" ? "" : "";

      //Only show the previous 10 pages from the current page.      
      if (x + 10 <= currentPage){
        blue = "project-hide";
      }
      let obj = {
        activeClass: active,
        blueClass: blue,
        eventsClass: pointerEvents
      }
      pages.push(obj);
    }
    //prev page
    active = "";
    blue = currentPage > 1 ? "blue" : "";
    pointerEvents = blue === "blue" ? "" : "noPointerEvents";
    let objPrev = {
      activeClass: active,
      blueClass: blue,
      eventsClass: pointerEvents
    }
    //unshift puts the object at the beginning of the array.
    pages.unshift(objPrev);

    //next page
    active = "";
    blue = currentPage < totalPages ? "blue" : "";
    pointerEvents = blue === "blue" ? "" : "noPointerEvents";
    let objNext = {
      activeClass: "",
      blueClass: blue,
      eventsClass: pointerEvents
    }
    pages.push(objNext);
    return pages;
  }
}
 function mapStateToProps(state: any) {
   return {
      reduxProjectManagement: state.reduxProjectManagement
   };
}
export default connect(mapStateToProps, mapDispatchToProps)(PagingControl);
