import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import {getFanSoundData} from '../../api-calls/chartDataService';
import {roundNumber} from '../../api-utilities/formatting';
import '../../css/Modals.scss';

// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
}
// local state
interface IState {soundData:any; chartError:boolean, loading:boolean}

class FanSoundDataModal extends React.Component<IProps, IState> {
    public state: IState = {soundData:null, chartError:false, loading:false};

  //This is a specific modal for displaying fan sound data
  async componentDidMount() {
    let data = this.props.reduxModals.modalData;
    let projectId = data.projectId;
    let unitId = data.unitId;
    let selectedFan = data.selectedFan;

    this.setState({loading:true});
    let soundData = await getFanSoundData(projectId, unitId, selectedFan);
    if(soundData.success){
        this.setState({soundData:soundData.data.unitFanPerformanceDTO, loading:false});
    }
    else{
        this.setState({chartError: true, loading:false});
    }
  }
  public render() {
    let data = this.state.soundData;
    return (
      <div className="fanCurveModal">

        {this.state.loading ? (<div className="fancurve-content fancurve-loading"></div>) : null}

        {this.state.soundData != null ? (
            
        <div>
            <table className="sound-performance-table-head-style">
                <tbody>
                    <tr>
                        <th>Sound Data</th>
                        <th>Inlet</th>
                        <th>Outlet</th>
                    </tr>
                    <tr>
                        <td>62.5</td>
                        <td>{roundNumber(data.inletSoundlevel63hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel63hz)}</td>
                    </tr>
                    <tr>
                        <td>125</td>
                        <td>{roundNumber(data.inletSoundlevel125hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel125hz)}</td>
                    </tr>
                    <tr>
                        <td>250</td>
                        <td>{roundNumber(data.inletSoundlevel250hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel250hz)}</td>
                    </tr>
                    <tr>
                        <td>500</td>
                        <td>{roundNumber(data.inletSoundlevel500hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel500hz)}</td>
                    </tr>
                    <tr>
                        <td>1000</td>
                        <td>{roundNumber(data.inletSoundlevel1000hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel1000hz)}</td>
                    </tr>
                    <tr>
                        <td>2000</td>
                        <td>{roundNumber(data.inletSoundlevel2000hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel2000hz)}</td>
                    </tr>
                    <tr>
                        <td>4000</td>
                        <td>{roundNumber(data.inletSoundlevel4000hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel4000hz)}</td>
                    </tr>
                    <tr>
                        <td>8000</td>
                        <td>{roundNumber(data.inletSoundlevel8000hz)}</td>
                        <td>{roundNumber(data.outletSoundlevel8000hz)}</td>
                    </tr>
                    <tr>
                        <td>LwA</td>
                        <td>{roundNumber(data.inletPowerWeightedLwA)}</td>
                        <td>{roundNumber(data.outletPowerWeightedLwA)}</td>
                    </tr>
                    <tr>
                        <td>dBA</td>
                        <td>{roundNumber(data.inletPressureWeightedDBa)}</td>
                        <td>{roundNumber(data.outletPressureWeightedDBa)}</td>
                    </tr>
                    <tr>
                        <td>Sones</td>
                        <td>{roundNumber(data.inletPerceivedLoudnessSones)}</td>
                        <td>{roundNumber(data.outletPerceivedLoudnessSones)}</td>
                    </tr>
                </tbody>
            </table>
                     
            <div className="">&nbsp;</div>

            <div className="sound-plain-text-table">
                <table className="sound-plain-text-table-head-style">
                    <tbody>
                        <tr>
                            <td><span>Lwa:</span></td>
                            <td><span>A weighted sound power level based on ANSI 1.4</span></td>

                        </tr>
                        <tr>
                            <td><span>dBA:</span></td>
                            <td><span>A weighted sound pressure level based on 11.5 dB attenuation per octave band at 5ft-dBA levels are not licensed by AMCA International</span></td>

                        </tr>
                        <tr>
                            <td><span>Sones:</span></td>
                            <td><span>Calculated using AMCA 301 at 5ft.</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
        
        ) : null}

        {this.state.chartError ? <div className="fancurve-content">
            <div className="fancurve-error">
                Unable to get fan sound data.
                <br/><br/> Please click the button to update unit design and try again.</div>
            </div> : null}
                

        <div className="button-container">
            <div className="proj-create-btn" onClick={() => this.userResponse('cancel')}>
                Close
            </div>
        </div>
      </div>
    );
  }


  private userResponse(response: string) {
    let modalResponse = {
        response:response,
        unitId:this.props.reduxModals.modalData.id
    }
    //This will trigger ModalContainer.tsx to hide all modals
    this.props.updateRedux('UPDATE_MODALS', { showModal: false, userResponse: modalResponse});
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FanSoundDataModal);

