import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import {getStateOptions} from "../../utilities/pageUtils";
import '../../css/ProjectModal.scss';
import { ProjectStatusFilterBy } from '../../interfaces/interfaces';
// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
  reduxProjectManagement: any;
}
// local state
interface IState {
  projectName: string;
  consultingEngineer: string;
  contractor: string;
  city: string;
  state: string;
  jobStatus:string
}

class ProjectModal extends React.Component<IProps, IState> {
  public state: IState = { 
    projectName: '',
    consultingEngineer: '',
    contractor: '',
    city: '',
    state: '',
    jobStatus:ProjectStatusFilterBy[ProjectStatusFilterBy.Design]
  };

  //This is a specific modal for adding or editing a project.  Input is project name.
  componentDidMount() {
    if (this.props.reduxModals.modalId === 'edit-project') {
      this.setState({ projectName: this.props.reduxProjectManagement.selectedProject.projectName,
                      consultingEngineer: this.props.reduxProjectManagement.selectedProject.consultingEngineer,
                      contractor: this.props.reduxProjectManagement.selectedProject.contractor,
                      city: this.props.reduxProjectManagement.selectedProject.city,
                      state: this.props.reduxProjectManagement.selectedProject.state,
                      jobStatus: this.props.reduxProjectManagement.selectedProject.jobStatus });
    }
  }
  validateAndSave(fieldName: string, newValue: string){
    if (newValue.length <= 50){
      this.setState({ [fieldName]: newValue } as Pick<IState, keyof IState>);
    }
  }
  public render() {
    const title = this.props.reduxModals.modalId === 'edit-project' ? 'Edit Job' : 'New Job';
    const buttonText = this.props.reduxModals.modalId === 'edit-project' ? 'Save' : 'Create';
    return (
      <div className="newProject">
        <div className="header">{title}</div>
        <div className="InputRow">
          <label className="proj-modal-label">Job Name</label>
          <input
            className="projectInput"
            type="text"
            placeholder="Job Name"
            value={this.state.projectName}
            onChange={(event) => this.validateAndSave("projectName", event.currentTarget.value)}
            autoFocus
          />
        </div>
        <div className="InputRow">
          <label className="proj-modal-label">Consulting Engineer</label>
          <input
            className="projectInput"
            type="text"
            placeholder="Consulting Engineer"
            value={this.state.consultingEngineer}
            onChange={(event) => this.validateAndSave("consultingEngineer", event.currentTarget.value)}
          />
        </div>
        <div className="InputRow">
          <label className="proj-modal-label">Contractor</label>
          <input
            className="projectInput"
            type="text"
            placeholder="Contractor"
            value={this.state.contractor}
            onChange={(event) => this.validateAndSave("contractor", event.currentTarget.value)}
          />
        </div>
        <div className="InputRow">
          <label className="proj-modal-label">City</label>
          <input
            className="projectInput"
            type="text"
            placeholder="City"
            value={this.state.city}
            onChange={(event) => this.validateAndSave("city", event.currentTarget.value)}
          />
        </div>
        <div className="InputRow">
          <div className="proj-modal-label">State</div>
          <div className="projectInput">
            <select
              className="projectInput"
              value={this.state.state}
              onChange={(event) => this.setState({state: event.currentTarget.value})}
            >
              {getStateOptions()}
            </select>
          </div>
        </div>

        <div className="InputRow">
          <div className="proj-modal-label">Job Status</div>
          <div className="projectInput">
            <select
              className="projectInput"
              value={this.state.jobStatus}
              onChange={(event) => this.setState({jobStatus: event.currentTarget.value})}
            >
              <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.Design]}</option>
              <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.Booking]}</option>
              <option>{ProjectStatusFilterBy[ProjectStatusFilterBy.Release]}</option>
            </select>
          </div>
        </div>

        <div className="button-container">
          <div className="proj-create-btn" onClick={() => this.userResponse('ok')}>
            {buttonText}
          </div>
          <div className="proj-cancel-btn" onClick={() => this.userResponse('cancel')}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
  //This will trigger the componentDidUpdate method on ProjectManagement.tsx
  private userResponse(response: string) {
    this.props.updateRedux('UPDATE_MODALS', { showModal: false, userResponse: response });
    if (response === 'ok' && this.state.projectName !== '') {
      this.props.updateRedux('UPDATE_PROJECT_MANAGEMENT', { projectName: this.state.projectName, consultingEngineer: this.state.consultingEngineer,
        contractor: this.state.contractor, city: this.state.city, state: this.state.state, jobStatus: this.state.jobStatus });
    }
    if (response === 'cancel') {
      this.props.updateRedux('UPDATE_PROJECT_MANAGEMENT', { projectName: '' });
    }
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxProjectManagement: state.reduxProjectManagement,
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);
