import { IAction } from '../../interfaces/interfaces';
import {
  HeatWheelPanel,
  CoilPanelItem,
  IndirectFiredFurnacePanel,
  AccuPanel,
  FilterPanelItem,
  FanPanelItem,
  DamperPanelItem
} from '../../TypescriptClient';

export const reduxHeatWheelPanel = (state: HeatWheelPanel = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_HEATWHEEL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxHotWaterCoilPanel = (state: CoilPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_HOTWATER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxHotWaterPreheatPanel = (state: CoilPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_PREHEAT') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxChilledWaterCoilPanel = (state: CoilPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_CHILLEDWATER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxSteamCoilPanel = (state: CoilPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_STEAM') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxDXCoilPanel = (state: CoilPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_DX') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxHGRHCoilPanel = (state: CoilPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_HGRH') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxIDFPanel = (state: IndirectFiredFurnacePanel = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_IDF') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxAccuPanel = (state: AccuPanel = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_ACCU') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxOAFilterPanel = (state: FilterPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_OAFILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxHEFilterPanel = (state: FilterPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_HEFILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxSAFilterPanel = (state: FilterPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_SAFILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxRAFilterPanel = (state: FilterPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_RAFILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxSupplyFanPanel = (state: FanPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_SUPPLYFAN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxExhaustFanPanel = (state: FanPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_EXHASTFAN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxOADamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_OADAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxEADamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_EADAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxRADamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_RADAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxSADamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_SADAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxOABypassDamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_OABYPASSDAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxEABypassDamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_EABYPASSDAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const reduxRecircDamperPanel = (state: DamperPanelItem = {}, action: IAction) => {
  if (action.type === 'UPDATE_PANELS_RECIRCDAMPER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
