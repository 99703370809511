import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { mapDispatchToProps } from "../../../../redux/actions";
import AccordionHeader from "../../../Shared/AccordionHeader";
import DamperDisplayGroup from "./DamperDisplayGroup";
import { callGetEndpoint } from "../../../../api-calls/readApiService";
import { isEmptyObject } from "../../../../utilities/helpers";
import "../../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  openAccordion: boolean;
  componentData: any;
}

class Dampers extends React.Component<IProps, IState> {
  public state: IState = {
    openAccordion: false,
    componentData: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const result = await callGetEndpoint("readapi/GetAllDamperInputValues", projectId, unitId);
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    if (!isEmptyObject(result)) {
      this.setState({
        componentData: result
      });
    }
  }

  private togglePanel() {
    this.setState({ openAccordion: !this.state.openAccordion }); //toggle to opposite of current state
  }
  public render() {
    const active = this.state.openAccordion;
    const componentData = this.state.componentData;
    if (isEmptyObject(componentData)) {
      return null;
    }

    const displayFields = componentData.uiDataContainer.displayFields;

    return (
      <div className="">
        <AccordionHeader
          title="Dampers"
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.togglePanel()}
        />

        <div className={`acc-panel-1000 ${active === true ? "acc-panel-active" : "acc-panel-inactive"}`}>
          <div className="accordion-content app-box-shadow">
            <div className="damper-spec-fields">
              <div className="spec-field-spacer1"></div>
              <div className="spec-dampers-container spec-margin-top-20">
                <div className="spec-input-label">Motorized/Gravity</div>
                <div className="spec-input-label">Type and Construction</div>
                <div className="spec-input-label">Blade</div>
                <div className="spec-input-label">Actuator</div>
              </div>
            <div className = "dampers-container">
              {displayFields.showOutsideAirDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.outsideAirDamperInputs.displayName}
                  targetVertex="OutsideAirDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.outsideAirDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptionsOutsideAir}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showEconomizerDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.economizerDamperInputs.displayName}
                  targetVertex="EconomizerDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.economizerDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptionsEconomizer}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showExhaustAirDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.exhaustAirDamperInputs.displayName}
                  targetVertex="ExhaustAirDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.exhaustAirDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptionsExhaustAir}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showReturnAirDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.returnAirDamperInputs.displayName}
                  targetVertex="ReturnAirDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.returnAirDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptionsReturnAir}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showSupplyAirDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.supplyAirDamperInputs.displayName}
                  targetVertex="SupplyAirDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.supplyAirDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptionsSupplyAir}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showOutsideAirBypassDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.outsideAirBypassDamperInputs.displayName}
                  targetVertex="OutsideAirBypassDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.outsideAirBypassDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptions}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showExhaustAirBypassDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.exhaustAirBypassDamperInputs.displayName}
                  targetVertex="ExhaustAirBypassDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.exhaustAirBypassDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptions}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showRecirculationDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.recirculationDamperInputs.displayName}
                  targetVertex="RecirculationDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.recirculationDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptions}
                  match={this.props.match}
                />
              ) : null}

              {displayFields.showFaceAndBypassDamper ? (
                <DamperDisplayGroup
                  title={componentData.uiDataContainer.faceAndBypassDamperInputs.displayName}
                  targetVertex="FaceAndBypassDamperInputs"
                  componentData={componentData}
                  userInputs={componentData.uiDataContainer.faceAndBypassDamperInputs}
                  constructionOptions={componentData.dropdownOptions.damperConstructionOptions}
                  match={this.props.match}
                />
              ) : null}

              <div className="spec-field-spacer2"></div>
              <div className="spec-field-spacer1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dampers);
