import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../redux/actions";
import AccordionHeader from "../Shared/AccordionHeader";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getControlsInputValues, updateInputField } from "../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import BMSPoints from "./BMSPoints";
import { isEmptyObject } from "../../utilities/helpers";
import "../../css/Accordions.scss";

// expected props
interface IProps {
    match: match<any>;
    updateRedux: any;
 }
  // local state
  interface IState {
    componentData: any;
    userInputs: any;
    displayFields: any;
    dropdownOptions: any[];
    validationErrors: any[];
    lastUpdatedTextbox: string;
    oaDamperConstructionWarningShown:boolean;
    prevIncludeOutsideAirInletAirflowMonitoring:boolean;
    openSystemInputsAccordion: boolean;
    openSystemOptionsAccordion: boolean;
  }

class Controls extends React.Component<IProps, IState> {
    public state: IState = {
        componentData: null,//may not need.
        userInputs: null,
        displayFields: null,
        dropdownOptions: [],
        validationErrors: [],
        lastUpdatedTextbox: "",
        oaDamperConstructionWarningShown:false,
        prevIncludeOutsideAirInletAirflowMonitoring:false,
        openSystemInputsAccordion:true,
        openSystemOptionsAccordion:true,
      };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await getControlsInputValues(projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError){
      const notification = {
        id: "controlsInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetControlsInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    
    if (!isEmptyObject(result.uiDataContainer)) {
        this.setState({
            userInputs: result.uiDataContainer.controlsInputs,
            displayFields: result.uiDataContainer.displayFields,
            dropdownOptions: result.dropdownOptions,
            validationErrors: result.validationErrors
          });
    }
  }
  componentWillUnmount() {
    removeNotification('outsideAirDamperConstruction');
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
      const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
      this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
    }
  
    //This gets called by textbox onBlur events
    public async saveTextboxValue(fieldName: string, newValue: string) {
      //If field value hasn't changed, don't call the server
      if (this.state.lastUpdatedTextbox !== fieldName) {
        return;
      }
      this.saveInputValue(fieldName, newValue);
    }
  
    //Save new value to redis and DB
    public async saveInputValue(fieldName: string, newValue: string) {
      this.props.updateRedux('UPDATE_MENUS', {closeSetPointsAccordian : true});
      //Store current value to compare to new value after update.  
      this.setState({"prevIncludeOutsideAirInletAirflowMonitoring": this.state.userInputs.includeOutsideAirInletAirflowMonitoring});

      const { projectId, unitId } = this.props.match.params;
      this.removeValidationError(fieldName);
      let result = await updateInputField("updateControls", projectId, unitId, fieldName, newValue, "ControlsInputs");
  
      if (result.success) {
        //If there are any errors in the response, add them to state
        if (result.data.validationErrors != null) {
          this.addValidationErrors(result.data.validationErrors);
        }
  
        //If there are any dropdownLists present in the response, then update those in state
        if (result.data.dropdownOptions != null) {
          this.updateDropdowns(result.data.dropdownOptions);
        }
        //If uiDataContainer was updated with new values, then update it in state
        if (result.data.uiDataContainer != null) {
          this.setState({
            userInputs: result.data.uiDataContainer.controlsInputs,
            displayFields: result.data.uiDataContainer.displayFields
          });
        }
        //Notify the setpoints page that controlsInclusion has changed and that we'll need to either hide or show
        //the setpoints accordian.
        if (fieldName === 'controlsInclusion'){
          this.props.updateRedux('UPDATE_MENUS', {providedAndInstalledByChanged : true});
        }
        //Check the previous value of includeOutsideAirInletAirflowMonitoring to it's current value.
        //Show warning message if they're different.
        if (!this.state.oaDamperConstructionWarningShown && this.state.userInputs.includeOutsideAirInletAirflowMonitoring !== this.state.prevIncludeOutsideAirInletAirflowMonitoring){
          let warning =
            "A change in airflow monitoring for OA Inlet will change the options for OA damper construction.  Check the OA Damper construction for available options.";
          const notification = {
            id: "outsideAirDamperConstruction",
            notificationType: "warning",
            title: "Warning",
            content: <div className="nf-div">{warning}</div>
          };
          addNotification(notification);
          this.setState({ oaDamperConstructionWarningShown: true });
        }
      }
    }
  
    //Check for errors on this textbox/selectbox, and return the appropriate CSS className
    public getCSS(fieldName: string, type: string) {
      return getInputCSS(this.state.validationErrors, fieldName, type);
    }
  
    //Update dropdowns with new options from the server
    public updateDropdowns(newDropdownOptions: any) {
      let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
      this.setState({ dropdownOptions: newList });
    }
  
    //Add any validation errors we got from the server to state
    public addValidationErrors(validationErrors: any[]) {
      let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
      this.setState({ validationErrors: newList });
    }
  
    public getValidationError(fieldName: string, isSetPoint: boolean) {
      //Call the validation method from pageUtils
      let validationClass = isSetPoint ? "validation-err-bms" : "validation-err-right";
      return getValidationError(this.state.validationErrors, fieldName, validationClass);
    }
  
    //Whenever we update a field, remove any previous error for that field
    //Also reset the lastUpdatedTextbox in state
    public removeValidationError(fieldName: string) {
      let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
      this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
    }
    private toggleSystemInputPanel(){
      this.setState({ openSystemInputsAccordion: !this.state.openSystemInputsAccordion }); //toggle to opposite of current state
    }
    private toggleSystemOptionsPanel(){
      this.setState({ openSystemOptionsAccordion: !this.state.openSystemOptionsAccordion }); //toggle to opposite of current state
    }
  public render() {
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;
    const systemInputsActive = this.state.openSystemInputsAccordion;
    const systemOptionsActive = this.state.openSystemOptionsAccordion;
    //displayFields.DehumidificationControlDisabled is true only in case of pool unit
    
    const dehumidControlDisplayName = displayFields && displayFields.isPoolUnit ? "Dehumidification Activation" : "Occupied Dehumidification Activation";
    if (!savedValues) {
      return null;
    }

    return (
      <div className="spec-body-content">
      <div className="">
      <AccordionHeader
        title="Controls System Inputs"
        subtitle=""
        classname="accordion-btn acc-btn-1000"
        active={this.state.openSystemInputsAccordion}
        locked={false}
        hasError={false}
        togglePanel={() => this.toggleSystemInputPanel()}
      />
      <div className={`acc-panel-1000 ${systemInputsActive === true ? 'acc-panel-active' : 'acc-panel-inactive'}`}>
      <div className="accordion-content app-box-shadow">
        <div className="spec-fields">

        <div className="spec-elec-fields-new">
          <div className="spec-input-label-2C">Controls Provided By</div>
          <div className="spec-input-2 controls-inputs">
            <select
              className="spec-selectbox-2"
              disabled = {displayFields.controlsInclusionDisabled}
              value={savedValues.controlsInclusion || ""}
              onChange={(event) => this.saveInputValue("controlsInclusion", event.currentTarget.value)}
            >
              {getSelectBoxOptions(this.state.dropdownOptions, "controlsInclusionOptions")}
            </select>
            {this.getValidationError("controlsInclusion", false)}
          </div>

          <div>
            <div className="spec-input-label-2C">Damper Actuators Provided By</div>
            <div className="spec-input-2 controls-inputs">
              <select
                className="spec-selectbox-2"
                disabled={!displayFields.damperActuatorsInclusionEnabled}
                value={savedValues.damperActuatorsInclusion || ""}
                onChange={(event) => this.saveInputValue("damperActuatorsInclusion", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "damperActuatorsInclusionOptions")}
              </select>
              {this.getValidationError("damperActuatorsInclusion", false)}
            </div>
          </div>

          {displayFields.showInterfaceCard ? (
            <div>
              <div className="spec-input-label-2C">Interface Card</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.interfaceCard || ""}
                  onChange={(event) => this.saveInputValue("interfaceCard", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "interfaceCardOptions")}
                </select>
                {this.getValidationError("interfaceCard", false)}
              </div>
            </div>
          ) : null}

          <div>
            <div className="spec-input-label-2C">Airflow Arrangement</div>
            <div className="spec-input-4 controls-inputs">{displayFields.airFlowArrangementLabel}</div>
          </div>

          <div>
            <div className="spec-input-label-2C">VAV/Constant Volume</div>
            <div className="spec-input-2 controls-inputs">{displayFields.vavOrConstantVolumeLabel}</div>
          </div>

          {displayFields.showSupplyFanControl ? (
            <div>
              <div className="spec-input-label-2C">Supply Fan Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.supplyFanControl || ""}
                  onChange={(event) => this.saveInputValue("supplyFanControl", event.currentTarget.value)}
                  disabled = {displayFields.supplyFanControlDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "supplyFanControlOptions")}
                </select>
                {this.getValidationError("supplyFanControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showExhaustFanControl ? (
            <div>
              <div className="spec-input-label-2C">Exhaust Fan Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.exhaustFanControl || ""}
                  onChange={(event) => this.saveInputValue("exhaustFanControl", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "exhaustFanControlOptions")}
                </select>
                {this.getValidationError("exhaustFanControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showReturnFanControl ? (
            <div>
              <div className="spec-input-label-2C">Return Fan Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.returnFanControl || ""}
                  onChange={(event) => this.saveInputValue("returnFanControl", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "returnFanControlOptions")}
                </select>
              </div>
            </div>
          ) : null}

          {displayFields.showTemperatureControl ? (
            <div>
              <div className="spec-input-label-2C">Temperature Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.temperatureControl || ""}
                  onChange={(event) => this.saveInputValue("temperatureControl", event.currentTarget.value)}
                  disabled={displayFields.temperatureControlDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "temperatureControlOptions")}
                </select>
                {this.getValidationError("temperatureControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showEconomizerInitiation ? (
            <div>
              <div className="spec-input-label-2C">Economizer Initiation</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.economizerInitiation || ""}
                  onChange={(event) => this.saveInputValue("economizerInitiation", event.currentTarget.value)}
                  disabled={displayFields.economizerInitiationDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "economizerInitiationOptions")}
                </select>
                {this.getValidationError("economizerInitiation", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showMinimumOutsideAirControl ? (
            <div>
              <div className="spec-input-label-2C">Minimum OA Control</div>
              <div className="spec-input-with-label controls-inputs">
                <select
                  disabled = {!displayFields.minimumOAControlEnabled}
                  className="spec-selectbox-2"
                  value={savedValues.minimumOutsideAirControl || ""}
                  onChange={(event) => this.saveInputValue("minimumOutsideAirControl", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "minimumOutsideAirControlOptions")}
                </select>
                {this.getValidationError("minimumOutsideAirControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showUnoccupiedModeControl ? (
            <div>
              <div className="spec-input-label-2C">Unoccupied Mode Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.unoccupiedModeControl || ""}
                  onChange={(event) => this.saveInputValue("unoccupiedModeControl", event.currentTarget.value)}
                  disabled={displayFields.unoccupiedModeControlDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "unoccupiedModeControlOptions")}
                </select>
                {this.getValidationError("unoccupiedModeControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showDehumidificationControl ? (
            <div>
              <div className="spec-input-label-2C">{dehumidControlDisplayName}</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.dehumidificationControl || ""}
                  onChange={(event) => this.saveInputValue("dehumidificationControl", event.currentTarget.value)}
                  disabled={displayFields.dehumidificationControlDisabled}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "dehumidificationControlOptions")}
                </select>
                {this.getValidationError("dehumidificationControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showHumidificationControl ? (
            <div>
              <div className="spec-input-label-2C">Humidification Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.humidificationControl || ""}
                  onChange={(event) => this.saveInputValue("humidificationControl", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "humidificationControlOptions")}
                </select>
                {this.getValidationError("humidificationControl", false)}
              </div>
            </div>
          ) : null}

          {displayFields.showMasterSlaveMultiUnitControl ? (
            <div>
              <div className="spec-input-label-2C">Master/Slave Multi-Unit Control</div>
              <div className="spec-input-2 controls-inputs">
                <select
                  className="spec-selectbox-2"
                  value={savedValues.masterSlaveMultiUnitControl || ""}
                  onChange={(event) => this.saveInputValue("masterSlaveMultiUnitControl", event.currentTarget.value)}
                >
                  {getSelectBoxOptions(this.state.dropdownOptions, "masterSlaveMultiUnitControlOptions")}
                </select>
                {this.getValidationError("masterSlaveMultiUnitControl", false)}
              </div>
            </div>
          ) : null}
        </div>

        <div className="spec-field-spacer1"></div>
        </div>
        </div>
        </div>
        {/* Controls Options */}
        <div className="">
        <AccordionHeader
          title="Controls Options"
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openSystemOptionsAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.toggleSystemOptionsPanel()}
        />
        <div className={`acc-panel-1000 ${systemOptionsActive === true ? 'acc-panel-active' : 'acc-panel-inactive'}`}>
        <div className="accordion-content app-box-shadow">
        <div className="spec-fields">
        <div className="spec-elec-fields-new">
          {displayFields.showIncludeUnoccupiedWarmUp ? (
            <div>
              <div className="spec-input-label-3">Unoccupied Warm Up</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeUnoccupiedWarmUp)}
                  onChange={(event) => this.saveInputValue("includeUnoccupiedWarmUp", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeUnoccupiedCoolDown ? (
            <div>
              <div className="spec-input-label-3">Unoccupied Cool Down</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeUnoccupiedCoolDown)}
                  onChange={(event) => this.saveInputValue("includeUnoccupiedCoolDown", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeHeatWheelRotationDetection ? (
            <div>
              <div className="spec-input-label-3">Heat Wheel Rotation Detection</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeHeatWheelRotationDetection)}
                  onChange={(event) => this.saveInputValue("includeHeatWheelRotationDetection", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeDemandControlVentilationCO2 ? (
            <div>
              <div className="spec-input-label-3">Demand Control Ventilation (CO2)</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeDemandControlVentilationCO2)}
                  onChange={(event) => this.saveInputValue("includeDemandControlVentilationCO2", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeDirtyFilterSwitches ? (
            <div>
              <div className="spec-input-label-3">Dirty Filter Switches</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeDirtyFilterSwitches)}
                  onChange={(event) => this.saveInputValue("includeDirtyFilterSwitches", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeFilterPressureTransducers ? (
            <div>
              <div className="spec-input-label-3">Filter Pressure Transducers</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeFilterPressureTransducers)}
                  onChange={(event) => this.saveInputValue("includeFilterPressureTransducers", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeCondensateDrainPanOverflowSwitch ? (
            <div>
              <div className="spec-input-label-3">Condensate Drain Pan Overflow Switch</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeCondensateDrainPanOverflowSwitch)}
                  onChange={(event) => this.saveInputValue("includeCondensateDrainPanOverflowSwitch", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeFreezestat ? (
            <div>
              <div className="spec-input-label-3">Freezestat</div>
              <div className="spec-input-3">
                <input
                  disabled={displayFields.freezeStateEnabled ? false : true}
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeFreezestat)}
                  onChange={(event) => this.saveInputValue("includeFreezestat", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeHighDuctStaticPressure ? (
            <div>
              <div className="spec-input-label-3">High Duct Static Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeHighDuctStaticPressure)}
                  onChange={(event) => this.saveInputValue("includeHighDuctStaticPressure", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          {displayFields.showIncludeLowDuctStaticPressure ? (
            <div>
              <div className="spec-input-label-3">Low Duct Static Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeLowDuctStaticPressure)}
                  onChange={(event) => this.saveInputValue("includeLowDuctStaticPressure", event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null}

          <div>
            <div className="spec-input-label-2">Damper at Connection</div>
            <div className="spec-input-controls2">
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.includeOutsideAirInletDamper)}
                disabled={displayFields.outsideAirInletDamperEnabled ? false : true}
                onChange={(event) => this.saveInputValue("includeOutsideAirInletDamper", event.currentTarget.checked.toString())}
              />{" "}
              OA Inlet
            </div>

            {displayFields.showIncludeReturnAirInletDamper ? (
              <div className="spec-input-2">
                <input
                  className=""
                  type="checkbox"
                  disabled={true}
                  checked={getChecked(savedValues.includeReturnAirInletDamper)}
                  onChange={(event) => this.saveInputValue("includeReturnAirInletDamper", event.currentTarget.checked.toString())}
                />{" "}
                RA Inlet
              </div>
            ) : null}

            {displayFields.showIncludeExhaustAirOutletDamper ? (
              <div className="spec-input-2">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeExhaustAirOutletDamper)}
                  disabled={displayFields.outsideAirInletDamperEnabled ? false : true}
                  onChange={(event) => this.saveInputValue("includeExhaustAirOutletDamper", event.currentTarget.checked.toString())}
                />{" "}
                EA Outlet
              </div>
            ) : null}
          </div>

          <div>
            <div className="spec-input-label-2">Smoke Dampers</div>
            {displayFields.showIncludeReturnAirInletSmokeDamper ? (
              <div className="spec-input-controls2">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeReturnAirInletSmokeDamper)}
                  onChange={(event) => this.saveInputValue("includeReturnAirInletSmokeDamper", event.currentTarget.checked.toString())}
                />{" "}
                RA Inlet
              </div>
            ) : null}

            <div className={displayFields.showIncludeReturnAirInletSmokeDamper ? "spec-input-2" : "spec-input-controls2"}>
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.includeSupplyAirOutletSmokeDamper)}
                onChange={(event) => this.saveInputValue("includeSupplyAirOutletSmokeDamper", event.currentTarget.checked.toString())}
              />{" "}
              SA Outlet
            </div>
          </div>

          <div>
            <div className="spec-input-label-2">Airflow Monitoring</div>

            <div className="spec-input-controls2">
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.includeOutsideAirInletAirflowMonitoring)}
                disabled={!displayFields.outsideAirInletAirflowMonitoringEnabled  ? true : false}
                onChange={(event) => this.saveInputValue("includeOutsideAirInletAirflowMonitoring", event.currentTarget.checked.toString())}
              />{" "}
              OA Inlet
            </div>
            
            <div className="spec-input-2">
              <input
                className=""
                disabled={!displayFields.enableSupplyFanAirFlowMonitoring ? true : false}
                type="checkbox"
                checked={getChecked(savedValues.includeSupplyFanAirflowMonitoring)} 
                onChange={(event) => this.saveInputValue("includeSupplyFanAirflowMonitoring", event.currentTarget.checked.toString())}
              />{" "}
              SA Fan
            </div>
            
            {displayFields.showExhaustFanAirFlowMonitoring ? (
              <div className="spec-input-2">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.includeExhaustFanAirflowMonitoring)}
                  disabled={!displayFields.enableExhaustFanAirFlowMonitoring}
                  onChange={(event) => this.saveInputValue("includeExhaustFanAirflowMonitoring", event.currentTarget.checked.toString())}
                />{" "}
                EA Fan
              </div>
            ) : null}

            {displayFields.showReturnFanAirFlowMonitoring ? (
              <div className="spec-input-2">
                <input
                  className=""
                  type="checkbox"
                  disabled={!displayFields.enableReturnFanAirFlowMonitoring}
                  checked={getChecked(savedValues.includeReturnFanAirflowMonitoring)}
                  onChange={(event) => this.saveInputValue("includeReturnFanAirflowMonitoring", event.currentTarget.checked.toString())}
                />{" "}
                RA Fan
              </div>
            ) : null}
          </div>

          <div>
            <div className="spec-input-label-2">Magnehelic Gauges</div>
            <div className="spec-input-controls2">
              <input
                className=""
                type="checkbox"
                checked={getChecked(savedValues.includeMagnehelicGauges)}
                onChange={(event) => this.saveInputValue("includeMagnehelicGauges", event.currentTarget.checked.toString())}
              />{" "}
              Filters
            </div>
          </div>
        </div>
        </div>
        <div className="spec-field-spacer1"></div>
        </div>
        </div>
        </div>
        {/* Set Points And Additional BMS Points */}
        {/* TODO: based on user role, set this class to display-none if user is a rep and controlsInclusion is not "others" */}
       
        <div className="">
          <BMSPoints match={this.props.match} />
        </div>

        <div className="spec-field-spacer1"></div>
        </div>
        </div>
    );
    
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Controls);
