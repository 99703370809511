import * as React from "react";
import { connect } from "react-redux";
import { store } from "../../redux/store";
import { updateReduxAction } from "../../redux/actions";
import { withRouter, matchPath } from "react-router-dom"; //withRouter needed for location property (see readMe)
import { mapDispatchToProps } from "../../redux/actions";
import { getProjectAndUnitName } from "../../api-calls/projectDataService";
import { isNullOrEmpty } from "../../utilities/helpers";
import { getStringId } from "../../api-utilities/formatting";

// expected props
interface IProps {
  updateRedux: any;
  location: any;
  history: any;
  //match: match<any>;
  reduxAppVersion: any;
  reduxDisplay: any;
}
// local state
interface IState {
  projectId: any;
  unitId: any;
  headerTitle: string;
}

class ProjectHeader extends React.Component<IProps, IState> {
  public state: IState = { projectId: null, unitId: null, headerTitle: "" };

  async componentDidMount() {
    //Try and get projectId and unitId from the URL
    const match = matchPath(this.props.history.location.pathname, {
      path: "/pid/:projectId/uid/:unitId",
      exact: false,
      strict: false
    });
    if (match) {
      const { projectId, unitId } = match.params as any;
      let projIdStr = getStringId("project", projectId);
      let unitIdStr = getStringId("unit", unitId);
      await this.getHeaderData(projIdStr, unitIdStr);
    }
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    let newParams = { projectId: null, unitId: null };
    //Try and get projectId and unitId from the URL
    const match = matchPath(this.props.history.location.pathname, {
      path: "/pid/:projectId/uid/:unitId",
      exact: false,
      strict: false
    });
    if (match) {
      const { projectId, unitId } = match.params as any;
      newParams.projectId = projectId;
      newParams.unitId = unitId;
      //Header Title has changed. Get and update UI. 
      if (this.props.reduxDisplay.headerTitleChanged === true && prevProps.reduxDisplay.headerTitleChanged === false){
        let projIdStr = getStringId("project", newParams.projectId!);
        let unitIdStr = getStringId("unit", newParams.unitId!);
        await this.getHeaderData(projIdStr, unitIdStr);
        this.props.updateRedux('UPDATE_DISPLAY', { headerTitleChanged: false });
      }
    }
    
    //If projectId or unitId have changed in the URL, we need to update the title in the header
    if (newParams.projectId !== this.state.projectId || newParams.unitId !== this.state.unitId) {
      //set these values in state, so we can compare them each time componentDidUpdate fires (prevProps doesn't work for this)
      this.setState({ projectId: newParams.projectId, unitId: newParams.unitId });

      let projIdStr = getStringId("project", newParams.projectId!);
      let unitIdStr = getStringId("unit", newParams.unitId!);
      await this.getHeaderData(projIdStr, unitIdStr);
    }
  }

  async getHeaderData(projectId: any, unitId: any) {
    if (projectId == "proj-null" || unitId == "unit-null") {
      return;
    }

    if (projectId && unitId) {
      let appVersion = isNullOrEmpty(this.props.reduxAppVersion.appVersion) ? 0 : this.props.reduxAppVersion.appVersion;

      let response = await getProjectAndUnitName(projectId, unitId, appVersion);
      if (response) {
        let projectName = isNullOrEmpty(response.projectName) ? "" : response.projectName;
        let unitTag = isNullOrEmpty(response.unitTag) ? "" : response.unitTag;
        this.setState({ headerTitle: `${projectName} - ${unitTag}` });
        return; //exit
      }
    }
    //if anything failed, just reset
    this.setState({ headerTitle: "" });
  }

  getTitle() {
    return this.state.headerTitle;
  }

  public render() {
    const route = this.props.location.pathname;

    const displayHeading =
      route.includes("/auth/login") || route.includes("/loginstart") || route.includes("/project") || route.includes("/pid/:projectId/uid/:unitId/project")
        ? null
        : this.getTitle();

    return <div>{displayHeading}</div>;
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxDisplay: state.reduxDisplay,
    reduxAppVersion: state.reduxAppVersion
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectHeader));
