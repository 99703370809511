import { store } from '../redux/store';
import { IdentityService } from '../security/IdentityService';
import { getStringId } from '../api-utilities/formatting';
//This file contains calls to the Lowry backend, which gets results from the ChartAPI


const apibase = process.env.REACT_APP_CLIENT_API;

export async function getAuthToken() {
  const auth = store.getState().reduxAuth;
  let token = '';
  //Check to see if reduxAuth contains the access_token. 
  //Using access_token from Redux prevents a bunch of calls to identityserver,
  //but on page reload, sometimes redux doesn't get populated fast enough, and the page tries
  //to call the server with no token, and fails.  So in that case we should get it from IdentityServer.
  if(auth.access_token !== ''){
    token = auth.access_token;
  }
  else{
    const identityService = new IdentityService();
    const user = await identityService.getUser();
    token = user.access_token;
  }
  return token;
}

export async function getFanCurveChart(projectId: number, unitId: number, selectedFan: string) {
    const token = await getAuthToken();
    
    let projIdStr = getStringId('project', projectId);
    let unitIdStr = getStringId('unit', unitId);
    
    const result = await fetch(apibase + `/dataapi/getFanCurveChart/${projIdStr}/${unitIdStr}/${selectedFan}`, {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` }
    });
    if(result.status === 400){
        return null;
    }
    if (result.status === 200) {
        console.log(result);
      const data = await result.blob();
      console.log(data);
      return data;
    }
}

export async function getFanSoundData(projectId: number, unitId: number, selectedFan: string) {
  const token = await getAuthToken();

  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

  const result = await fetch(apibase + `/dataapi/getFanSoundData/${projIdStr}/${unitIdStr}/${selectedFan}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  
  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}