import * as React from "react";

//Get validation error div to display below the affected field
export function getValidationError(validationErrors: any[], fieldName: string, cssClass: string) {
  if (validationErrors) {
    const error = validationErrors.find((x: any) => x.fieldName.toLowerCase() === fieldName.toLowerCase() && x.suppressErrorInUi !== true);
    if (error) {
      return <div className={cssClass}>{error.errorMessage}</div>;
    }
  }
  return null;
}
export function getPoolValidationError(validationErrors: any[], fieldName: string, poolIndex: number, cssClass: string) {
  if (validationErrors) {
    const error = validationErrors.find((x: any) => x.fieldName.toLowerCase() === fieldName.toLowerCase() && x.poolIndex === poolIndex && x.suppressErrorInUi !== true);
    if (error) {
      return <div className={cssClass}>{error.errorMessage}</div>;
    }
  }
  return null;
}
export function checkForErrors(validationErrors: any[], fieldName: string) {
  if (validationErrors) {
    const hasError = validationErrors.find((x: any) => x.fieldName.toLowerCase() === fieldName.toLowerCase() && x.suppressErrorInUi !== true);
    return hasError ? true : false;
  }
  return false;
}

export function getFilteredValidationErrors(validationErrors: any[], fieldName: string) {
  if (!validationErrors) {
    return [];
  }
  //make a copy of error messages collection from state
  let errorsArray = [...validationErrors];
  //Make a new array that only includes items not matching the given fieldName
  let errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== fieldName.toLowerCase());

  //----- Special case for UnitLocation: also need to remove any validation error on the CoolingType field -----
  if (fieldName.toLowerCase() === "unitlocation") {
    errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== "coolingtype");
  }
  return errors;
}
export function getFilteredPoolValidationErrors(validationErrors: any[], fieldName: string, poolIndex: number) {
  if (!validationErrors) {
    return [];
  }
  //make a copy of error messages collection from state
  let errorsArray = [...validationErrors];
  //Make a new array that only includes items not matching the given fieldName
  let errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== fieldName.toLowerCase() && item.poolIndex === poolIndex);

  //----- Special case for UnitLocation: also need to remove any validation error on the CoolingType field -----
  if (fieldName.toLowerCase() === "unitlocation") {
    errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== "coolingtype");
  }
  return errors;
}
//When receiving a response from the server, we want to:
//1. Add any new validation messages to state
//2. Preserve any previous validation messages that were already in state
//3. Replace any validation messages that have the same name but a new message coming from the server
export function getNewValidationErrorsList(validationErrors: any[], newValidationErrors: any[]) {
  //make a copy of previous error messages collection from state
  let currentErrorsArray = [...validationErrors];

  //newValidationErrors is a list of new errors that came from the server

  //now loop through existing(previous) errors, and add back to the list if there's not already a new one with the same name
  for (const error of currentErrorsArray) {
    const exists = newValidationErrors.find((x: any) => x.fieldName.toLowerCase() === error.fieldName.toLowerCase());
    if (!exists) {
      newValidationErrors.push(error);
    }
  }
  return newValidationErrors;
}

//Check for errors on this textbox/selectbox, and return the appropriate CSS className
export function getInputCSS(validationErrors: any[], fieldName: string, type: string) {
  const hasError = checkForErrors(validationErrors, fieldName);

  let className = "";
  if (type === "textbox") {
    className = "input-numeric";
  }
  if (type === "selectbox") {
    className = "input-select";
  }
  if (type === "accordiontextbox") {
    className = "accordion-textbox";
  }
  if (type === "accordionselect") {
    className = "accordion-select";
  }
  if (type === "coilFilterFPMtextbox") {
    className = "input-numeric-40";
  }
  if (type === "accordiontextbox-50") {
    className = "input-numeric-50";
  }
  if (fieldName === "coilAndFilterFpmLimit") {
    className = "input-numeric-40";
  }
  if (type === "casingDimensionTextbox") {
    className = "input-numeric-100";
  }
  if (type === "spectextbox") {
    className = "spec-textbox";
  }
  if (type === "poolInput"){
    className = "pool-input-numeric";
  }
  if (type === "poolInputSmall"){
    className = "pool-input-numeric-50";
  }
  if (type === "poolSelect"){
    className = "pool-select";
  }
  if (type === "poolSelectLarge"){
    className = "pool-select-large";
  }
  //If error exists, add the error class
  if (hasError) {
    className += " input-error";
  }
  return className;
}

export function getSelectBoxOptions(dropdownOptions: any[], boxName: any) {
  const options = dropdownOptions[boxName];
  if (options) {
    return options.map((item: any, index: number) => (
      <option key={index} value={item.textValue}>
        {item.displayValue}
      </option>
    ));
  }
  return <option></option>;
}
export function getSelectBoxOptionsSingle(dropdownOptions: any[]){
  const options = dropdownOptions;
  if (options) {
    return options.map((item: any, index: number) => (
      <option key={index} value={item.textValue}>
        {item.displayValue}
      </option>
    ));
  }
  return <option></option>;
}
export function getNewDropdownList(dropdownOptions: any[], newDropdownOptions: any) {
  //Make a copy of the list from state.dropdownOptions
  const newState = Object.assign({}, dropdownOptions, {});

  //Update newState with any new values we got from the server
  Object.entries(newDropdownOptions).forEach(([key, value]) => {
    if (value != null) {
      (newState as any)[key] = value;
    }
  });
  return newState;
}

//Checks whether a checkbox is checked or not
// export function getChecked(property: any) {
//   if (property && property.value) {
//     return property.value;
//   }
//   return false;
// }
export function getChecked(property: any) {
  if (typeof property === "undefined" || property === null || property == "false" || property == false) {
    return false;
  }
  if (property == true || property == "true") {
    return true;
  }
}
export function getStateOptions(){
  const options = getStates();
  if (options) {
    return options.map((item: string, index: number) => (
      <option key={index} value={item}>
        {item}
      </option>
    ));
  }
  return <option></option>;
}
export function getJobStatusOptions(){
  
}
function getStates(){
  return [ 'Choose an Option', 'AB', 'AK', 'AL', 'AR', 'AZ', 'BC', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MB', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NB', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NL', 'NM', 'NS', 'NT', 'NU', 'NV', 'NY', 'OH', 'OK', 'ON', 'OR', 'PA', 'PE', 'QC', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY', 'YT' ];
}
export function convertTubeWallThickness(incomingValue: string){
  switch(incomingValue) {
    case "SixteenThousandths":
      return "0.016";
    case "TwentyThousandths":
      return "0.02";
    case "TwentyFiveThousandths":
      return "0.025";
    case "ThirtyFiveThousandths":
      return "0.035";
    case "FortyNineThousandths":
      return "0.049";
    default:
      return "";
  }
}
export function convertFinThickness(incomingValue: string){
  switch(incomingValue) {
    case "SixThousandths":
      return "0.006";
    case "EightThousandths":
      return "0.008";
    case "TenThousandths":
      return "0.010";
    default:
      return "";
  }
}