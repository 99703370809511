import {getAuthToken} from './projectDataService';
import {displayErrorString} from '../api-utilities/api-calls';
import { store } from '../redux/store';
import { updateReduxAction } from '../redux/actions';
import {DrawingScreen, DrawingViewType} from '../api-utilities/enums';
import { getStringId } from '../api-utilities/formatting';

const apibase = process.env.REACT_APP_CLIENT_API;

export async function getAllPoolValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetAllPoolValues", projectId, unitId);
}
export async function getUnitType(projectId: number, unitId: number){
  return await callGetUnitType("readapi/GetUnitType", projectId, unitId);
}
export async function getConfigInputsAllValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetConfigInputsAllValues", projectId, unitId);
}
export async function getInletOutletValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetInletOutletValues", projectId, unitId);
}
export async function getTempsAndSystemValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetTempsAndSystemValues", projectId, unitId);
}
export async function getCoilInputValues(projectId: number, unitId:number, componentName: string) {
  return await callGetEndpoint(`readapi/GetCoilInputValues/${componentName}`, projectId, unitId);
}
// export async function getAllCoilInputValues(projectId: number, unitId:number) {
//   return await callGetEndpoint(`readapi/GetAllCoilInputValues`, projectId, unitId);
// }
export async function getRefrigerationInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetRefrigerationInputValues", projectId, unitId);
}
export async function getFanInputValues(projectId: number, unitId:number, componentName: string) {
  return await callGetEndpoint(`readapi/GetFanInputValues/${componentName}`, projectId, unitId);
}
// export async function getAllFanInputValues(projectId: number, unitId:number) {
//   return await callGetEndpoint(`readapi/GetAllFanInputValues`, projectId, unitId);
// }
export async function getFilterInputValues(projectId: number, unitId:number, componentName: string) {
  return await callGetEndpoint(`readapi/GetFilterInputValues/${componentName}`, projectId, unitId);
}
// export async function getHeatWheelInputValues(projectId: number, unitId:number) {
//   return await callGetEndpoint("readapi/GetHeatWheelInputValues", projectId, unitId);
// }
export async function getPlateHeatExchangerInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetPlateHeatExchangerInputValues", projectId, unitId);
}
// export async function getHumidifierManifoldInputValues(projectId: number, unitId:number, componentName: string) {
//   return await callGetEndpoint(`readapi/GetHumidifierManifoldInputValues/${componentName}`, projectId, unitId);
// }
export async function getSoundAttenuatorInputValues(projectId: number, unitId:number, componentName: string) {
  return await callGetEndpoint(`readapi/GetSoundAttenuatorInputValues/${componentName}`, projectId, unitId);
}
export async function getIndirectFiredFurnaceInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetIndirectFiredFurnaceInputValues", projectId, unitId);
}
export async function getControlsInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetControlsInputValues", projectId, unitId);
}
export async function getBMSInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetBMSInputValues", projectId, unitId);
}
export async function getElectricalInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetElectricalInputValues", projectId, unitId);
}
export async function getUnitCabinetInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetUnitCabinetInputValues", projectId, unitId);
}
export async function getWarrantyInputValues(projectId: number, unitId:number) {
  return await callGetEndpoint("readapi/GetWarrantyInputValues", projectId, unitId);
}
 //ClientAPI will check the "NeedsRecalcLevel" on the Unit vertex and only run the appropriate calculations
 //Note: I'm not using callGetEndpoint here because I don't want it displaying the default red error box when hasError:true
 export async function runPerformanceCalcs(projectId: number, unitId:number) {
  let targetEndpoint = "writeapi/runPerformanceCalculations";
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

      const result = await fetch(`${apibase}/${targetEndpoint}/${projIdStr}/${unitIdStr}`, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (result.status === 200) {
        const data = await result.json();
        return data;
      }
      else{
          displayErrorString("Requst failed", targetEndpoint);
          return {hasError:true, errorMessage: "Request failed."};
      }
}
//This is called by the save button on toolbar.
export async function setReCalcLevel(projectId:string, unitId:string, recalcLevel:string){
  const token = await getAuthToken();

  const result = await fetch(apibase + `/writeapi/setNeedsReCalcLevel/${projectId}/${unitId}/${recalcLevel}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}
//This is called by the save button on toolbar.
export async function runFullUnitStructureRebuild(projectId:string, unitId:string){
  const token = await getAuthToken();

  const result = await fetch(apibase + `/writeapi/runFullUnitStructureRebuild/${projectId}/${unitId}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}
export async function getComponentList(projectId: number, unitId:number) {
  return await callGetEndpoint("dataapi/getComponentList", projectId, unitId);
}
//Use this method to get any drawing (not schematic) for the UI only.
export async function getUnitDrawing(routeParams: any, viewType: DrawingViewType, uiScreen: DrawingScreen, commonData:string) {
  const { unitId, projectId } = routeParams;
  const drawingRequest = {
    ProjectId: projectId,
    UnitId: unitId,
    View: viewType,
    Type: uiScreen,
    CommonDataByUnitViewModel: commonData
  }
  return await callPostEndpoint("readapi/GetUnitDrawing", drawingRequest);
}
export async function getInletOutletSizingDrawing(routeParams: any, selectedConnection: string, commonData: any){
  const { unitId, projectId } = routeParams;
  const inletOutletSizingSchematicRequest = {
    ProjectId: projectId,
    UnitId: unitId,
    SelectedConnection: selectedConnection,
    CommonDataByUnitViewModel: commonData
  }
  return await callPostEndpoint("readapi/GetInletOutletSizingSchematicSvg", inletOutletSizingSchematicRequest);
}
export async function callGetUnitType(targetEndpoint: string, projectId: number, unitId:number){
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

      const result = await fetch(`${apibase}/${targetEndpoint}/${projIdStr}/${unitIdStr}`, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (result.status === 200) {
        const data = await result.text();
        //Error notifications are displayed on individual pages.
        return data;
      }
      else{
          displayErrorString("Requst failed", targetEndpoint);
          return {hasError:true, errorMessage: "Request failed."};
      }  
}

//For all GET endpoints that include projectId and unitId
export async function callGetEndpoint(targetEndpoint: string, projectId: number, unitId:number) {
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

      const result = await fetch(`${apibase}/${targetEndpoint}/${projIdStr}/${unitIdStr}`, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (result.status === 200) {
        const data = await result.json();
        //Error notifications are displayed on individual pages.
        return data;
      }
      else{
          displayErrorString("Requst failed", targetEndpoint);
          return {hasError:true, errorMessage: "Request failed."};
      }
}

//For all GET endpoints that don't include the unitId
export async function callGetEndpoint2(targetEndpoint: string, projectId: number) {
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);

      const result = await fetch(`${apibase}/${targetEndpoint}/${projIdStr}`, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (result.status === 200) {
        const data = await result.json();
        //Error notifications are displayed on individual pages.
        return data;
      }
      else{
          return {hasError:true, errorMessage: "Request failed."};
      }
}

export async function jobSearch(targetEndpoint: string, searchText: string) {
  const token = await getAuthToken();

      const result = await fetch(`${apibase}/${targetEndpoint}/${searchText}`, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (result.status === 200) {
        const data = await result.json();
        //Error notifications are displayed on individual pages.
        return data;
      }
      else{
          displayErrorString("Requst failed", targetEndpoint);
          return {hasError:true, errorMessage: "Request failed."};
      }
}
export async function jobSearchByStatusAndName(targetEndpoint: string, jobStatus: string, searchText: string) {
  const token = await getAuthToken();

      const result = await fetch(`${apibase}/${targetEndpoint}/${jobStatus}?search=${searchText}`, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
  
      if (result.status === 200) {
        const data = await result.json();
        //Error notifications are displayed on individual pages.
        return data;
      }
      else{
          displayErrorString("Requst failed", targetEndpoint);
          return {hasError:true, errorMessage: "Request failed."};
      }
}
export async function testUpdateProject(){
  const token = await getAuthToken();

  const editedProjectRequest = {
    projectName: "test",
    projectId:"proj-7"
  };
  const result = await fetch(apibase + '/dataapi/updateProject', {
    method: 'post',
    body: JSON.stringify(editedProjectRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //result.json is our expected json object.
  //result.text is how error messages come in.
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  return response;
}


export async function getLayoutSchematicSVG(projectId: number, unitId: number) {
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);
  //if(token != ''){
    const result = await fetch(`${apibase}/readapi/GetLayoutSchematicSvg/${projIdStr}/${unitIdStr}`
    , {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` }
    });

    if (result.status === 200) {
      return await result.json();
    }
    else{
        return {hasError:true, errorMessage: "Request failed."};
    }
}

// export async function getConnectionSchematicSvg(projectId: number, unitId: number){
//   const token = await getAuthToken();
//   let projIdStr = getStringId('project', projectId);
//   let unitIdStr = getStringId('unit', unitId);
//   //if (token != '') {
//     const result = await fetch(`${apibase}/readapi/GetConnectionSchematicSvg/${projIdStr}/${unitIdStr}`,
//     {
//       method: 'get',
//       headers: {Authorization: `Bearer ${token}`}
//     });

//     if (result.status === 200) {
//       const data = await result.json();
//       if (data.hasError) {
//         //do we want to show anything if there is an error?
//       }
//       return data;
//     }
//     else{
//       return {hasError:true, errorMessage: "Request failed."};
//     }
// }
//Standard method for updating any field.
//Each component needs to specify its targetVertex (ChilledWaterCoilInputs, HotWaterCoilInputs, etc.)
export async function updateInputField(endpoint:string, projectId: number, unitId: number, fieldName:string, newValue:string, targetVertex: string) {
  //For now we just have every "component" field trigger a recalc.  We can get more granular later.
  store.dispatch(updateReduxAction('UPDATE_OPTIMIZE_DESIGN', {showRecalcButton: true}));  

  //Update redux to invalidate the unit price
  store.dispatch(updateReduxAction('UPDATE_PRICING', { priceNeedsUpdate: true }));

  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

  const fieldUpdateRequest = {
          projectId: projIdStr,
          unitId: unitIdStr,
          fieldName: fieldName,
          newValue: newValue,
          targetVertex: targetVertex
        };
        return await callPostEndpoint(`writeapi/${endpoint}`, fieldUpdateRequest);
}
export async function runPoolCalculations(projectId: number, unitId: number){

  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);  

  const token = await getAuthToken();

  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));

  const result = await fetch(`${apibase}/writeapi/runPoolCalculations/${projIdStr}/${unitIdStr}`,
  {
    method: 'get',
    headers: { Authorization: `Bearer ${token}` }
  });
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
  return response;
}
export async function runPoolSpaceValidations(projectId: number, unitId: number){
  const token = await getAuthToken();
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

    const result = await fetch(`${apibase}/readapi/GetPoolSpaceValidations/${projIdStr}/${unitIdStr}`
    , {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` }
    });

    if (result.status === 200) {
      return await result.json();
    }
    else{
        return {hasError:true, errorMessage: "Request failed."};
    }
}
export async function deleteIndividualPool(projectId: number, unitId: number, poolIndex: number){
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

  const addPoolRequest = {
    projectId: projIdStr,
    unitId: unitIdStr,
    poolIndex: poolIndex
  };
  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));
  const token = await getAuthToken();

  const result = await fetch(`${apibase}/writeapi/deleteIndividualPool`, {
    method: 'post',
    body: JSON.stringify(addPoolRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //success: data will be in result.json
  //error: error message will be in result.text
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
  return response;   
}
export async function addIndividualPool(projectId: number, unitId: number){
  
  let projIdStr = getStringId('project', projectId);
  let unitIdStr = getStringId('unit', unitId);

  const addPoolRequest = {
    projectId: projIdStr,
    unitId: unitIdStr,
  };
  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: true }));
  const token = await getAuthToken();

  const result = await fetch(`${apibase}/writeapi/addIndividualPool`, {
    method: 'post',
    body: JSON.stringify(addPoolRequest),
    headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
  });

  //success: data will be in result.json
  //error: error message will be in result.text
  const response = {
    success: result.status === 200 ? true : false,
    data: result.status === 200 ? await result.json() : await result.text()
  };
  store.dispatch(updateReduxAction('UPDATE_DISPLAY', { showLoader: false }));
  return response; 
}

  export async function callPostEndpoint(targetEndpoint:string, requestObject:any) {
    const token = await getAuthToken();

    const result = await fetch(`${apibase}/${targetEndpoint}`, {
      method: 'post',
      body: JSON.stringify(requestObject),
      headers: { 'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}` }
    });
  
    //success: data will be in result.json
    //error: error message will be in result.text
    const response = {
      success: result.status === 200 ? true : false,
      data: result.status === 200 ? await result.json() : await result.text()
    };

    return response; 
  }