import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { getUnitType } from "../../api-calls/readApiService";
import { Link, match, withRouter } from "react-router-dom";
import "../../css/TopMenu.scss";

// expected props
interface IProps {
  reduxMenus: any;
  match: match<any>;
  updateRedux: any;
}
// local state
interface IState {}

class TopMenu extends React.Component<IProps, IState> {
  //public state: IState = { };
  async componentDidMount() {
    if (this.props.match) {
      const { projectId, unitId } = this.props.match.params;
      if (projectId && unitId) {
        let unitType = await getUnitType(projectId, unitId);
        console.log(unitType);
        if (unitType === "Pool") {
          this.props.updateRedux("UPDATE_MENUS", { showPoolInputs: true });
        } else {
          this.props.updateRedux("UPDATE_MENUS", { showPoolInputs: false });
        }
      }
    }
  }
  private getLinkUrl(target: string) {
    if (target === "design") {
      let newTarget = this.props.reduxMenus.showPoolInputs ? "design/pools" : "design/config";
      target = newTarget;
    }
    const routeParams = this.props.match.params;
    return "/pid/" + routeParams.projectId + "/uid/" + routeParams.unitId + "/" + target;
  }
  private getBaseUrl() {
    const routeParams = this.props.match.params;
    return "/pid/" + routeParams.projectId + "/uid/" + routeParams.unitId;
  }

  private createMenuLink(topMenuIndex: number, urlFragment: string, label: string) {
    const baseUrl = urlFragment == "/project" ? "" : this.getBaseUrl(); //don't include baseUrl for job files page
    const isActive = this.props.reduxMenus.topMenu.activeItem == topMenuIndex;
    const isPrevious = topMenuIndex == this.props.reduxMenus.topMenu.activeItem - 1; //Don't show arrow tip if menuItem is immediately previous to the selected one
    return (
      <Link to={baseUrl + urlFragment}>
        {isActive ? <div className="topmenu-tail-selected"></div> : null}
        <div className={`topmenu-link ${isActive ? " topmenu-selected" : ""}`}>{label}</div>
        {isPrevious ? null : <div className={`topmenu-arrow ${isActive ? " topmenu-arrow-selected" : ""}`}></div>}
      </Link>
    );
  }
  public render() {
    const active = this.props.reduxMenus.topMenu.activeItem;

    return (
      <div className="topmenu">
        {this.createMenuLink(1, "/project", "Job Files")}
        {this.createMenuLink(2, "/design/config", "Create Design")}
        {this.createMenuLink(3, "/drawing/drawing", "Drawings")}
        {this.createMenuLink(4, "/layout/doors", "Fine Tune Design")}
        {this.createMenuLink(5, "/spec/controls", "Select Options")}
        {this.createMenuLink(6, "/outputs/pdfoutputs", "Outputs")}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopMenu));
