import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject, isNullOrEmpty } from "../../../utilities/helpers";
import { formatNumber } from "../../../api-utilities/formatting";
import { IHeatWheelOption } from "../../../interfaces/interfaces";
import { addNotification, clearAllPerformanceNotifications } from "../../../api-utilities/notification-tools";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  dataContainer: any;
}

class HeatWheel extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    dataContainer: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await callGetEndpoint("readapi/GetHeatWheelPerformanceValues", projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    if (result.uiDataContainer && result.uiDataContainer.heatWheelInputs) {
      this.setState({
        userInputs: result.uiDataContainer.heatWheelInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
    if (result.hasError) {
      const notification = {
        id: "wheelInputValuesError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetHeatWheelPerformanceValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
  }

  //Save new value to redis and DB
  public async saveInputValue(newValue: string) {
    let fieldName = "modelNumber";
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    clearAllPerformanceNotifications();
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    let inputResult = await updateInputField("updateHeatWheel", projectId, unitId, fieldName, newValue, "HeatWheelInputs");

    //Run performance to update read-only props for chilled water coil and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);

    const result = await callGetEndpoint("readapi/GetHeatWheelPerformanceValues", projectId, unitId);
    
    if (result.uiDataContainer && result.uiDataContainer.heatWheelInputs) {
      this.setState({
        userInputs: result.uiDataContainer.heatWheelInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------

  getCSSClass(modelNumber: string, selectedModelNumber: string) {
    return modelNumber == selectedModelNumber ? "acc-td-optional acc-td-selected" : "acc-td-optional";
  }
  getHeatWheelChoices(dataContainer: any) {
    const context = this;
    const options = dataContainer.heatWheelOptions;

    const selectedHeatWheel = this.getSelectedHeatWheel(dataContainer);
    const selectedModelNumber = selectedHeatWheel.modelNumber;

    const arr = options.map(function (item: IHeatWheelOption, index: number) {
      return (
        <React.Fragment key={index}>
          <div className="acc-td2"></div>
          <div className={context.getCSSClass(item.modelNumber, selectedModelNumber)} onClick={() => context.saveInputValue(item.modelNumber)}>
            <div className="acc-data1">{item.modelNumber.replace("3C", "")}</div>
            <div className="acc-data2">{formatNumber(item.totalEffectiveness, 0)}%</div>
            <div className="acc-data2">{formatNumber(item.summerLAT, 1)}F</div>
            <div className="acc-data2">{formatNumber(item.summerWetBulbLAT, 1)}F</div>
            <div className="acc-data2">{formatNumber(item.winterLAT, 1)}F</div>
            <div className="acc-data2">{formatNumber(item.pressureDrop, 2)}"</div>
          </div>
        </React.Fragment>
      );
    });
    return arr;
  }

  getSelectedHeatWheel(dataContainer: any) {
    const userInputs = this.state.userInputs;
    const options = dataContainer.heatWheelOptions;

    //If the user has selected a heatWheel, return that
    if (!isNullOrEmpty(userInputs.modelNumber)) {
      return options.find((x: any) => x.modelNumber == userInputs.modelNumber);
    } else {
      //otherwise return the default one
      return options.find((x: any) => x.isDefaultOption);
    }
  }

  public render() {
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;
    let dataContainer = this.state.dataContainer;
    const isLocked = false;

    if (!savedValues) {
      return null;
    }

    const selectedHeatWheel = this.getSelectedHeatWheel(dataContainer);

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">Heat Wheel</legend>

        <div className="accordion-content">
          {/* <div className={`acc-panel-540 ${active === true ? 'acc-panel-active' : 'acc-panel-inactive'}`}>
          <div className={`accordion-content app-box-shadow ${isLocked ? 'acc-content-locked' : ''}`}>
            <div className="acc-locking">
              <input
                className="acc-checkbox"
                type="checkbox"
                disabled={!selectedHeatWheelModelNumber}
                checked={isLocked}
                onChange={(event) => this.inputBuilder.updateCheckbox('isSelectionLocked', event)}
              />
              <span className="acc-lock-text">Lock Heat Wheel Selection</span>
            </div> */}

          <div className="hw-spacer"></div>

          {/* {getErrorNotifications(this.props.reduxHeatwheel.errorMessages)} */}

          <div className="acc-table">
            <div className="acc-td1">
              <div className="acc-data1">
                <b>Model</b>
              </div>
              <div className="acc-data2">Total Effectiveness</div>
              <div className="acc-data2">Summer Design DB LAT</div>
              <div className="acc-data2">Summer Design WB LAT</div>
              <div className="acc-data2">Winter Design LAT</div>
              <div className="acc-data2">Air Pressure Drop</div>
            </div>

            {this.getHeatWheelChoices(dataContainer)}
          </div>

          {/* <div className="acc-show-more">
              <span className="acc-show-txt" onClick={() => this.toggleMoreFields()}>
                {this.state.openMoreFields ? 'Show less...' : 'Show more...'}
              </span>
            </div> */}
          <div className="acc-spacer"></div>

          <div className="">
            <div className="accordion-label-hw"></div>
            <div className="accordion-label-centered">Sensible</div>
            <div className="accordion-label-centered">Latent</div>
            <div className="accordion-label-centered">Total</div>
            <div></div>
            <div className="accordion-label-hw">Summer Eff.</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.summerSensibleEff, 0)}%</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.summerLatentEff, 0)}%</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.summerTotalEff, 0)}%</div>
            <div></div>
            <div className="accordion-label-hw">Winter Eff.</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.winterSensibleEff, 0)}%</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.winterLatentEff, 0)}%</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.winterTotalEff, 0)}%</div>
            <div></div>
            <div className="accordion-label-hw">Enthalpy Rec. Ratio (ERR)</div>
            <div className="accordion-label-centered">-</div>
            <div className="accordion-label-centered">-</div>
            <div className="accordion-label-centered">{formatNumber(selectedHeatWheel.coolingEnthalpyRecoveryRatio, 0)}%</div>
            <div className="acc-spacer"></div>
          </div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(HeatWheel);
