import { DesignPerformanceResult, EffectivenessValues } from '../TypescriptClient';

//Get the numeric part of a project or unit id.
export function getNumericId(value: string){
  //Example: "proj-354" ==> 354
  //Example: "unit-302" ==> 302
  let str = value.split('-').pop(); //split returns an array.  pop returns the last element.
  return Number(str);               //convert to number
}

//Get the string version of a project or unit id.
export function getStringId(itemType: string, id: number){
  //Example: 354 ==> "proj-354"
  //Example: 302 ==> "unit-302"
  if(itemType == "project"){
    return `proj-${id}`;
  }
  if(itemType == "unit"){
    return `unit-${id}`;
  }
}



//Function to remove commas from a string
export function removeCommas(value: string) {
  return value.replace(/,/g, '');
}

//Format with commas and specified number of decimal places
export function roundNumber(value: number) {
  if (value) {
    const formatted = Math.round(value);
    return formatted.toString();
  }
  return value;
}

//Format with commas and specified number of decimal places
export function formatNumber(value: any, decimalPlaces: number) { 

   //for some reason zero returns empty string. so we have to manually deal with zero here.
   if(value === 0 && decimalPlaces == 0){return "0";}
   if(value === 0 && decimalPlaces == 1){return "0.0";}
   if(value === 0 && decimalPlaces == 2){return "0.00";}
   
  if (value) {
    const formatted = value.toLocaleString('en-US', {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    });
    return formatted.toString();
  }
  return "";   //If value was null or undefined, return empty string
}

export function RoundToNearest(value: number, increment: number) {
  return formatNumber(Math.round((value / increment)) * increment, 0);
}
export function RoundUpToNearest(value: number, increment: number){
  //LOWRY-16913: Round up when the decimal is at or above 0.1 and round down when below
  let decimalPart = value % 1;
  if (Math.abs(decimalPart) > .1){
    return formatNumber(Math.ceil((value/increment)) * increment, 0)
  }
  else {
    return RoundToNearest(value, 1);
  }
}
//Formatting function for most of the common textbox inputs in the app that have a "value" property.
//Loop through each of the properties on the dataObject coming back from the server.
//If property is listed in the formatObject, then call formatNumber() to format it properly.
export function formatData(dataObject: any, formatObject: any) {
  //Loop through each property on dataObject
  Object.keys(dataObject).forEach(function (key) {
    //If the propertyName exists in formatObject
    if (key in formatObject) {
      //If propertyName isn't null and has a "value" property
      if (dataObject[key] && dataObject[key].value) {
        //Get the properly formatted string with commas & correct decimal places
        const formattedValue = formatNumber(dataObject[key].value, formatObject[key].decimalPlaces);
        //Update the property on dataObject
        dataObject[key] = Object.assign({}, dataObject[key], { value: formattedValue });
      }
    }
  });
  return dataObject;
}

export const ConfigInputFormats = {
  outsideAirAltitudeOnlyAcfm: { decimalPlaces: 0 },
  supplyAirAltitudeOnlyAcfm: { decimalPlaces: 0 },
  returnAirAltitudeOnlyAcfm: { decimalPlaces: 0 },
  exhaustAirAltitudeOnlyAcfm: { decimalPlaces: 0 }
};

export const TempsSystemFormats = {
  elevation: { decimalPlaces: 0 },
  outsideSummerDryBulb: { decimalPlaces: 1 },
  outsideSummerWetBulb: { decimalPlaces: 1 },
  outsideWinterDryBulb: { decimalPlaces: 1 },
  supplyESP: { decimalPlaces: 2 },
  returnESP: { decimalPlaces: 2 },
  returnSummerDryBulb: { decimalPlaces: 1 },
  returnSummerRelativeHumidity: { decimalPlaces: 0 },
  returnWinterDryBulb: { decimalPlaces: 1 },
  returnWinterRelativeHumidity: { decimalPlaces: 0 },
  coolingOffCoilDryBulb: { decimalPlaces: 1 },
  preheatDryBulb: {decimalPlaces: 1},
  heatingTemp: { decimalPlaces: 1 },
  reheatTemp: { decimalPlaces: 1 }
};

export const CommonFormats = {
  enteringAirTemp: { decimalPlaces: 1 },
  leavingAirTemp: { decimalPlaces: 1 },
  rows: { decimalPlaces: 0 },
  finsPerInch: { decimalPlaces: 0 },
  enteringAirDryBulb: { decimalPlaces: 1 },
  enteringAirWetBulb: { decimalPlaces: 1 },
  leavingAirDryBulb: { decimalPlaces: 1 },
  leavingAirWetBulb: { decimalPlaces: 1 }
};

// --------------------- Formatting for HeatWheel ---------------------
function formatEffectiveness(val: number) {
  return formatNumber(val * 100, HeatWheelFormats.effectiveness.decimalPlaces) + '%';
}
function formatEffectivenessValues(vals: EffectivenessValues) {
  return {
    sensibleEffectiveness: formatEffectiveness(vals.sensibleEffectiveness!),
    latentEffectiveness: formatEffectiveness(vals.latentEffectiveness!),
    totalEffectiveness: formatEffectiveness(vals.totalEffectiveness!)
  };
}
export function formatPlateHeatExchangerData(plateHXData:any){
  if (plateHXData){
    for (const item of plateHXData.plateHXChoices) {
      item.sensibleEfficiency =
        formatNumber(item.sensibleEfficiency * 100, PlateHXFormats.sensibleEfficiency.decimalPlaces) + '%';
      item.airPressureDrop = formatNumber(item.airPressureDrop, PlateHXFormats.pressureDrop.decimalPlaces) + '"';
      item.summerDesignLat =
        formatNumber(item.summerDesignLat, PlateHXFormats.airTemperature.decimalPlaces) + 'F';
      item.winterDesignLat =
        formatNumber(item.winterDesignLat, PlateHXFormats.airTemperature.decimalPlaces) + 'F';
    }
    plateHXData.supplyAirAltitudeOnlyAcfm = formatNumber(plateHXData.supplyAirAltitudeOnlyAcfm,0);
    plateHXData.returnAirAltitudeOnlyAcfm = formatNumber(plateHXData.returnAirAltitudeOnlyAcfm,0);
    plateHXData.summerTempEfficiency = formatNumber(plateHXData.summerTempEfficiency * 100, 0) + '%';
    plateHXData.winterTempEfficiency = formatNumber(plateHXData.winterTempEfficiency * 100, 0) + '%';
  }
  return plateHXData;
}
export const PlateHXFormats = {
  sensibleEfficiency: { decimalPlaces: 0 },
  pressureDrop: { decimalPlaces: 2 },
  airTemperature: { decimalPlaces: 1 },
};
export function formatHeatWheelData(heatWheelData: any) {
  if (heatWheelData) {
    for (const item of heatWheelData.heatWheelChoices) {
      item.totalEffectiveness =
        formatNumber(item.totalEffectiveness * 100, HeatWheelFormats.totalEffectiveness.decimalPlaces) + '%';
      item.pressureDrop = formatNumber(item.pressureDrop, HeatWheelFormats.pressureDrop.decimalPlaces) + '"';
      item.summerLeavingAirTemperature =
        formatNumber(item.summerLeavingAirTemperature, HeatWheelFormats.airTemperature.decimalPlaces) + 'F';
      item.winterLeavingAirTemperature =
        formatNumber(item.winterLeavingAirTemperature, HeatWheelFormats.airTemperature.decimalPlaces) + 'F';
    }
    heatWheelData.summerEffectivenessValues = formatEffectivenessValues(heatWheelData.summerEffectivenessValues);
    heatWheelData.winterEffectivenessValues = formatEffectivenessValues(heatWheelData.winterEffectivenessValues);
  }
  return heatWheelData;
}
export const HeatWheelFormats = {
  totalEffectiveness: { decimalPlaces: 0 },
  pressureDrop: { decimalPlaces: 2 },
  relativeCost: { decimalPlaces: 2 },
  airTemperature: { decimalPlaces: 1 },
  effectiveness: { decimalPlaces: 0 }
};

// --------------------- Formatting for Inlets/Outlets ---------------------
export function formatInletOutletData(inletOutletData: any) {
  if (inletOutletData) {
    inletOutletData.airflowCFM = formatNumber(inletOutletData.airflowCFM, InletOutletFormats.airflow.decimalPlaces);
    inletOutletData.faceVelocityFpm.value = formatNumber(
      inletOutletData.faceVelocityFpm.value,
      InletOutletFormats.faceVelocity.decimalPlaces
    );
    inletOutletData.dimensionA.value = formatNumber(
      inletOutletData.dimensionA.value,
      InletOutletFormats.dimensions.decimalPlaces
    );
    inletOutletData.dimensionB.value = formatNumber(
      inletOutletData.dimensionB.value,
      InletOutletFormats.dimensions.decimalPlaces
    );
    inletOutletData.dimensionC.value = formatNumber(
      inletOutletData.dimensionC.value,
      InletOutletFormats.dimensions.decimalPlaces
    );
    inletOutletData.dimensionD.value = formatNumber(
      inletOutletData.dimensionD.value,
      InletOutletFormats.dimensions.decimalPlaces
    );
  }
  return inletOutletData;
}
export const InletOutletFormats = {
  airflow: { decimalPlaces: 0 },
  dimensions: { decimalPlaces: 0 },
  faceVelocity: { decimalPlaces: 0 }
};

// --------------------- Formatting for Filters ---------------------
export function formatFilterData(filterData: any) {
  if (filterData) {
    filterData.faceVelocity = formatNumber(filterData.faceVelocity, FilterFormats.faceVelocity.decimalPlaces);
    filterData.pressureDrop = formatNumber(filterData.pressureDrop, FilterFormats.pressureDrop.decimalPlaces);
  }
  return filterData;
}
export const FilterFormats = {
  faceVelocity: { decimalPlaces: 0 },
  pressureDrop: { decimalPlaces: 2 }
};

// --------------------- Formatting for HotGasReheat Coil ---------------------
export function formatHGRHCoilData(coilData: any) {
  if (coilData) {
    coilData.enteringAirTemp = formatNumber(coilData.enteringAirTemp, CommonFormats.enteringAirTemp.decimalPlaces);
    coilData.leavingAirTemp = formatNumber(coilData.leavingAirTemp, CommonFormats.leavingAirTemp.decimalPlaces);
    coilData.reheatCircuits = formatNumber(coilData.reheatCircuits, HGRHCoilFormats.reheatCircuits.decimalPlaces);
    coilData.rows = formatNumber(coilData.rows, CommonFormats.rows.decimalPlaces);
    coilData.finsPerInch = formatNumber(coilData.finsPerInch, CommonFormats.finsPerInch.decimalPlaces);
    coilData.airPressureDrop = formatNumber(coilData.airPressureDrop, CoilFormats.airPressureDrop.decimalPlaces);
  }
  return coilData;
}
export const HGRHCoilFormats = {
  reheatCircuits: { decimalPlaces: 0 }
};

// --------------------- Formatting for Direct Expansion Coil --------------
export function formatDxCoilData(coilData: any) {
  if (coilData) {
    coilData.enteringAirDryBulb = formatNumber(
      coilData.enteringAirDryBulb,
      CommonFormats.enteringAirDryBulb.decimalPlaces
    );
    coilData.enteringAirWetBulb = formatNumber(
      coilData.enteringAirWetBulb,
      CommonFormats.enteringAirWetBulb.decimalPlaces
    );
    coilData.leavingAirDryBulb = formatNumber(
      coilData.leavingAirDryBulb,
      CommonFormats.leavingAirDryBulb.decimalPlaces
    );
    coilData.leavingAirWetBulb = formatNumber(
      coilData.leavingAirWetBulb,
      CommonFormats.leavingAirWetBulb.decimalPlaces
    );
    coilData.eer = formatNumber(coilData.eer, DxCoilFormats.eer.decimalPlaces);
    coilData.condensingCapacity = formatNumber(
      coilData.condensingCapacity,
      DxCoilFormats.condensingCapacity.decimalPlaces
    );
    coilData.coolingTons = formatNumber(coilData.coolingTons, DxCoilFormats.coolingTons.decimalPlaces);
    coilData.rows = formatNumber(coilData.rows, CommonFormats.rows.decimalPlaces);
    coilData.finsPerInch = formatNumber(coilData.finsPerInch, CommonFormats.finsPerInch.decimalPlaces);

    const stmp = formatNumber(coilData.suctionTemperature.value, DxCoilFormats.suctionTemperature.decimalPlaces);
    coilData.suctionTemperature = Object.assign({}, coilData.suctionTemperature, { value: stmp });

    const atmp = formatNumber(coilData.ambientTemp.value, DxCoilFormats.ambientTemp.decimalPlaces);
    coilData.ambientTemp = Object.assign({}, coilData.ambientTemp, { value: atmp });

    coilData.airPressureDrop = formatNumber(coilData.airPressureDrop, CoilFormats.airPressureDrop.decimalPlaces);
  }
  return coilData;
}
export const DxCoilFormats = {
  suctionTemperature: { decimalPlaces: 1 },
  condensingCapacity: { decimalPlaces: 0 },
  ambientTemp: { decimalPlaces: 0 },
  eer: { decimalPlaces: 1 },
  coolingTons: { decimalPlaces: 1 }
};

// --------------------- Formatting for Electric Heaters ---------------------
export function formatElectHeaterData(heaterData: any) {
  if (heaterData) {
    heaterData.enteringAirDryBulb = formatNumber(
      heaterData.enteringAirDryBulb,
      CommonFormats.enteringAirDryBulb.decimalPlaces
    );
    heaterData.leavingAirTemp = formatNumber(heaterData.leavingAirTemp, CommonFormats.leavingAirTemp.decimalPlaces);
    heaterData.voltage = formatNumber(heaterData.voltage, ElectHeaterFormats.voltage.decimalPlaces);
    heaterData.powerKw = formatNumber(heaterData.powerKw, ElectHeaterFormats.powerKw.decimalPlaces);
    heaterData.amps = formatNumber(heaterData.amps, ElectHeaterFormats.amps.decimalPlaces);
  }
  return heaterData;
}
export const ElectHeaterFormats = {
  voltage: { decimalPlaces: 0 },
  powerKw: { decimalPlaces: 1 },
  amps: { decimalPlaces: 1 }
};

// --------------------- Formatting for Indirect Fired Furnace ---------------------
export function formatIDFData(furnaceData: any) {
  if (furnaceData) {
    furnaceData.enteringAirTemperature = formatNumber(
      furnaceData.enteringAirTemperature,
      FurnaceFormats.enteringAirTemperature.decimalPlaces
    );
    furnaceData.leavingAirTemperature = formatNumber(
      furnaceData.leavingAirTemperature,
      FurnaceFormats.leavingAirTemperature.decimalPlaces
    );
    furnaceData.outputMbh = formatNumber(furnaceData.outputMbh, FurnaceFormats.outputMbh.decimalPlaces);
  }
  return furnaceData;
}
export const FurnaceFormats = {
  enteringAirTemperature: { decimalPlaces: 1 },
  leavingAirTemperature: { decimalPlaces: 1 },
  outputMbh: { decimalPlaces: 0 }
};

// --------------------- Formatting for Hot/Cold Water Coils ---------------------
export function formatCoilData(coilData: any) {
  if (coilData) {
    coilData.enteringAirDryBulb = formatNumber(
      coilData.enteringAirDryBulb,
      CommonFormats.enteringAirDryBulb.decimalPlaces
    );
    if (coilData.enteringAirWetBulb) {
      coilData.enteringAirWetBulb = formatNumber(
        coilData.enteringAirWetBulb,
        CommonFormats.enteringAirWetBulb.decimalPlaces
      );
    }
    coilData.waterPressureDrop = formatNumber(coilData.waterPressureDrop, CoilFormats.waterPressureDrop.decimalPlaces);
    if (coilData.maxWaterPressureDrop) {
      coilData.maxWaterPressureDrop.value = formatNumber(
        coilData.maxWaterPressureDrop.value,
        CoilFormats.maxWaterPressureDrop.decimalPlaces
      );
    }
    coilData.leavingAirDryBulb = formatNumber(
      coilData.leavingAirDryBulb,
      CommonFormats.leavingAirDryBulb.decimalPlaces
    );
    if (coilData.leavingAirWetBulb) {
      coilData.leavingAirWetBulb = formatNumber(
        coilData.leavingAirWetBulb,
        CommonFormats.leavingAirWetBulb.decimalPlaces
      );
    }
    coilData.rows = formatNumber(coilData.rows, CommonFormats.rows.decimalPlaces);
    coilData.finsPerInch = formatNumber(coilData.finsPerInch, CommonFormats.finsPerInch.decimalPlaces);

    const eft = formatNumber(
      coilData.enteringFluidTemperature.value,
      CoilFormats.enteringFluidTemperature.decimalPlaces
    );
    coilData.enteringFluidTemperature = Object.assign({}, coilData.enteringFluidTemperature, { value: eft });

    const lft = formatNumber(coilData.leavingFluidTemperature.value, CoilFormats.leavingFluidTemperature.decimalPlaces);
    coilData.leavingFluidTemperature = Object.assign({}, coilData.leavingFluidTemperature, { value: lft });

    const gpct = formatNumber(coilData.glycolPercent.value, CoilFormats.glycolPercent.decimalPlaces);
    coilData.glycolPercent = Object.assign({}, coilData.glycolPercent, { value: gpct });

    coilData.airPressureDrop = formatNumber(coilData.airPressureDrop, CoilFormats.airPressureDrop.decimalPlaces);

    if (coilData.fluidFlowRateGPM) {
      coilData.fluidFlowRateGPM = formatNumber(coilData.fluidFlowRateGPM, CoilFormats.fluidFlowRateGPM.decimalPlaces);
    }
  }
  return coilData;
}
export const CoilFormats = {
  waterPressureDrop: { decimalPlaces: 1 },
  maxWaterPressureDrop: { decimalPlaces: 1 },
  enteringFluidTemperature: { decimalPlaces: 1 },
  leavingFluidTemperature: { decimalPlaces: 1 },
  glycolPercent: { decimalPlaces: 0 },
  airPressureDrop: { decimalPlaces: 2 },
  fluidFlowRateGPM: { decimalPlaces: 1 }
};

// --------------------- Formatting for Steam Coil ---------------------
export function formatSteamCoilData(coilData: any) {
  if (coilData) {
    coilData.enteringAirTemp = formatNumber(coilData.enteringAirTemp, CommonFormats.enteringAirTemp.decimalPlaces);
    coilData.leavingAirTemp = formatNumber(coilData.leavingAirTemp, CommonFormats.leavingAirTemp.decimalPlaces);
    coilData.steamLoading = formatNumber(coilData.steamLoading, SteamCoilFormats.steamLoading.decimalPlaces);
    coilData.rows = formatNumber(coilData.rows, CommonFormats.rows.decimalPlaces);
    coilData.finsPerInch = formatNumber(coilData.finsPerInch, CommonFormats.finsPerInch.decimalPlaces);

    const sp = formatNumber(coilData.steamPressure.value, SteamCoilFormats.steamPressure.decimalPlaces);
    coilData.steamPressure = Object.assign({}, coilData.steamPressure, { value: sp });

    coilData.airPressureDrop = formatNumber(coilData.airPressureDrop, CoilFormats.airPressureDrop.decimalPlaces);
  }
  return coilData;
}
export const SteamCoilFormats = {
  steamPressure: { decimalPlaces: 1 },
  steamLoading: { decimalPlaces: 1 }
};

// --------------------- Formatting for Fans ---------------------
export function formatFanData(fanData: any) {
  if (fanData) {
    const spo = formatNumber(
      fanData.totalStaticPressureOverride.value,
      fanFormats.totalStaticPressureOverride.decimalPlaces
    );
    fanData.totalStaticPressureOverride = Object.assign({}, fanData.totalStaticPressureOverride, { value: spo });

    fanData.airflow = formatNumber(fanData.airflow, fanFormats.airflow.decimalPlaces);
    fanData.totalStaticPressure = formatNumber(
      fanData.totalStaticPressure,
      fanFormats.totalStaticPressure.decimalPlaces
    );

    for (const item of fanData.fanChoices) {
      item.fanRpm = formatNumber(item.fanRpm, fanFormats.fanRpm.decimalPlaces);
      item.motorRpm = formatNumber(item.motorRpm, fanFormats.motorRpm.decimalPlaces);
      item.horsePowerDescription = formatNumber(
        item.horsePowerDescription,
        fanFormats.horsePowerDescription.decimalPlaces
      );
      item.staticEfficiencyPercent = formatNumber(
        item.staticEfficiencyPercent,
        fanFormats.staticEfficiencyPercent.decimalPlaces
      );
      item.arrayLwaOutletDb = formatNumber(item.arrayLwaOutletDb, fanFormats.arrayLwaOutletDb.decimalPlaces);
    }
  }
  return fanData;
}
export const fanFormats = {
  airflow: { decimalPlaces: 0 },
  totalStaticPressure: { decimalPlaces: 2 },
  totalStaticPressureOverride: { decimalPlaces: 2 },
  fanRpm: { decimalPlaces: 0 },
  motorRpm: { decimalPlaces: 0 },
  horsePowerDescription: { decimalPlaces: 1 },
  staticEfficiencyPercent: { decimalPlaces: 1 },
  arrayLwaOutletDb: { decimalPlaces: 1 }
};

// --------------------- Formatting for Performance Page ---------------------
export function formatPerformanceData(performanceData: any, optimizedDesignPerformance: DesignPerformanceResult) {
  if (performanceData) {
    const fpmLimit = formatNumber(
      performanceData.coilAndFilterFpmLimit.value,
      PerformanceFormats.coilAndFilterFpmLimit.decimalPlaces
    );
    performanceData.coilAndFilterFpmLimit = Object.assign({}, performanceData.coilAndFilterFpmLimit, {
      value: fpmLimit
    });

    const hwLimit = formatNumber(
      performanceData.casingHeightOrWidthLimit.value,
      PerformanceFormats.casingHeightOrWidthLimit.decimalPlaces
    );
    performanceData.casingHeightOrWidthLimit = Object.assign({}, performanceData.casingHeightOrWidthLimit, {
      value: hwLimit
    });
    //----- optimizedDesignPerformanceResult -----
    const optCasHeight = formatNumber(
      optimizedDesignPerformance.casingHeight!,
      PerformanceFormats.casingHeight.decimalPlaces
    );
    const optCasWidth = formatNumber(
      optimizedDesignPerformance.casingWidth!,
      PerformanceFormats.casingWidth.decimalPlaces
    );
    const optCasWeight = formatNumber(
      optimizedDesignPerformance.approxWeight!,
      PerformanceFormats.approxWeight.decimalPlaces
    );
    const optPrice = formatNumber(
      optimizedDesignPerformance.budgetPrice!,
      PerformanceFormats.budgetPrice.decimalPlaces
    );
    const formattedOptimizedDesignPerformance = {
      casingHeight: optCasHeight,
      casingWidth: optCasWidth,
      approxWeight: optCasWeight,
      budgetPrice: '$' + optPrice
    };
    performanceData.optimizedDesignPerformanceResult = Object.assign(
      {},
      optimizedDesignPerformance,
      formattedOptimizedDesignPerformance
    );

    //----- designPerformanceResult -----
    const custCasHeight = formatNumber(
      performanceData.designPerformanceResult.casingHeight,
      PerformanceFormats.casingHeight.decimalPlaces
    );
    performanceData.designPerformanceResult = Object.assign({}, performanceData.designPerformanceResult, {
      casingHeight: custCasHeight
    });

    const custCasWidth = formatNumber(
      performanceData.designPerformanceResult.casingWidth,
      PerformanceFormats.casingWidth.decimalPlaces
    );
    performanceData.designPerformanceResult = Object.assign({}, performanceData.designPerformanceResult, {
      casingWidth: custCasWidth
    });

    const custCasWeight = formatNumber(
      performanceData.designPerformanceResult.approxWeight,
      PerformanceFormats.approxWeight.decimalPlaces
    );
    performanceData.designPerformanceResult = Object.assign({}, performanceData.designPerformanceResult, {
      approxWeight: custCasWeight
    });

    const custPrice = formatNumber(
      performanceData.designPerformanceResult.budgetPrice,
      PerformanceFormats.budgetPrice.decimalPlaces
    );
    performanceData.designPerformanceResult = Object.assign({}, performanceData.designPerformanceResult, {
      budgetPrice: '$' + custPrice
    });
  }
  return performanceData;
}
export const PerformanceFormats = {
  coilAndFilterFpmLimit: { decimalPlaces: 0 },
  casingHeightOrWidthLimit: { decimalPlaces: 0 },
  casingHeight: { decimalPlaces: 0 },
  casingWidth: { decimalPlaces: 0 },
  approxWeight: { decimalPlaces: 0 },
  budgetPrice: { decimalPlaces: 0 }
};

// --------------------- Formatting for Sound Attenuators ---------------------
export function formatAttenuatorData(attenuatorData: any) {
  if (attenuatorData) {
    attenuatorData.faceVelocity = formatNumber(
      attenuatorData.faceVelocity,
      AttenuatorFormats.faceVelocity.decimalPlaces
    );
  }
  return attenuatorData;
}
export const AttenuatorFormats = {
  faceVelocity: { decimalPlaces: 0 }
};

// --------------------- Formatting for Humidifier Manifold ---------------------
export function formatHumidifierData(humidifierData: any) {
  if (humidifierData) {
    humidifierData.airTemp = formatNumber(humidifierData.airTemp, HumidifierFormats.airTemp.decimalPlaces);
    humidifierData.enteringRH = formatNumber(humidifierData.enteringRH, HumidifierFormats.enteringRH.decimalPlaces);
    humidifierData.desiredLeavingRH.value = formatNumber(
      humidifierData.desiredLeavingRH.value,
      HumidifierFormats.desiredLeavingRH.decimalPlaces
    );
    humidifierData.steamPressure.value = formatNumber(
      humidifierData.steamPressure.value,
      HumidifierFormats.steamPressure.decimalPlaces
    );
    humidifierData.steamCapacity = formatNumber(
      humidifierData.steamCapacity,
      HumidifierFormats.steamCapacity.decimalPlaces
    );
  }
  return humidifierData;
}
export const HumidifierFormats = {
  airTemp: { decimalPlaces: 1 },
  enteringRH: { decimalPlaces: 1 },
  desiredLeavingRH: { decimalPlaces: 0 },
  steamPressure: { decimalPlaces: 0 },
  steamCapacity: { decimalPlaces: 1 }
}

//------------------------Formatting for Unit Price Summary Objects----------------------
export function formatProjectInfo(projectInfo : any){
  if(projectInfo){
    projectInfo.createdByFirstName = titleCase(projectInfo.createdByFirstName);
    projectInfo.createdByLastName = titleCase(projectInfo.createdByLastName);
    projectInfo.officeName = titleCase(projectInfo.officeName);
  }
  return projectInfo;
}
export function formatUnitListPriceInfo(unitListPriceInfo : any){
  if(unitListPriceInfo){
    if(unitListPriceInfo.unitTag === null || unitListPriceInfo.unitTag === ""){
      unitListPriceInfo.unitTag = "No tag added to this unit";
    }
    if(unitListPriceInfo.listPrice !== null && unitListPriceInfo.listPrice > 0){
      unitListPriceInfo.listPrice = "$" + formatNumber(unitListPriceInfo.listPrice, 0).toString()
    }
    if(unitListPriceInfo.totalListPrice !== null && unitListPriceInfo.totalListPrice > 0){
      unitListPriceInfo.totalListPrice = "$" +formatNumber(unitListPriceInfo.totalListPrice, 0).toString()
    }
    if(unitListPriceInfo.netPrice !== null && unitListPriceInfo.netPrice > 0){
      unitListPriceInfo.netPrice = "$" +formatNumber(unitListPriceInfo.netPrice, 0).toString()
    }
    if(unitListPriceInfo.totalFreightCost !== null && unitListPriceInfo.unitTag !== "" &&  unitListPriceInfo.totalFreightCost > 0){
      unitListPriceInfo.totalFreightCost = "$" +formatNumber(unitListPriceInfo.totalFreightCost, 2).toString()
    }
    
  }
  return unitListPriceInfo;
}
export function formatTrucksRequired(trucksRequired:any, unitQuantity: any){
  let formattedTrucksRequired;
  if((unitQuantity ?? 0) > 1){
    if(trucksRequired !== null && trucksRequired !== "" && trucksRequired !== "Unshippable"){
      formattedTrucksRequired = unitQuantity.toString() + " " + trucksRequired;
      return formattedTrucksRequired;
    }
  }
  return trucksRequired;
}
export function titleCase(str : any) {
  if(str){
    str.toLowerCase();
    var strAr = str.split(" ");
    for(var i=0;i<strAr.length;i++)
    {
       strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].substring(1).toLowerCase();     
    }
    str = strAr.join(" ");
  }
  return str;
}
