import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
import { removeNotification } from '../../api-utilities/notification-tools';
import { INotification } from '../../interfaces/interfaces';
import '../../css/Notifications.scss';

// expected props
interface IProps {
  notificationData: INotification;
  updateRedux: any;
}
// local state
interface IState {}

class Notification extends React.Component<IProps, IState> {
  public render() {
    const data = this.props.notificationData;
    const type = this.props.notificationData.notificationType; //success,info,warning,error

    return (
      <div className="nf-body">
        <div className="nf-tr">
          <div className={['nf-left', `nf-${type}-tab`].join(' ')}></div>
          <div className="nf-right">
            <div className={`nf-${type}-bar`}>
              {data.title}
              <div className="nf-x" onClick={() => removeNotification(data.id)}>
                x
              </div>
            </div>
            <div className={`nf-${type}-content`}>{data.content}</div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
