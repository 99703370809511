import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  reduxMenus: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  operatingMode: any;
}
class CombinedFilter extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    operatingMode: null,
  };

  async componentDidMount() {
    await this.getFilterData();
  }

  private async getFilterData() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint(`readapi/GetCombinedFilterInputValues`, projectId, unitId);

    if (result.uiDataContainer && result.uiDataContainer.filterInputsList) {
      this.setState({
        userInputs: result.uiDataContainer.filterInputsList,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        operatingMode: result.uiDataContainer.filterOpMode
      });
    }
  }
  private async RunPerformance(){
    const { projectId, unitId } = this.props.match.params;
   
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for filters and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);
    this.getFilterData();
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
  }

  private GetPressureDrop(StageName : string, operatingMode: any){
    
    if(StageName === "Stage1"){
      if (operatingMode.stage1FilterLoading === undefined || operatingMode.stage1FilterLoading === null){
        return formatNumber((operatingMode.stage1CleanPressureDrop), 2);
      }
      else{
        return formatNumber((operatingMode.stage1CleanPressureDrop + operatingMode.stage1FilterLoading), 2);
      }
    }
    else{
      if (operatingMode.stage2FilterLoading === undefined || operatingMode.stage2FilterLoading === null){
        return formatNumber((operatingMode.stage2CleanPressureDrop), 2);
      }
      else{
        return formatNumber((operatingMode.stage2CleanPressureDrop + operatingMode.stage2FilterLoading), 2);
      }
    }

  }
  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string, targetVertex: string) {
    const { projectId, unitId } = this.props.match.params;
    //const filterType = this.props.filterType;
    this.removeValidationError(fieldName);

    let result = await updateInputField("updateFilter", projectId, unitId, fieldName, newValue, targetVertex);

    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.filterInputsList,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------

  public render() {
    if (!this.state.userInputs) {
      return null;
    }

    const outsidePrefilter = this.state.userInputs.find((x: any) => x.filterLocation === "OutsideAirPreFilter");
    const highEfficiencyFilter = this.state.userInputs.find((x: any) => x.filterLocation === "OutsideAirHighEfficiencyFilter");
    let operatingMode = this.state.operatingMode;
    const displayFields = this.state.displayFields;

    //Determine whether to show the update design button.
    let outsidePreFilterClass = displayFields.showOutsideAirPreFilter && displayFields.showHighEfficiencyFilter === false ? "" : "app-display-none";
    let highEfficiencyFilterClass = displayFields.showOutsideAirPreFilter === true ? "" : "app-display-none";

    let disableFilterRemoval;
    let label = "";
    if (displayFields) {
      label = displayFields.isSingleTunnel ? "Supply" : "Outside";
    }
    let title = "";
    //if userInputs has more than one record, there's a preFilter and High efficiency filter.
    if (this.state.userInputs.length > 1) {
      title = `${label} Air Filters`;
      disableFilterRemoval = true; //Always disable filter removal if a preFilter and High Efficiency filter exist.
    } else {
      title = `${label} Air PreFilter`;
      //Both preFilter and High Efficiency do not exist so Use server side logic (property) to determine.
      disableFilterRemoval = displayFields.filterRemovalDisabled;
    }

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">{title}</legend>

        {displayFields.showOutsideAirPreFilter ? (
          <div className="accordion-content">
            <div className="acc-fan-spacer"></div>
            <div className="accordion-row">
              <div className="acc-heading">Pre-Filter</div>
              <div className="acc-fieldset">
                <div className="accordion-label">Loading Level</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("loadingLevel", "accordionselect")}
                    value={outsidePrefilter.loadingLevel || ""}
                    onChange={(event) => this.saveInputValue("loadingLevel", event.currentTarget.value, "OutsideAirPreFilterInputs")}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "loadingLevelOptions")}
                  </select>
                </div>
                {this.getValidationError("loadingLevel")}
              </div>
              <div className="accordion-label-90-left">Type</div>
              <div className="acc-label-ellip acc-label-110">{outsidePrefilter.filterType}</div>
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Filter Removal</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("filterRemoval", "accordionselect")}
                    value={outsidePrefilter.filterRemoval || ""}
                    onChange={(event) => this.saveInputValue("filterRemoval", event.currentTarget.value, "OutsideAirPreFilterInputs")}
                    disabled={disableFilterRemoval}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "filterRemovalOptions")}
                  </select>
                </div>
                {this.getValidationError("filterRemoval")}
              </div>
              <div className="accordion-label">Face Velocity</div>
              <div className="accordion-label-numeric">{formatNumber(operatingMode.faceVelocity, 0)}</div>
            </div>

            <div className="accordion-row">
              <div className="accordion-label"></div>
              <div className="accordion-input"></div>
              <div className="accordion-label">Pressure Drop</div>
              <div className="accordion-label-numeric">{this.GetPressureDrop("Stage1",operatingMode)}</div>
            </div>
            <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin " + outsidePreFilterClass : "perf-reset-btn-small-disabled drawing-update-design-margin " + outsidePreFilterClass}>
              <div onClick={() => this.RunPerformance()}>Update Design</div>
            </div>
            <div className="accordion-row"></div>
          </div>
        ) : null}

        {displayFields.showHighEfficiencyFilter ? (
          <div className="accordion-content">
            <div className="acc-fan-spacer"></div>
            <div className="accordion-row">
              <div className="acc-heading">High Efficiency Filter</div>
              <div className="acc-fieldset">
                <div className="accordion-label">Loading Level</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("loadingLevel", "accordionselect")}
                    value={highEfficiencyFilter.loadingLevel || ""}
                    onChange={(event) => this.saveInputValue("loadingLevel", event.currentTarget.value, "OutsideAirHighEfficiencyFilterInputs")}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "loadingLevelOptions")}
                  </select>
                </div>
                {this.getValidationError("loadingLevel")}
              </div>
              <div className="accordion-label-90-left">Type</div>
              <div className="acc-label-ellip acc-label-110">{highEfficiencyFilter.filterType}</div>
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Filter Removal</div>
                <div className="accordion-input">
                  <select
                    className={this.getCSS("filterRemoval", "accordionselect")}
                    value={highEfficiencyFilter.filterRemoval || ""}
                    onChange={(event) => this.saveInputValue("filterRemoval", event.currentTarget.value, "OutsideAirHighEfficiencyFilterInputs")}
                    disabled={disableFilterRemoval}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "filterRemovalOptions")}
                  </select>
                </div>
                {this.getValidationError("filterRemoval")}
              </div>
              <div className="accordion-label">Face Velocity</div>
              <div className="accordion-label-numeric">{formatNumber(operatingMode.faceVelocity, 0)}</div>
            </div>

            <div className="accordion-row">
              <div className="accordion-label"></div>
              <div className="accordion-input"></div>
              <div className="accordion-label">Pressure Drop</div>
              <div className="accordion-label-numeric">{this.GetPressureDrop("Stage2",operatingMode)}</div>
            </div>
            <div className={this.props.reduxMenus.optimizedDesignInputChanged ? "perf-reset-btn-small drawing-update-design-margin " + highEfficiencyFilterClass : "perf-reset-btn-small-disabled drawing-update-design-margin " + highEfficiencyFilterClass}>
              <div onClick={() => this.RunPerformance()}>Update Design</div>
            </div>
            <div className="accordion-row"></div>
          </div>
        ) : null}
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CombinedFilter);
