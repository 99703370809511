import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { updateUnitDesign } from "../../../api-utilities/performanceUpdate";
import { mapDispatchToProps } from "../../../redux/actions";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { callGetEndpoint, updateInputField } from "../../../api-calls/readApiService";
import { isEmptyObject } from "../../../utilities/helpers";
import { addNotification } from "../../../api-utilities/notification-tools";
import { formatNumber } from "../../../api-utilities/formatting";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  match: match<any>;
  updateRedux: any;
  reduxMenus: any;
  dxType: string;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  hasHotGasReheatCoil: boolean;
  dataContainer: any;
}

class PackagedAc extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    hasHotGasReheatCoil: false,
    dataContainer: null
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await callGetEndpoint("readapi/GetRefrigerationInputValues", projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError) {
      const notification = {
        id: "refrigerationInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetRefrigerationInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.refrigerationInputs) {
      this.setState({
        userInputs: result.uiDataContainer.refrigerationInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        hasHotGasReheatCoil: result.uiDataContainer.hotGasReheatCoil != null ? true : false,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
  }
  private async RunPerformance() {
    const { projectId, unitId } = this.props.match.params;

    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    //Run performance to update read-only props for coils and update optimized design numbers.
    let perResponse = await updateUnitDesign(projectId, unitId);
    const result = await callGetEndpoint("readapi/GetRefrigerationInputValues", projectId, unitId);

    if (result.hasError) {
      const notification = {
        id: "refrigerationInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">GetRefrigerationInputValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.uiDataContainer && result.uiDataContainer.refrigerationInputs) {
      this.setState({
        userInputs: result.uiDataContainer.refrigerationInputs,
        displayFields: result.uiDataContainer.displayFields,
        dropdownOptions: result.dropdownOptions,
        validationErrors: result.validationErrors,
        hasHotGasReheatCoil: result.uiDataContainer.hotGasReheatCoil != null ? true : false,
        dataContainer: result.uiDataContainer //contains all the extra data fields
      });
    }
    this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: false });
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);

    let result = await updateInputField("updateRefrigeration", projectId, unitId, fieldName, newValue, "RefrigerationInputs");
    if (result.success) {
      this.props.updateRedux("UPDATE_MENUS", { optimizedDesignInputChanged: true });
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.refrigerationInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------
  private showActualCompressorType() {
    if (this.state.userInputs.compressorType === "Automatic") {
      return true;
    }
    return false;
  }
  public async updateCheckbox(propertyName: string, newValue: any) {
    await this.saveInputValue(propertyName, newValue.toString());
  }

  private getTitle() {
    if (this.props.dxType == "packaged") {
      return "Refrigeration - Packaged Air Cooled";
    }
    if (this.props.dxType == "packagedHgrh") {
      return "Refrigeration - Packaged Air Cooled w/HGRH";
    }
    if (this.props.dxType == "split") {
      return "Refrigeration - Split System (Remote Compressors/Condensers by Others)";
    }
    return "Refrigeration";
  }

  //-----------------------------------------------------------------------------------------------
  public render() {
    let dataContainer = this.state.dataContainer;
    if (isEmptyObject(dataContainer)) {
      return null;
    }

    const dxCoil = dataContainer.dxCoil;
    let dxCoilOpMode = dataContainer.dxCoilOpMode;
    const hotGasReheatCoil = dataContainer.hotGasReheatCoil;
    const hgrhCoilOpMode = dataContainer.hgrhCoilOpMode;

    //dxCoil object has to exist. HGRH is optional.
    if (isEmptyObject(dxCoil)) {
      return null;
    }

    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;
    if (!savedValues) {
      return null;
    }

    if (isEmptyObject(dxCoilOpMode)) {
      const notification = {
        id: "coilOperatingModeError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">DX Coil operating mode not found.</div>
      };
      addNotification(notification);
      dxCoilOpMode = {};
    }

    let disableCircuits = false; //dxcoil.forceCircuitQuantityToTwo;

    //Determine whether to show the update design button.
    let showOrHideUpdate = this.state.hasHotGasReheatCoil ? "app-display-none" : "";

    return (
      <fieldset className="optimize-fieldset">
        <legend className="optimize-legend">{this.getTitle()}</legend>

        <div className="">
          <div className="accordion-content">
            <div className="refrig-spacer"></div>
            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label">Circuit Quantity</div>
                <div className="accordion-input">
                  <select
                    disabled={disableCircuits}
                    className={this.getCSS("circuitQuantity", "accordionselect")}
                    value={savedValues.circuitQuantity}
                    onChange={(event) => this.saveInputValue("circuitQuantity", event.currentTarget.value)}
                  >
                    {getSelectBoxOptions(this.state.dropdownOptions, "circuitQuantityOptions")}
                  </select>
                </div>
                {this.getValidationError("circuitQuantity")}
              </div>
              {displayFields.showCircuitQuantity ? (
                <>
                  <div className="accordion-label">Circuit Quantity</div>
                  <div className="accordion-label-numeric">{dataContainer.circuitQuantity}</div>
                </>
              ) : null}
            </div>

            <div className="accordion-row">
              <div className="acc-fieldset">
                <div className="accordion-label"></div>
                <div className="accordion-input"></div>
              </div>
              <div className="accordion-label">Cooling Tons</div>
              <div className="accordion-label-numeric">{formatNumber(dataContainer.coolingTons, 1)}</div>
            </div>

            {this.props.dxType == "split" ? null : (
              <div className="accordion-row">
                <div className="acc-fieldset">
                  <div className="accordion-label">Ambient Temp</div>
                  <div className="accordion-input">
                    <input
                      type="text"
                      className={this.getCSS("ambientTemp", "accordiontextbox")}
                      value={savedValues.ambientTemp}
                      onChange={(event) => this.updateTextboxValue("ambientTemp", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("ambientTemp", event.currentTarget.value)}
                    />
                  </div>
                  {this.getValidationError("ambientTemp")}
                </div>

                <div className="accordion-label">EER</div>
                <div className="accordion-label-numeric">{formatNumber(dataContainer.energyEfficiencyRatio, 1)}</div>
              </div>
            )}
            {this.props.dxType == "split" ? null : (
              <div className="accordion-row">
                <div className="acc-fieldset">
                  <div className="accordion-label">Compressor Type</div>
                  <div className="accordion-input">
                    <select
                      className={this.getCSS("compressorType", "accordionselect")}
                      value={savedValues.compressorType}
                      onChange={(event) => this.saveInputValue("compressorType", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "compressorTypeOptions")}
                    </select>
                  </div>
                  {this.getValidationError("compressorType")}
                </div>
                {this.showActualCompressorType() ? (
                  <>
                    <div className="accordion-label">Compressor Type</div>
                    <div className="accordion-input-100">{dataContainer.actualCompressorType}</div>
                  </>
                ) : null}
              </div>
            )}
            <div className="refrig-divider"></div>

            {/* /** =================== DIRECT EXPANSION COIL SECTION ========================== **/}
            <div className="refrig-dxcoil">
              <div className="accordion-title">Direct Expansion Coil</div>
              <div className="accordion-row">
                <div className="accordion-label">Entering Air DB</div>
                <div className="accordion-input">
                  <div className="accordion-label-numeric2">{formatNumber(dxCoilOpMode.enteringDryBulb, 1)}</div>
                </div>
                <div className="accordion-label">Rows</div>
                <div className="accordion-label-numeric">{dxCoil.rows}</div>
              </div>
              <div className="accordion-row">
                <div className="accordion-label">Entering Air WB</div>
                <div className="accordion-input">
                  <div className="accordion-label-numeric2">{formatNumber(dxCoilOpMode.enteringWetBulb, 1)}</div>
                </div>
                {/* <div className="accordion-label">Leaving Air WB</div>
                <div className="accordion-label-numeric">{dxcoil.leavingAirWetBulb}</div> */}
                <div className="accordion-label">Fins Per Inch</div>
                <div className="accordion-label-numeric">{dxCoil.finsPerInch}</div>
              </div>
              <div className="accordion-row">
                <div className="accordion-label">Leaving Air DB</div>
                <div className="accordion-input">
                  <div className="accordion-label-numeric2">{formatNumber(dxCoilOpMode.leavingDryBulb, 1)}</div>
                </div>
                <div className="accordion-label">Air Pressure Drop</div>
                <div className="accordion-label-numeric">{formatNumber(dxCoilOpMode.airPressureDrop, 2)}</div>
              </div>
              <div className="accordion-row">
                <div className="accordion-label">Leaving Air WB</div>
                <div className="accordion-input">
                  <div className="accordion-label-numeric2">{formatNumber(dxCoilOpMode.leavingWetBulb, 1)}</div>
                </div>
                <div className="accordion-label"></div>
                <div className="accordion-label-numeric"></div>
              </div>
              {
                //TODO: figure out a way to implement this
                this.props.dxType === "split" ? (
                  <div className="accordion-row">
                    <div className="acc-fieldset">
                      <div className="accordion-label">Suction Temp</div>
                      <div className="accordion-input">
                        <input
                          type="text"
                          className={this.getCSS("dxSuctionTemperature", "accordiontextbox")}
                          value={formatNumber(savedValues.dxSuctionTemperature, 1)}
                          onChange={(event) => this.updateTextboxValue("dxSuctionTemperature", event.currentTarget.value)}
                          onBlur={(event) => this.saveTextboxValue("dxSuctionTemperature", event.currentTarget.value)}
                        />
                      </div>
                      {this.getValidationError("dxSuctionTemperature")}
                    </div>
                  </div>
                ) : null
              }
              <div className="accordion-row"></div>
              {/* <div className="acc-show-more">
                <span className="acc-show-txt" onClick={() => this.toggleMoreFields()}>
                  {this.state.openMoreFields ? 'Show less...' : 'Show more...'}
                </span>
              </div> */}
              <div className="acc-spacer"></div>
              <div className="">
                <div className="accordion-row">
                  <div className="acc-fieldset">
                    <div className="accordion-label">Rows Override</div>
                    <div className="accordion-input">
                      <select
                        className={this.getCSS("dxRows", "accordionselect")}
                        value={savedValues.dxRows || 0}
                        onChange={(event) => this.saveInputValue("dxRows", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "dxRowsOptions")}
                      </select>
                    </div>
                    {this.getValidationError("dxRows")}
                  </div>

                  <div className="acc-fieldset">
                    <div className="accordion-label">Tube Thickness</div>
                    <div className="accordion-input-100">
                      <select
                        className={this.getCSS("dxTubeWallThickness", "accordionselect")}
                        value={savedValues.dxTubeWallThickness}
                        onChange={(event) => this.saveInputValue("dxTubeWallThickness", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "dxTubeWallThicknessOptions")}
                      </select>
                    </div>
                    {this.getValidationError("dxTubeWallThickness")}
                  </div>
                </div>

                <div className="accordion-row">
                  <div className="acc-fieldset">
                    <div className="accordion-label">Fins per Inch Override</div>
                    <div className="accordion-input">
                      <select
                        className={this.getCSS("dxFinsPerInch", "accordionselect")}
                        value={savedValues.dxFinsPerInch || 0}
                        onChange={(event) => this.saveInputValue("dxFinsPerInch", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "dxFinsPerInchOptions")}
                      </select>
                    </div>
                    {this.getValidationError("dxFinsPerInch")}
                  </div>

                  <div className="acc-fieldset">
                    <div className="accordion-label">Fin Material</div>
                    <div className="accordion-input-100">
                      <select
                        className={this.getCSS("dxFinMaterial", "accordionselect")}
                        value={savedValues.dxFinMaterial}
                        onChange={(event) => this.saveInputValue("dxFinMaterial", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "dxFinMaterialOptions")}
                      </select>
                    </div>
                    {this.getValidationError("dxFinMaterial")}
                  </div>
                </div>

                <div className="accordion-row">
                  <div className="accordion-label">Rifled Tubes</div>
                  <div className="accordion-input">
                    <input
                      className=""
                      type="checkbox"
                      checked={savedValues.dxRifledTubes}
                      onChange={(event) => this.updateCheckbox("dxRifledTubes", event.currentTarget.checked)}
                    />
                  </div>
                  <div className="acc-fieldset">
                    <div className="accordion-label">Fin Thickness</div>
                    <div className="accordion-input-100">
                      <select
                        className={this.getCSS("dxFinThickness", "accordionselect")}
                        value={savedValues.dxFinThickness}
                        onChange={(event) => this.saveInputValue("dxFinThickness", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "dxFinThicknessOptions")}
                      </select>
                    </div>
                    {this.getValidationError("finThickness")}
                  </div>
                </div>
                <div
                  className={
                    this.props.reduxMenus.optimizedDesignInputChanged
                      ? "perf-reset-btn-small drawing-update-design-margin " + showOrHideUpdate
                      : "perf-reset-btn-small-disabled drawing-update-design-margin " + showOrHideUpdate
                  }
                >
                  <div onClick={() => this.RunPerformance()}>Update Design</div>
                </div>
                <div className="acc-spacer"></div>
              </div>
            </div>

            {/* /** =================== HOT GAS REHEAT COIL SECTION ========================== **/}
            {this.state.hasHotGasReheatCoil ? (
              <>
                <div className="refrig-divider"></div>

                <div className="refrig-dxcoil">
                  <div className="accordion-title">Hot Gas Reheat Coil</div>

                  <div className="acc-coil-spacer"></div>

                  {/* TODO: get this working again */}
                  {/* {getErrorNotifications(this.props.reduxHotGasReheat.errorMessages)} */}

                  <div className="accordion-row">
                    <div className="accordion-label">Entering Air Temp</div>
                    <div className="accordion-input">
                      <div className="accordion-label-numeric2">{formatNumber(hgrhCoilOpMode.enteringDryBulb, 1)}</div>
                    </div>
                    <div className="accordion-label">Rows</div>
                    <div className="accordion-label-numeric">{hotGasReheatCoil.rows}</div>
                  </div>

                  <div className="accordion-row">
                    <div className="accordion-label">Leaving Air Temp</div>
                    <div className="accordion-input">
                      <div className="accordion-label-numeric2">{formatNumber(hgrhCoilOpMode.leavingDryBulb, 1)}</div>
                    </div>
                    <div className="accordion-label">Fins Per Inch</div>
                    <div className="accordion-label-numeric">{hotGasReheatCoil.finsPerInch}</div>
                  </div>

                  <div className="accordion-row">
                    <div className="accordion-label">Reheat Circuits</div>
                    <div className="accordion-input">
                      <div className="accordion-label-numeric2">{hotGasReheatCoil.circuitQuantity}</div>
                    </div>
                    <div className="accordion-label">Air Pressure Drop</div>
                    <div className="accordion-label-numeric">{formatNumber(hgrhCoilOpMode.airPressureDrop, 2)}</div>
                  </div>

                  <div className="accordion-row"></div>

                  <div className="acc-spacer"></div>

                  <div className="accordion-row">
                    <div className="acc-fieldset">
                      <div className="accordion-label">Rows Override</div>
                      <div className="accordion-input">
                        <select
                          className={this.getCSS("hgrhRows", "accordionselect")}
                          value={savedValues.hgrhRows || ""}
                          onChange={(event) => this.saveInputValue("hgrhRows", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "hgrhRowsOptions")}
                        </select>
                      </div>
                      {this.getValidationError("hgrhRows")}
                    </div>

                    <div className="acc-fieldset">
                      <div className="accordion-label">Tube Thickness</div>
                      <div className="accordion-input-100">
                        <select
                          className={this.getCSS("hgrhTubeWallThickness", "accordionselect")}
                          value={savedValues.hgrhTubeWallThickness}
                          onChange={(event) => this.saveInputValue("hgrhTubeWallThickness", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "hgrhTubeWallThicknessOptions")}
                        </select>
                      </div>
                      {this.getValidationError("hgrhTubeWallThickness")}
                    </div>
                  </div>

                  <div className="accordion-row">
                    <div className="acc-fieldset">
                      <div className="accordion-label">Fins per Inch Override</div>
                      <div className="accordion-input">
                        <select
                          className={this.getCSS("hgrhFinsPerInch", "accordionselect")}
                          value={savedValues.hgrhFinsPerInch}
                          onChange={(event) => this.saveInputValue("hgrhFinsPerInch", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "hgrhFinsPerInchOptions")}
                        </select>
                      </div>
                      {this.getValidationError("hgrhFinsPerInch")}
                    </div>
                    <div className="acc-fieldset">
                      <div className="accordion-label">Fin Material</div>
                      <div className="accordion-input-100">
                        <select
                          className={this.getCSS("hgrhFinMaterial", "accordionselect")}
                          value={savedValues.hgrhFinMaterial}
                          onChange={(event) => this.saveInputValue("hgrhFinMaterial", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "hgrhFinMaterialOptions")}
                        </select>
                      </div>
                      {this.getValidationError("hgrhFinMaterial")}
                    </div>
                  </div>

                  <div className="accordion-row">
                    <div className="accordion-label"></div>
                    <div className="accordion-input"></div>

                    <div className="acc-fieldset">
                      <div className="accordion-label">Fin Thickness</div>
                      <div className="accordion-input-100">
                        <select
                          className={this.getCSS("hgrhFinThickness", "accordionselect")}
                          value={savedValues.hgrhFinThickness}
                          onChange={(event) => this.saveInputValue("hgrhFinThickness", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "hgrhFinThicknessOptions")}
                        </select>
                      </div>
                      {this.getValidationError("hgrhFinThickness")}
                    </div>
                  </div>
                  <div
                    className={
                      this.props.reduxMenus.optimizedDesignInputChanged
                        ? "perf-reset-btn-small drawing-update-design-margin"
                        : "perf-reset-btn-small-disabled drawing-update-design-margin"
                    }
                  >
                    <div onClick={() => this.RunPerformance()}>Update Design</div>
                  </div>
                  <div className="acc-spacer"></div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </fieldset>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxValidation: state.reduxValidation,
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PackagedAc);
