import { store } from '../redux/store';
import { displayError} from '../api-utilities/api-calls';
import {getAuthToken} from '../api-calls/projectDataService';
//This file contains calls to the LOWRY Backend DataController

export async function generateSpecMateDocuments(projectId:number, unitIds:Array<number>, submittalCoverSheet:boolean, 
    performanceDataSummary:boolean, unitDrawing:boolean, salesGuidSpecification:boolean, salesScope:boolean, versionTemplate:string){

    const apibase = process.env.REACT_APP_CLIENT_API;
    const token = await getAuthToken();
    let response;
    const specMateDocumentsRequest = {
        ProjectID: projectId,
        UnitIDs: unitIds,
        SubmittalCoverSheet: submittalCoverSheet,
        PerformanceDataSummary: performanceDataSummary,
        UnitDrawing: unitDrawing,
        SalesGuidSpecification: salesGuidSpecification,
        SalesScope: salesScope,
        VersionTemplate: versionTemplate
        };
    try {
        const result = await fetch(apibase + '/dataapi/generatespecmatedocuments', {
            method: 'post',
            body: JSON.stringify(specMateDocumentsRequest),
            headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
        });
        response = {
            //Handles the call from pdf Service
            success: result.status === 200 ? true:false,
            data: result.status === 200 ? await result.blob() : await result.json()
        };
    
        //If not success then display error.
        if(!response.success){
            let errorObj = {
                status: result.status,
                message: response.data.pdfResponse.errorMessage
            }
            if (response.data.pdfResponse.errorMessage.includes("A task was canceled")){
                //The ui will handle this response by popping up a toast notification.
                return response;
            }
            displayError(errorObj, "generatespecmatedocuments");
            return;
        }
        return response;
    }
    catch (error){
        //The ui will handle any caught error by popping up a toast notification.
        response = {
            success: false,
            data: "show try again"
        };
        return response;
    }
}


export async function submitPdfProcessingRequest(pdfProcessingRequest:any){

    const apibase = process.env.REACT_APP_CLIENT_API;
    const token = await getAuthToken();
    let response;

    try {
        const result = await fetch(apibase + '/dataapi/addPdfProcessingRequest', {
            method: 'post',
            body: JSON.stringify(pdfProcessingRequest),
            headers: { 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}` }
        });
        response = {
            //Handles the call from pdf Service
            success: result.status === 200 ? true:false,
            data: result.status === 200 ? await result.json() : await result.text()
        };
    
        //If not success then display error.
        if(!response.success){
            let errorObj = {
                status: result.status,
                message: response.data.errorMessage
            }
            displayError(errorObj, "submitPdfProcessingRequest");
            return;
        }
        return response;
    }
    catch (error){
        displayError(error, 'submitPdfProcessingRequest');
    }
}



export async function getPdfProcessingRecords(projectId:any) {
    const apibase = process.env.REACT_APP_CLIENT_API;
    const auth = store.getState().reduxAuth;
    const token = await getAuthToken();
    let response;
    try {
      const result = await fetch(apibase + '/dataapi/getPdfProcessingRecords/' + projectId, {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });
      response = {
        //Handles the call from pdf Service
        success: result.status === 200 ? true:false,
        data: result.status === 200 ? await result.json() : await result.text()
        };
        //If not success then display error.
        if(!response.success){
            let errorObj = {
                status: result.status,
                message: response.data.errorMessage
            }
            displayError(errorObj, "getPdfProcessingRecords");
            return;
        }
        return response;
    }
    catch (error){
        displayError(error, 'getPdfProcessingRecords');
    }
  }


