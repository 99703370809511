import * as React from "react";
import { connect } from "react-redux";
import { Route, match } from "react-router-dom";
import { mapDispatchToProps } from "../redux/actions";
import OutputsSubMenu from "./Menus/OutputsSubMenu";
import PdfOutputs from "./Outputs/PdfOutputs";
import CadOutputs from "./Outputs/CadOutputs";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  reduxMenus: any;
}
// local state
interface IState {}

class Outputs extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.updateRedux("UPDATE_MENUS", { enableUnitIcons: true });
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 6 } });
  }

  public render() {
    return (
      <div className="app-body-content">
        <OutputsSubMenu></OutputsSubMenu>
        <Route path="/pid/:projectId/uid/:unitId/outputs/pdfoutputs" component={PdfOutputs} />
        <Route path="/pid/:projectId/uid/:unitId/outputs/cadoutputs" component={CadOutputs} />
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Outputs);
