export const updateReduxAction = (actionType: string, data: any) => {
  return {
    type: actionType,
    newState: data
  };
};
export function mapDispatchToProps(dispatch: any) {
  return {
    updateRedux: (actionType: string, data: object) => dispatch(updateReduxAction(actionType, data))
  };
}
