import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import "../../css/UnitCopyModal.scss";
// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
  reduxProjectManagement: any;
}
// local state
interface IState {
  unitTagName: string;
  sourceProjectId: string;
  targetProjectId: string;
}

class UnitCopyModal extends React.Component<IProps, IState> {
  public state: IState = { unitTagName: "", sourceProjectId: "", targetProjectId: "" };

  //This is a specific modal for copying a unit to the project selected by the user.
  componentDidMount() {
    console.log(this.props.reduxModals.modalData);
    console.log(this.props.reduxProjectManagement.projectList);
    //return;

    let currentUnit = this.props.reduxModals.modalData;
    let projectList = this.props.reduxProjectManagement.projectList;
    let selectedProject = this.props.reduxProjectManagement.selectedProject;

    //Default Unit tag name to "copy of...current unit tag"
    //Default seleted project to same as current project
    //...User may edit both.
    let unitTag = "Copy of " + currentUnit.unitTag;
    this.setState({ unitTagName: unitTag, sourceProjectId: selectedProject.id, targetProjectId: selectedProject.id });
  }
  public render() {
    let disabled = this.state.unitTagName === "" || this.state.targetProjectId === "" ? "disabledDiv" : "";

    return (
      <div className="copyUnit">
        <div className="header">Copy Unit</div>
        <div className="unitName">
          <label className="unitModalLabel">Unit Tag</label>
          <input
            className="unitTagInput"
            type="text"
            placeholder="Unit Tag Name"
            value={this.state.unitTagName}
            onChange={(event) => this.setState({ unitTagName: event.currentTarget.value })}
          />
        </div>
        <legend className="unitCopyLegend">Select Job to Copy to</legend>

        <div className="projectChoices">{this.getProjectChoices()}</div>
        <div className="button-container">
          <div className={"proj-create-btn " + disabled} onClick={() => this.userResponse("ok")}>
            Copy
          </div>
          <div className="proj-cancel-btn" onClick={() => this.userResponse("cancel")}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
  private getProjectChoices() {
    //Show radio button for each project.
    let projectList = this.props.reduxProjectManagement.projectList;

    //Find the project this unit belongs to, and show it at top of the list,
    //because most times, users will copy a unit to the same project. (Per Dave)
    let currentUnit = this.props.reduxModals.modalData;
    let currentProject = this.props.reduxProjectManagement.selectedProject;
    //let currentProject = projectList.find((x: any) => x.id === currentUnit.projectID);

    //Create an ordered list, with the currentProject at the top
    let orderedProjectList = [];
    orderedProjectList.push(currentProject);

    //Add the rest of the projects, but don't add currentProject twice
    for (const proj of projectList) {
      if (proj.id !== currentProject.id) {
        orderedProjectList.push(proj);
      }
    }

    return orderedProjectList.map((item: any, index: number) => (
      <div key={index}>
        <input
          type="radio"
          name="project"
          value={item.projectName}
          checked={item.id === this.state.targetProjectId ? true : false}
          onChange={() => this.updateProjectSelected(item.id)}
        />
        <div className="divChoices">{item.projectName}</div>
      </div>
    ));
  }

  //Select the project to copy to
  private updateProjectSelected(projectId: string) {
    console.log("seletedProjectId:" + projectId);
    this.setState({ targetProjectId: projectId });
  }

  private userResponse(response: string) {
    let copyUnitResponse = {
      response: response,
      sourceProjectId: this.state.sourceProjectId,
      targetProjectId: this.state.targetProjectId,
      unitTagName: this.state.unitTagName,
      unitId: this.props.reduxModals.modalData.id
    };
    //This will trigger the componentDidUpdate method on ProjectManagement.tsx
    this.props.updateRedux("UPDATE_MODALS", { showModal: false, userResponse: copyUnitResponse });
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxProjectManagement: state.reduxProjectManagement,
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitCopyModal);
