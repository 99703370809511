import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import imgLogo from "../images/specmatelogo.png";
import ModalContainer from "./Modals/ModalContainer";
import TopMenu from "./Menus/TopMenu";
import UnitDesign from "./UnitDesign";
import Drawings from "./Drawings";
import UnitLayout from "./UnitLayout";
import ProjectManagement from "./Project/ProjectManagement";
import SpecItems from "./SpecItems";
import Outputs from "./Outputs";
import Pricing from "./Pricing";
import LoginStart from "./Auth/LoginStart";
import IdentityLogin from "./Auth/IdentityLogin";
import IdentityLogout from "./Auth/IdentityLogout";
import TopRightMenu from "./Menus/TopRightMenu";
import TopRightPricing from "./Menus/TopRightPricing";
import ProjectHeader from "./Menus/ProjectHeader";
import ProjectManagementHeader from "./Menus/ProjectManagementHeader";
import ServerError from "./Modals/ServerError";
import ModalNotifications from "./Modals/ModalNotifications";
import Loading from "./Shared/Loading";
import { mapDispatchToProps } from "../redux/actions";
import { IdentityService } from "../security/IdentityService";
import IdentityContext from "../security/IdentityContext";
import PrivateRoute from "./PrivateRoute";
import { getAppVersionNumber } from "../api-calls/systemDataService";
import { getPdfProcessingRecords } from "../api-calls/pdfGeneratorService";
import "../css/App.scss";
import "../css/TopMenu2.scss";

// expected props
interface IProps {
  updateRedux: any;
  reduxAppVersion: any;
  reduxOutputDocumentsInfo: any;
}
// local state
interface IState {}

class App extends React.Component<IProps, IState> {
  timer: any; //define a timer variable for our SetTimeout
  private readonly identityService = new IdentityService();

  async componentDidMount() {
    //Check if there's a user id_token in localstorage.  If so, extract all the info from it and load back into reduxAuth.
    //This needs to be done to reload the userId, access_token, etc. back into redux if the site reloads.
    const user = await this.identityService.getUser();
    //console.log(user);

    if (user && user.profile) {
      await this.identityService.loadUserInfoToRedux(user);
    }

    //Get app version number
    let response = await getAppVersionNumber();
    if (response && !response.hasError) {
      let version = response.appVersion;
      if (version != null) {
        this.props.updateRedux("UPDATE_APP_VERSION", { appVersion: version });
      }
    }
  }

  async componentDidUpdate(prevProps: any) {
    if (prevProps.reduxOutputDocumentsInfo.projectId !== this.props.reduxOutputDocumentsInfo.projectId) {
      clearTimeout(this.timer);
    }

    if (
      this.props.reduxOutputDocumentsInfo.projectId !== null &&
      prevProps.reduxOutputDocumentsInfo.pollForUpdates === false &&
      this.props.reduxOutputDocumentsInfo.pollForUpdates === true
    ) {
      await this.getPDFRecordsAndConfigurePolling();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  private async getPDFRecordsAndConfigurePolling() {
    //console.log('GettingData From App page projectDataAPI');
    const previousActiveRecordsCount = this.props.reduxOutputDocumentsInfo.activeProcessingRecordsCount;
    const previousShowDocsReadyNotificationIcon = this.props.reduxOutputDocumentsInfo.showDocsReadyNotificationIcon;
    const isOnOutputPage = this.props.reduxOutputDocumentsInfo.isOutputPage;
    const response = await getPdfProcessingRecords(this.props.reduxOutputDocumentsInfo.projectId);
    if (!response?.data.hasError) {
      //Check if any records are actively running (status not equal to completed or failed)
      let dtos = response?.data.pdfProcessingDTOs;
      let activeRecords = dtos.filter(
        (item: any) => item.processingStatusName.toLowerCase() !== "completed" && item.processingStatusName.toLowerCase() !== "failed"
      );
      this.props.updateRedux("UPDATE_ACTIVEOUTPUTDOCUMENTS", {
        pdfRecords: dtos,
        activeProcessingRecordsCount: activeRecords.length,
        pollForUpdates: activeRecords.length > 0 ? true : false,
        showDocsReadyNotificationIcon:
          (previousShowDocsReadyNotificationIcon || activeRecords.length < previousActiveRecordsCount) && !isOnOutputPage ? true : false
      });
      //console.log(this.props.reduxOutputDocumentsInfo.showDocsReadyNotificationIcon);
      await this.PollForStatusUpdates();
    }
  }

  private async PollForStatusUpdates() {
    if (this.props.reduxOutputDocumentsInfo.pollForUpdates == true) {
      clearTimeout(this.timer);
      //poll for updates recursively
      this.timer = setTimeout(() => this.getPDFRecordsAndConfigurePolling(), 5000);
    } else {
      clearTimeout(this.timer);
    }
  }

  public render() {
    return (
      <BrowserRouter>
        {/* This line loads the IdentityService instance into React "Context" object and wraps
            the whole app in that context.  Every component will have access to the context. */}
        <IdentityContext.Provider value={this.identityService}>
          <div className="app">
            <ModalContainer />

            <header className="app-fixed-header">
              <div className="app-header-table">
                <div className="app-header-td1">
                  <img src={imgLogo} className="app-logo-img" alt="logo" />
                </div>
                <div className="app-header-td2">
                  <ProjectHeader />
                </div>
                <div className="app-header-td3">
                  <TopRightMenu />
                </div>
                <div className="app-header-td4">
                  {/** Pricing only appears for pages that contain /pid in the route **/}
                  <Route path="/pid/:projectId/uid/:unitId" component={TopRightPricing} />
                </div>
              </div>
            </header>

            <div className="app-chevron-bar">
              {/** Chevron TopMenu bar only appears for pages that contain /pid in the route **/}
              <Route path="/pid/:projectId/uid/:unitId" component={TopMenu} />

              {/** Special header for Project Management page with all inactive chevron links **/}
              <Route path="/project" component={ProjectManagementHeader} />
            </div>

            <div className="app-body">
              <div className="app-body-top"></div>

              <Route path="/" exact component={LoginStart} />
              <Route path="/loginstart" exact component={LoginStart} />

              <Route path="/login" exact component={IdentityLogin} />
              <Route path="/logout" exact component={IdentityLogout} />

              {/* Routes the user cannot get to unless they're logged in. */}
              {/* NOTE: PrivateRoute.tsx is a custom component we built to handle these routes */}
              <Switch>
                <PrivateRoute path="/project" component={ProjectManagement} />
                <PrivateRoute path="/pid/:projectId/uid/:unitId/drawing" component={Drawings} />
                <PrivateRoute path="/pid/:projectId/uid/:unitId/design" component={UnitDesign} />
                <PrivateRoute path="/pid/:projectId/uid/:unitId/layout" component={UnitLayout} />
                <PrivateRoute path="/pid/:projectId/uid/:unitId/spec" component={SpecItems} />
                <PrivateRoute path="/pid/:projectId/uid/:unitId/outputs" component={Outputs} />
                <PrivateRoute path="/pid/:projectId/uid/:unitId/pricing" component={Pricing} />
              </Switch>

              <div className="app-body-bottom"></div>
            </div>

            <section className="app-footer">
              <div className="app-footer-content">Version {this.props.reduxAppVersion.appVersion}</div>
            </section>
            <ModalNotifications />
            <ServerError />
            <Loading />
          </div>
        </IdentityContext.Provider>
      </BrowserRouter>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxAppVersion: state.reduxAppVersion,
    reduxOutputDocumentsInfo: state.reduxOutputDocumentsInfo
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
