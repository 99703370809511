import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../redux/actions";
import AccordionHeader from "../Shared/AccordionHeader";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { getBMSInputValues, updateInputField } from "../../api-calls/readApiService";
import {getValidationError, getChecked, getInputCSS, getFilteredValidationErrors, getNewValidationErrorsList} from "../../utilities/pageUtils";
import { isEmptyObject } from "../../utilities/helpers";
import "../../css/Accordions.scss";

// expected props
interface IProps {
    match: match<any>;
    reduxMenus: any;
    updateRedux: any;
 }
  // local state
  interface IState {
    componentData: any;
    userInputs: any;
    bmsDisplayFields: any;
    validationErrors: any[];
    lastUpdatedTextbox: string;
    openPointsAccordion: boolean;
  }

class BMSSetPoints extends React.Component<IProps, IState> {
    public state: IState = {
        componentData: null,
        userInputs: null,
        bmsDisplayFields: null,
        validationErrors: [],
        lastUpdatedTextbox: "",
        openPointsAccordion: false
      };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await getBMSInputValues(projectId, unitId);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.hasError){
        const notification = {
          id: "bmsPointsInputsError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">GetBMSInputValues {result.errorMessage}</div>
        };
        addNotification(notification);
    }

    if (!isEmptyObject(result.uiDataContainer)) {
        this.setState({
            userInputs: result.uiDataContainer.controlsInputs,
            bmsDisplayFields: result.uiDataContainer.bmsDisplayFields,
            validationErrors: result.validationErrors
          });
    }

  }
  async componentDidUpdate(prevProps:any){
    //When an input on the controls page changes and it affects what is shown as far as setpoints and additional BMS points
    //we need to close the setpoints accordian so that when they re-open it, it can go to the database and refresh it's values
    //and display fields.  closeSetPointsAccordian is set to true on the details and inclusion pages for any input that affects
    //set points and if it's true here, we close the accordian.
    if (prevProps.reduxMenus.closeSetPointsAccordian !== this.props.reduxMenus.closeSetPointsAccordian && this.props.reduxMenus.closeSetPointsAccordian === true && 
        this.state.openPointsAccordion === true) {
        this.togglePointsPanel();
        this.props.updateRedux('UPDATE_MENUS', {closeSetPointsAccordian : false});
        console.log("Open Accordian");
    }
    else if (prevProps.reduxMenus.closeSetPointsAccordian !== this.props.reduxMenus.closeSetPointsAccordian && this.props.reduxMenus.closeSetPointsAccordian === true) {
        this.props.updateRedux('UPDATE_MENUS', {closeSetPointsAccordian : false});
    }
    //The "ProvidedByAndInstalledBy" field is special because it toggles whether or not the setpoints accordian shows or not.
    //On the inclusion page for this control, if a user changes the value then we know that we need to save to the database
    //and get new values and display fields for setpoints.  The display field "show_any" determines whether to show or hide
    //the setpoints accordian. "Provided by Others and Installed by Others" means we hide the setpoints accordian.
    if (prevProps.reduxMenus.providedAndInstalledByChanged !== this.props.reduxMenus.providedAndInstalledByChanged && 
        this.props.reduxMenus.providedAndInstalledByChanged === true) {
          const { projectId, unitId } = this.props.match.params;
          //Get updated BMS values and display fields.
          const result = await getBMSInputValues(projectId, unitId);

          if (result.hasError){
            const notification = {
              id: "BMSPointsInputsError",
              notificationType: "error",
              title: "Error",
              content: <div className="nf-div">GetBMSInputValues {result.errorMessage}</div>
            };
            addNotification(notification);
        }
          this.setState({
            userInputs: result.uiDataContainer.controlsInputs,
            bmsDisplayFields: result.uiDataContainer.bmsDisplayFields,
            validationErrors: result.validationErrors
          });
          this.props.updateRedux('UPDATE_MENUS', {providedAndInstalledByChanged : false});      
      }
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
      const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
      this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
    }
  
    //This gets called by textbox onBlur events
    public async saveTextboxValue(fieldName: string, newValue: string) {
      //If field value hasn't changed, don't call the server
      if (this.state.lastUpdatedTextbox !== fieldName) {
        return;
      }
      this.saveInputValue(fieldName, newValue);
    }
  
    //Save new value to redis and DB
    public async saveInputValue(fieldName: string, newValue: string) {
      //Store current value to compare to new value after update.  

      const { projectId, unitId } = this.props.match.params;
      this.removeValidationError(fieldName);
      let result = await updateInputField("updateBMSControls", projectId, unitId, fieldName, newValue, "ControlsInputs");
  
      if (result.success) {
        //If there are any errors in the response, add them to state
        if (result.data.validationErrors != null) {
          this.addValidationErrors(result.data.validationErrors);
        }
  
        //If uiDataContainer was updated with new values, then update it in state
        if (result.data.uiDataContainer != null) {
          this.setState({
            userInputs: result.data.uiDataContainer.controlsInputs,
            bmsDisplayFields: result.data.uiDataContainer.bmsDisplayFields
          });
        }
      }
    }
  
    //Check for errors on this textbox/selectbox, and return the appropriate CSS className
    public getCSS(fieldName: string, type: string) {
      return getInputCSS(this.state.validationErrors, fieldName, type);
    }
  
    //Add any validation errors we got from the server to state
    public addValidationErrors(validationErrors: any[]) {
      let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
      this.setState({ validationErrors: newList });
    }
  
    public getValidationError(fieldName: string, isSetPoint: boolean) {
      //Call the validation method from pageUtils
      let validationClass = isSetPoint ? "validation-err-bms" : "validation-err-right";
      return getValidationError(this.state.validationErrors, fieldName, validationClass);
    }
  
    //Whenever we update a field, remove any previous error for that field
    //Also reset the lastUpdatedTextbox in state
    public removeValidationError(fieldName: string) {
        let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
        this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
    }
    private async togglePointsPanel(){
        if (this.state.openPointsAccordion === false) {//If the panel was clicked and is about to open.
            const { projectId, unitId } = this.props.match.params;
            this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
            const result = await getBMSInputValues(projectId, unitId);
            this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
            if (!isEmptyObject(result.uiDataContainer)) {
                this.setState({
                    userInputs: result.uiDataContainer.controlsInputs,
                    bmsDisplayFields: result.uiDataContainer.bmsDisplayFields,
                    validationErrors: result.validationErrors
                });
            }
        }
        this.setState({openPointsAccordion: !this.state.openPointsAccordion});
        
    }
  public render() {
    const savedValues = this.state.userInputs;
    const bmsDisplayFields = this.state.bmsDisplayFields;
    const systemPointsActive = this.state.openPointsAccordion;
    const spReturnDehumControlDewDisplayName = bmsDisplayFields && bmsDisplayFields.isPoolUnit ? "Dehumidification Set Point - Return Dew Point (°F)" : "Occupied Dehumidification Activation Set Point - Return Dew Point (°F)";
    const spSpaceUnOccupiedDehumControlDisplayName= bmsDisplayFields && bmsDisplayFields.isPoolUnit ? "Dehumidification Set Point - Space RH (%)" : "Occupied Dehumidification Activation Set Point - Space RH (%)";

    if (!savedValues || !bmsDisplayFields || !bmsDisplayFields.show_Any) {
        return null;
    }

    return (
        <div className=""> 
        <AccordionHeader
          title="Set Points & Additional BMS Points"
          subtitle=""
          classname="accordion-btn acc-btn-1000"
          active={this.state.openPointsAccordion}
          locked={false}
          hasError={false}
          togglePanel={() => this.togglePointsPanel()}
        />
        <div className={`acc-panel-1000 ${systemPointsActive === true ? 'acc-panel-active' : 'acc-panel-inactive'}`}>
        <div className="accordion-content app-box-shadow">
        <div className="spec-field-spacer1"></div>
        
        <div className="spec-heading-2">Set Points</div>
        
        <div className="spec-elec-fields">

          {bmsDisplayFields.show_SpSupplyTemp ? (
          <div>
            <div className="spec-input-label-4">Occupied Supply Temp Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spSupplyTemp', 'textbox')}
              value={savedValues.spSupplyTemp}
              onChange={(event) => this.updateTextboxValue('spSupplyTemp', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSupplyTemp', event.currentTarget.value)}
            />
            {this.getValidationError('spSupplyTemp', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccSupplyTemp ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Supply Temp Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spUnoccSupplyTemp', 'textbox')}
              value={savedValues.spUnoccSupplyTemp}
              onChange={(event) => this.updateTextboxValue('spUnoccSupplyTemp', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spUnoccSupplyTemp', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccSupplyTemp', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpActiveReturnTempCooling ? (
          <div>
            <div className="spec-input-label-4">Occupied Return Temp Cooling Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spActiveReturnTempCooling', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spActiveReturnTempCooling}
              onChange={(event) => this.updateTextboxValue('spActiveReturnTempCooling', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spActiveReturnTempCooling', event.currentTarget.value)}
            />
            {this.getValidationError('spActiveReturnTempCooling', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpActiveReturnTempHeating ? (
          <div>
            <div className="spec-input-label-4">Occupied Return Temp Heating Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spActiveReturnTempHeating', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spActiveReturnTempHeating}
              onChange={(event) => this.updateTextboxValue('spActiveReturnTempHeating', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spActiveReturnTempHeating', event.currentTarget.value)}
            />
            {this.getValidationError('spActiveReturnTempHeating', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccupiedReturnTempCoolingF ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Return Temp Cooling Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spUnoccupiedReturnTempCoolingF', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spUnoccupiedReturnTempCoolingF}
              onChange={(event) => this.updateTextboxValue('spUnoccupiedReturnTempCoolingF', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spUnoccupiedReturnTempCoolingF', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccupiedReturnTempCoolingF', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccupiedReturnTempHeatingF ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Return Temp Heating Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spUnoccupiedReturnTempHeatingF', 'textbox')}
              value={savedValues.spUnoccupiedReturnTempHeatingF}
              onChange={(event) => this.updateTextboxValue('spUnoccupiedReturnTempHeatingF', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spUnoccupiedReturnTempHeatingF', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccupiedReturnTempHeatingF', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpActiveSpaceTempCooling ? (
          <div>
            <div className="spec-input-label-4">Occupied Space Temp Cooling Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spActiveSpaceTempCooling', 'textbox')}
              value={savedValues.spActiveSpaceTempCooling}
              onChange={(event) => this.updateTextboxValue('spActiveSpaceTempCooling', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spActiveSpaceTempCooling', event.currentTarget.value)}
            />
            {this.getValidationError('spActiveSpaceTempCooling', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpActiveSpaceTempHeating ? (
          <div>
            <div className="spec-input-label-4">Occupied Space Temp Heating Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spActiveSpaceTempHeating', 'textbox')}
              value={savedValues.spActiveSpaceTempHeating}
              onChange={(event) => this.updateTextboxValue('spActiveSpaceTempHeating', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spActiveSpaceTempHeating', event.currentTarget.value)}
            />
            {this.getValidationError('spActiveSpaceTempHeating', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccCool ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Space Temp Cooling Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spUnoccCool', 'textbox')}
              value={savedValues.spUnoccCool}
              onChange={(event) => this.updateTextboxValue('spUnoccCool', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spUnoccCool', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccCool', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccHeat ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Space Temp Heating Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spUnoccHeat', 'textbox')}
              value={savedValues.spUnoccHeat}
              onChange={(event) => this.updateTextboxValue('spUnoccHeat', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spUnoccHeat', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccHeat', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpOutsideResetMinOat ? (
          <div>
            <div className="spec-input-label-4">Outside Air Reset Min OAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spOutsideResetMinOat', 'textbox')}
              value={savedValues.spOutsideResetMinOat}
              onChange={(event) => this.updateTextboxValue('spOutsideResetMinOat', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spOutsideResetMinOat', event.currentTarget.value)}
            />
            {this.getValidationError('spOutsideResetMinOat', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpOutsideResetMaxOat ? (
          <div>
            <div className="spec-input-label-4">Outside Air Reset Max OAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spOutsideResetMaxOat', 'textbox')}
              value={savedValues.spOutsideResetMaxOat}
              onChange={(event) => this.updateTextboxValue('spOutsideResetMaxOat', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spOutsideResetMaxOat', event.currentTarget.value)}
            />
            {this.getValidationError('spOutsideResetMaxOat', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpOutsideResetMinLat ? (
          <div>
            <div className="spec-input-label-4">Outside Air Reset Min LAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spOutsideResetMinLat', 'textbox')}
              value={savedValues.spOutsideResetMinLat}
              onChange={(event) => this.updateTextboxValue('spOutsideResetMinLat', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spOutsideResetMinLat', event.currentTarget.value)}
            />
            {this.getValidationError('spOutsideResetMinLat', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpOutsideResetMaxLat ? (
          <div>
            <div className="spec-input-label-4">Outside Air Reset Max LAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spOutsideResetMaxLat', 'textbox')}
              value={savedValues.spOutsideResetMaxLat}
              onChange={(event) => this.updateTextboxValue('spOutsideResetMaxLat', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spOutsideResetMaxLat', event.currentTarget.value)}
            />
            {this.getValidationError('spOutsideResetMaxLat', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpMaxSFTurndown ? (
          <div>
            <div className="spec-input-label-4">Min Supply Fan Airflow (%)</div>
            <input
              type="text"
              className={this.getCSS('spMaxSFTurndown', 'textbox')}
              value={savedValues.spMaxSFTurndown}
              onChange={(event) => this.updateTextboxValue('spMaxSFTurndown', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spMaxSFTurndown', event.currentTarget.value)}
            />
            {this.getValidationError('spMaxSFTurndown', true)}
          </div>
          ) : null}


          {bmsDisplayFields.show_SpOccupiedSupplyFanSpeed ? (
          <div>
            <div className="spec-input-label-4">Occupied Supply Fan Speed (%)</div>
            <input
              disabled={true}
              type="text"
              className={this.getCSS('spOccupiedSupplyFanSpeed', 'textbox')}
              value={savedValues.spOccupiedSupplyFanSpeed}
              // update method not implemented in specMate (read only for now)
              //onChange={(event) => this.updateTextboxValue('spOccupiedSupplyFanSpeed', event.currentTarget.value)}
              //onBlur={(event) => this.saveTextboxValue('spOccupiedSupplyFanSpeed', event.currentTarget.value)}
            />
            {this.getValidationError('spOccupiedSupplyFanSpeed', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccupiedSupplyFanSpeed ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Supply Fan Speed (%)</div>
            <input
              disabled={true}
              type="text"
              className={this.getCSS('spUnoccupiedSupplyFanSpeed', 'textbox')}
              value={savedValues.spUnoccupiedSupplyFanSpeed}
              // update method not implemented in specMate (read only for now)
              //onChange={(event) => this.updateTextboxValue('spUnoccupiedSupplyFanSpeed', event.currentTarget.value)}
              //onBlur={(event) => this.saveTextboxValue('spUnoccupiedSupplyFanSpeed', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccupiedSupplyFanSpeed', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpDehumCoilLeaving ? (
          <div>
            <div className="spec-input-label-4">Dehumidification Coil Leaving Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spDehumCoilLeaving', 'textbox')}
              value={savedValues.spDehumCoilLeaving}
              onChange={(event) => this.updateTextboxValue('spDehumCoilLeaving', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spDehumCoilLeaving', event.currentTarget.value)}
            />
            {this.getValidationError('spDehumCoilLeaving', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpHeatMin ? (
          <div>
            <div className="spec-input-label-4">Supply Min Heating Set Point (°F)</div>
            <input
              type="text"
              disabled={bmsDisplayFields.disableEditing_SpHeatMin}
              className={this.getCSS('spHeatMin', 'textbox')}
              value={savedValues.spHeatMin}
              onChange={(event) => this.updateTextboxValue('spHeatMin', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spHeatMin', event.currentTarget.value)}
            />
            {this.getValidationError('spHeatMin', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpHeatMax ? (
          <div>
            <div className="spec-input-label-4">Supply Max Heating Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spHeatMax', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spHeatMax}
              onChange={(event) => this.updateTextboxValue('spHeatMax', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spHeatMax', event.currentTarget.value)}
            />
            {this.getValidationError('spHeatMax', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpCoolMin ? (
          <div>
            <div className="spec-input-label-4">Supply Min Cooling Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spCoolMin', 'textbox')}
              value={savedValues.spCoolMin}
              onChange={(event) => this.updateTextboxValue('spCoolMin', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spCoolMin', event.currentTarget.value)}
            />
            {this.getValidationError('spCoolMin', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpCoolMax ? (
          <div>
            <div className="spec-input-label-4">Supply Max Cooling Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spCoolMax', 'textbox')}
              value={savedValues.spCoolMax}
              onChange={(event) => this.updateTextboxValue('spCoolMax', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spCoolMax', event.currentTarget.value)}
            />
            {this.getValidationError('spCoolMax', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpDefrost ? (
          <div>
            <div className="spec-input-label-4">HX Defrost Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spDefrost', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spDefrost}
              onChange={(event) => this.updateTextboxValue('spDefrost', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spDefrost', event.currentTarget.value)}
            />
            {this.getValidationError('spDefrost', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpEconomizerActivationDegree ? (
          <div>
            <div className="spec-input-label-4">Economizer Activation Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spEconomizerActivationDegree', 'textbox')}
              value={savedValues.spEconomizerActivationDegree}
              onChange={(event) => this.updateTextboxValue('spEconomizerActivationDegree', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spEconomizerActivationDegree', event.currentTarget.value)}
            />
            {this.getValidationError('spEconomizerActivationDegree', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpEconomizerActivationBtu ? (
          <div>
            <div className="spec-input-label-4">Economizer Activation Set Point (BTU/lb)</div>
            <input
              type="text"
              className={this.getCSS('spEconomizerActivationBtu', 'textbox')}
              value={savedValues.spEconomizerActivationBtu}
              onChange={(event) => this.updateTextboxValue('spEconomizerActivationBtu', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spEconomizerActivationBtu', event.currentTarget.value)}
            />
            {this.getValidationError('spEconomizerActivationBtu', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpPreheat ? (
          <div>
            <div className="spec-input-label-4">Preheating Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spPreheat', 'textbox')}
              value={savedValues.spPreheat}
              onChange={(event) => this.updateTextboxValue('spPreheat', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spPreheat', event.currentTarget.value)}
            />
            {this.getValidationError('spPreheat', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSupplyWheelDifferential ? (
          <div>
            <div className="spec-input-label-4">Wheel Differential Supply Pressure Set Point ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spSupplyWheelDifferential', 'textbox')}
              value={savedValues.spSupplyWheelDifferential}
              onChange={(event) => this.updateTextboxValue('spSupplyWheelDifferential', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSupplyWheelDifferential', event.currentTarget.value)}
            />
            {this.getValidationError('spSupplyWheelDifferential', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpExhaustWheelDifferential ? (
          <div>
            <div className="spec-input-label-4">Wheel Differential Exhaust Pressure Set Point ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spExhaustWheelDifferential', 'textbox')}
              value={savedValues.spExhaustWheelDifferential}
              onChange={(event) => this.updateTextboxValue('spExhaustWheelDifferential', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spExhaustWheelDifferential', event.currentTarget.value)}
            />
            {this.getValidationError('spExhaustWheelDifferential', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpPreheatLockout ? (
          <div>
            <div className="spec-input-label-4">Preheating System Lockout OAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spPreheatLockout', 'textbox')}
              value={savedValues.spPreheatLockout}
              onChange={(event) => this.updateTextboxValue('spPreheatLockout', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spPreheatLockout', event.currentTarget.value)}
            />
            {this.getValidationError('spPreheatLockout', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpHeatingLockout ? (
          <div>
            <div className="spec-input-label-4">Heating System Lockout OAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spHeatingLockout', 'textbox')}
              value={savedValues.spHeatingLockout}
              onChange={(event) => this.updateTextboxValue('spHeatingLockout', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spHeatingLockout', event.currentTarget.value)}
            />
            {this.getValidationError('spHeatingLockout', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpCoolLockout ? (
          <div>
            <div className="spec-input-label-4">Cooling System Lockout OAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spCoolLockout', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spCoolLockout}
              onChange={(event) => this.updateTextboxValue('spCoolLockout', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spCoolLockout', event.currentTarget.value)}
            />
            {this.getValidationError('spCoolLockout', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpReturnDehumControlDew ? (
          <div>
            <div className="spec-input-label-4">{spReturnDehumControlDewDisplayName}</div>
            <input
              type="text"
              className={this.getCSS('spReturnDehumControlDew', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spReturnDehumControlDew}
              onChange={(event) => this.updateTextboxValue('spReturnDehumControlDew', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spReturnDehumControlDew', event.currentTarget.value)}
            />
            {this.getValidationError('spReturnDehumControlDew', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpReturnDehumControlHumidity ? (
          <div>
            <div className="spec-input-label-4">Occupied Dehumidification Activation Set Point - Return RH (%)</div>
            <input
              type="text"
              className={this.getCSS('spReturnDehumControlHumidity', 'textbox')}
              value={savedValues.spReturnDehumControlHumidity}
              onChange={(event) => this.updateTextboxValue('spReturnDehumControlHumidity', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spReturnDehumControlHumidity', event.currentTarget.value)}
            />
            {this.getValidationError('spReturnDehumControlHumidity', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpOutsideDehumControlDew ? (
          <div>
            <div className="spec-input-label-4">Occupied Dehumidification Activation Set Point - Outside Air Dew Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spOutsideDehumControlDew', 'textbox')}
              value={savedValues.spOutsideDehumControlDew}
              onChange={(event) => this.updateTextboxValue('spOutsideDehumControlDew', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spOutsideDehumControlDew', event.currentTarget.value)}
            />
            {this.getValidationError('spOutsideDehumControlDew', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSpaceDehumControlDew ? (
          <div>
            <div className="spec-input-label-4">Occupied Dehumidification Activation Set Point - Space Dew Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spSpaceDehumControlDew', 'textbox')}
              value={savedValues.spSpaceDehumControlDew}
              onChange={(event) => this.updateTextboxValue('spSpaceDehumControlDew', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSpaceDehumControlDew', event.currentTarget.value)}
            />
            {this.getValidationError('spSpaceDehumControlDew', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSpaceUnoccupiedDehumControlHumidity ? (
          <div>
            <div className="spec-input-label-4">{spSpaceUnOccupiedDehumControlDisplayName}</div>
            <input
              type="text"
              className={this.getCSS('spSpaceUnoccupiedDehumControlHumidity', 'textbox')}
              value={savedValues.spSpaceUnoccupiedDehumControlHumidity}
              onChange={(event) => this.updateTextboxValue('spSpaceUnoccupiedDehumControlHumidity', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSpaceUnoccupiedDehumControlHumidity', event.currentTarget.value)}
            />
            {this.getValidationError('spSpaceUnoccupiedDehumControlHumidity', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpDehumidCoilLeavingMinSetPt ? (
          <div>
            <div className="spec-input-label-4">Dehumidification Coil Leaving Minimum Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spDehumidCoilLeavingMinSetPt', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spDehumidCoilLeavingMinSetPt}
              onChange={(event) => this.updateTextboxValue('spDehumidCoilLeavingMinSetPt', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spDehumidCoilLeavingMinSetPt', event.currentTarget.value)}
            />
            {this.getValidationError('spDehumidCoilLeavingMinSetPt', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpPoolSpaceStaticPressureVsAdjSpace ? (
          <div>
            <div className="spec-input-label-4">Pool Space Static Pressure vs Adjacent Space Set Point ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spPoolSpaceStaticPressureVsAdjSpace', 'textbox')}
              disabled={bmsDisplayFields.isPoolUnit}
              value={savedValues.spPoolSpaceStaticPressureVsAdjSpace}
              onChange={(event) => this.updateTextboxValue('spPoolSpaceStaticPressureVsAdjSpace', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spPoolSpaceStaticPressureVsAdjSpace', event.currentTarget.value)}
            />
            {this.getValidationError('spPoolSpaceStaticPressureVsAdjSpace', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpPoolSpaceStaticPressureVsOutdoor ? (
          <div>
            <div className="spec-input-label-4">Pool Space Static Pressure vs Outdoor Set Point ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spPoolSpaceStaticPressureVsOutdoor', 'textbox')}
              value={savedValues.spPoolSpaceStaticPressureVsOutdoor}
              onChange={(event) => this.updateTextboxValue('spPoolSpaceStaticPressureVsOutdoor', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spPoolSpaceStaticPressureVsOutdoor', event.currentTarget.value)}
            />
            {this.getValidationError('spPoolSpaceStaticPressureVsOutdoor', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpMinOaControlCfm ? (
          <div>
            <div className="spec-input-label-4">Minimum Outside Air Set Point (CFM)</div>
            <input
              type="text"
              disabled = {bmsDisplayFields.disableEditing_SpMinOaControlCfm}
              className={this.getCSS('spMinOaControlCfm', 'textbox')}
              value={savedValues.spMinOaControlCfm || ""}
              onChange={(event) => this.updateTextboxValue('spMinOaControlCfm', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spMinOaControlCfm', event.currentTarget.value)}
            />
            {this.getValidationError('spMinOaControlCfm', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpMaxOaControlCfm ? (
          <div>
            <div className="spec-input-label-4">Maximum Outside Air Set Point (CFM)</div>
            <input
              type="text"
              disabled = {true}
              className={this.getCSS('spMinOaControlCfm', 'textbox')}
              value={savedValues.spMaxOaControlCfm || ""}
            />
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSupplyCfm ? (
          <div>
            <div className="spec-input-label-4">Supply Fan Air Flow Set Point(CFM)</div>
            <input
              type="text"
              disabled = {true}
              className={this.getCSS('spMinOaControlCfm', 'textbox')}
              value={savedValues.spSupplyCfm || ""}
            />
          </div>
          ) : null}

          {bmsDisplayFields.show_SpMinOaControlAirflow ? (
          <div>
            <div className="spec-input-label-4">Minimum Outside Air Set Point (%)</div>
            <input
              type="text"
              className={this.getCSS('spMinOaControlAirflow', 'textbox')}
              value={savedValues.spMinOaControlAirflow}
              onChange={(event) => this.updateTextboxValue('spMinOaControlAirflow', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spMinOaControlAirflow', event.currentTarget.value)}
            />
            {this.getValidationError('spMinOaControlAirflow', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSupplyFanControlCfm ? (
          <div>
            <div className="spec-input-label-4">Airflow Set Point - Supply Fan (CFM)</div>
            <input
              type="text"
              className={this.getCSS('spSupplyFanControlCfm', 'textbox')}
              value={savedValues.spSupplyFanControlCfm}
              onChange={(event) => this.updateTextboxValue('spSupplyFanControlCfm', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSupplyFanControlCfm', event.currentTarget.value)}
            />
            {this.getValidationError('spSupplyFanControlCfm', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpExhaustReliefFanSpace ? (
          <div>
            <div className="spec-input-label-4">Space Static Pressure Set Point - Exhaust Fan ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spExhaustReliefFanSpace', 'textbox')}
              value={savedValues.spExhaustReliefFanSpace}
              onChange={(event) => this.updateTextboxValue('spExhaustReliefFanSpace', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spExhaustReliefFanSpace', event.currentTarget.value)}
            />
            {this.getValidationError('spExhaustReliefFanSpace', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpExhaustReliefFanCfm ? (
          <div>
            <div className="spec-input-label-4">Airflow Set Point - Exhaust Fan (CFM)</div>
            <input
              type="text"
              className={this.getCSS('spExhaustReliefFanCfm', 'textbox')}
              value={savedValues.spExhaustReliefFanCfm}
              onChange={(event) => this.updateTextboxValue('spExhaustReliefFanCfm', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spExhaustReliefFanCfm', event.currentTarget.value)}
            />
            {this.getValidationError('spExhaustReliefFanCfm', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpReturnReliefFanCfm ? (
          <div>
            <div className="spec-input-label-4">Airflow Set Point - Return Fan (CFM)</div>
            <input
              type="text"
              className={this.getCSS('spReturnReliefFanCfm', 'textbox')}
              value={savedValues.spReturnReliefFanCfm}
              onChange={(event) => this.updateTextboxValue('spReturnReliefFanCfm', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spReturnReliefFanCfm', event.currentTarget.value)}
            />
            {this.getValidationError('spReturnReliefFanCfm', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpTrackSupplyFanOffsetReturnFanCFM ? (
          <div>
            <div className="spec-input-label-4">Track Supply Fan Offset Set Point - Return Fan (CFM)</div>
            <input
              type="text"
              className={this.getCSS('spTrackSupplyFanOffsetReturnFanCFM', 'textbox')}
              value={savedValues.spTrackSupplyFanOffsetReturnFanCFM}
              onChange={(event) => this.updateTextboxValue('spTrackSupplyFanOffsetReturnFanCFM', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spTrackSupplyFanOffsetReturnFanCFM', event.currentTarget.value)}
            />
            {this.getValidationError('spTrackSupplyFanOffsetReturnFanCFM', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpTrackSupplyFanOffsetExhaustFanCfm ? (
          <div>
            <div className="spec-input-label-4">Track Supply Fan Offset Set Point - Exhaust Fan (CFM)</div>
            <input
              type="text"
              className={this.getCSS('spTrackSupplyFanOffsetExhaustFanCfm', 'textbox')}
              value={savedValues.spTrackSupplyFanOffsetExhaustFanCfm}
              onChange={(event) => this.updateTextboxValue('spTrackSupplyFanOffsetExhaustFanCfm', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spTrackSupplyFanOffsetExhaustFanCfm', event.currentTarget.value)}
            />
            {this.getValidationError('spTrackSupplyFanOffsetExhaustFanCfm', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpExhaustOaOffset ? (
          <div>
            <div className="spec-input-label-4">Exhaust/Outside Air Offset (CFM)</div>
            <input
              type="text"
              className={this.getCSS('spExhaustOaOffset', 'textbox')}
              value={savedValues.spExhaustOaOffset}
              onChange={(event) => this.updateTextboxValue('spExhaustOaOffset', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spExhaustOaOffset', event.currentTarget.value)}
            />
            {this.getValidationError('spExhaustOaOffset', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpCO2 ? (
          <div>
            <div className="spec-input-label-4">CO&#8322; Set Point (PPM)</div>
            <input
              type="text"
              className={this.getCSS('spCO2', 'textbox')}
              value={savedValues.spCO2}
              onChange={(event) => this.updateTextboxValue('spCO2', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spCO2', event.currentTarget.value)}
            />
            {this.getValidationError('spCO2', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpCo2oaAirflow ? (
          <div>
            <div className="spec-input-label-4">CO&#8322; OA Min Airflow (%)</div>
            <input
              type="text"
              className={this.getCSS('spCo2oaAirflow', 'textbox')}
              value={savedValues.spCo2oaAirflow}
              onChange={(event) => this.updateTextboxValue('spCo2oaAirflow', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spCo2oaAirflow', event.currentTarget.value)}
            />
            {this.getValidationError('spCo2oaAirflow', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpHighDuctNum ? (
          <div>
            <div className="spec-input-label-4">High Supply Duct Static Pressure ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spHighDuctNum', 'textbox')}
              value={savedValues.spHighDuctNum}
              onChange={(event) => this.updateTextboxValue('spHighDuctNum', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spHighDuctNum', event.currentTarget.value)}
            />
            {this.getValidationError('spHighDuctNum', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpLowDuctNum ? (
          <div>
            <div className="spec-input-label-4">Low Return Duct Static Pressure ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spLowDuctNum', 'textbox')}
              value={savedValues.spLowDuctNum}
              onChange={(event) => this.updateTextboxValue('spLowDuctNum', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spLowDuctNum', event.currentTarget.value)}
            />
            {this.getValidationError('spLowDuctNum', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSupplyFanControlDuct ? (
          <div>
            <div className="spec-input-label-4">Duct Static Pressure Set Point - Supply Fan ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spSupplyFanControlDuct', 'textbox')}
              value={savedValues.spSupplyFanControlDuct}
              onChange={(event) => this.updateTextboxValue('spSupplyFanControlDuct', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSupplyFanControlDuct', event.currentTarget.value)}
            />
            {this.getValidationError('spSupplyFanControlDuct', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpReturnReliefFanDuct ? (
          <div>
            <div className="spec-input-label-4">Duct Static Pressure Set Point - Return Fan ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spReturnReliefFanDuct', 'textbox')}
              value={savedValues.spReturnReliefFanDuct}
              onChange={(event) => this.updateTextboxValue('spReturnReliefFanDuct', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spReturnReliefFanDuct', event.currentTarget.value)}
            />
            {this.getValidationError('spReturnReliefFanDuct', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpReturnReliefFanSpace ? (
          <div>
            <div className="spec-input-label-4">Space Static Pressure - Return Fan ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spReturnReliefFanSpace', 'textbox')}
              value={savedValues.spReturnReliefFanSpace}
              onChange={(event) => this.updateTextboxValue('spReturnReliefFanSpace', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spReturnReliefFanSpace', event.currentTarget.value)}
            />
            {this.getValidationError('spReturnReliefFanSpace', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpExhaustReliefFanDuct ? (
          <div>
            <div className="spec-input-label-4">Duct Static Pressure Set Point - Exhaust Fan ("W.C.)</div>
            <input
              type="text"
              className={this.getCSS('spExhaustReliefFanDuct', 'textbox')}
              value={savedValues.spExhaustReliefFanDuct}
              onChange={(event) => this.updateTextboxValue('spExhaustReliefFanDuct', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spExhaustReliefFanDuct', event.currentTarget.value)}
            />
            {this.getValidationError('spExhaustReliefFanDuct', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpFreezestatNum ? (
          <div>
            <div className="spec-input-label-4">Freezestat Set Point (°F)</div>
            <input
              type="text"
              className={this.getCSS('spFreezestatNum', 'textbox')}
              
              value={savedValues.spFreezestatNum}
              onChange={(event) => this.updateTextboxValue('spFreezestatNum', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spFreezestatNum', event.currentTarget.value)}
            />
            {this.getValidationError('spFreezestatNum', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpHumidLockout ? (
          <div>
            <div className="spec-input-label-4">Humidification System Lockout OAT (°F)</div>
            <input
              type="text"
              className={this.getCSS('spHumidLockout', 'textbox')}
              value={savedValues.spHumidLockout}
              onChange={(event) => this.updateTextboxValue('spHumidLockout', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spHumidLockout', event.currentTarget.value)}
            />
            {this.getValidationError('spHumidLockout', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpHumid ? (
          <div>
            <div className="spec-input-label-4">Supply Humidification High Limit (%RH)</div>
            <input
              type="text"
              className={this.getCSS('spHumid', 'textbox')}
              value={savedValues.spHumid}
              onChange={(event) => this.updateTextboxValue('spHumid', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spHumid', event.currentTarget.value)}
            />
            {this.getValidationError('spHumid', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpReturnHumidRh ? (
          <div>
            <div className="spec-input-label-4">Return Humidification (%RH)</div>
            <input
              type="text"
              className={this.getCSS('spReturnHumidRh', 'textbox')}
              value={savedValues.spReturnHumidRh}
              onChange={(event) => this.updateTextboxValue('spReturnHumidRh', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spReturnHumidRh', event.currentTarget.value)}
            />
            {this.getValidationError('spReturnHumidRh', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpSpaceHumidRh ? (
          <div>
            <div className="spec-input-label-4">Space Humidification (%RH)</div>
            <input
              type="text"
              className={this.getCSS('spSpaceHumidRh', 'textbox')}
              value={savedValues.spSpaceHumidRh}
              onChange={(event) => this.updateTextboxValue('spSpaceHumidRh', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spSpaceHumidRh', event.currentTarget.value)}
            />
            {this.getValidationError('spSpaceHumidRh', true)}
          </div>
          ) : null}

          {bmsDisplayFields.show_SpUnoccHumid ? (
          <div>
            <div className="spec-input-label-4">Unoccupied Space Temperature Humidification	 (%RH)</div>
            <input
              type="text"
              className={this.getCSS('spUnoccHumid', 'textbox')}
              value={savedValues.spUnoccHumid}
              onChange={(event) => this.updateTextboxValue('spUnoccHumid', event.currentTarget.value)}
              onBlur={(event) => this.saveTextboxValue('spUnoccHumid', event.currentTarget.value)}
            />
            {this.getValidationError('spUnoccHumid', true)}
          </div>
          ) : null}

          </div>

        <div className="spec-field-spacer1"></div>
        {/* Additional BMS Points */}
        <div className="spec-heading-2">Additional BMS Points</div>
        <div className="spec-elec-fields">

        {bmsDisplayFields.show_AddHardWiredInput ? (
            <div>
              <div className="spec-input-label-3">Hard Wired Occupancy Input</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHardWiredInput)}
                  onChange={(event) => this.saveInputValue('addHardWiredInput', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSupplyAirHumidity ? (
            <div>
              <div className="spec-input-label-3">Supply Air Humidity</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSupplyAirHumidity)}
                  onChange={(event) => this.saveInputValue('addSupplyAirHumidity', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddOutsideAirHumidity ? (
            <div>
              <div className="spec-input-label-3">Outside Air Humidity</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addOutsideAirHumidity)}
                  onChange={(event) => this.saveInputValue('addOutsideAirHumidity', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

        {bmsDisplayFields.show_AddReturnAirTemp ? (
            <div>
              <div className="spec-input-label-3">Return Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addReturnAirTemp)}
                  onChange={(event) => this.saveInputValue('addReturnAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddReturnAirHumidity ? (
            <div>
              <div className="spec-input-label-3">Return Air Humidity</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addReturnAirHumidity)}
                  onChange={(event) => this.saveInputValue('addReturnAirHumidity', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddExhaustAirTemp ? (
            <div>
              <div className="spec-input-label-3">Exhaust Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addExhaustAirTemp)}
                  onChange={(event) => this.saveInputValue('addExhaustAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddExhaustAirHumidity ? (
            <div>
              <div className="spec-input-label-3">Exhaust Air Humidity</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addExhaustAirHumidity)}
                  onChange={(event) => this.saveInputValue('addExhaustAirHumidity', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSpaceAirTemp ? (
            <div>
              <div className="spec-input-label-3">Space Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSpaceAirTemp)}
                  onChange={(event) => this.saveInputValue('addSpaceAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSpaceAirHumidity ? (
            <div>
              <div className="spec-input-label-3">Space Air Humidity</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSpaceAirHumidity)}
                  onChange={(event) => this.saveInputValue('addSpaceAirHumidity', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddMixedAirTemp ? (
            <div>
              <div className="spec-input-label-3">Mixed Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addMixedAirTemp)}
                  onChange={(event) => this.saveInputValue('addMixedAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddMixedAirHumidity ? (
            <div>
              <div className="spec-input-label-3">Mixed Air Humidity</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addMixedAirHumidity)}
                  onChange={(event) => this.saveInputValue('addMixedAirHumidity', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddCoolingEnteringAirTemp ? (
            <div>
              <div className="spec-input-label-3">Cooling Coil Entering Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addCoolingEnteringAirTemp)}
                  onChange={(event) => this.saveInputValue('addCoolingEnteringAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddCoolingLeavingAirTemp ? (
            <div>
              <div className="spec-input-label-3">Cooling Coil Leaving Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addCoolingLeavingAirTemp)}
                  onChange={(event) => this.saveInputValue('addCoolingLeavingAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHeatingEnteringAirTemp ? (
            <div>
              <div className="spec-input-label-3">Heating Coil Entering Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHeatingEnteringAirTemp)}
                  onChange={(event) => this.saveInputValue('addHeatingEnteringAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHeatingLeavingAirTemp ? (
            <div>
              <div className="spec-input-label-3">Heating Coil Leaving Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHeatingLeavingAirTemp)}
                  onChange={(event) => this.saveInputValue('addHeatingLeavingAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddPreheatingEnteringAirTemp ? (
            <div>
              <div className="spec-input-label-3">Pre-Heating Coil Entering Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addPreheatingEnteringAirTemp)}
                  onChange={(event) => this.saveInputValue('addPreheatingEnteringAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSupplyCfm ? (
            <div>
              <div className="spec-input-label-3">Supply CFM</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSupplyCfm)}
                  onChange={(event) => this.saveInputValue('addSupplyCfm', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddExhaustCfm ? (
            <div>
              <div className="spec-input-label-3">Exhaust CFM</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addExhaustCfm)}
                  onChange={(event) => this.saveInputValue('addExhaustCfm', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddReturnCfm ? (
            <div>
              <div className="spec-input-label-3">Return CFM</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addReturnCfm)}
                  onChange={(event) => this.saveInputValue('addReturnCfm', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddOutsideAirCfm ? (
            <div>
              <div className="spec-input-label-3">Outside Air CFM</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addOutsideAirCfm)}
                  onChange={(event) => this.saveInputValue('addOutsideAirCfm', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHxenteringOutsideAirTemp ? (
            <div>
              <div className="spec-input-label-3">Entering Outside Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHxenteringOutsideAirTemp)}
                  onChange={(event) => this.saveInputValue('addHxenteringOutsideAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHxleavingSupplyAirTemp ? (
            <div>
              <div className="spec-input-label-3">Leaving Supply Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHxleavingSupplyAirTemp)}
                  onChange={(event) => this.saveInputValue('addHxleavingSupplyAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHxenteringExhaustAirTemp ? (
            <div>
              <div className="spec-input-label-3">Entering Exhaust Air Temperature</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHxenteringExhaustAirTemp)}
                  onChange={(event) => this.saveInputValue('addHxenteringExhaustAirTemp', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHxsupplyDifferentialPressure ? (
            <div>
              <div className="spec-input-label-3">Supply Differential Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHxsupplyDifferentialPressure)}
                  onChange={(event) => this.saveInputValue('addHxsupplyDifferentialPressure', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHxexhaustDifferentialPressure ? (
            <div>
              <div className="spec-input-label-3">Exhaust Differential Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHxexhaustDifferentialPressure)}
                  onChange={(event) => this.saveInputValue('addHxexhaustDifferentialPressure', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSupplyDuctStaticPressure ? (
            <div>
              <div className="spec-input-label-3">Supply Duct Static Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSupplyDuctStaticPressure)}
                  onChange={(event) => this.saveInputValue('addSupplyDuctStaticPressure', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddReturnDuctStaticPressure ? (
            <div>
              <div className="spec-input-label-3">Return Duct Static Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addReturnDuctStaticPressure)}
                  onChange={(event) => this.saveInputValue('addReturnDuctStaticPressure', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddExhaustDuctStaticPressure ? (
            <div>
              <div className="spec-input-label-3">Exhaust Duct Static Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addExhaustDuctStaticPressure)}
                  onChange={(event) => this.saveInputValue('addExhaustDuctStaticPressure', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSpaceStaticPressure ? (
            <div>
              <div className="spec-input-label-3">Space Static Pressure</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSpaceStaticPressure)}
                  onChange={(event) => this.saveInputValue('addSpaceStaticPressure', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {/* {bmsDisplayFields.show_DirtyFilterSwitch ? (
            <div>
              <div className="spec-input-label-3">Dirty Filter Switch</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={savedValues.dirtyFilterSwitch}
                  onChange={(event) => this.saveInputValue('dirtyFilterSwitch', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null } */}

          {bmsDisplayFields.show_AddFilterPressureAnalogInput ? (
            <div>
              <div className="spec-input-label-3">Dirty Filter Switch</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addFilterPressureAnalogInput)}
                  onChange={(event) => this.saveInputValue('addFilterPressureAnalogInput', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddFilterPressureTransducer ? (
            <div>
              <div className="spec-input-label-3">Filter Pressure Transducer</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addFilterPressureTransducer)}
                  onChange={(event) => this.saveInputValue('addFilterPressureTransducer', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddSpaceCo2 ? (
            <div>
              <div className="spec-input-label-3">Space CO&#8322;</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addSpaceCo2)}
                  onChange={(event) => this.saveInputValue('addSpaceCo2', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddReturnCo2 ? (
            <div>
              <div className="spec-input-label-3">Return CO&#8322;</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addReturnCo2)}
                  onChange={(event) => this.saveInputValue('addReturnCo2', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddDrainCondensate ? (
            <div>
              <div className="spec-input-label-3">Drain Condensate</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addDrainCondensate)}
                  onChange={(event) => this.saveInputValue('addDrainCondensate', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHeatWheelRotationDetection ? (
            <div>
              <div className="spec-input-label-3">Enthalpy Wheel Rotation Detection</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHeatWheelRotationDetection)}
                  onChange={(event) => this.saveInputValue('addHeatWheelRotationDetection', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddFreezestat ? (
            <div>
              <div className="spec-input-label-3">Freezestat</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addFreezestat)}
                  onChange={(event) => this.saveInputValue('addFreezestat', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddHighSupplyDuctStatic ? (
            <div>
              <div className="spec-input-label-3">High Supply Duct Static</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addHighSupplyDuctStatic)}
                  onChange={(event) => this.saveInputValue('addHighSupplyDuctStatic', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

          {bmsDisplayFields.show_AddLowReturnDuctStatic ? (
            <div>
              <div className="spec-input-label-3">Low Return Duct Static</div>
              <div className="spec-input-3">
                <input
                  className=""
                  type="checkbox"
                  checked={getChecked(savedValues.addLowReturnDuctStatic)}
                  onChange={(event) => this.saveInputValue('addLowReturnDuctStatic', event.currentTarget.checked.toString())}
                />
              </div>
            </div>
          ) : null }

        </div>

        <div className="spec-field-spacer1"></div>
        </div>
        </div>
        </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BMSSetPoints);
