import * as React from "react";
import { store } from "../redux/store";
import { updateReduxAction } from "../redux/actions";
import { INotification } from "../interfaces/interfaces";
import { isEmptyArray } from "../utilities/helpers";
import NotificationMessage from "../components/Shared/NotificationMessage";

export function addNotification(notification: INotification) {
  const reduxStore = store.getState();
  //Get list of existing ids
  const idList = reduxStore.reduxNotifications.notifications.map((x) => x.id);
  //Add this item to the notifications array if it doesn't already exist
  if (!idList.includes(notification.id)) {
    //Clone the redux array
    const newArray = Array.from(reduxStore.reduxNotifications.notifications);
    newArray.push(notification);
    store.dispatch(updateReduxAction("UPDATE_NOTIFICATIONS", { notifications: newArray }));
  }
}

export function removeNotification(id: string) {
  const reduxStore = store.getState();
  //Clone the redux array
  const newArray = Array.from(reduxStore.reduxNotifications.notifications);
  //Get items that don't match the given id
  const filteredArray = newArray.filter((item) => item.id !== id);
  //update redux with filtered list
  store.dispatch(updateReduxAction("UPDATE_NOTIFICATIONS", { notifications: filteredArray }));
}

export function clearAllPerformanceNotifications() {
  removeNotification("selectreduxSupplyFanFailure");
  removeNotification("selectreduxExhaustFanFailure");
  removeNotification("selectHeatWheelFailure");
  removeNotification("casingLimitReset");
}

//Method to support smaller notification messages in context (like the ones inside accordions)
export function getErrorNotifications(errorCollection: string[] | undefined) {
  if (!isEmptyArray(errorCollection)) {
    const content = errorCollection!.map((msg, index) => <div key={index}>{msg}</div>);

    const Inotification = {
      id: "",
      notificationType: "error",
      title: "",
      content: content
    };
    return (
      <div className="acc-errors">
        <NotificationMessage notificationData={Inotification} />
      </div>
    );
  }
  return null;
}

export function accordionHasError(errorCollection: string[] | undefined) {
  return isEmptyArray(errorCollection) ? false : true;
}

export function formatIndividualErrors(errorList: string[]) {
  return errorList.map((msg, index) => <div key={index}>{msg}</div>);
}
