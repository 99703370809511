import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateInputField } from "../../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  //reduxUnitCabinet: UnitCabinetUI;
  componentData: any;
}

// local state
interface IState {
  doorLengthWarningShown: boolean;
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class Doors extends React.Component<IProps, IState> {
  public state: IState = {
    doorLengthWarningShown: false,
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const componentData = this.props.componentData;
    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: componentData.uiDataContainer.fineTuneInputs,
        displayFields: componentData.uiDataContainer.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }

  //componentDidMount() {
  //Show door length warning if applicable when page loads.
  // if (this.props.reduxUnitCabinet.doorLengthWarning){
  //   this.showDoorLengthWarning(this.props.reduxUnitCabinet.doorLengthWarning);
  //   this.setState({doorLengthWarningShown:true});
  // }
  //}
  componentDidUpdate(prevProps: any) {
    //If door length warning changed and a warning exists.
    // if (this.props.reduxUnitCabinet.doorLengthWarning !== prevProps.reduxUnitCabinet.doorLengthWarning &&
    //   this.props.reduxUnitCabinet.doorLengthWarning) {
    //     //If we haven"t already shown the warning, then show it.
    //     if (this.state.doorLengthWarningShown === false){
    //       this.showDoorLengthWarning(this.props.reduxUnitCabinet.doorLengthWarning);
    //       this.setState({doorLengthWarningShown:true});
    //     }
    // }
    // //If we"ve shown the warning already and the warning no longer exists, then remove the notification.
    // else if (this.state.doorLengthWarningShown == true && !this.props.reduxUnitCabinet.doorLengthWarning) {
    //   removeNotification("unitCabinetDoorLength");
    //   this.setState({doorLengthWarningShown:false});
    // }
  }
  componentWillUnmount() {
    //removeNotification("unitCabinetDoorLength");
  }

  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn"t changed, don"t call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateUnitCabinet", projectId, unitId, fieldName, newValue, "FineTuneInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fineTuneInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------
  public render() {
    //const reduxUnitCabinet = this.props.reduxUnitCabinet;
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;

    if (!savedValues) {
      return null;
    }

    //Window Type selection deactivated and grayed out if "Window Inclusion" is set to "None".
    const disableWindowSelection = savedValues.windowInclusion === "0" ? true : false;

    return (
      <div className="spec-fields">
        <div className="spec-field-spacer1"></div>

        <div className="spec-heading-2">Doors</div>

        <div className="spec-elec-fields">
          {/* {reduxUnitCabinet.inwardSwinging ? (
            <div>
              <div className="spec-input-label-2">Inward Swinging</div>
              <div className="spec-input-2">
                <select
                  className="spec-selectbox-2"
                  value={this.getInputValue("inwardSwinging")}
                  onChange={(event) => this.saveSelectBoxInput("inwardSwinging", event)}
                >
                  {this.getSelectOptions("inwardSwinging")}
                </select>
                {this.getValidationErrors("inwardSwinging")}
              </div>
            </div>
          ) : null} */}

          {/* {savedValues.thermalBreak != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Thermal Break</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.thermalBreak || ""}
                onChange={(event) => this.saveInputValue("thermalBreak", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "thermalBreakOptions")}
              </select>
              {this.getValidationError("thermalBreak")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.windowInclusion != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Window Inclusion</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.windowInclusion || ""}
                onChange={(event) => this.saveInputValue("windowInclusion", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "windowInclusionOptions")}
              </select>
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.window != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Window Type</div>
            <div className="spec-input-2">
              <select
                disabled={displayFields.windowTypeEnabled ? false : true}
                className="spec-selectbox-2"
                value={savedValues.windowType || ""}
                onChange={(event) => this.saveInputValue("window", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "windowTypeOptions")}
              </select>
              {this.getValidationError("window")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.pressureReliefLatch != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Pressure Relief Latch</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.pressureReliefLatch || ""}
                onChange={(event) => this.saveInputValue("pressureReliefLatch", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "pressureReliefLatchOptions")}
              </select>
              {this.getValidationError("pressureReliefLatch")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.interiorHandles != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Interior Handles</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.interiorHandles || ""}
                onChange={(event) => this.saveInputValue("interiorHandles", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "interiorHandlesOptions")}
              </select>
              {this.getValidationError("interiorHandles")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.tieBack != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Tie-Back</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.tieBack || ""}
                onChange={(event) => this.saveInputValue("tieBack", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "tieBackOptions")}
              </select>
              {this.getValidationError("tieBack")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.lock != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Lock</div>
            <div className="spec-input-2">
              <select className="spec-selectbox-2" value={savedValues.lock || ""} onChange={(event) => this.saveInputValue("lock", event.currentTarget.value)}>
                {getSelectBoxOptions(this.state.dropdownOptions, "doorLockOptions")}
              </select>
              {this.getValidationError("lock")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.doorHandleInterlock != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Door Handle Interlock</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.doorHandleInterlock || ""}
                onChange={(event) => this.saveInputValue("doorHandleInterlock", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "doorHandleInterlockOptions")}
              </select>
              {this.getValidationError("doorHandleInterlock")}
            </div>
          </div>
          {/* ) : null} */}

          {/* {savedValues.testPort != null ? ( */}
          <div>
            <div className="spec-input-label-3B">Test Port</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.testPort || ""}
                onChange={(event) => this.saveInputValue("testPort", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "testPortOptions")}
              </select>
              {this.getValidationError("testPort")}
            </div>
          </div>
          {/* ) : null} */}
        </div>
        <div className="spec-field-spacer1"></div>
      </div>
    );
  }
  showDoorLengthWarning(warning: any) {
    const notification = {
      id: "unitCabinetDoorLength",
      notificationType: "warning",
      title: "Warning",
      content: <div className="nf-div">{warning}</div>
    };
    //addNotification(notification);
  }
}
//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxUnitCabinet: state.reduxUnitCabinet
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Doors);
