import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import { mapDispatchToProps } from "../../../redux/actions";
import { updateInputField } from "../../../api-calls/readApiService";
import {getValidationError, getSelectBoxOptions, getFilteredValidationErrors, getNewDropdownList, getNewValidationErrorsList
} from "../../../utilities/pageUtils";
import { isEmptyObject } from "../../../utilities/helpers";
import "../../../css/Accordions.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  componentData: any;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
}

class FanVFDInstall extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: ""
  };

  async componentDidMount() {
    const componentData = this.props.componentData;
    if (!isEmptyObject(componentData)) {
      this.setState({
        userInputs: componentData.uiDataContainer.controlsInputs,
        displayFields: componentData.uiDataContainer.displayFields,
        dropdownOptions: componentData.dropdownOptions,
        validationErrors: componentData.validationErrors
      });
    }
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateElectrical", projectId, unitId, fieldName, newValue, "ControlsInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.controlsInputs,
          displayFields: result.data.uiDataContainer.displayFields
        });
      }
    }
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-err-right");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //------------------------------------------------------------------------------------------

  public render() {
    const savedValues = this.state.userInputs;

    if (!savedValues) {
      return null;
    }

    return (
      <div className="spec-field-install">
        <div className="spec-elec-field-installation">
          <div className="spec-coils-container">
            <div className="spec-input-label-2">Provision/Installation</div>
            <div className="spec-input-2">
              <select
                className="spec-selectbox-2"
                value={savedValues.vfdProvisionInstallation || ""}
                onChange={(event) => this.saveInputValue("vfdProvisionInstallation", event.currentTarget.value)}
              >
                {getSelectBoxOptions(this.state.dropdownOptions, "vfdProvisionInstallationOptions")}
              </select>
              {this.getValidationError("vfdProvisionInstallation")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FanVFDInstall);
