import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  UnitSelectionUI,
  ConfigurationInputsUI,
  ConnectionsAndOptionsUI,
  TempsAndSystemUI,
  PerformanceUI,
  HeatWheelComponentUI,
  PlateHXComponentUI,
  IDFComponentUI,
  ChilledWaterCoilComponentUI,
  HotWaterCoilComponentUI,
  ElectricHeaterComponentUI,
  DxCoilComponentUI,
  HotGasReheatComponentUI,
  SteamCoilComponentUI,
  FanComponentUI,
  FilterComponentUI,
  AccessEditingAndDrawingUI,
  AccessDoorsUI,
  CoilConnectionsUI,
  LengthInputsUI,
  ElectFurnacePanelsUI
} from '../TypescriptClient';
import { IAction, IModifyPropertyError, INotification } from '../interfaces/interfaces';
import {
  reduxHeatWheelPanel,
  reduxHotWaterCoilPanel,
  reduxHotWaterPreheatPanel,
  reduxChilledWaterCoilPanel,
  reduxSteamCoilPanel,
  reduxDXCoilPanel,
  reduxHGRHCoilPanel,
  reduxIDFPanel,
  reduxAccuPanel,
  reduxOAFilterPanel,
  reduxHEFilterPanel,
  reduxSAFilterPanel,
  reduxRAFilterPanel,
  reduxSupplyFanPanel,
  reduxExhaustFanPanel,
  reduxOADamperPanel,
  reduxEADamperPanel,
  reduxRADamperPanel,
  reduxSADamperPanel,
  reduxOABypassDamperPanel,
  reduxEABypassDamperPanel,
  reduxRecircDamperPanel
} from './reducers/componentConstruction';
import { reduxElectricalSupplyFan, reduxElectricalExhaustFan, reduxElectrical } from './reducers/electrical';
import { reduxControls } from './reducers/controls';
import {reduxProjectManagement} from './reducers/projectManagement';
import { reduxUnitCabinet } from './reducers/unitCabinet';
import { reduxSupplyAttenuator, reduxReturnAttenuator, reduxHumidifierManifold } from './reducers/unitComponents';
import { getInitialState } from '../redux/localstorage';
import {reduxOptimizeDesign} from './reducers/optimizeDesign';

//GLOBAL REDUCER: This is a special reducer that fires on every redux update
//It updates a timestamp to keep track of user session activity.
const reduxSession = (state = { userActivityTimestamp: Date.now() }, action: IAction) => {
  const d = new Date();
  return Object.assign({}, state, { userActivityTimestamp: d });
};

const reduxAppVersion = (state = { appVersion: '' }, action: IAction) => {
  if (action.type === 'UPDATE_APP_VERSION') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

const reduxAuth = (state = { userId: '', access_token: '', userRoles:[], state: '', postalCode: ''}, action: IAction) => {
  if (action.type === 'UPDATE_AUTH') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};
const reduxConfigInputs = (state: ConfigurationInputsUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_CONFIG_INPUTS') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxConnections = (state: ConnectionsAndOptionsUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_CONNECTIONS') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxTempsAndSystem = (state: TempsAndSystemUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_TEMPS_SYSTEM') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxPerformance = (state: PerformanceUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_PERFORMANCE') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxEditDrawing = (state: AccessEditingAndDrawingUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_EDIT_DRAWING') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxAccessDoors = (state: AccessDoorsUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_ACCESS_DOORS') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
const reduxCoilConnections = (state: CoilConnectionsUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_COIL_CONNECTIONS') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
const reduxElectFurnacePanels = (state: ElectFurnacePanelsUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_ELECTRICAL_FURNACE_PANELS') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
const reduxLengthInputs = (state: LengthInputsUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_LENGTH_INPUTS') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxHeatwheel = (state: HeatWheelComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_HEATWHEEL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxPlateHeatExchanger = (state: PlateHXComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_PLATEHEATEXCHANGER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
const reduxIDF = (state: IDFComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_IDF') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxChilledWaterCoil = (state: ChilledWaterCoilComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_CHILLEDWATER_COIL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxHotWaterCoil = (state: HotWaterCoilComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_HOTWATER_COIL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxPreHeatHotWaterCoil = (state: HotWaterCoilComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_PREHEATHOTWATER_COIL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxElectricHeater = (state: ElectricHeaterComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_ELECTRICHEATER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxPreHeatElectHeater = (state: ElectricHeaterComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_PREHEAT_ELECTHEATER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxDXCoil = (state: DxCoilComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_DXCOIL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxHotGasReheat = (state: HotGasReheatComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_HOTGAS_REHEAT') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxSteamCoil = (state: SteamCoilComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_STEAMCOIL') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxSupplyFan = (state: FanComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_SUPPLYFAN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxExhaustFan = (state: FanComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_EXHAUSTFAN') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxOutsidePrefilter = (state: FilterComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_OUTSIDE_PREFILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxOutsideFinalfilter = (state: FilterComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_OUTSIDE_FINALFILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxSupplyAirFilter = (state: FilterComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_SUPPLY_FILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxReturnAirFilter = (state: FilterComponentUI = {}, action: IAction) => {
  if (action.type === 'UPDATE_RETURN_FILTER') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxOutsideAirInlet = (state: any = {}, action: IAction) => {
  if (action.type === 'UPDATE_OUTSIDE_INLET') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxSupplyAirOutlet = (state: any = {}, action: IAction) => {
  if (action.type === 'UPDATE_SUPPLY_OUTLET') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxReturnAirInlet = (state: any = {}, action: IAction) => {
  if (action.type === 'UPDATE_RETURN_INLET') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxExhaustAirOutlet = (state: any = {}, action: IAction) => {
  if (action.type === 'UPDATE_EXHAUST_OUTLET') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};

const reduxMenus = (
  state = {
    topMenu: { activeItem: 0 },
    sizingAccordion: { activeId: 'SAOutlet', drawingView: 'Top' },
    showModal: false,
    showTopRightDropdown1: false,
    showTopRightDropdown2: false,
    enableSave: true,
    enableUnitIcons:true,
    closeSetPointsAccordian: false,
    providedAndInstalledByChanged: false,
    optimizedDesignInputChanged: false,
    showPoolInputs: false
  },
  action: IAction
) => {
  if (action.type === 'UPDATE_MENUS') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

const reduxModals = (
  state = { showModal: false, modalType: '', modalId: '', modalData: null, hideCancelBtn: false, userResponse: null },
  action: IAction
) => {
  if (action.type === 'UPDATE_MODALS') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

const reduxOutputDocumentsInfo = (
  state={projectId: null, pdfRecords:null, activeProcessingRecordsCount: 0, pollForUpdates : false, showDocsReadyNotificationIcon: false, isOutputPage: false},
  action: IAction
) => {
  if(action.type === 'UPDATE_ACTIVEOUTPUTDOCUMENTS'){
    return Object.assign({}, state, action.newState);
  }
  return state;
};

const defaultErrors: Array<IModifyPropertyError> = [];
//NOTE on [userEditedFields]: keeps track of fields a user has edited at least once on the current page.
//Its just used to prevent initial field validation errors when you land on a screen the first time.
//This gets reset each time user goes to a new page.
const defaultStringArray: Array<string> = [];
const reduxValidation = (
  state = { modifyPropertyErrors: defaultErrors, userEditedFields: defaultStringArray },
  action: IAction
) => {
  if (action.type === 'UPDATE_VALIDATION') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

const reduxServerErrors = (
  state = { showError: false, errorType: null, errorSource: '', errorMessage: '' },
  action: IAction
) => {
  if (action.type === 'UPDATE_SERVER_ERROR') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

//Toast-like notifications that appear at the bottom of the screen
//Types: success, info, warning, error
const notificationsList: Array<INotification> = [];
const reduxNotifications = (state = { notifications: notificationsList }, action: IAction) => {
  if (action.type === 'UPDATE_NOTIFICATIONS') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

//Container for any special items we need to display
const reduxDisplay = (state = { showLoader: false, unitToDelete:"", headerTitleChanged:false}, action: IAction) => {
  if (action.type === 'UPDATE_DISPLAY') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

//Container for errors that prevent a page from being able to load
const reduxPrerequisiteErrors = (state = { errorList: null }, action: IAction) => {
  if (action.type === 'UPDATE_PREREQUISITE_ERRORS') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};

//Container for pricing at top right of screen
const reduxPricing = (state = { priceNeedsUpdate: true, runPricing: false }, action: IAction) => {
  if (action.type === 'UPDATE_PRICING') {
    return Object.assign({}, state, action.newState);
  }
  return state;
};
const reduxComponentPerformance = (state: any = {}, action: IAction) => {
  if (action.type === 'UPDATE_COMPONENT_PERFORMANCE') {
    return action.newState ? Object.assign({}, state, action.newState) : {};
  }
  return state;
};
export const store = createStore(
  combineReducers({
    reduxSession: reduxSession,
    reduxAppVersion: reduxAppVersion,
    reduxAuth: reduxAuth,
    reduxConfigInputs: reduxConfigInputs,
    reduxConnections: reduxConnections,
    reduxTempsAndSystem: reduxTempsAndSystem,
    reduxPerformance: reduxPerformance,
    reduxEditDrawing: reduxEditDrawing,
    reduxAccessDoors: reduxAccessDoors,
    reduxCoilConnections: reduxCoilConnections,
    reduxElectFurnacePanels: reduxElectFurnacePanels,
    reduxLengthInputs: reduxLengthInputs,
    reduxHeatwheel: reduxHeatwheel,
    reduxPlateHeatExchanger:reduxPlateHeatExchanger,
    reduxIDF: reduxIDF,
    reduxChilledWaterCoil: reduxChilledWaterCoil,
    reduxHotWaterCoil: reduxHotWaterCoil,
    reduxPreHeatHotWaterCoil: reduxPreHeatHotWaterCoil,
    reduxElectricHeater: reduxElectricHeater,
    reduxPreHeatElectHeater: reduxPreHeatElectHeater,
    reduxDXCoil: reduxDXCoil,
    reduxHotGasReheat: reduxHotGasReheat,
    reduxSteamCoil: reduxSteamCoil,
    reduxSupplyFan: reduxSupplyFan,
    reduxExhaustFan: reduxExhaustFan,
    reduxOutsidePrefilter: reduxOutsidePrefilter,
    reduxOutsideFinalfilter: reduxOutsideFinalfilter,
    reduxSupplyAirFilter: reduxSupplyAirFilter,
    reduxReturnAirFilter: reduxReturnAirFilter,
    reduxOutsideAirInlet: reduxOutsideAirInlet,
    reduxSupplyAirOutlet: reduxSupplyAirOutlet,
    reduxReturnAirInlet: reduxReturnAirInlet,
    reduxExhaustAirOutlet: reduxExhaustAirOutlet,
    reduxMenus: reduxMenus,
    reduxModals: reduxModals,
    reduxValidation: reduxValidation,
    reduxServerErrors: reduxServerErrors,
    reduxNotifications: reduxNotifications,
    reduxDisplay: reduxDisplay,
    reduxPrerequisiteErrors: reduxPrerequisiteErrors,
    reduxHeatWheelPanel: reduxHeatWheelPanel,
    reduxHotWaterCoilPanel: reduxHotWaterCoilPanel,
    reduxHotWaterPreheatPanel: reduxHotWaterPreheatPanel,
    reduxChilledWaterCoilPanel: reduxChilledWaterCoilPanel,
    reduxSteamCoilPanel: reduxSteamCoilPanel,
    reduxDXCoilPanel: reduxDXCoilPanel,
    reduxHGRHCoilPanel: reduxHGRHCoilPanel,
    reduxIDFPanel: reduxIDFPanel,
    reduxAccuPanel: reduxAccuPanel,
    reduxOAFilterPanel: reduxOAFilterPanel,
    reduxHEFilterPanel: reduxHEFilterPanel,
    reduxSAFilterPanel: reduxSAFilterPanel,
    reduxRAFilterPanel: reduxRAFilterPanel,
    reduxSupplyFanPanel: reduxSupplyFanPanel,
    reduxExhaustFanPanel: reduxExhaustFanPanel,
    reduxOADamperPanel: reduxOADamperPanel,
    reduxEADamperPanel: reduxEADamperPanel,
    reduxRADamperPanel: reduxRADamperPanel,
    reduxSADamperPanel: reduxSADamperPanel,
    reduxOABypassDamperPanel: reduxOABypassDamperPanel,
    reduxEABypassDamperPanel: reduxEABypassDamperPanel,
    reduxRecircDamperPanel: reduxRecircDamperPanel,
    reduxElectricalSupplyFan: reduxElectricalSupplyFan,
    reduxElectricalExhaustFan: reduxElectricalExhaustFan,
    reduxElectrical: reduxElectrical,
    reduxControls: reduxControls,
    reduxUnitCabinet: reduxUnitCabinet,
    reduxSupplyAttenuator: reduxSupplyAttenuator,
    reduxReturnAttenuator: reduxReturnAttenuator,
    reduxHumidifierManifold: reduxHumidifierManifold,
    reduxProjectManagement:reduxProjectManagement,
    reduxOptimizeDesign: reduxOptimizeDesign,
    reduxOutputDocumentsInfo: reduxOutputDocumentsInfo,
    reduxPricing: reduxPricing,
    reduxComponentPerformance: reduxComponentPerformance
  }),
  getInitialState(),
  composeWithDevTools()
);
