import React from "react";

export function getTooltipContent(fieldName: string, showTooltip: boolean){
    
    //These fields require special html.
    if (fieldName === "waterDesignTemp"){
        return getWaterDesignTempMessage(showTooltip);
    }
    if (fieldName === "poolApplicationType"){
        return getPoolApplicationTypeMessage(showTooltip);
    }
    //Other fields.    
    const toolTipMessage = getTooltipMessage(fieldName);
    
    return (
    <div className={showTooltip ? "pool-tooltip1" : "app-display-none"}>
        <div className="dimensions-tooltip-content">
            {toolTipMessage}
            <br />
        </div>
    </div>
    );
}
function getTooltipMessage(fieldName: string){
 let message = "";
 if (fieldName === "poolDesignType"){
     message = "Used for determining the initial activity factor before accounting for additional activity from pool features.";
 }
 if (fieldName === "poolArea"){
     message = "Used for calculating the pool evaporation rate.";
 }
 if (fieldName === "poolFeatures"){
     message = `Pool features increase the water evaporation rate and must be accounted for using the activity factor.  If you are at 
     all uncertain about whether a pool space includes one of these features or whether the activity factor listed is accurate for the given feature, contact Innovent for assistance.`;
 }
 if (fieldName === "activityFactor"){
    message = `The Activity Factor is a multiplier used to modify the ASHRAE equation for evaporation rate based on the type of pool and water 
    features.  If you are at all uncertain about whether the activity factor listed is accurate for a pool within a pool space, contact Innovent for assistance.`;
 }
 if (fieldName === "dayDewPoint"){
    message = "Recommended to use 0.4% ASHRAE Dehumidification Dewpoint Day.";
 }
 if (fieldName === "spaceVolume"){
    message = "Used for calculating the Supply Air CFM (Supply CFM = Space Volume x ACH).";
 }
 if (fieldName === "airChangesPerHour"){
    message = `ASHRAE recommends 4-6 ACH for recreational pools and 6-8 ACH for competition pools with spectators. Innovent recommends using 6 ACH, 
    if you'd like to design lower than 6 ACH, then please reach out to your RSM.`;
 }
 if (fieldName === "deckArea"){
    message = `Used for calculating outside airflow per sqft to ensure the design stays above the min required.  This is the area of the 
    pool space not including the area of the pools themselves.`;
 }
 if (fieldName === "winterDesignDayDryBulb"){
    message = "Recommended to use 0.4% ASHRAE Heating DB Temperature.";
 }
 if (fieldName === "evapMeanCoincidentDryBulb"){
    message = "Recommended to use 0.4% ASHRAE Evaporation MCDB Temperature.";
 }
 if (fieldName === "poolConstructionType"){
     message = `New applications should always use 6 ACH, if you'd like to design with less ACH then please reach out to your RSM. Retrofit 
     applications allow different ACH values in order to help meet the existing design CFM and corresponding duct work.`;
 }
 if (fieldName === "evapWetBulb"){
    message = "Recommended to use 0.4% ASHRAE Evaporation WB Temperature.";
 }
 if (fieldName === "designDayDryBulb"){
    message = "Recommended to use 0.4% ASHRAE Cooling DB Temperature.";
 }
 if (fieldName === "dryBulbMeanCoincidentWetBulb"){
    message = "Recommended to use 0.4% ASHRAE Cooling MCWB Temperature.";
 }
 if (fieldName === "summerSpaceLoads"){
    message = "These are any additional sensible loads which will be used in the design for cooling components.";
 }
 if (fieldName === "winterSpaceLoads"){
    message = "These are any additional sensible loads which will be used in the design for heating components.  Enter a positive number.";
 }
 if (fieldName === "purgeMode"){
    message = `A high ventilation mode used after superchlorination to more rapidly remove airborne disinfection by-products from the 
    space to keep contaminants from being re-entrained in the water so the pool can be safely re-opened for use.`;
 }
 if (fieldName === "seatsQuantity"){
    message = "ASHRAE requires designing the amount of outside air to include 7.5 CFM per spectator.";
 }
 if (fieldName === "seatingArea"){
    message = "ASHRAE requires designing the amount of outside air to include 0.06 CFM/sq ft of spectator area.";
 }
 if (fieldName === "seatingIncludedInPoolDeckArea"){
    message = `The Innovent unit can be designed to include the spectator area in the design which will increase the design CFM. 
    If not included, then it is assumed the spectator area is being served by a dedicated air handling unit.`;
 }
 if (fieldName === "poolSpaceAirTemperature"){
    message = "It is recommended to have the air temperature 2°F warmer than the pool water so that swimmers don't feel cold when leaving the pool.";
 }
 if (fieldName === "summerIndoorRelativeHumidity"){
    message = `The higher the relative humidity design set point the lower the dehumidification load. ASHRAE recommends maintaining the pool area 
    relative humidity between 50-60% RH for swimmer comfort and preventing organism growth.`;
 }
 if (fieldName === "winterIndoorRelativeHumidity"){
    message = "In winter, the outdoor air is used exclusively for dehumidifying the space and no mechanical cooling is required.";
 }
 return message;
}
function getWaterDesignTempMessage(showTooltip: boolean){
    return (
        <div className={showTooltip ? "pool-tooltip1" : "app-display-none"}>
        <div className="dimensions-tooltip-content">
            ASHRAE guidelines are the following ranges based on Pool Type:
            <ul>
            <li>Competition Pool = 78-80°F</li>
            <li>Recreation Pool = 82-84°F</li>
            <li>Therapy Pool = 90-95°F</li>
            <li>Spa = 100-104°F</li>
            </ul>
        </div>
    </div>
    );
}
function getPoolApplicationTypeMessage(showTooltip: boolean){
    return (
        <div className={showTooltip ? "pool-tooltip1" : "app-display-none"}>
        <div className="dimensions-tooltip-content">
        The default is set to "Standard" when the design dewpoint is above 68°F. 
        If you'd like to design with 100% OA/No Cooling when the design dewpoint is greater than 68°F, then please reach out to your RSM.
            <ul>
            <li>Standard - Cooling coil is required in the unit design</li>
            <li>Arid Climate - No additional dehumidification is required, using min OA is sufficient but still may require a sensible cooling coil</li>
            <li>100% OA/No Cooling - No cooling coil is required, the unit will use up to 100% OA for dehumidification</li>
            </ul>
        </div>
    </div>
    );
}